import { Filter, FilterAlt } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import React from "react";

const FilterButton = ({ handleClick = () => {}, count = 0 }) => {
  return (
    <Button
      variant="contained"
      onClick={handleClick}
      color="secondary"
      sx={{
        width: "20rem",
        height: "4rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        borderRadius: "10px",
        background: "#0099ff",
        fontSize: "1.2rem",
      }}
    >
      Filter
      <FilterAlt />
      <Typography
        sx={{
          padding: "0 5px",
          background: "#fff",
          color: "black",
          borderRadius: "50%",
        }}
      >
        {count}
      </Typography>
    </Button>
  );
};

export default FilterButton;
