import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import CustomLoader from "app/components/common/CustomLoader";
import {
  addIdCardFrame,
  customizeIdCardFrame,
  editIdCardFrame,
  getTotalIdCardFrame,
} from "app/services/org/reporting.service";
import uploadFile from "app/utils/uploadFile";
import { useFormik } from "formik";
import React, { useRef, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import profilePlaceHolder from "assets/media/images/idCard-Images/userImage.png";
import demoLogo from "assets/media/images/NoticeBoard-images/demoLogo.png";
import LocationName from "../components/LocationName";
import moment from "moment";

const CARD_WIDTH = "5.5cm";
const CARD_HEIGHT = "8.6cm";

// const horizontalHeight = "8.56cm";
const horizontalWidth = "8.56cm";
const horizontalHeight = "5.39cm";

// width: 1011, // dimension for card
// height: 638, // dimension for card
// HorizontalStaffIdCard
const TypographyFormik = ({
  formik,
  label,
  value,
  labelFontWeight = "400",
  fontSize = "9px",
  textAling = "left",
}) => {
  return (
    <Typography
      sx={{
        fontSize: `${formik?.values?.fontSize}px`,
        fontWeight: `${formik?.values?.fontWeight}`,
        position: "absolute",
        zIndex: 2,
        textAlign: textAling,
        color: formik?.values?.fontColor ?? "#000000",
        top: `${formik?.values?.y_axis}px` ?? 20,
        left: `${formik?.values?.x_axis}px` ?? 20,
        fontStyle: `${formik?.values?.fontStyle}` ?? "normal",
      }}
    >
      <span
        style={{
          fontSize: fontSize,
          fontWeight: labelFontWeight,
          color: "colors.primary",
        }}
      >
        {label ?? null}
      </span>
      {value}
    </Typography>
  );
};

const StudentTypographyFormik = ({
  formik,
  label,
  value,
  labelFontWeight = "400",
  fontSize = "9px",
  textAling = "left",
  close = false,
}) => {
  const [labelValue, setLabelValue] = useState(value);
  return (
    <Grid container item xs={12} style={{ position: "relative" }}>
      {/* for label */}
      {label && (
        <Grid item xs={close ? 2 : 4} style={{ position: "relative" }}>
          <Typography
            sx={{
              fontSize: `${formik?.values?.fontSize}px`,
              fontWeight: `${formik?.values?.fontWeight}`,
              position: "absolute",
              zIndex: 2,
              width: "100%",
              textAlign: textAling,
              color: formik?.values?.fontColor ?? "#000000",
              top: `${formik?.values?.y_axis}px` ?? 20,
              left: `${formik?.values?.x_axis}px` ?? 20,
              fontStyle: `${formik?.values?.fontStyle}` ?? "normal",
            }}
          >
            <span
              style={{
                fontWeight: labelFontWeight,
                color: "colors.primary",
              }}
            >
              {label ?? null}
            </span>
          </Typography>
        </Grid>
      )}
      {/* for values */}
      <Grid
        item
        xs={close ? 4 : label ? 6 : 12}
        style={{ position: "relative" }}
      >
        <Typography
          sx={{
            fontSize: `${formik?.values?.fontSize}px`,
            fontWeight: `${formik?.values?.fontWeight}`,
            position: "absolute",
            zIndex: 2,
            textAlign: textAling,
            color: formik?.values?.fontColor ?? "#000000",
            top: `${formik?.values?.y_axis}px` ?? 20,
            left: `${formik?.values?.x_axis}px` ?? 20,
            fontStyle: `${formik?.values?.fontStyle}` ?? "normal",
          }}
        >
          {value ?? label}
        </Typography>
      </Grid>
    </Grid>
  );
};

const renderStack = ({
  stateName,
  formik,
  label,
  setState,
  openedCard,
  setOpenedCard,
  state,
}) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1} margin={2}>
      <input
        type="checkbox"
        disabled={state?.backgroundFrame ? false : true}
        checked={state?.[stateName]}
        style={{ cursor: "pointer", width: "20px", height: "20px" }}
        onClick={() => {
          setState({
            ...state,
            [stateName]: !state?.[stateName],
          });
        }}
      />
      <LocationName
        item={label}
        openedCard={openedCard}
        setOpenedCard={setOpenedCard}
        formik={formik}
      />
    </Stack>
  );
};

const generateObject = ({
  stateKey,
  formik,
  labelKeyName,
  shape = "circle",
}) => {
  return stateKey
    ? [
        {
          labelName: formik?.values?.labelName,
          labelKeyName: labelKeyName,
          showOnCard: true,
          x_axis: formik?.values?.x_axis,
          y_axis: formik?.values?.y_axis,
          width: 400, // for Logo
          height: 400, // for Logo
          shape: formik?.values?.shape ?? shape, // for Logo
          fontColor: formik?.values?.fontColor,
          fontWeight: formik?.values?.fontWeight,
          fontSize: formik?.values?.fontSize,
          fontStyle: formik?.values?.fontStyle,
        },
      ]
    : [];
};

const formikValidationSchema = yup.object({
  x_axis: yup.string().required("Required"),
  y_axis: yup.string().required("Required"),
  fontColor: yup.string().optional(""),
  fontWeight: yup.string().required("Required"),
  fontSize: yup.string().required("Required"),
  fontStyle: yup.string().required("Required"),
});

const CustomStaffIdCardPage = () => {
  const selectedSchool = useSelector((state) => state.auth.userDetail);
  const { frameCode } = useParams();
  const [existingFrames, setExistingFrames] = useState();
  const [dimensions, setDimensions] = useState({
    width: 1011, // dimension for card
    height: 638, // dimension for card
  });
  const [loading, setLoading] = useState(false);
  const [openedCard, setOpenedCard] = useState(null);

  const { mutate: addIdCardFrameMutate, isLoading: addIdCardFrameLoading } =
    useMutation(addIdCardFrame, {
      queryKey: ["addIdCardFrame"],
      onSuccess: ({ data }) => {
        toast.success(data?.message);
        // handleClose();
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
      enabled: false,
    });

  const {
    isFetching: getTotalIdCardFrameFetching,
    isLoading: getTotalIdCardFrameLoading,
    data: getTotalIdCardFrame_Data,
    refetch: getTotalIdCardFrameRefetch,
  } = useQuery({
    queryKey: ["getTotalIdCardFrame", frameCode],
    queryFn: () =>
      getTotalIdCardFrame({
        frameCode: String(frameCode).replace(/ /g, "/") ?? "",
      }),
    onSuccess: async ({ data }) => {
      setExistingFrames(data?.obj);
      setState((prev) => ({
        ...prev,
        backgroundFrame: data?.obj?.frameURL,
      }));
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
    enabled: frameCode ? true : false,
  });

  const createFormikConfig = (
    x_axis,
    y_axis,
    fontSize,
    labelName,
    labelKeyName,
    shape,
    fontColor,
    fontWeight,
    fontStyle
  ) => ({
    initialValues: {
      shape: shape ?? "circle",
      labelName: labelName ?? "",
      labelKeyName: labelKeyName ?? "",
      x_axis,
      y_axis,
      fontColor: fontColor ?? "#000",
      fontWeight: fontWeight ?? "500",
      fontSize: fontSize ?? "9",
      fontStyle: fontStyle ?? "normal",
    },
    validationSchema: formikValidationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const findContent = (labelKeyName) =>
    getTotalIdCardFrame_Data?.data?.obj?.contents?.find(
      (content) => content?.labelKeyName === labelKeyName
    );

  /*********************************************************/
  /*************************FOR  STAFF *******************/
  /*********************************************************/

  const staffNameContent = findContent("StaffName");
  const staffDesignationContent = findContent("StaffDesignation");
  const staffMobileNumberContent = findContent("StaffMobileNumber");
  const staffAddressContent = findContent("StaffAddress");
  const staffFatherNameContent = findContent("StaffFatherName");
  const staffMotherNameContent = findContent("StaffMotherName");
  const staffDOBContent = findContent("StaffDOB");
  const staffGenderContent = findContent("StaffGender");
  const staffAadharContent = findContent("StaffAadhar");
  const staffPANContent = findContent("StaffPAN");
  const staffBloodGroupContent = findContent("StaffBloodGroup");
  const staffProfilePicContent = findContent("Profile Pic");
  const departmentContent = findContent("Department");
  const staffIdCardNumberContent = findContent("StaffIdCardNumber");
  const staffEmployeeIdContent = findContent("StaffEmployeeId");
  const staffJoiningDateContent = findContent("StaffJoiningDate");
  const staffEmploymentTypeContent = findContent("StaffEmploymentType");
  const staffPrincipalSignContent = findContent("StaffPrincipalSign");
  const schoolAddressContent = findContent("SchoolAddress");
  const logoContent = findContent("Logo");
  const schoolNameContent = findContent("SchoolName");
  // School Contact Number
  const schoolContactNumberContent = findContent("School Contact Number");

  const [state, setState] = useState({
    horizontalFrame: false,
    verticalFrame: true,
    frameImage: null,
    backgroundFrame: null,
    staffName: staffNameContent ? true : false,
    staffFatherName: staffFatherNameContent ? true : false,
    staffMotherName: staffMotherNameContent ? true : false,
    staffMobileNumber: staffMobileNumberContent ? true : false,
    DOB: staffDOBContent ? true : false,
    Address: staffAddressContent ? true : false,
    PrincipalSign: staffPrincipalSignContent ? true : false,
    SchoolAddress: schoolAddressContent ? true : false,
    IdCardNumber: staffIdCardNumberContent ? true : false,
    logo: logoContent ? true : false,
    profilePic: staffProfilePicContent ? true : false,
    schoolName: schoolNameContent ? true : false,
    joinningDate: staffJoiningDateContent ? true : false,
    gender: staffGenderContent ? true : false,
    bloodGroup: staffBloodGroupContent ? true : false,
    designation: staffDesignationContent ? true : false,
    employmentType: staffEmploymentTypeContent ? true : false,
    PanNo: staffPANContent ? true : false,
    department: departmentContent ? true : false,
    employeeId: staffEmployeeIdContent ? true : false,
    aadhar: staffAadharContent ? true : false,
    schoolContact: schoolContactNumberContent ? true : false,
  });

  const profilePicFormik = useFormik(
    createFormikConfig(
      staffProfilePicContent?.x_axis ?? "76",
      staffProfilePicContent?.y_axis ?? "71",
      staffProfilePicContent?.fontSize ?? "55",
      staffProfilePicContent?.labelName ?? "Profile Pic",
      staffProfilePicContent?.labelKeyName ?? "Profile Pic",
      staffProfilePicContent?.shape ?? "circle",
      staffProfilePicContent?.fontColor ?? "#000",
      staffProfilePicContent?.fontWeight ?? "500",
      staffProfilePicContent?.fontStyle ?? "normal"
    )
  );

  const schoolContactNumberFormik = useFormik(
    createFormikConfig(
      schoolContactNumberContent?.x_axis ?? "76",
      schoolContactNumberContent?.y_axis ?? "60",
      schoolContactNumberContent?.fontSize ?? "20",
      schoolContactNumberContent?.labelName ?? "School Contact Number",
      schoolContactNumberContent?.labelKeyName ?? "School Contact Number",
      schoolContactNumberContent?.shape ?? "circle",
      schoolContactNumberContent?.fontColor ?? "#000",
      schoolContactNumberContent?.fontWeight ?? "500",
      schoolContactNumberContent?.fontStyle ?? "normal"
    )
  );

  const nameFormik = useFormik(
    createFormikConfig(
      staffNameContent?.x_axis ?? "54",
      staffNameContent?.y_axis ?? "129",
      staffNameContent?.fontSize ?? "12",
      staffNameContent?.labelName ?? "Name",
      staffNameContent?.labelKeyName ?? "Name",
      staffNameContent?.shape ?? "circle",
      staffNameContent?.fontColor ?? "#000",
      staffNameContent?.fontWeight ?? "500",
      staffNameContent?.fontStyle ?? "normal"
    )
  );

  const departmentNameFormik = useFormik(
    createFormikConfig(
      departmentContent?.x_axis ?? "54",
      departmentContent?.y_axis ?? "129",
      departmentContent?.fontSize ?? "12",
      departmentContent?.labelName ?? "Department",
      departmentContent?.labelKeyName ?? "Department",
      departmentContent?.shape ?? "circle",
      departmentContent?.fontColor ?? "#000",
      departmentContent?.fontWeight ?? "500",
      departmentContent?.fontStyle ?? "normal"
    )
  );

  const designationFormik = useFormik(
    createFormikConfig(
      staffDesignationContent?.x_axis ?? "54",
      staffDesignationContent?.y_axis ?? "129",
      staffDesignationContent?.fontSize ?? "12",
      staffDesignationContent?.labelName ?? "Designation",
      staffDesignationContent?.labelKeyName ?? "Designation",
      staffDesignationContent?.shape ?? "circle",
      staffDesignationContent?.fontColor ?? "#000",
      staffDesignationContent?.fontWeight ?? "500",
      staffDesignationContent?.fontStyle ?? "normal"
    )
  );

  const fatherNameFormik = useFormik(
    createFormikConfig(
      staffFatherNameContent?.x_axis ?? "54",
      staffFatherNameContent?.y_axis ?? "129",
      staffFatherNameContent?.fontSize ?? "12",
      staffFatherNameContent?.labelName ?? "Father's Name",
      staffFatherNameContent?.labelKeyName ?? "Father's Name",
      staffFatherNameContent?.shape ?? "circle",
      staffFatherNameContent?.fontColor ?? "#000",
      staffFatherNameContent?.fontWeight ?? "500",
      staffFatherNameContent?.fontStyle ?? "normal"
    )
  );

  const motherNameFormik = useFormik(
    createFormikConfig(
      staffMotherNameContent?.x_axis ?? "54",
      staffMotherNameContent?.y_axis ?? "129",
      staffMotherNameContent?.fontSize ?? "12",
      staffMotherNameContent?.labelName ?? "Mother's Name",
      staffMotherNameContent?.labelKeyName ?? "Mother's Name",
      staffMotherNameContent?.shape ?? "circle",
      staffMotherNameContent?.fontColor ?? "#000",
      staffMotherNameContent?.fontWeight ?? "500",
      staffMotherNameContent?.fontStyle ?? "normal"
    )
  );

  const mobileNumberFormik = useFormik(
    createFormikConfig(
      staffMobileNumberContent?.x_axis ?? "54",
      staffMobileNumberContent?.y_axis ?? "129",
      staffMobileNumberContent?.fontSize ?? "12",
      staffMobileNumberContent?.labelName ?? "Mobile Number",
      staffMobileNumberContent?.labelKeyName ?? "Mobile Number",
      staffMobileNumberContent?.shape ?? "circle",
      staffMobileNumberContent?.fontColor ?? "#000",
      staffMobileNumberContent?.fontWeight ?? "500",
      staffMobileNumberContent?.fontStyle ?? "normal"
    )
  );

  const DOBFormik = useFormik(
    createFormikConfig(
      staffDOBContent?.x_axis ?? "54",
      staffDOBContent?.y_axis ?? "129",
      staffDOBContent?.fontSize ?? "12",
      staffDOBContent?.labelName ?? "DOB",
      staffDOBContent?.labelKeyName ?? "DOB",
      staffDOBContent?.shape ?? "circle",
      staffDOBContent?.fontColor ?? "#000",
      staffDOBContent?.fontWeight ?? "500",
      staffDOBContent?.fontStyle ?? "normal"
    )
  );

  const addressFormik = useFormik(
    createFormikConfig(
      staffAddressContent?.x_axis ?? "54",
      staffAddressContent?.y_axis ?? "129",
      staffAddressContent?.fontSize ?? "12",
      staffAddressContent?.labelName ?? "Address",
      staffAddressContent?.labelKeyName ?? "Address",
      staffAddressContent?.shape ?? "circle",
      staffAddressContent?.fontColor ?? "#000",
      staffAddressContent?.fontWeight ?? "500",
      staffAddressContent?.fontStyle ?? "normal"
    )
  );

  const principalSignFormik = useFormik(
    createFormikConfig(
      staffPrincipalSignContent?.x_axis ?? "54",
      staffPrincipalSignContent?.y_axis ?? "129",
      staffPrincipalSignContent?.fontSize ?? "12",
      staffPrincipalSignContent?.labelName ?? "Principal Sign",
      staffPrincipalSignContent?.labelKeyName ?? "Principal Sign",
      staffPrincipalSignContent?.shape ?? "circle",
      staffPrincipalSignContent?.fontColor ?? "#000",
      staffPrincipalSignContent?.fontWeight ?? "500",
      staffPrincipalSignContent?.fontStyle ?? "normal"
    )
  );

  const schoolAddressFormik = useFormik(
    createFormikConfig(
      schoolAddressContent?.x_axis ?? "54",
      schoolAddressContent?.y_axis ?? "129",
      schoolAddressContent?.fontSize ?? "12",
      schoolAddressContent?.labelName ?? "School Address",
      schoolAddressContent?.labelKeyName ?? "School Address",
      schoolAddressContent?.shape ?? "circle",
      schoolAddressContent?.fontColor ?? "#000",
      schoolAddressContent?.fontWeight ?? "500",
      schoolAddressContent?.fontStyle ?? "normal"
    )
  );

  const logoFormik = useFormik(
    createFormikConfig(
      logoContent?.x_axis ?? "54",
      logoContent?.y_axis ?? "129",
      logoContent?.fontSize ?? "12",
      logoContent?.labelName ?? "Logo",
      logoContent?.labelKeyName ?? "Logo",
      logoContent?.shape ?? "circle",
      logoContent?.fontColor ?? "#000",
      logoContent?.fontWeight ?? "500",
      logoContent?.fontStyle ?? "normal"
    )
  );

  const schoolNameFormik = useFormik(
    createFormikConfig(
      schoolNameContent?.x_axis ?? "54",
      schoolNameContent?.y_axis ?? "7",
      schoolNameContent?.fontSize ?? "15",
      schoolNameContent?.labelName ?? "School Name",
      schoolNameContent?.labelKeyName ?? "School Name",
      schoolNameContent?.shape ?? "circle",
      schoolNameContent?.fontColor ?? "#000",
      schoolNameContent?.fontWeight ?? "500",
      schoolNameContent?.fontStyle ?? "normal"
    )
  );

  const idCardNumberFormik = useFormik(
    createFormikConfig(
      staffIdCardNumberContent?.x_axis ?? "54",
      staffIdCardNumberContent?.y_axis ?? "129",
      staffIdCardNumberContent?.fontSize ?? "12",
      staffIdCardNumberContent?.labelName ?? "Id Card Number",
      staffIdCardNumberContent?.labelKeyName ?? "Id Card Number",
      staffIdCardNumberContent?.shape ?? "circle",
      staffIdCardNumberContent?.fontColor ?? "#000",
      staffIdCardNumberContent?.fontWeight ?? "500",
      staffIdCardNumberContent?.fontStyle ?? "normal"
    )
  );

  const employeeIdFormik = useFormik(
    createFormikConfig(
      staffEmployeeIdContent?.x_axis ?? "54",
      staffEmployeeIdContent?.y_axis ?? "129",
      staffEmployeeIdContent?.fontSize ?? "12",
      staffEmployeeIdContent?.labelName ?? "Employee Id",
      staffEmployeeIdContent?.labelKeyName ?? "Employee Id",
      staffEmployeeIdContent?.shape ?? "circle",
      staffEmployeeIdContent?.fontColor ?? "#000",
      staffEmployeeIdContent?.fontWeight ?? "500",
      staffEmployeeIdContent?.fontStyle ?? "normal"
    )
  );

  const joinningDateFormik = useFormik(
    createFormikConfig(
      staffJoiningDateContent?.x_axis ?? "54",
      staffJoiningDateContent?.y_axis ?? "129",
      staffJoiningDateContent?.fontSize ?? "12",
      staffJoiningDateContent?.labelName ?? "Joining Date",
      staffJoiningDateContent?.labelKeyName ?? "Joining Date",
      staffJoiningDateContent?.shape ?? "circle",
      staffJoiningDateContent?.fontColor ?? "#000",
      staffJoiningDateContent?.fontWeight ?? "500",
      staffJoiningDateContent?.fontStyle ?? "normal"
    )
  );

  const employmentTypeFormik = useFormik(
    createFormikConfig(
      staffEmploymentTypeContent?.x_axis ?? "54",
      staffEmploymentTypeContent?.y_axis ?? "129",
      staffEmploymentTypeContent?.fontSize ?? "12",
      staffEmploymentTypeContent?.labelName ?? "Employment Type",
      staffEmploymentTypeContent?.labelKeyName ?? "Employment Type",
      staffEmploymentTypeContent?.shape ?? "circle",
      staffEmploymentTypeContent?.fontColor ?? "#000",
      staffEmploymentTypeContent?.fontWeight ?? "500",
      staffEmploymentTypeContent?.fontStyle ?? "normal"
    )
  );

  const genderFormik = useFormik(
    createFormikConfig(
      staffGenderContent?.x_axis ?? "54",
      staffGenderContent?.y_axis ?? "129",
      staffGenderContent?.fontSize ?? "12",
      staffGenderContent?.label ?? "Gender",
      staffGenderContent?.labelKeyName ?? "Gender",
      staffGenderContent?.shape ?? "circle",
      staffGenderContent?.fontColor ?? "#000",
      staffGenderContent?.fontWeight ?? "500",
      staffGenderContent?.fontStyle ?? "normal"
    )
  );

  const bloodGroupFormik = useFormik(
    createFormikConfig(
      staffBloodGroupContent?.x_axis ?? "54",
      staffBloodGroupContent?.y_axis ?? "129",
      staffBloodGroupContent?.fontSize ?? "12",
      staffBloodGroupContent?.labelName ?? "Blood Group",
      staffBloodGroupContent?.labelKeyName ?? "Blood Group",
      staffBloodGroupContent?.shape ?? "circle",
      staffBloodGroupContent?.fontColor ?? "#000",
      staffBloodGroupContent?.fontWeight ?? "500",
      staffBloodGroupContent?.fontStyle ?? "normal"
    )
  );

  const PanNoFormik = useFormik(
    createFormikConfig(
      staffPANContent?.x_axis ?? "54",
      staffPANContent?.y_axis ?? "129",
      staffPANContent?.fontSize ?? "12",
      staffPANContent?.labelName ?? "PAN No",
      staffPANContent?.labelKeyName ?? "PAN No",
      staffPANContent?.shape ?? "circle",
      staffPANContent?.fontColor ?? "#000",
      staffPANContent?.fontWeight ?? "500",
      staffPANContent?.fontStyle ?? "normal"
    )
  );

  const aadharFormik = useFormik(
    createFormikConfig(
      staffAadharContent?.x_axis ?? "54",
      staffAadharContent?.y_axis ?? "129",
      staffAadharContent?.fontSize ?? "12",
      staffAadharContent?.labelName ?? "Aadhar",
      staffAadharContent?.labelKeyName ?? "Aadhar",
      staffAadharContent?.shape ?? "circle",
      staffAadharContent?.fontColor ?? "#000",
      staffAadharContent?.fontWeight ?? "500",
      staffAadharContent?.fontStyle ?? "normal"
    )
  );

  const [formikLoading, setFormikLoading] = useState(false);

  const handlePayUpload = (e) => {
    let file = e.target.files[0];
    let fileType = file.type;
    let fileSize = file.size;

    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
    if (!allowedTypes.includes(fileType)) {
      toast.error("Invalid file type");
      return;
    }
    if (fileSize > 5 * 1000000) {
      // fileSize > 5MB then show popup message
      toast.error(
        `File size is too large, please upload image of size less than 5MB.\nSelected File Size: ${
          fileSize / 1000000
        }MB only`
      );
      return;
    }
    setState((prev) => ({
      ...prev,
      frameImage: {
        file: e.target.files[0],
        link: URL.createObjectURL(e.target.files[0]),
      },
    }));
  };

  useEffect(() => {
    if (state?.frameImage?.file) {
      handleUploadPayImage();
    }
  }, [state?.frameImage?.file]);

  const handleUploadPayImage = async (values) => {
    try {
      setLoading(true);
      let s3FilePath = `public/${state?.frameImage?.file?.name}`;
      let attachmentUrl = await uploadFile(
        state?.frameImage?.file,
        s3FilePath,
        state?.frameImage?.file?.type
      );
      setState((prev) => ({
        ...prev,
        backgroundFrame: attachmentUrl?.fileURL,
      }));
      setLoading(false);
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  };

  const {
    mutate: customizeIdCardFrameMutate,
    isLoading: customizeIdCardFrameLoading,
  } = useMutation(customizeIdCardFrame, {
    queryKey: ["customizeIdCardFrame"],
    onSuccess: ({ data }) => {
      toast.success(data?.message);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
    enabled: false,
  });
  const { mutate: editIdCardFrameMutate, isLoading: editIdCardFrameLoading } =
    useMutation(editIdCardFrame, {
      queryKey: ["editIdCardFrame"],
      onSuccess: ({ data }) => {
        toast.success(data?.message);
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
      enabled: false,
    });

  const reportTemplateRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => reportTemplateRef.current,
    documentTitle: "Id Card",
    pageStyle: {},
  });

  const fields = [
    { stateKey: state?.logo, formik: logoFormik, labelKeyName: "Logo" },
    {
      stateKey: state?.profilePic,
      formik: profilePicFormik,
      labelKeyName: "Profile Pic",
    },
    {
      stateKey: state?.schoolName,
      formik: schoolNameFormik,
      labelKeyName: "SchoolName",
    },
    {
      stateKey: state?.staffName,
      formik: nameFormik,
      labelKeyName: "StaffName",
    },
    {
      stateKey: state?.staffFatherName,
      formik: fatherNameFormik,
      labelKeyName: "StaffFatherName",
    },
    {
      stateKey: state?.staffMotherName,
      formik: motherNameFormik,
      labelKeyName: "StaffMotherName",
    },
    {
      stateKey: state?.staffMobileNumber,
      formik: mobileNumberFormik,
      labelKeyName: "StaffMobileNumber",
    },
    {
      stateKey: state?.DOB,
      formik: DOBFormik,
      labelKeyName: "StaffDOB",
    },
    {
      stateKey: state?.Address,
      formik: addressFormik,
      labelKeyName: "StaffAddress",
    },
    {
      stateKey: state?.PrincipalSign,
      formik: principalSignFormik,
      labelKeyName: "StaffPrincipalSign",
    },
    {
      stateKey: state?.SchoolAddress,
      formik: schoolAddressFormik,
      labelKeyName: "SchoolAddress",
    },
    {
      stateKey: state?.IdCardNumber,
      formik: idCardNumberFormik,
      labelKeyName: "StaffIdCardNumber",
    },
    {
      stateKey: state?.joinningDate,
      formik: joinningDateFormik,
      labelKeyName: "StaffJoiningDate",
    },
    {
      stateKey: state?.department,
      formik: departmentNameFormik,
      labelKeyName: "Department",
    },
    {
      stateKey: state?.employeeId,
      formik: employeeIdFormik,
      labelKeyName: "StaffEmployeeId",
    },
    {
      stateKey: state?.employmentType,
      formik: employmentTypeFormik,
      labelKeyName: "StaffEmploymentType",
    },
    {
      stateKey: state?.gender,
      formik: genderFormik,
      labelKeyName: "StaffGender",
    },
    {
      stateKey: state?.bloodGroup,
      formik: bloodGroupFormik,
      labelKeyName: "StaffBloodGroup",
    },
    {
      stateKey: state?.PanNo,
      formik: PanNoFormik,
      labelKeyName: "StaffPAN",
    },
    {
      stateKey: state?.aadhar,
      formik: aadharFormik,
      labelKeyName: "StaffAadhar",
    },
    {
      stateKey: state?.designation,
      formik: designationFormik,
      labelKeyName: "StaffDesignation",
    },
    {
      stateKey: state?.staffMobileNumber,
      formik: mobileNumberFormik,
      labelKeyName: "StaffMobileNumber",
    },
    {
      stateKey: state?.schoolContact,
      formik: schoolContactNumberFormik,
      labelKeyName: "School Contact Number",
    },
  ];

  const handleSaveTemplate = () => {
    addIdCardFrameMutate({
      for: "Staff",
      themeColor: "red",
      orientation: state?.verticalFrame ? "portrait" : "landscape",
      width: "200",
      height: "200",
      sizeFor: "Normal",
      frameURL: state?.backgroundFrame,
      contents: fields.flatMap((field) => generateObject(field)),
    });
  };

  const handleCustomize = () => {
    editIdCardFrameMutate({
      for: "Staff",
      idCardFrameDocId: existingFrames?._id,
      themeColor: "red",
      orientation: state?.verticalFrame ? "portrait" : "landscape",
      width: "200",
      height: "200",
      sizeFor: "Normal",
      frameURL: state?.backgroundFrame,
      contents: fields.flatMap((field) => generateObject(field)),
    });
  };

  // useEffect for for the handling of the asynchronous data
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      backgroundFrame: existingFrames?.frameURL,
      staffName: staffNameContent ? true : false,
      staffFatherName: staffFatherNameContent ? true : false,
      staffMotherName: staffMotherNameContent ? true : false,
      staffMobileNumber: staffMobileNumberContent ? true : false,
      DOB: staffDOBContent ? true : false,
      Address: staffAddressContent ? true : false,
      PrincipalSign: staffPrincipalSignContent ? true : false,
      SchoolAddress: schoolAddressContent ? true : false,
      IdCardNumber: staffIdCardNumberContent ? true : false,
      logo: logoContent ? true : false,
      profilePic: staffProfilePicContent ? true : false,
      schoolName: schoolNameContent ? true : false,
      joinningDate: staffJoiningDateContent ? true : false,
      gender: staffGenderContent ? true : false,
      bloodGroup: staffBloodGroupContent ? true : false,
      designation: staffDesignationContent ? true : false,
      employmentType: staffEmploymentTypeContent ? true : false,
      PanNo: staffPANContent ? true : false,
      department: departmentContent ? true : false,
      employeeId: staffEmployeeIdContent ? true : false,
      aadhar: staffAadharContent ? true : false,
      schoolContact: schoolContactNumberContent ? true : false,
    }));
  }, [frameCode, getTotalIdCardFrame_Data]);

  useEffect(() => {
    setFormikLoading(true);
    const formikDataChanger = async () => {
      if (state?.horizontalFrame) {
        await profilePicFormik?.setValues(
          createFormikConfig(
            staffProfilePicContent?.x_axis ?? "76",
            staffProfilePicContent?.y_axis ?? "71",
            staffProfilePicContent?.fontSize ?? "55",
            staffProfilePicContent?.labelName ?? "Profile Pic",
            staffProfilePicContent?.labelKeyName ?? "Profile Pic",
            staffProfilePicContent?.shape ?? "circle",
            staffProfilePicContent?.fontColor ?? "#000",
            staffProfilePicContent?.fontWeight ?? "500",
            staffProfilePicContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await schoolContactNumberFormik?.setValues(
          createFormikConfig(
            schoolContactNumberContent?.x_axis ?? "26",
            schoolContactNumberContent?.y_axis ?? "145",
            schoolContactNumberContent?.fontSize ?? "20",
            schoolContactNumberContent?.labelName ?? "School Contact Number",
            schoolContactNumberContent?.labelKeyName ?? "School Contact Number",
            schoolContactNumberContent?.shape ?? "square",
            schoolContactNumberContent?.fontColor ?? "#000",
            schoolContactNumberContent?.fontWeight ?? "500",
            schoolContactNumberContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await nameFormik?.setValues(
          createFormikConfig(
            staffNameContent?.x_axis ?? "54",
            staffNameContent?.y_axis ?? "129",
            staffNameContent?.fontSize ?? "12",
            staffNameContent?.labelName ?? "Name",
            staffNameContent?.labelKeyName ?? "Name",
            staffNameContent?.shape ?? "circle",
            staffNameContent?.fontColor ?? "#000",
            staffNameContent?.fontWeight ?? "500",
            staffNameContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await departmentNameFormik?.setValues(
          createFormikConfig(
            departmentContent?.x_axis ?? "54",
            departmentContent?.y_axis ?? "129",
            departmentContent?.fontSize ?? "12",
            departmentContent?.labelName ?? "Department",
            departmentContent?.labelKeyName ?? "Department",
            departmentContent?.shape ?? "circle",
            departmentContent?.fontColor ?? "#000",
            departmentContent?.fontWeight ?? "500",
            departmentContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await designationFormik?.setValues(
          createFormikConfig(
            staffDesignationContent?.x_axis ?? "54",
            staffDesignationContent?.y_axis ?? "129",
            staffDesignationContent?.fontSize ?? "12",
            staffDesignationContent?.labelName ?? "Designation",
            staffDesignationContent?.labelKeyName ?? "Designation",
            staffDesignationContent?.shape ?? "circle",
            staffDesignationContent?.fontColor ?? "#000",
            staffDesignationContent?.fontWeight ?? "500",
            staffDesignationContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await fatherNameFormik?.setValues(
          createFormikConfig(
            staffFatherNameContent?.x_axis ?? "54",
            staffFatherNameContent?.y_axis ?? "129",
            staffFatherNameContent?.fontSize ?? "12",
            staffFatherNameContent?.labelName ?? "Father's Name",
            staffFatherNameContent?.labelKeyName ?? "Father's Name",
            staffFatherNameContent?.shape ?? "circle",
            staffFatherNameContent?.fontColor ?? "#000",
            staffFatherNameContent?.fontWeight ?? "500",
            staffFatherNameContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await motherNameFormik?.setValues(
          createFormikConfig(
            staffMotherNameContent?.x_axis ?? "54",
            staffMotherNameContent?.y_axis ?? "129",
            staffMotherNameContent?.fontSize ?? "12",
            staffMotherNameContent?.labelName ?? "Mother's Name",
            staffMotherNameContent?.labelKeyName ?? "Mother's Name",
            staffMotherNameContent?.shape ?? "circle",
            staffMotherNameContent?.fontColor ?? "#000",
            staffMotherNameContent?.fontWeight ?? "500",
            staffMotherNameContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await mobileNumberFormik?.setValues(
          createFormikConfig(
            staffMobileNumberContent?.x_axis ?? "54",
            staffMobileNumberContent?.y_axis ?? "129",
            staffMobileNumberContent?.fontSize ?? "12",
            staffMobileNumberContent?.labelName ?? "Mobile Number",
            staffMobileNumberContent?.labelKeyName ?? "Mobile Number",
            staffMobileNumberContent?.shape ?? "circle",
            staffMobileNumberContent?.fontColor ?? "#000",
            staffMobileNumberContent?.fontWeight ?? "500",
            staffMobileNumberContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await DOBFormik?.setValues(
          createFormikConfig(
            staffDOBContent?.x_axis ?? "54",
            staffDOBContent?.y_axis ?? "129",
            staffDOBContent?.fontSize ?? "12",
            staffDOBContent?.labelName ?? "DOB",
            staffDOBContent?.labelKeyName ?? "DOB",
            staffDOBContent?.shape ?? "circle",
            staffDOBContent?.fontColor ?? "#000",
            staffDOBContent?.fontWeight ?? "500",
            staffDOBContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await addressFormik?.setValues(
          createFormikConfig(
            staffAddressContent?.x_axis ?? "54",
            staffAddressContent?.y_axis ?? "129",
            staffAddressContent?.fontSize ?? "12",
            staffAddressContent?.labelName ?? "Address",
            staffAddressContent?.labelKeyName ?? "Address",
            staffAddressContent?.shape ?? "circle",
            staffAddressContent?.fontColor ?? "#000",
            staffAddressContent?.fontWeight ?? "500",
            staffAddressContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await principalSignFormik?.setValues(
          createFormikConfig(
            staffPrincipalSignContent?.x_axis ?? "54",
            staffPrincipalSignContent?.y_axis ?? "129",
            staffPrincipalSignContent?.fontSize ?? "12",
            staffPrincipalSignContent?.labelName ?? "Principal Sign",
            staffPrincipalSignContent?.labelKeyName ?? "Principal Sign",
            staffPrincipalSignContent?.shape ?? "circle",
            staffPrincipalSignContent?.fontColor ?? "#000",
            staffPrincipalSignContent?.fontWeight ?? "500",
            staffPrincipalSignContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await schoolAddressFormik?.setValues(
          createFormikConfig(
            schoolAddressContent?.x_axis ?? "54",
            schoolAddressContent?.y_axis ?? "129",
            schoolAddressContent?.fontSize ?? "12",
            schoolAddressContent?.labelName ?? "School Address",
            schoolAddressContent?.labelKeyName ?? "School Address",
            schoolAddressContent?.shape ?? "circle",
            schoolAddressContent?.fontColor ?? "#000",
            schoolAddressContent?.fontWeight ?? "500",
            schoolAddressContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await logoFormik?.setValues(
          createFormikConfig(
            logoContent?.x_axis ?? "54",
            logoContent?.y_axis ?? "129",
            logoContent?.fontSize ?? "12",
            logoContent?.labelName ?? "Logo",
            logoContent?.labelKeyName ?? "Logo",
            logoContent?.shape ?? "circle",
            logoContent?.fontColor ?? "#000",
            logoContent?.fontWeight ?? "500",
            logoContent?.fontStyle ?? "normal"
          )?.initialValues
        );

        await schoolNameFormik?.setValues(
          createFormikConfig(
            schoolNameContent?.x_axis ?? "54",
            schoolNameContent?.y_axis ?? "7",
            schoolNameContent?.fontSize ?? "15",
            schoolNameContent?.labelName ?? "School Name",
            schoolNameContent?.labelKeyName ?? "School Name",
            schoolNameContent?.shape ?? "circle",
            schoolNameContent?.fontColor ?? "#000",
            schoolNameContent?.fontWeight ?? "500",
            schoolNameContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await idCardNumberFormik?.setValues(
          createFormikConfig(
            staffIdCardNumberContent?.x_axis ?? "54",
            staffIdCardNumberContent?.y_axis ?? "129",
            staffIdCardNumberContent?.fontSize ?? "12",
            staffIdCardNumberContent?.labelName ?? "Id Card Number",
            staffIdCardNumberContent?.labelKeyName ?? "Id Card Number",
            staffIdCardNumberContent?.shape ?? "circle",
            staffIdCardNumberContent?.fontColor ?? "#000",
            staffIdCardNumberContent?.fontWeight ?? "500",
            staffIdCardNumberContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await employeeIdFormik?.setValues(
          createFormikConfig(
            staffEmployeeIdContent?.x_axis ?? "54",
            staffEmployeeIdContent?.y_axis ?? "129",
            staffEmployeeIdContent?.fontSize ?? "12",
            staffEmployeeIdContent?.labelName ?? "Employee Id",
            staffEmployeeIdContent?.labelKeyName ?? "Employee Id",
            staffEmployeeIdContent?.shape ?? "circle",
            staffEmployeeIdContent?.fontColor ?? "#000",
            staffEmployeeIdContent?.fontWeight ?? "500",
            staffEmployeeIdContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await joinningDateFormik?.setValues(
          createFormikConfig(
            staffJoiningDateContent?.x_axis ?? "54",
            staffJoiningDateContent?.y_axis ?? "129",
            staffJoiningDateContent?.fontSize ?? "12",
            staffJoiningDateContent?.labelName ?? "Joining Date",
            staffJoiningDateContent?.labelKeyName ?? "Joining Date",
            staffJoiningDateContent?.shape ?? "circle",
            staffJoiningDateContent?.fontColor ?? "#000",
            staffJoiningDateContent?.fontWeight ?? "500",
            staffJoiningDateContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await employmentTypeFormik?.setValues(
          createFormikConfig(
            staffEmploymentTypeContent?.x_axis ?? "54",
            staffEmploymentTypeContent?.y_axis ?? "129",
            staffEmploymentTypeContent?.fontSize ?? "12",
            staffEmploymentTypeContent?.labelName ?? "Employment Type",
            staffEmploymentTypeContent?.labelKeyName ?? "Employment Type",
            staffEmploymentTypeContent?.shape ?? "circle",
            staffEmploymentTypeContent?.fontColor ?? "#000",
            staffEmploymentTypeContent?.fontWeight ?? "500",
            staffEmploymentTypeContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await genderFormik?.setValues(
          createFormikConfig(
            staffGenderContent?.x_axis ?? "54",
            staffGenderContent?.y_axis ?? "129",
            staffGenderContent?.fontSize ?? "12",
            staffGenderContent?.labelName ?? "Gender",
            staffGenderContent?.labelKeyName ?? "Gender",
            staffGenderContent?.shape ?? "circle",
            staffGenderContent?.fontColor ?? "#000",
            staffGenderContent?.fontWeight ?? "500",
            staffGenderContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await bloodGroupFormik?.setValues(
          createFormikConfig(
            staffBloodGroupContent?.x_axis ?? "54",
            staffBloodGroupContent?.y_axis ?? "129",
            staffBloodGroupContent?.fontSize ?? "12",
            staffBloodGroupContent?.labelName ?? "Blood Group",
            staffBloodGroupContent?.labelKeyName ?? "Blood Group",
            staffBloodGroupContent?.shape ?? "circle",
            staffBloodGroupContent?.fontColor ?? "#000",
            staffBloodGroupContent?.fontWeight ?? "500",
            staffBloodGroupContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await PanNoFormik?.setValues(
          createFormikConfig(
            staffPANContent?.x_axis ?? "54",
            staffPANContent?.y_axis ?? "129",
            staffPANContent?.fontSize ?? "12",
            staffPANContent?.labelName ?? "PAN No",
            staffPANContent?.labelKeyName ?? "PAN No",
            staffPANContent?.shape ?? "circle",
            staffPANContent?.fontColor ?? "#000",
            staffPANContent?.fontWeight ?? "500",
            staffPANContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await aadharFormik?.setValues(
          createFormikConfig(
            staffAadharContent?.x_axis ?? "54",
            staffAadharContent?.y_axis ?? "129",
            staffAadharContent?.fontSize ?? "12",
            staffAadharContent?.labelName ?? "Aadhar",
            staffAadharContent?.labelKeyName ?? "Aadhar",
            staffAadharContent?.shape ?? "circle",
            staffAadharContent?.fontColor ?? "#000",
            staffAadharContent?.fontWeight ?? "500",
            staffAadharContent?.fontStyle ?? "normal"
          )?.initialValues
        );
      } else {
        await profilePicFormik?.setValues(
          createFormikConfig(
            staffProfilePicContent?.x_axis ?? "76",
            staffProfilePicContent?.y_axis ?? "71",
            staffProfilePicContent?.fontSize ?? "55",
            staffProfilePicContent?.labelName ?? "Profile Pic",
            staffProfilePicContent?.labelKeyName ?? "Profile Pic",
            staffProfilePicContent?.shape ?? "circle",
            staffProfilePicContent?.fontColor ?? "#000",
            staffProfilePicContent?.fontWeight ?? "500",
            staffProfilePicContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await nameFormik?.setValues(
          createFormikConfig(
            staffNameContent?.x_axis ?? "54",
            staffNameContent?.y_axis ?? "129",
            staffNameContent?.fontSize ?? "12",
            staffNameContent?.labelName ?? "Name",
            staffNameContent?.labelKeyName ?? "Name",
            staffNameContent?.shape ?? "circle",
            staffNameContent?.fontColor ?? "#000",
            staffNameContent?.fontWeight ?? "500",
            staffNameContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await departmentNameFormik?.setValues(
          createFormikConfig(
            departmentContent?.x_axis ?? "54",
            departmentContent?.y_axis ?? "129",
            departmentContent?.fontSize ?? "12",
            departmentContent?.labelName ?? "Department",
            departmentContent?.labelKeyName ?? "Department",
            departmentContent?.shape ?? "circle",
            departmentContent?.fontColor ?? "#000",
            departmentContent?.fontWeight ?? "500",
            departmentContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await designationFormik?.setValues(
          createFormikConfig(
            staffDesignationContent?.x_axis ?? "54",
            staffDesignationContent?.y_axis ?? "129",
            staffDesignationContent?.fontSize ?? "12",
            staffDesignationContent?.labelName ?? "Designation",
            staffDesignationContent?.labelKeyName ?? "Designation",
            staffDesignationContent?.shape ?? "circle",
            staffDesignationContent?.fontColor ?? "#000",
            staffDesignationContent?.fontWeight ?? "500",
            staffDesignationContent?.fontStyle ?? "normal"
          )?.initialValues
        );

        await fatherNameFormik?.setValues(
          createFormikConfig(
            staffFatherNameContent?.x_axis ?? "54",
            staffFatherNameContent?.y_axis ?? "129",
            staffFatherNameContent?.fontSize ?? "12",
            staffFatherNameContent?.labelName ?? "Father's Name",
            staffFatherNameContent?.labelKeyName ?? "Father's Name",
            staffFatherNameContent?.shape ?? "circle",
            staffFatherNameContent?.fontColor ?? "#000",
            staffFatherNameContent?.fontWeight ?? "500",
            staffFatherNameContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await motherNameFormik?.setValues(
          createFormikConfig(
            staffMotherNameContent?.x_axis ?? "54",
            staffMotherNameContent?.y_axis ?? "129",
            staffMotherNameContent?.fontSize ?? "12",
            staffMotherNameContent?.labelName ?? "Mother's Name",
            staffMotherNameContent?.labelKeyName ?? "Mother's Name",
            staffMotherNameContent?.shape ?? "circle",
            staffMotherNameContent?.fontColor ?? "#000",
            staffMotherNameContent?.fontWeight ?? "500",
            staffMotherNameContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await mobileNumberFormik?.setValues(
          createFormikConfig(
            staffMobileNumberContent?.x_axis ?? "54",
            staffMobileNumberContent?.y_axis ?? "129",
            staffMobileNumberContent?.fontSize ?? "12",
            staffMobileNumberContent?.labelName ?? "Mobile Number",
            staffMobileNumberContent?.labelKeyName ?? "Mobile Number",
            staffMobileNumberContent?.shape ?? "circle",
            staffMobileNumberContent?.fontColor ?? "#000",
            staffMobileNumberContent?.fontWeight ?? "500",
            staffMobileNumberContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await DOBFormik?.setValues(
          createFormikConfig(
            staffDOBContent?.x_axis ?? "54",
            staffDOBContent?.y_axis ?? "129",
            staffDOBContent?.fontSize ?? "12",
            staffDOBContent?.labelName ?? "DOB",
            staffDOBContent?.labelKeyName ?? "DOB",
            staffDOBContent?.shape ?? "circle",
            staffDOBContent?.fontColor ?? "#000",
            staffDOBContent?.fontWeight ?? "500",
            staffDOBContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await addressFormik?.setValues(
          createFormikConfig(
            staffAddressContent?.x_axis ?? "54",
            staffAddressContent?.y_axis ?? "129",
            staffAddressContent?.fontSize ?? "12",
            staffAddressContent?.labelName ?? "Address",
            staffAddressContent?.labelKeyName ?? "Address",
            staffAddressContent?.shape ?? "circle",
            staffAddressContent?.fontColor ?? "#000",
            staffAddressContent?.fontWeight ?? "500",
            staffAddressContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await principalSignFormik?.setValues(
          createFormikConfig(
            staffPrincipalSignContent?.x_axis ?? "54",
            staffPrincipalSignContent?.y_axis ?? "129",
            staffPrincipalSignContent?.fontSize ?? "12",
            staffPrincipalSignContent?.labelName ?? "Principal Sign",
            staffPrincipalSignContent?.labelKeyName ?? "Principal Sign",
            staffPrincipalSignContent?.shape ?? "circle",
            staffPrincipalSignContent?.fontColor ?? "#000",
            staffPrincipalSignContent?.fontWeight ?? "500",
            staffPrincipalSignContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await schoolAddressFormik?.setValues(
          createFormikConfig(
            schoolAddressContent?.x_axis ?? "54",
            schoolAddressContent?.y_axis ?? "129",
            schoolAddressContent?.fontSize ?? "12",
            schoolAddressContent?.labelName ?? "School Address",
            schoolAddressContent?.labelKeyName ?? "School Address",
            schoolAddressContent?.shape ?? "circle",
            schoolAddressContent?.fontColor ?? "#000",
            schoolAddressContent?.fontWeight ?? "500",
            schoolAddressContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await logoFormik?.setValues(
          createFormikConfig(
            logoContent?.x_axis ?? "54",
            logoContent?.y_axis ?? "129",
            logoContent?.fontSize ?? "12",
            logoContent?.labelName ?? "Logo",
            logoContent?.labelKeyName ?? "Logo",
            logoContent?.shape ?? "circle",
            logoContent?.fontColor ?? "#000",
            logoContent?.fontWeight ?? "500",
            logoContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await schoolNameFormik?.setValues(
          createFormikConfig(
            schoolNameContent?.x_axis ?? "54",
            schoolNameContent?.y_axis ?? "7",
            schoolNameContent?.fontSize ?? "15",
            schoolNameContent?.labelName ?? "School Name",
            schoolNameContent?.labelKeyName ?? "School Name",
            schoolNameContent?.shape ?? "circle",
            schoolNameContent?.fontColor ?? "#000",
            schoolNameContent?.fontWeight ?? "500",
            schoolNameContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await idCardNumberFormik?.setValues(
          createFormikConfig(
            staffIdCardNumberContent?.x_axis ?? "54",
            staffIdCardNumberContent?.y_axis ?? "129",
            staffIdCardNumberContent?.fontSize ?? "12",
            staffIdCardNumberContent?.labelName ?? "Id Card Number",
            staffIdCardNumberContent?.labelKeyName ?? "Id Card Number",
            staffIdCardNumberContent?.shape ?? "circle",
            staffIdCardNumberContent?.fontColor ?? "#000",
            staffIdCardNumberContent?.fontWeight ?? "500",
            staffIdCardNumberContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await employeeIdFormik?.setValues(
          createFormikConfig(
            staffEmployeeIdContent?.x_axis ?? "54",
            staffEmployeeIdContent?.y_axis ?? "129",
            staffEmployeeIdContent?.fontSize ?? "12",
            staffEmployeeIdContent?.labelName ?? "Employee Id",
            staffEmployeeIdContent?.labelKeyName ?? "Employee Id",
            staffEmployeeIdContent?.shape ?? "circle",
            staffEmployeeIdContent?.fontColor ?? "#000",
            staffEmployeeIdContent?.fontWeight ?? "500",
            staffEmployeeIdContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await joinningDateFormik?.setValues(
          createFormikConfig(
            staffJoiningDateContent?.x_axis ?? "54",
            staffJoiningDateContent?.y_axis ?? "129",
            staffJoiningDateContent?.fontSize ?? "12",
            staffJoiningDateContent?.labelName ?? "Joining Date",
            staffJoiningDateContent?.labelKeyName ?? "Joining Date",
            staffJoiningDateContent?.shape ?? "circle",
            staffJoiningDateContent?.fontColor ?? "#000",
            staffJoiningDateContent?.fontWeight ?? "500",
            staffJoiningDateContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await employmentTypeFormik?.setValues(
          createFormikConfig(
            staffEmploymentTypeContent?.x_axis ?? "54",
            staffEmploymentTypeContent?.y_axis ?? "129",
            staffEmploymentTypeContent?.fontSize ?? "12",
            staffEmploymentTypeContent?.labelName ?? "Employment Type",
            staffEmploymentTypeContent?.labelKeyName ?? "Employment Type",
            staffEmploymentTypeContent?.shape ?? "circle",
            staffEmploymentTypeContent?.fontColor ?? "#000",
            staffEmploymentTypeContent?.fontWeight ?? "500",
            staffEmploymentTypeContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await genderFormik?.setValues(
          createFormikConfig(
            staffGenderContent?.x_axis ?? "54",
            staffGenderContent?.y_axis ?? "129",
            staffGenderContent?.fontSize ?? "12",
            staffGenderContent?.labelName ?? "Gender",
            staffGenderContent?.labelKeyName ?? "Gender",
            staffGenderContent?.shape ?? "circle",
            staffGenderContent?.fontColor ?? "#000",
            staffGenderContent?.fontWeight ?? "500",
            staffGenderContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await bloodGroupFormik?.setValues(
          createFormikConfig(
            staffBloodGroupContent?.x_axis ?? "54",
            staffBloodGroupContent?.y_axis ?? "129",
            staffBloodGroupContent?.fontSize ?? "12",
            staffBloodGroupContent?.labelName ?? "Blood Group",
            staffBloodGroupContent?.labelKeyName ?? "Blood Group",
            staffBloodGroupContent?.shape ?? "circle",
            staffBloodGroupContent?.fontColor ?? "#000",
            staffBloodGroupContent?.fontWeight ?? "500",
            staffBloodGroupContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await PanNoFormik?.setValues(
          createFormikConfig(
            staffPANContent?.x_axis ?? "54",
            staffPANContent?.y_axis ?? "129",
            staffPANContent?.fontSize ?? "12",
            staffPANContent?.labelName ?? "PAN No",
            staffPANContent?.labelKeyName ?? "PAN No",
            staffPANContent?.shape ?? "circle",
            staffPANContent?.fontColor ?? "#000",
            staffPANContent?.fontWeight ?? "500",
            staffPANContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await aadharFormik?.setValues(
          createFormikConfig(
            staffAadharContent?.x_axis ?? "54",
            staffAadharContent?.y_axis ?? "129",
            staffAadharContent?.fontSize ?? "12",
            staffAadharContent?.labelName ?? "Aadhar",
            staffAadharContent?.labelKeyName ?? "Aadhar",
            staffAadharContent?.shape ?? "circle",
            staffAadharContent?.fontColor ?? "#000",
            staffAadharContent?.fontWeight ?? "500",
            staffAadharContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await idCardNumberFormik?.setValues(
          createFormikConfig(
            staffIdCardNumberContent?.x_axis ?? "54",
            staffIdCardNumberContent?.y_axis ?? "129",
            staffIdCardNumberContent?.fontSize ?? "12",
            staffIdCardNumberContent?.labelName ?? "Id Card Number",
            staffIdCardNumberContent?.labelKeyName ?? "Id Card Number",
            staffIdCardNumberContent?.shape ?? "circle",
            staffIdCardNumberContent?.fontColor ?? "#000",
            staffIdCardNumberContent?.fontWeight ?? "500",
            staffIdCardNumberContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await employeeIdFormik?.setValues(
          createFormikConfig(
            staffEmployeeIdContent?.x_axis ?? "54",
            staffEmployeeIdContent?.y_axis ?? "129",
            staffEmployeeIdContent?.fontSize ?? "12",
            staffEmployeeIdContent?.labelName ?? "Employee Id",
            staffEmployeeIdContent?.labelKeyName ?? "Employee Id",
            staffEmployeeIdContent?.shape ?? "circle",
            staffEmployeeIdContent?.fontColor ?? "#000",
            staffEmployeeIdContent?.fontWeight ?? "500",
            staffEmployeeIdContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await joinningDateFormik?.setValues(
          createFormikConfig(
            staffJoiningDateContent?.x_axis ?? "54",
            staffJoiningDateContent?.y_axis ?? "129",
            staffJoiningDateContent?.fontSize ?? "12",
            staffJoiningDateContent?.labelName ?? "Joining Date",
            staffJoiningDateContent?.labelKeyName ?? "Joining Date",
            staffJoiningDateContent?.shape ?? "circle",
            staffJoiningDateContent?.fontColor ?? "#000",
            staffJoiningDateContent?.fontWeight ?? "500",
            staffJoiningDateContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await employmentTypeFormik?.setValues(
          createFormikConfig(
            staffEmploymentTypeContent?.x_axis ?? "54",
            staffEmploymentTypeContent?.y_axis ?? "129",
            staffEmploymentTypeContent?.fontSize ?? "12",
            staffEmploymentTypeContent?.labelName ?? "Employment Type",
            staffEmploymentTypeContent?.labelKeyName ?? "Employment Type",
            staffEmploymentTypeContent?.shape ?? "circle",
            staffEmploymentTypeContent?.fontColor ?? "#000",
            staffEmploymentTypeContent?.fontWeight ?? "500",
            staffEmploymentTypeContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await schoolContactNumberFormik?.setValues(
          createFormikConfig(
            schoolContactNumberContent?.x_axis ?? "60",
            schoolContactNumberContent?.y_axis ?? "71",
            schoolContactNumberContent?.fontSize ?? "20",
            schoolContactNumberContent?.labelName ?? "School Contact Number",
            schoolContactNumberContent?.labelKeyName ?? "School Contact Number",
            schoolContactNumberContent?.shape ?? "circle",
            schoolContactNumberContent?.fontColor ?? "#000",
            schoolContactNumberContent?.fontWeight ?? "500",
            schoolContactNumberContent?.fontStyle ?? "normal"
          )?.initialValues
        );
      }
    };
    formikDataChanger();
    setFormikLoading(false);
  }, [state?.horizontalFrame, state?.verticalFrame, frameCode]);

  const DetailsOnCard = () => {
    return (
      <>
        {/* school name */}
        {state?.schoolName && (
          <TypographyFormik
            formik={schoolNameFormik}
            value={"Ekalsutra Edtech Pvt Ltd"}
            fontSize="20px"
            textAling="center"
          />
        )}
        {/* adress */}
        {state?.SchoolAddress && (
          <TypographyFormik
            formik={schoolAddressFormik}
            label="School Address: "
            value={`${selectedSchool?.schoolAddress?.address ?? "Address"}, ${
              selectedSchool?.schoolAddress?.dist ?? "District"
            }, ${selectedSchool?.schoolAddress?.state ?? "State"}, ${
              selectedSchool?.schoolAddress?.pinCode ?? "Pincode"
            }`}
          />
        )}
        {/* name */}
        {state?.staffName && (
          <StudentTypographyFormik
            formik={nameFormik}
            labelName={nameFormik?.values?.labelName ?? "Staff Name"}
            textAling="center"
            value="Staff Name"
          />
        )}
        {/* father name */}
        {state?.staffFatherName && (
          <StudentTypographyFormik
            formik={fatherNameFormik}
            label={fatherNameFormik?.values?.labelName ?? "F Name"}
            textAling="center"
            value={"Father's Name"}
          />
        )}
        {/* mother name */}
        {state?.staffMotherName && (
          <StudentTypographyFormik
            formik={motherNameFormik}
            label={motherNameFormik?.values?.labelName ?? "F Name"}
            textAling="center"
            value={"Mother's Name"}
          />
        )}
        {/* mobile number */}
        {state?.staffMobileNumber && (
          <StudentTypographyFormik
            formik={mobileNumberFormik}
            value={mobileNumberFormik?.values?.labelName ?? "Mob No."}
            textAling="center"
            label={"Mobile Number"}
          />
        )}
        {/* dob  */}
        {state?.DOB && (
          <StudentTypographyFormik
            formik={DOBFormik}
            label={DOBFormik?.values?.labelName ?? "DOB"}
            value={"12/12/2021"}
            textAling="center"
          />
        )}
        {/* Address  */}
        {state?.Address && (
          <StudentTypographyFormik
            formik={addressFormik}
            label={addressFormik?.values?.labelName ?? "Address"}
            value={"Address"}
            textAling="center"
          />
        )}

        {/* Idcard number*/}
        {state?.IdCardNumber && (
          <StudentTypographyFormik
            formik={idCardNumberFormik}
            label={idCardNumberFormik?.values?.labelName ?? "ID:"}
            textAling="center"
            value={"123456"}
            close={true}
          />
        )}
        {/* joining date*/}
        {state?.joinningDate && (
          <StudentTypographyFormik
            formik={joinningDateFormik}
            label={joinningDateFormik?.values?.labelName ?? "Joining Date"}
            textAling="center"
            value={"12/12/2021"}
          />
        )}
        {/* gender*/}
        {state?.gender && (
          <StudentTypographyFormik
            formik={genderFormik}
            label={genderFormik?.values?.labelName ?? "Gender"}
            textAling="center"
            value={"Gender"}
          />
        )}
        {/* bloodGroup*/}
        {state?.bloodGroup && (
          <StudentTypographyFormik
            formik={bloodGroupFormik}
            label={bloodGroupFormik?.values?.labelName ?? "Blood Group"}
            textAling="center"
            value={"B+"}
          />
        )}
        {/* employement*/}
        {state?.employmentType && (
          <StudentTypographyFormik
            formik={employmentTypeFormik}
            label={employmentTypeFormik?.values?.labelName ?? "Emp Type"}
            textAling="center"
            value={"Permanent"}
          />
        )}
        {/* pan no*/}
        {state?.PanNo && (
          <StudentTypographyFormik
            formik={PanNoFormik}
            label={PanNoFormik?.values?.labelName ?? "PAN No"}
            textAling="center"
            value={"PAN No"}
          />
        )}
        {/* department name*/}
        {state?.department && (
          <StudentTypographyFormik
            formik={departmentNameFormik}
            label={departmentNameFormik?.values?.labelName ?? "Department"}
            textAling="center"
            value={"Department"}
          />
        )}
        {/* department name*/}
        {state?.aadhar && (
          <StudentTypographyFormik
            formik={aadharFormik}
            label={aadharFormik?.values?.labelName ?? "Aadhar"}
            textAling="center"
            value={"Aadhar"}
          />
        )}
        {/* employye id*/}
        {state?.employeeId && (
          <StudentTypographyFormik
            formik={employeeIdFormik}
            label={employeeIdFormik?.values?.labelName ?? "Emp Id"}
            textAling="center"
            value={"Emp Id"}
          />
        )}
        {/* designation id*/}
        {state?.designation && (
          <StudentTypographyFormik
            formik={designationFormik}
            label={designationFormik?.values?.labelName ?? "Designation"}
            textAling="center"
            value={"Designation"}
          />
        )}
        {/* principale sign*/}
        {state?.PrincipalSign && (
          <StudentTypographyFormik
            formik={principalSignFormik}
            label={principalSignFormik?.values?.labelName ?? "Principal Sign"}
            textAling="center"
            value={"Principal Sign"}
          />
        )}
        {state?.schoolContact && (
          <StudentTypographyFormik
            formik={schoolContactNumberFormik}
            value={selectedSchool?.mobileNumber ?? "1234567890"}
            label={
              schoolContactNumberFormik?.values?.labelName ??
              "School Contact Number: "
            }
            close={true}
          />
        )}
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Custom Staff Id Card</title>
      </Helmet>

      <CustomLoader
        show={
          getTotalIdCardFrameFetching ||
          getTotalIdCardFrameLoading ||
          loading ||
          formikLoading ||
          addIdCardFrameLoading
        }
      />

      {/* two check box for the horizontal and vertifical fram */}
      <Stack direction="row" alignItems="center" spacing={10}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <input
            type="checkbox"
            checked={state?.horizontalFrame}
            style={{ cursor: "pointer", width: "20px", height: "20px" }}
            onClick={() => {
              setState({
                ...state,
                horizontalFrame: !state?.horizontalFrame,
                verticalFrame: false,
              });
            }}
          />
          <Typography>Horizontal Frame</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <input
            type="checkbox"
            checked={state?.verticalFrame}
            style={{ cursor: "pointer", width: "20px", height: "20px" }}
            onClick={() => {
              setState({
                ...state,
                horizontalFrame: false,
                verticalFrame: true,
              });
            }}
          />
          <Typography>Veritcal Frame</Typography>
        </Stack>
      </Stack>
      <CustomLoader show={loading || addIdCardFrameLoading} />
      <Grid container>
        {/* for showing images */}
        <Grid item xs={4}>
          {state?.horizontalFrame && (
            <Grid
              item
              xs={12}
              sx={{}}
              md={6}
              ref={reportTemplateRef}
              margin={5}
            >
              <Box
                sx={{
                  height: horizontalHeight,
                  width: horizontalWidth,
                  // height: dimensions?.height ?? CARD_HEIGHT,
                  // width: dimensions?.width ?? CARD_WIDTH,
                  alignSelf: "center",

                  backgroundImage: `url(${state?.backgroundFrame ?? ""})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  position: "absolute",
                  zIndex: 1,
                }}
              >
                {/* school logo */}
                {state?.logo && (
                  <Typography
                    sx={{
                      position: "absolute",
                      zIndex: 1,
                      top: `${logoFormik?.values?.y_axis}px` ?? 0,
                      left: `${logoFormik?.values?.x_axis}px` ?? 0,
                    }}
                  >
                    <img
                      style={{
                        height: `100%`,
                        width: `${logoFormik?.values?.fontSize}px`,
                        color: logoFormik?.values?.fontColor ?? "#000000",
                        fontWeight: logoFormik?.values?.fontWeight,
                        fontStyle: logoFormik?.values?.fontStyle ?? "normal",
                        borderRadius:
                          logoFormik?.values?.shape == "circle" ? "50%" : "0%",
                        objectFit: "contain",
                      }}
                      src={demoLogo}
                    />
                  </Typography>
                )}

                {/* profile pic */}
                {state?.profilePic && (
                  <Box
                    style={{
                      position: "absolute",
                      zIndex: 1,
                      top: `${profilePicFormik?.values?.y_axis}px` ?? 0,
                      left: `${profilePicFormik?.values?.x_axis}px` ?? 0,
                      height: `${profilePicFormik?.values?.fontSize}px`,
                      width: `${profilePicFormik?.values?.fontSize}px`,
                      borderRadius:
                        profilePicFormik?.values?.shape == "circle"
                          ? "50%"
                          : "0%",
                      objectFit: "contain",
                      backgroundColor: "#00CC8C10",
                      borderWidth: 1,
                    }}
                  >
                    <img
                      src={profilePlaceHolder}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                        alignSelf: "center",
                        borderRadius:
                          profilePicFormik?.values?.shape == "circle"
                            ? "50%"
                            : "0%",
                        overflow: "hidden",
                      }}
                      alt="profile-pic"
                    />
                  </Box>
                )}

                <DetailsOnCard />
              </Box>
            </Grid>
          )}

          {state?.verticalFrame && (
            <Grid
              item
              xs={12}
              sx={{}}
              md={6}
              ref={reportTemplateRef}
              margin={5}
            >
              <Box
                sx={{
                  height: CARD_HEIGHT,
                  width: CARD_WIDTH,
                  alignSelf: "center",
                  backgroundImage: `url(${state?.backgroundFrame ?? ""})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  position: "absolute",
                  zIndex: 1,
                }}
              >
                {/* school logo */}
                {state?.logo && (
                  <Typography
                    sx={{
                      position: "absolute",
                      zIndex: 1,
                      top: `${logoFormik?.values?.y_axis}px` ?? 0,
                      left: `${logoFormik?.values?.x_axis}px` ?? 0,
                    }}
                  >
                    <img
                      style={{
                        height: `100%`,
                        width: `${logoFormik?.values?.fontSize}px`,
                        color: logoFormik?.values?.fontColor ?? "#000000",
                        fontWeight: logoFormik?.values?.fontWeight,
                        fontStyle: logoFormik?.values?.fontStyle ?? "normal",
                        borderRadius:
                          logoFormik?.values?.shape == "circle" ? "50%" : "0%",
                        objectFit: "contain",
                      }}
                      src={demoLogo}
                    />
                  </Typography>
                )}

                {/* profile pic */}
                {state?.profilePic && (
                  <Box
                    style={{
                      position: "absolute",
                      zIndex: 1,
                      top: `${profilePicFormik?.values?.y_axis}px` ?? 0,
                      left: `${profilePicFormik?.values?.x_axis}px` ?? 0,
                      height: `${profilePicFormik?.values?.fontSize}px`,
                      width: `${profilePicFormik?.values?.fontSize}px`,
                      borderRadius:
                        profilePicFormik?.values?.shape == "circle"
                          ? "50%"
                          : "0%",
                      objectFit: "contain",
                      backgroundColor: "#00CC8C10",
                      borderWidth: 1,
                    }}
                  >
                    <img
                      src={profilePlaceHolder}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                        alignSelf: "center",
                        borderRadius:
                          profilePicFormik?.values?.shape == "circle"
                            ? "50%"
                            : "0%",
                        overflow: "hidden",
                      }}
                      alt="profile-pic"
                    />
                  </Box>
                )}

                <DetailsOnCard />
              </Box>
            </Grid>
          )}
        </Grid>

        {/* for giving of list of items */}
        <Grid
          item
          xs={8}
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* uplaod button for frame */}
          <Stack direction="row" alignItems="center" spacing={4}>
            <Box>
              <Button
                variant="contained"
                color="secondary"
                component="label"
                style={{ margin: "10px" }}
              >
                <input
                  type="file"
                  accept="image/*,application/pdf"
                  hidden
                  onChange={handlePayUpload}
                />
                Upload
              </Button>
            </Box>
            <Box>
              <Button
                disabled={state?.backgroundFrame ? false : true}
                variant="contained"
                color="secondary"
                component="label"
                onClick={handlePrint}
                style={{ margin: "10px" }}
              >
                Print
              </Button>
            </Box>
            <Box>
              <Button
                disabled={state?.backgroundFrame ? false : true}
                variant="contained"
                color="secondary"
                component="label"
                onClick={() => {
                  if (frameCode) {
                    handleCustomize();
                  } else {
                    handleSaveTemplate();
                  }
                }}
                style={{ margin: "10px" }}
              >
                Save
              </Button>
            </Box>
            <Box>
              <Button
                disabled={state?.backgroundFrame ? false : true}
                variant="contained"
                color="secondary"
                component="label"
                onClick={() => {
                  setState({
                    ...state,
                    frameImage: null,
                    backgroundFrame: null,
                  });
                }}
                style={{ margin: "10px" }}
              >
                Remove Frame
              </Button>
            </Box>
            {/*  <Box>
              <CustomTextField
                label={"Height"}
                value={dimensions?.height}
                type="number"
                onChange={(e) => {
                  setDimensions({ ...dimensions, height: e.target.value });
                }}
              />
            </Box>
            <Box>
              <CustomTextField
                label={"Width"}
                type="number"
                value={dimensions?.width}
                onChange={(e) => {
                  setDimensions({ ...dimensions, width: e.target.value });
                }}
              />
            </Box> */}
          </Stack>
          {/* map the states for showing checkbox with the label */}
          {/* for logo */}
          <Stack direction="row" alignItems="center" spacing={1} margin={2}>
            <input
              disabled={state?.backgroundFrame ? false : true}
              type="checkbox"
              checked={state?.logo}
              style={{ cursor: "pointer", width: "20px", height: "20px" }}
              onClick={() => {
                setState({
                  ...state,
                  logo: !state?.logo,
                });
              }}
            />
            <LocationName
              item={"logo"}
              showShape={true}
              openedCard={openedCard}
              setOpenedCard={setOpenedCard}
              formik={logoFormik}
              showColor={false}
              showFontWeight={false}
              showFontStyle={false}
              disabledLabel={true}
            />
          </Stack>
          {/* for profile pic */}
          <Stack direction="row" alignItems="center" spacing={1} margin={2}>
            <input
              disabled={state?.backgroundFrame ? false : true}
              type="checkbox"
              checked={state?.profilePic}
              style={{ cursor: "pointer", width: "20px", height: "20px" }}
              onClick={() => {
                setState({
                  ...state,
                  profilePic: !state?.profilePic,
                });
              }}
            />
            <LocationName
              item={"profilePic"}
              openedCard={openedCard}
              setOpenedCard={setOpenedCard}
              formik={profilePicFormik}
              showShape={true}
              disabledLabel={true}
              showFontWeight={false}
              showFontStyle={false}
              showColor={false}
            />
          </Stack>
          {/* text based cards */}
          {[
            {
              state: "staffName",
              formik: nameFormik,
              label: "name",
            },
            {
              state: "schoolName",
              formik: schoolNameFormik,
              label: "School Name",
            },
            {
              state: "staffFatherName",
              formik: fatherNameFormik,
              label: "fatherName",
            },
            {
              state: "staffMotherName",
              formik: motherNameFormik,
              label: "motherName",
            },
            {
              state: "DOB",
              formik: DOBFormik,
              label: "DOB",
            },
            {
              state: "staffMobileNumber",
              formik: mobileNumberFormik,
              label: "phone",
            },
            {
              state: "Address",
              formik: addressFormik,
              label: "Address",
            },
            {
              state: "PrincipalSign",
              formik: principalSignFormik,
              label: "PrincipalSign",
              value: "Principal Sign",
            },

            {
              state: "SchoolAddress",
              formik: schoolAddressFormik,
              label: "SchoolAddress",
            },
            {
              state: "IdCardNumber",
              formik: idCardNumberFormik,
              label: "Id Card Number",
            },
            {
              state: "joinningDate",
              formik: joinningDateFormik,
              label: "Joinning Date",
            },
            {
              state: "gender",
              formik: genderFormik,
              label: "Gender",
            },
            {
              state: "bloodGroup",
              formik: bloodGroupFormik,
              label: "Blood Group",
            },
            {
              state: "designation",
              formik: designationFormik,
              label: "Designation",
            },
            {
              state: "employmentType",
              formik: employmentTypeFormik,
              label: "Employment Type",
            },
            {
              state: "PanNo",
              formik: PanNoFormik,
              label: "PAN No",
            },
            {
              state: "department",
              formik: departmentNameFormik,
              label: "Department",
            },
            {
              state: "employeeId",
              formik: employeeIdFormik,
              label: "Employee Id",
            },
            {
              state: "aadhar",
              formik: aadharFormik,
              label: "AADHAR No",
            },
            {
              state: "schoolContact",
              formik: schoolContactNumberFormik,
              label: "School Contact Number",
            },
          ].map((item, index) => {
            return renderStack({
              stateName: item.state,
              formik: item.formik,
              label: item.label,
              openedCard: openedCard,
              setOpenedCard: setOpenedCard,
              state: state,
              setState: setState,
            });
          })}
        </Grid>
      </Grid>
    </>
  );
};

export default CustomStaffIdCardPage;
