import { axiosInstance } from "../axios";
const SCHOOL_URL = process.env.REACT_APP_SCHOOL_BASE;
const user_route = `${SCHOOL_URL}/user`;

export const updateProfilePic = (body) => {
  return axiosInstance.put(`${user_route}/updateProfilePic`, body);
};
export const updatePersonalInfo = (payload) => {
  return axiosInstance.put(`${user_route}/updatePersonalInfo`, payload);
};
export const updateParentInfo = (payload) => {
  return axiosInstance.put(`${user_route}/updateParentInfo`, payload);
};

export const updateAddressInfo = (payload) => {
  return axiosInstance.put(`${user_route}/updateAddressInfo`, payload);
};
export const updateEducationInfo = (payload) => {
  return axiosInstance.post(`${user_route}/addEducationInfo`, payload);
};
export const getEducationInfo = () => {
  return axiosInstance.get(`${user_route}/getEducationInfo`);
};
export const deleteEducationInfo = (id) => {
  return axiosInstance.delete(`${user_route}/deleteEducationInfo/${id}`);
};

export const updateExperienceInfo = (payload) => {
  return axiosInstance.post(`${user_route}/addExperienceInfo`, payload);
};
export const getExperienceInfo = () => {
  return axiosInstance.get(`${user_route}/getExperienceInfo`);
};
export const deleteExperienceInfo = (id) => {
  return axiosInstance.delete(`${user_route}/deleteExperience/${id}`);
};
