import { InputAdornment, MenuItem, Stack, Typography } from "@mui/material";
import { setSession } from "app/services/redux/commonSlice";
import useSelectorHook from "app/services/redux/useSelectorHook";
import { CustomSelectBox } from "assets/styles/globalStyledComponent";
import React from "react";

import { useDispatch } from "react-redux";

const SessionBox = () => {
  const dispatch = useDispatch();
  const { selectedSession } = useSelectorHook();
  return (
    <Stack>
      <CustomSelectBox
        sx={{ fontWeight: 600 }}
        displayEmpty
        size="small"
        value={selectedSession}
        onChange={(e) => dispatch(setSession(e.target.value))}
        startAdornment={
          <InputAdornment position="start">
            <Typography
              sx={{
                fontWeight: 600,
                color: (theme) => theme.palette.primary.main,
              }}
            >
              Session:
            </Typography>
          </InputAdornment>
        }
      >
        {["2021-2022", "2022-2023", "2023-2024", "2024-2025"].map((item, i) => (
          <MenuItem value={item} key={i} sx={{ fontWeight: 600 }}>
            {item}
          </MenuItem>
        ))}
      </CustomSelectBox>
    </Stack>
  );
};

export default SessionBox;
