import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  DialogActions,
  DialogContent,
  Grid,
  Tab,
  Typography,
} from "@mui/material";
import CommonDialog from "app/components/common/CommonDialog";
import CustomLoader from "app/components/common/CustomLoader";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import {
  getSchool,
  linkSchoolWithInstituteGroup,
} from "app/services/org/school.service";
import {
  CustomTextField,
  TabsWrapper,
} from "assets/styles/globalStyledComponent";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AddInstituteComponent from "./AddInstituteComponent";
const AddInstituteDialog = ({
  open = false,
  onClose = () => {},
  refetch = () => {},
  tabValue = "1",
  instituteGroupDocId,
}) => {
  const [state, setState] = useState({
    instituteCode: "",
    linkInstituteData: false,
  });
  const { id } = useParams();
  const [value, setValue] = useState("1");

  const handleClose = () => {
    setState((prev) => ({
      ...prev,
      linkInstituteData: false,
      instituteCode: "",
    }));
    onClose();
  };
  const handleClickLink = () => {
    const body = {
      instituteGroupDocId: instituteGroupDocId,
      instituteDocId: state.linkInstituteData?._id,
    };
    linkSchoolWithInstituteGroupMutate(body);
  };
  const { mutate: getSchoolMutate, isLoading: getSchoolLoading } = useMutation(
    "getSchool",
    getSchool,
    {
      onSuccess: (success) => {
        setState((prev) => ({
          ...prev,
          linkInstituteData: success?.data?.obj,
        }));
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );
  const {
    mutate: linkSchoolWithInstituteGroupMutate,
    isLoading: linkSchoolWithInstituteGroupLoading,
  } = useMutation(
    "linkSchoolWithInstituteGroup",
    linkSchoolWithInstituteGroup,
    {
      onSuccess: (success) => {
        handleClose();
        toast.success(success?.data?.message);
        refetch();
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );
  useEffect(() => {
    setValue(tabValue);
  }, [tabValue]);

  return (
    <>
      <CustomLoader />
      <CommonDialog
        open={open}
        onClose={handleClose}
        minWidth="600px"
        title="Add Institute"
      >
        <TabContext value={value}>
          <Box mx={2}>
            <TabsWrapper>
              <TabList
                onChange={(e, newValue) => setValue(newValue)}
                aria-label="basic tabs example"
              >
                <Tab label="Link Existing Institute" value="1" />
                <Tab label="New Institute" value="2" />
              </TabList>
            </TabsWrapper>
          </Box>
          <DialogContent>
            <TabPanel value="1" sx={{ p: "0 !important" }}>
              <Grid container spacing={1} alignItems={"flex-end"}>
                <Grid item xs={3}>
                  <TextFieldLabel title={"Institute's Code"} />
                  <CustomTextField
                    placeholder="Institute's code"
                    size="small"
                    fullWidth
                    value={state.instituteCode}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        instituteCode: e.target.value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <LoadingButton
                    disabled={!Boolean(state.instituteCode)}
                    variant="contained"
                    color="secondary"
                    size="large"
                    loading={getSchoolLoading}
                    onClick={() =>
                      getSchoolMutate({ schoolCode: state.instituteCode })
                    }
                  >
                    View
                  </LoadingButton>
                </Grid>
              </Grid>
              <Typography my={1} fontWeight={600} fontSize={16}>
                {state.linkInstituteData?.schoolName}
              </Typography>
              {state.linkInstituteData && (
                <>
                  <Typography>Address:</Typography>
                  <Typography fontWeight={600} fontSize={14}>
                    {Object.values(state.linkInstituteData?.schoolAddress ?? {})
                      ?.map((item) => item ?? "")
                      .join(", ")}
                  </Typography>
                </>
              )}
            </TabPanel>
            <TabPanel value="2" sx={{ p: "0 !important" }}>
              <AddInstituteComponent formId="Add-institure-form" />
            </TabPanel>
          </DialogContent>
        </TabContext>
        {value === "1" ? (
          <DialogActions>
            <LoadingButton
              disabled={!state.linkInstituteData}
              variant="contained"
              size="large"
              color="secondary"
              sx={{ px: 5 }}
              onClick={handleClickLink}
              loading={linkSchoolWithInstituteGroupLoading}
            >
              Link
            </LoadingButton>
          </DialogActions>
        ) : (
          <DialogActions>
            <LoadingButton
              variant="contained"
              size="large"
              color="secondary"
              sx={{ px: 5 }}
              type="submit"
              form="Add-institure-form"
            >
              Save
            </LoadingButton>
          </DialogActions>
        )}
      </CommonDialog>
    </>
  );
};

export default AddInstituteDialog;
