import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import styled from "styled-components";
import { useQuery } from "react-query";
import moment from "moment";

import PersonalInfoModal from "../StaffProfileEditModals/PersonalInfoModal";
import ParentInfoModal from "../StaffProfileEditModals/ParentInfoModal";
import EducationInfoModal from "../StaffProfileEditModals/EducationInfoModal";
import PrevExperienceModal from "../StaffProfileEditModals/PrevExperienceModal";
import AddressInfoModal from "../StaffProfileEditModals/AddressInfoModal";
import { PencilSquare } from "react-bootstrap-icons";
import {
  Box,
  DialogTitle,
  Grid,
  Stack,
  Dialog,
  DialogContent,
  Container,
} from "@mui/material";
import { toast } from "react-toastify";
import uploadFile from "app/utils/uploadFile";
import { LoadingButton } from "@mui/lab";

import DialogHeader from "app/components/common/DialogHeader";
import { getDuration } from "app/utils/helper";
import { updateProfilePic } from "app/services/auth/user.service";
import { getUserProfile } from "app/services/auth/auth.service";

const NameComponent = ({ label, text, flex = 1 }) => {
  return (
    <Box>
      <Typography fontSize={14} color="primary" sx={{ opacity: 0.75 }}>
        {label}
      </Typography>
      <Typography fontSize={16} fontWeight={600} color="primary">
        {text && text !== "undefined" && text !== undefined
          ? String(text).replace(/undefined/g, "")
          : "--"}
      </Typography>
    </Box>
  );
};
const StaffProfilePage = () => {
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [isUploadPicLoading, setIsUploadPicLoading] = useState(false);
  const [educationInfoDialog, setEducationInfoDialog] = useState(false);
  const [ExperienceInfoDialog, setExperienceInfoDialog] = useState(false);

  const [expanded, setExpanded] = useState({
    profile: true,
    personal_info: true,
    contact_info: true,
    edc_info: true,
    prv_exp: true,
    parent_info: true,
    assigned_info: true,
  });

  const {
    isLoading: getUserDetailLoading,
    data: staffResponse,
    refetch: getUserDetail,
  } = useQuery({
    queryKey: ["getUserProfile"],
    queryFn: () => getUserProfile(),
    onSuccess: (data) => {
      toast.success(data?.data?.message);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
    enabled: false,
    retry: false,
  });
  const staffDetails = staffResponse?.data?.obj?.staffDetail;

  const handleClickUpload = async (e) => {
    setIsUploadPicLoading(true);
    if (e.target.files) {
      let s3FilePath = `staff/${e.target.files[0].name}`;
      let attachmentUrl = await uploadFile(
        e.target.files[0],
        s3FilePath,
        e.target.files[0].type
      );
      try {
        const res = await updateProfilePic({
          profilePic: attachmentUrl?.fileURL,
        });
        toast.success(res?.data?.message);
        getUserDetail();
        setIsUploadPicLoading(false);
      } catch (error) {
        setIsUploadPicLoading(false);
        toast.error(error?.response?.data?.message);
      }
    }
  };
  useEffect(() => {
    getUserDetail();
  }, []);
  return (
    <>
      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        PaperProps={{
          sx: { minWidth: "500px" },
        }}
      >
        <DialogHeader
          title={`Edit ${modalTitle}`}
          handleClose={() => setOpenEditDialog(false)}
        />

        <DialogContent>
          {modalTitle === "Personal Info" ? (
            <PersonalInfoModal
              handleCloseModal={() => setOpenEditDialog(false)}
              getUserDetail={getUserDetail}
              staffResponse={staffResponse}
            />
          ) : modalTitle === "Contact Info" ? (
            <AddressInfoModal
              handleCloseModal={() => setOpenEditDialog(false)}
              getUserDetail={getUserDetail}
              staffDetails={staffDetails}
            />
          ) : modalTitle === "Parent Information" ? (
            <ParentInfoModal
              handleCloseModal={() => setOpenEditDialog(false)}
              getUserDetail={getUserDetail}
              staffResponse={staffResponse}
            />
          ) : null}
        </DialogContent>
      </Dialog>
      {/* Education Info Dialog */}
      <Dialog
        open={educationInfoDialog}
        onClose={() => setEducationInfoDialog(false)}
        PaperProps={{
          sx: { minWidth: "900px" },
        }}
      >
        <DialogTitle sx={{ p: "0 !important" }}>
          <DialogHeader
            title="Edit Education Info"
            handleClose={() => setEducationInfoDialog(false)}
          />
        </DialogTitle>
        <DialogContent>
          <EducationInfoModal
            handleCloseModal={() => setEducationInfoDialog(false)}
            getUserDetail={getUserDetail}
          />
        </DialogContent>
      </Dialog>
      {/* experience dialog */}
      <Dialog
        open={ExperienceInfoDialog}
        onClose={() => setExperienceInfoDialog(false)}
        PaperProps={{
          sx: { minWidth: "980px" },
        }}
      >
        <DialogTitle sx={{ p: "0 !important" }}>
          <DialogHeader
            title="Edit Previous Experiences"
            handleClose={() => setExperienceInfoDialog(false)}
          />
        </DialogTitle>
        <DialogContent>
          <PrevExperienceModal
            handleCloseModal={() => setExperienceInfoDialog(false)}
            getUserDetail={getUserDetail}
          />
        </DialogContent>
      </Dialog>

      <Container maxWidth="xl" sx={{ mt: "70px" }}>
        <Wrapper>
          {/* Personal Information */}
          <Accordion
            expanded={expanded.personal_info}
            onChange={() => {
              setExpanded({
                ...expanded,
                personal_info: !expanded.personal_info,
              });
            }}
            className="customaccordian_style"
            sx={{ borderRadius: "12px", boxShadow: "none" }}
          >
            <AccordionSummary
              expandIcon={
                <ArrowForwardIosIcon
                  sx={{
                    transform: expanded.personal_info
                      ? "rotate(270deg) !important"
                      : "rotate(0deg) !important",
                  }}
                />
              }
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Typography className="accordian_title">
                  Personal Information
                </Typography>

                <Stack
                  alignItems="center"
                  justifyContent="center"
                  className="edit_icon__container"
                  sx={{ mr: 2 }}
                  onClick={() => {
                    setOpenEditDialog(true);
                    setModalTitle("Personal Info");
                  }}
                >
                  <PencilSquare
                    size={14}
                    style={{
                      color: "#0B4977",
                    }}
                  />
                </Stack>
              </Stack>
            </AccordionSummary>
            <AccordionDetails className="accordiantDetail_border">
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box>
                    <Box>
                      <img
                        src={
                          staffDetails?.profilePic
                            ? staffDetails?.profilePic
                            : "/image-placeholder.jpeg"
                        }
                        style={{
                          height: "80px",
                          width: "80px",
                          objectFit: "contain",
                        }}
                        alt="user-profile-ekalsutra"
                      />
                    </Box>
                    <LoadingButton
                      variant="outlined"
                      color="secondary"
                      component="label"
                      loading={isUploadPicLoading}
                      sx={{ mt: 1 }}
                    >
                      {staffDetails?.profilePic ? "Update" : "Upload"}
                      <input
                        hidden
                        accept="image/*"
                        type="file"
                        onChange={handleClickUpload}
                      />
                    </LoadingButton>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={10}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={3}>
                      <NameComponent
                        label="Name"
                        text={`${staffDetails?.firstName} ${staffDetails?.middleName} ${staffDetails?.lastName}`}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <NameComponent
                        label="Gender"
                        text={staffDetails?.gender}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <NameComponent
                        label="DOB"
                        text={moment(new Date(staffDetails?.DOB)).format(
                          "DD MMM, YYYY"
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <NameComponent
                        label="Marital Status"
                        text={staffDetails?.maritalStatus}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <NameComponent
                        label="Mobile Number"
                        text={staffDetails?.mobileNumber}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <NameComponent
                        label="Alternate Number"
                        text={staffDetails?.alternateNumber}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <NameComponent
                        label="PAN Number"
                        text={staffDetails?.PAN}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <NameComponent
                        label="Email Id"
                        text={staffDetails?.email}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          {/* Address info */}
          <Accordion
            expanded={expanded.contact_info}
            onChange={() => {
              setExpanded({
                ...expanded,
                contact_info: !expanded.contact_info,
              });
            }}
            className="customaccordian_style"
            sx={{ borderRadius: "12px", boxShadow: "none" }}
          >
            <AccordionSummary
              expandIcon={
                <ArrowForwardIosIcon
                  sx={{
                    transform: expanded.contact_info
                      ? "rotate(270deg) !important"
                      : "rotate(0deg) !important",
                  }}
                />
              }
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Typography className="accordian_title">
                  Address Information
                </Typography>

                <Stack
                  alignItems="center"
                  justifyContent="center"
                  className="edit_icon__container"
                  sx={{ mr: 2 }}
                  onClick={() => {
                    setOpenEditDialog(true);
                    setModalTitle("Contact Info");
                  }}
                >
                  <PencilSquare
                    size={14}
                    style={{
                      color: "#0B4977",
                    }}
                  />
                </Stack>
              </Stack>
            </AccordionSummary>
            <AccordionDetails className="accordiantDetail_border">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <NameComponent
                    label="Current Address"
                    text={`${staffDetails?.currentAddress?.address}, ${staffDetails?.currentAddress?.dist}, ${staffDetails?.currentAddress?.state} ${staffDetails?.currentAddress?.pinCode}`}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <NameComponent
                    label="Current Address"
                    text={`${staffDetails?.permanentAddress?.address}, ${staffDetails?.permanentAddress?.dist}, ${staffDetails?.permanentAddress?.state} ${staffDetails?.permanentAddress?.pinCode}`}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          {/* parent info */}
          <Accordion
            expanded={expanded.parent_info}
            onChange={() => {
              setExpanded({ ...expanded, parent_info: !expanded.parent_info });
            }}
            className="customaccordian_style"
            sx={{ borderRadius: "12px", boxShadow: "none" }}
          >
            <AccordionSummary
              expandIcon={
                <ArrowForwardIosIcon
                  sx={{
                    transform: expanded.parent_info
                      ? "rotate(270deg) !important"
                      : "rotate(0deg) !important",
                  }}
                />
              }
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Typography className="accordian_title">
                  Parent Infomation
                </Typography>

                <Stack
                  alignItems="center"
                  justifyContent="center"
                  className="edit_icon__container"
                  sx={{ mr: 2 }}
                  onClick={() => {
                    setOpenEditDialog(true);
                    setModalTitle("Parent Information");
                  }}
                >
                  <PencilSquare
                    size={14}
                    style={{
                      color: "#0B4977",
                    }}
                  />
                </Stack>
              </Stack>
            </AccordionSummary>
            <AccordionDetails className="accordiantDetail_border">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <NameComponent
                    label="Father's Name"
                    text={`${staffDetails?.fatherInfo?.firstName} ${staffDetails?.fatherInfo?.middleName} ${staffDetails?.fatherInfo?.lastName}`}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <NameComponent
                    label="Mother's Name"
                    text={`${staffDetails?.motherInfo?.firstName} ${staffDetails?.motherInfo?.middleName} ${staffDetails?.motherInfo?.lastName}`}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          {/* Education information */}
          <Accordion
            expanded={expanded.edc_info}
            onChange={() => {
              setExpanded({ ...expanded, edc_info: !expanded.edc_info });
            }}
            className="customaccordian_style"
            sx={{ borderRadius: "12px", boxShadow: "none" }}
          >
            <AccordionSummary
              expandIcon={
                <ArrowForwardIosIcon
                  sx={{
                    transform: expanded.edc_info
                      ? "rotate(270deg) !important"
                      : "rotate(0deg) !important",
                  }}
                />
              }
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Typography className="accordian_title">
                  Education Information
                </Typography>

                <Stack
                  alignItems="center"
                  justifyContent="center"
                  className="edit_icon__container"
                  sx={{ mr: 2 }}
                  onClick={() => setEducationInfoDialog(true)}
                >
                  <PencilSquare
                    size={14}
                    style={{
                      color: "#0B4977",
                    }}
                  />
                </Stack>
              </Stack>
            </AccordionSummary>
            <AccordionDetails className="accordiantDetail_border">
              <Grid container spacing={1} columns={15}>
                {[
                  "Description",
                  "University/ Board",
                  "Percentage",
                  "Grades",
                  "Passing Year",
                ].map((item, i) => (
                  <Grid item xs={3} key={i}>
                    <Typography
                      fontSize={14}
                      color="primary"
                      sx={{ opacity: 0.75 }}
                    >
                      {item}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              <Grid
                container
                item
                columnSpacing={1}
                rowSpacing={1.5}
                columns={15}
              >
                {staffDetails?.educationInfo?.map((ed, i) => (
                  <>
                    <Grid item xs={3} key={i}>
                      <Typography
                        fontSize={16}
                        fontWeight={600}
                        color="primary"
                      >
                        {ed?.description ?? "--"}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} key={i}>
                      <Typography
                        fontSize={16}
                        fontWeight={600}
                        color="primary"
                      >
                        {ed?.instituteName ?? "--"}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} key={i}>
                      <Typography
                        fontSize={16}
                        fontWeight={600}
                        color="primary"
                      >
                        {ed?.percentage}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} key={i}>
                      <Typography
                        fontSize={16}
                        fontWeight={600}
                        color="primary"
                      >
                        {ed?.grades}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} key={i}>
                      <Typography
                        fontSize={16}
                        fontWeight={600}
                        color="primary"
                      >
                        {ed?.passingYear}
                      </Typography>
                    </Grid>
                  </>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
          {/* Prvious Experiences */}
          <Accordion
            expanded={expanded.prv_exp}
            onChange={() => {
              setExpanded({ ...expanded, prv_exp: !expanded.prv_exp });
            }}
            className="customaccordian_style"
            sx={{ borderRadius: "12px", boxShadow: "none" }}
          >
            <AccordionSummary
              expandIcon={
                <ArrowForwardIosIcon
                  sx={{
                    transform: expanded.prv_exp
                      ? "rotate(270deg) !important"
                      : "rotate(0deg) !important",
                  }}
                />
              }
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Typography className="accordian_title">
                  Previous Experience
                </Typography>

                <Stack
                  alignItems="center"
                  justifyContent="center"
                  className="edit_icon__container"
                  sx={{ mr: 2 }}
                  onClick={() => {
                    setExperienceInfoDialog(true);
                  }}
                >
                  <PencilSquare
                    size={14}
                    style={{
                      color: "#0B4977",
                    }}
                  />
                </Stack>
              </Stack>
            </AccordionSummary>

            <AccordionDetails className="accordiantDetail_border">
              <Grid container spacing={1}>
                {[
                  "Employer's Name",
                  "Designation",
                  "From",
                  "To",
                  "Duration",
                  "Attachment",
                ].map((item, i) => (
                  <Grid item xs={2} key={i}>
                    <Typography
                      fontSize={14}
                      color="primary"
                      sx={{ opacity: 0.75 }}
                    >
                      {item}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              <Grid container item columnSpacing={1} rowSpacing={1.5}>
                {staffDetails?.experiences?.map((exp, i) => (
                  <>
                    <Grid item xs={2} key={i}>
                      <Typography
                        fontSize={16}
                        fontWeight={600}
                        color="primary"
                      >
                        {exp.employerName ?? "--"}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} key={i}>
                      <Typography
                        fontSize={16}
                        fontWeight={600}
                        color="primary"
                      >
                        {exp.designation ?? "--"}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} key={i}>
                      <Typography
                        fontSize={16}
                        fontWeight={600}
                        color="primary"
                      >
                        {exp.joiningDate
                          ? moment(new Date(exp.joiningDate)).format(
                              "DD MMM, YYYY"
                            )
                          : "--"}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} key={i}>
                      <Typography
                        fontSize={16}
                        fontWeight={600}
                        color="primary"
                      >
                        {exp.separationDate
                          ? moment(new Date(exp.separationDate)).format(
                              "DD MMM, YYYY"
                            )
                          : "--"}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} key={i}>
                      <Typography
                        fontSize={16}
                        fontWeight={600}
                        color="primary"
                      >
                        {getDuration(exp.separationDate, exp.joiningDate)}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} key={i}>
                      {exp?.experienceCertificate && (
                        <a
                          href={exp?.experienceCertificate}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          view
                        </a>
                      )}
                    </Grid>
                  </>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Wrapper>
      </Container>
    </>
  );
};

export default StaffProfilePage;

const Wrapper = styled.div`
  border-radius: round;
  overflow: hidden !important;
  margin: 2px;
  flex-wrap: wrap;
  .edit_icon__container {
    background-color: #ebf0f4;
    height: 26px;
    width: 26px;
    border-radius: 8px;
    cursor: pointer;
  }
  .customaccordian_style {
    box-shadow: none;
    border-radius: 15px !important;
    border: 1.5px solid #b6c8d6;
    margin-top: 10px;
  }
  .accordiantDetail_border {
    border-top: 1.5px solid #b6c8d6;
  }
  .accordian_title {
    font-size: 16px;
    font-weight: 500;
  }
  .MuiAccordion-root::before {
    opacity: 0 !important;
  }
`;
