import { LoadingButton } from "@mui/lab";
import {
  DialogActions,
  DialogContent,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import CommonDialog from "app/components/common/CommonDialog";
import CustomErrorText from "app/components/common/CustomErrorText";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import { PRODUCT_NAMES } from "app/config/constants";
import { addProduct } from "app/services/org/product.service";
import useSelectorHook from "app/services/redux/useSelectorHook";
import { IndianCurrency } from "app/utils/helper";
import {
  CustomSelectBox,
  CustomTextField,
  CustomTextFieldDisabled,
} from "assets/styles/globalStyledComponent";
import { useFormik } from "formik";
import React from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import * as Yup from "yup";
const validationSchema = Yup.object({
  productType: Yup.string().required("Required"),
  productName: Yup.string().required("Required"),
  productDescription: Yup.string().required("Required"),
  uom: Yup.string().required("Required"),
  rate: Yup.string().required("Required"),
  minimumRate: Yup.string().required("Required"),
  factor: Yup.string().required("Required"),
  dueDate: Yup.date().required("Required"),
  expiryDate: Yup.date().required("Required"),
  remarks: Yup.string().required("Required"),
});
const AddProductDialog = ({
  onClose = () => {},
  open = false,
  refetch = () => {},
}) => {
  const dt = new Date();
  const { selectedSession } = useSelectorHook();
  const {
    values,
    setValues,
    errors,
    touched,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      productType: "software",
      productName: "",
      productDescription: "",
      uom: "",
      rate: "",
      minimumRate: "",
      factor: "",
      amount: "",
      dueDate: dt,
      expiryDate: dt,
      remarks: "",
    },
    onSubmit: (formValues) => {
      const body = {
        productType: formValues.productType,
        productName: formValues.productName,
        productDescription: formValues.productDescription,
        session: selectedSession,
        expiresOn: formValues.expiryDate,
        factor: formValues.factor,
        rate: formValues.rate,
        minimumRate: formValues.minimumRate,
        unitOfMesurement: formValues.uom,
        dueDate: formValues.dueDate,
        remarks: formValues.remarks,
      };
      addProductMutate(body);
    },
    validationSchema,
  });
  const handleCloseDialog = () => {
    resetForm();
    onClose();
  };
  const { mutate: addProductMutate, isLoading: addProductLoading } =
    useMutation("addProduct", addProduct, {
      onSuccess: (success) => {
        toast.success(success?.data?.message);
        refetch();
        handleCloseDialog();
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
    });
  return (
    <CommonDialog
      onClose={handleCloseDialog}
      open={open}
      title="Add More Product"
      minWidth="1000px"
    >
      <DialogContent>
        <form onSubmit={handleSubmit} id="add-product-form">
          <Grid container spacing={1} columns={14.5}>
            <Grid item xs={14.5} lg={2}>
              <TextFieldLabel title={"Product Type"} required />
              <CustomSelectBox
                native
                displayEmpty
                size="small"
                fullWidth
                name="productType"
                value={values.productType}
                onChange={(e) =>
                  setValues((prev) => ({
                    ...prev,
                    productType: e.target.value,
                    productName: "",
                  }))
                }
                error={touched.productType && Boolean(errors.productType)}
              >
                <MenuItem value="" disabled component="option">
                  Select
                </MenuItem>
                {Object.keys(PRODUCT_NAMES)?.map((item, i) => (
                  <MenuItem value={item} key={i} component="option">
                    {item}
                  </MenuItem>
                ))}
              </CustomSelectBox>
              {touched.productType && Boolean(errors.productType) && (
                <Typography color="error">{errors.productType}</Typography>
              )}
            </Grid>
            <Grid item xs={14.5} lg={2}>
              <TextFieldLabel title={"Product Name"} required />
              <CustomSelectBox
                native
                displayEmpty
                size="small"
                fullWidth
                name="productName"
                value={values.productName}
                onChange={handleChange}
                error={touched.productName && Boolean(errors.productName)}
              >
                <MenuItem value="" disabled component="option">
                  Select
                </MenuItem>

                {PRODUCT_NAMES[values.productType]?.map((item, i) => (
                  <MenuItem value={item} key={i} component="option">
                    {item}
                  </MenuItem>
                ))}
              </CustomSelectBox>
              {touched.productName && Boolean(errors.productName) && (
                <Typography color="error">{errors.productName}</Typography>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={1} columns={14.5} py={1.5}>
            <Grid item xs={14.5} lg={4}>
              <TextFieldLabel title={"Product Discription"} required />
              <CustomTextField
                size="small"
                fullWidth
                placeholder="Type ...."
                name="productDescription"
                value={values.productDescription}
                onChange={handleChange}
                error={
                  touched.productDescription &&
                  Boolean(errors.productDescription)
                }
              />
              {touched.productDescription &&
                Boolean(errors.productDescription) && (
                  <Typography color="error">
                    {errors.productDescription}
                  </Typography>
                )}
            </Grid>
            <Grid item xs={14.5} lg={1}>
              <TextFieldLabel title={"UOM"} required />
              <CustomTextField
                size="small"
                placeholder="Unit of Measurement"
                fullWidth
                name="uom"
                value={values.uom}
                onChange={handleChange}
                error={touched.uom && Boolean(errors.uom)}
              />
              {touched.uom && Boolean(errors.uom) && (
                <Typography color="error">{errors.uom}</Typography>
              )}
            </Grid>
            <Grid item xs={14.5} lg={1.5}>
              <TextFieldLabel title={"Rate"} required />
              <CustomTextField
                size="small"
                fullWidth
                placeholder="Type rate"
                name="rate"
                type="number"
                value={values.rate}
                onChange={handleChange}
                error={touched.rate && Boolean(errors.rate)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography>&#8377;</Typography>
                    </InputAdornment>
                  ),
                }}
              />
              {touched.rate && Boolean(errors.rate) && (
                <Typography color="error">{errors.rate}</Typography>
              )}
            </Grid>
            <Grid item xs={14.5} lg={1.5}>
              <TextFieldLabel title={"Min. Rate"} required />
              <CustomTextField
                size="small"
                fullWidth
                placeholder="Type rate"
                name="minimumRate"
                type="number"
                value={values.minimumRate}
                onChange={handleChange}
                error={touched.minimumRate && Boolean(errors.minimumRate)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography>&#8377;</Typography>
                    </InputAdornment>
                  ),
                }}
              />
              {touched.minimumRate && Boolean(errors.minimumRate) && (
                <Typography color="error">{errors.minimumRate}</Typography>
              )}
            </Grid>
            <Grid item xs={14.5} lg={1}>
              <TextFieldLabel title={"Factor"} required />
              <CustomTextField
                size="small"
                fullWidth
                name="factor"
                type="number"
                placeholder="Type .."
                value={values.factor}
                onChange={handleChange}
                error={touched.factor && Boolean(errors.factor)}
              />
              {touched.factor && Boolean(errors.factor) && (
                <Typography color="error">{errors.factor}</Typography>
              )}
            </Grid>
            <Grid item xs={14.5} lg={1.5}>
              <TextFieldLabel title={"Final Rate"} />
              <CustomTextFieldDisabled
                size="small"
                fullWidth
                placeholder="amount"
                value={IndianCurrency(
                  Number(values.rate ?? 0) * Number(values.factor ?? 0)
                )}
              />
            </Grid>
            <Grid item xs={14.5} lg={2}>
              <TextFieldLabel title={"Due Date"} required />
              <DatePicker
                inputFormat="DD-MM-YYYY"
                value={values.dueDate}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    fullWidth
                    size="small"
                    error={touched.dueDate && Boolean(errors.dueDate)}
                  />
                )}
                onChange={(e) => setValues((prev) => ({ ...prev, dueDate: e }))}
              />
            </Grid>
            <Grid item xs={14.5} lg={2}>
              <TextFieldLabel title={"Expiry Date"} required />
              <DatePicker
                inputFormat="DD-MM-YYYY"
                value={values.expiryDate}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    fullWidth
                    size="small"
                    error={touched.expiryDate && Boolean(errors.expiryDate)}
                  />
                )}
                onChange={(e) =>
                  setValues((prev) => ({ ...prev, expiryDate: e }))
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={14.5} lg={8}>
              <TextFieldLabel title={"Remark"} />
              <CustomTextField
                size="small"
                placeholder="Type remark ..."
                fullWidth
                name="remarks"
                value={values.remarks}
                onChange={handleChange}
                error={touched.remarks && Boolean(errors.remarks)}
              />
              {touched.remarks && Boolean(errors.remarks) && (
                <Typography color="error">{errors.remarks}</Typography>
              )}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          form="add-product-form"
          variant="contained"
          color="secondary"
          type="submit"
          size="large"
          loading={addProductLoading}
        >
          + Add Product
        </LoadingButton>
      </DialogActions>
    </CommonDialog>
  );
};

export default AddProductDialog;
