import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useMutation, useQuery } from "react-query";
import {
  addIdCardFrame,
  customizeIdCardFrame,
  editIdCardFrame,
  getTotalIdCardFrame,
} from "app/services/org/reporting.service";
import { useReactToPrint } from "react-to-print";
import uploadFile from "app/utils/uploadFile";
import profilePlaceHolder from "assets/media/images/idCard-Images/userImage.png";
import CustomLoader from "app/components/common/CustomLoader";
import LocationName from "../components/LocationName";
import demoLogo from "assets/media/images/NoticeBoard-images/demoLogo.png";
import { useParams } from "react-router-dom";
import moment from "moment";

const CARD_WIDTH = "5.5cm";
const CARD_HEIGHT = "8.6cm";

const horizontalWidth = "8.56cm";

const horizontalHeight = "5.39cm";

const TypographyFormik = ({
  formik,
  label,
  value,
  labelFontWeight = "400",
  fontSize = "9px",
  textAling = "left",
}) => {
  return (
    <Typography
      sx={{
        fontSize: `${formik?.values?.fontSize}px`,
        fontWeight: `${formik?.values?.fontWeight}`,
        position: "absolute",
        zIndex: 2,
        textAlign: textAling,
        color: formik?.values?.fontColor ?? "#000000",
        top: `${formik?.values?.y_axis}px` ?? 20,
        left: `${formik?.values?.x_axis}px` ?? 20,
        fontStyle: `${formik?.values?.fontStyle}` ?? "normal",
      }}
    >
      <span
        style={{
          fontSize: fontSize,
          fontWeight: labelFontWeight,
          color: "colors.primary",
        }}
      >
        {label ?? null}
      </span>
      {value}
    </Typography>
  );
};

const StudentTypographyFormik = ({
  formik,
  label,
  value,
  labelFontWeight = "400",
  fontSize = "9px",
  textAling = "flex-end",
  close = false,
}) => {
  const [labelValue, setLabelValue] = useState(value);
  return (
    <Grid container item xs={12}>
      {/* for label */}
      {label && (
        <Grid item xs={close ? 1 : 4} style={{ position: "relative" }}>
          <Typography
            sx={{
              fontSize: `${formik?.values?.fontSize}px`,
              fontWeight: `${formik?.values?.fontWeight}`,
              position: "absolute",
              zIndex: 2,
              width: "100%",
              textAlign: textAling,
              color: formik?.values?.fontColor ?? "#000000",
              top: `${formik?.values?.y_axis}px` ?? 20,
              left: `${formik?.values?.x_axis}px` ?? 20,
              fontStyle: `${formik?.values?.fontStyle}` ?? "normal",
            }}
          >
            <span
              style={{
                fontWeight: labelFontWeight,
                color: "colors.primary",
              }}
            >
              {label ?? null}
            </span>
          </Typography>
        </Grid>
      )}
      {/* for values */}
      <Grid
        item
        xs={close ? 4 : label ? 8 : 12}
        style={{ position: "relative" }}
      >
        <Typography
          sx={{
            fontSize: `${formik?.values?.fontSize}px`,
            fontWeight: `${formik?.values?.fontWeight}`,
            position: "absolute",
            zIndex: 2,
            textAlign: textAling,
            color: formik?.values?.fontColor ?? "#000000",
            top: `${formik?.values?.y_axis}px` ?? 20,
            left: `${formik?.values?.x_axis}px` ?? 20,
            fontStyle: `${formik?.values?.fontStyle}` ?? "normal",
          }}
        >
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

const renderStack = ({
  stateName,
  formik,
  label,
  setState,
  openedCard,
  setOpenedCard,
  state,
}) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1} margin={2}>
      <input
        type="checkbox"
        disabled={state?.backgroundFrame ? false : true}
        checked={state?.[stateName]}
        style={{ cursor: "pointer", width: "20px", height: "20px" }}
        onClick={() => {
          setState({
            ...state,
            [stateName]: !state?.[stateName],
          });
        }}
      />
      <LocationName
        item={label}
        openedCard={openedCard}
        setOpenedCard={setOpenedCard}
        formik={formik}
      />
    </Stack>
  );
};

const generateObject = ({
  stateKey,
  formik,
  labelKeyName,
  shape = "circle",
}) => {
  return stateKey
    ? [
        {
          labelName: formik?.values?.labelName,
          labelKeyName: labelKeyName,
          showOnCard: true,
          x_axis: formik?.values?.x_axis,
          y_axis: formik?.values?.y_axis,
          width: 400, // for Logo
          height: 400, // for Logo
          shape: formik?.values?.shape ?? shape, // for Logo
          fontColor: formik?.values?.fontColor,
          fontWeight: formik?.values?.fontWeight,
          fontSize: formik?.values?.fontSize,
          fontStyle: formik?.values?.fontStyle,
        },
      ]
    : [];
};

const formikValidationSchema = yup.object({
  x_axis: yup.string().required("Required"),
  y_axis: yup.string().required("Required"),
  fontColor: yup.string().optional(""),
  fontWeight: yup.string().required("Required"),
  fontSize: yup.string().required("Required"),
  fontStyle: yup.string().required("Required"),
});

const CustomIdCardPage = ({ handleClose = () => {} }) => {
  const selectedSchool = useSelector((state) => state.auth.userDetail);
  const { frameCode } = useParams();
  const [existingFrames, setExistingFrames] = useState();
  const [loading, setLoading] = useState(false);
  const [openedCard, setOpenedCard] = useState(null);

  const { mutate: addIdCardFrameMutate, isLoading: addIdCardFrameLoading } =
    useMutation(addIdCardFrame, {
      queryKey: ["addIdCardFrame"],
      onSuccess: ({ data }) => {
        toast.success(data?.message);
        handleClose();
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
      enabled: false,
    });

  const {
    isFetching: getTotalIdCardFrameFetching,
    isLoading: getTotalIdCardFrameLoading,
    data: getTotalIdCardFrame_Data,
    refetch: getTotalIdCardFrameRefetch,
  } = useQuery({
    queryKey: ["getTotalIdCardFrame", frameCode],
    queryFn: () =>
      getTotalIdCardFrame({
        frameCode: String(frameCode).replace(/ /g, "/") ?? "",
      }),
    onSuccess: async ({ data }) => {
      setExistingFrames(data?.obj);
      setState((prev) => ({
        ...prev,
        backgroundFrame: data?.obj?.frameURL,
      }));
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
    enabled: frameCode ? true : false,
  });

  const createFormikConfig = (
    x_axis,
    y_axis,
    fontSize,
    labelName,
    labelKeyName,
    shape,
    fontColor,
    fontWeight,
    fontStyle
  ) => ({
    initialValues: {
      shape: shape ?? "circle",
      labelName: labelName ?? "",
      labelKeyName: labelKeyName ?? "",
      x_axis,
      y_axis,
      fontColor: fontColor ?? "#000",
      fontWeight: fontWeight ?? "500",
      fontSize: fontSize ?? "9",
      fontStyle: fontStyle ?? "normal",
    },
    validationSchema: formikValidationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const findContent = (labelKeyName) =>
    getTotalIdCardFrame_Data?.data?.obj?.contents?.find(
      (content) => content?.labelKeyName === labelKeyName
    );

  /*********************************************************/
  /*************************FOR  STUDENT *******************/
  /*********************************************************/
  const profilePicContent = findContent("Profile Pic");
  const logoContent = findContent("Logo");
  const schoolNameContent = findContent("schoolName");
  const nameContent = findContent("Name");
  const classNameContent = findContent("Class");
  const admissionContent = findContent("Admission Number");
  const DOBContent = findContent("DOB");
  const addressContent = findContent("Address");
  const fatherNameContent = findContent("Father Name");
  const motherNameContent = findContent("Mother Name");
  const mobileNumberContent = findContent("Mobile Number");
  const principalSignContent = findContent("Principal Sign");
  const transportContent = findContent("Transport");
  const schoolAddressContent = findContent("School Address");
  const idCardNumberContent = findContent("Id Card Number");
  const admissionDateContent = findContent("Admission Date");
  const rollNoContent = findContent("Roll No");
  const genderContent = findContent("Gender");
  const bloodGroupContent = findContent("Blood Group");
  const regNoContent = findContent("Reg No");
  const srNoContent = findContent("SR No");
  const penNoContent = findContent("PEN No");
  const admissionTypeContent = findContent("Admission Type");
  const feeRelaxationCategoryContent = findContent("Fee Relaxation Category");
  const houseContent = findContent("House");
  const aadharContent = findContent("Aadhar");
  const categoryContent = findContent("Category");
  // School Contact Number
  const schoolContactNumberContent = findContent("School Contact Number");

  const [state, setState] = useState({
    horizontalFrame: false,
    verticalFrame: true,
    frameImage: null,
    backgroundFrame: null,
    StudentName: nameContent ? true : false,
    FatherName: fatherNameContent ? true : false,
    MotherName: motherNameContent ? true : false,
    MobileNumber: mobileNumberContent ? true : false,
    DOB: DOBContent ? true : false,
    Address: addressContent ? true : false,
    Class: classNameContent ? true : false,
    AdmissionNumber: admissionContent ? true : false,
    PrincipalSign: principalSignContent ? true : false,
    Transport: transportContent ? true : false,
    SchoolAddress: schoolAddressContent ? true : false,
    IdCardNumber: idCardNumberContent ? true : false,
    logo: logoContent ? true : false,
    profilePic: profilePicContent ? true : false,
    schoolName: schoolNameContent ? true : false,
    admissionDate: admissionDateContent ? true : false,
    rollNo: rollNoContent ? true : false,
    gender: genderContent ? true : false,
    bloodGroup: bloodGroupContent ? true : false,
    regNo: regNoContent ? true : false,
    srNo: srNoContent ? true : false,
    penNo: penNoContent ? true : false,
    admissionType: admissionTypeContent ? true : false,
    feeRelaxation: feeRelaxationCategoryContent ? true : false,
    house: houseContent ? true : false,
    aadhar: aadharContent ? true : false,
    category: categoryContent ? true : false,
    schoolContact: schoolContactNumberContent ? true : false,
    overlayText: {
      x: 0,
      y: 0,
      color: "#000",
      fontSize: 16,
      enabled: false,
    },
  });

  const profilePicFormik = useFormik(
    createFormikConfig(
      profilePicContent?.x_axis ?? "76",
      profilePicContent?.y_axis ?? "71",
      profilePicContent?.fontSize ?? "55",
      profilePicContent?.labelName ?? "Profile Pic",
      profilePicContent?.labelKeyName ?? "Profile Pic",
      profilePicContent?.shape ?? "circle",
      profilePicContent?.fontColor ?? "#000",
      profilePicContent?.fontWeight ?? "500",
      profilePicContent?.fontStyle ?? "normal"
    )
  );
  const schoolContactNumberFormik = useFormik(
    createFormikConfig(
      schoolContactNumberContent?.x_axis ?? "76",
      schoolContactNumberContent?.y_axis ?? "60",
      schoolContactNumberContent?.fontSize ?? "20",
      schoolContactNumberContent?.labelName ?? "School Contact Number",
      schoolContactNumberContent?.labelKeyName ?? "School Contact Number",
      schoolContactNumberContent?.shape ?? "circle",
      schoolContactNumberContent?.fontColor ?? "#000",
      schoolContactNumberContent?.fontWeight ?? "500",
      schoolContactNumberContent?.fontStyle ?? "normal"
    )
  );
  const nameFormik = useFormik(
    createFormikConfig(
      nameContent?.x_axis ?? "54",
      nameContent?.y_axis ?? "129",
      nameContent?.fontSize ?? "12",
      nameContent?.labelName ?? "Name",
      nameContent?.labelKeyName ?? "Name",
      nameContent?.shape ?? "circle",
      nameContent?.fontColor ?? "#000",
      nameContent?.fontWeight ?? "500",
      nameContent?.fontStyle ?? "normal"
    )
  );
  const classNameFormik = useFormik(
    createFormikConfig(
      classNameContent?.x_axis ?? "72",
      classNameContent?.y_axis ?? "143",
      classNameContent?.fontSize ?? "10",
      classNameContent?.labelName ?? "Class",
      classNameContent?.labelKeyName ?? "Class",
      classNameContent?.shape ?? "circle",
      classNameContent?.fontColor ?? "#000",
      classNameContent?.fontWeight ?? "500",
      classNameContent?.fontStyle ?? "normal"
    )
  );
  const admissionFormik = useFormik(
    createFormikConfig(
      admissionContent?.x_axis ?? "33",
      admissionContent?.y_axis ?? "156",
      admissionContent?.fontSize ?? "9",
      admissionContent?.labelName ?? "Adm. No.:",
      admissionContent?.labelKeyName ?? "Admission Number",
      admissionContent?.shape ?? "circle",
      admissionContent?.fontColor ?? "#000",
      admissionContent?.fontWeight ?? "500",
      admissionContent?.fontStyle ?? "normal"
    )
  );
  const DOBFormik = useFormik(
    createFormikConfig(
      DOBContent?.x_axis ?? "33",
      DOBContent?.y_axis ?? "169",
      DOBContent?.fontSize ?? "9",
      DOBContent?.labelName ?? "DOB:",
      DOBContent?.labelKeyName ?? "DOB",
      DOBContent?.shape ?? "circle",
      DOBContent?.fontColor ?? "#000",
      DOBContent?.fontWeight ?? "500",
      DOBContent?.fontStyle ?? "normal"
    )
  );
  const addressFormik = useFormik(
    createFormikConfig(
      addressContent?.x_axis ?? "30",
      addressContent?.y_axis ?? "223",
      addressContent?.fontSize ?? "9",
      addressContent?.labelName ?? "Address",
      addressContent?.labelKeyName ?? "Address",
      addressContent?.shape ?? "circle",
      addressContent?.fontColor ?? "#000",
      addressContent?.fontWeight ?? "500",
      addressContent?.fontStyle ?? "normal"
    )
  );
  const fatherNameFormik = useFormik(
    createFormikConfig(
      fatherNameContent?.x_axis ?? "30",
      fatherNameContent?.y_axis ?? "195",
      fatherNameContent?.fontSize ?? "9",
      fatherNameContent?.labelName ?? "F.Name",
      fatherNameContent?.labelKeyName ?? "Father Name",
      fatherNameContent?.shape ?? "circle",
      fatherNameContent?.fontColor ?? "#000",
      fatherNameContent?.fontWeight ?? "500",
      fatherNameContent?.fontStyle ?? "normal"
    )
  );
  const motherNameFormik = useFormik(
    createFormikConfig(
      motherNameContent?.x_axis ?? "30",
      motherNameContent?.y_axis ?? "208",
      motherNameContent?.fontSize ?? "9",
      motherNameContent?.labelName ?? "M.Name",
      motherNameContent?.labelKeyName ?? "Mother Name",
      motherNameContent?.shape ?? "circle",
      motherNameContent?.fontColor ?? "#000",
      motherNameContent?.fontWeight ?? "500",
      motherNameContent?.fontStyle ?? "normal"
    )
  );
  const logoFormik = useFormik(
    createFormikConfig(
      logoContent?.x_axis ?? "10",
      logoContent?.y_axis ?? "13",
      logoContent?.fontSize ?? "35",
      logoContent?.labelName ?? "Logo",
      logoContent?.labelKeyName ?? "Logo",
      logoContent?.shape ?? "circle",
      logoContent?.fontColor ?? "#000",
      logoContent?.fontWeight ?? "500",
      logoContent?.fontStyle ?? "normal"
    )
  );
  const schoolNameFormik = useFormik(
    createFormikConfig(
      schoolNameContent?.x_axis ?? "44",
      schoolNameContent?.y_axis ?? "3",
      schoolNameContent?.fontSize ?? "14",
      schoolNameContent?.labelName ?? "School Name",
      schoolNameContent?.labelKeyName ?? "School Name",
      schoolNameContent?.shape ?? "circle",
      schoolNameContent?.fontColor ?? "#000",
      schoolNameContent?.fontWeight ?? "500",
      schoolNameContent?.fontStyle ?? "normal"
    )
  );

  const mobileNumberFormik = useFormik(
    createFormikConfig(
      mobileNumberContent?.x_axis ?? "33",
      mobileNumberContent?.y_axis ?? "180",
      mobileNumberContent?.fontSize ?? "9",
      mobileNumberContent?.labelName ?? "Mob No.",
      mobileNumberContent?.labelKeyName ?? "Mobile Number",
      mobileNumberContent?.shape ?? "circle",
      mobileNumberContent?.fontColor ?? "#000",
      mobileNumberContent?.fontWeight ?? "500",
      mobileNumberContent?.fontStyle ?? "normal"
    )
  );
  const principalSignFormik = useFormik(
    createFormikConfig(
      principalSignContent?.x_axis ?? "11",
      principalSignContent?.y_axis ?? "275",
      principalSignContent?.fontSize ?? "9",
      principalSignContent?.labelName ?? "Principal Sign",
      principalSignContent?.labelKeyName ?? "Principal Sign",
      principalSignContent?.shape ?? "circle",
      principalSignContent?.fontColor ?? "#000",
      principalSignContent?.fontWeight ?? "500",
      principalSignContent?.fontStyle ?? "normal"
    )
  );
  const transportFormik = useFormik(
    createFormikConfig(
      transportContent?.x_axis ?? "139",
      transportContent?.y_axis ?? "275",
      transportContent?.fontSize ?? "9",
      transportContent?.labelName ?? "Transport",
      transportContent?.labelKeyName ?? "Transport",
      transportContent?.shape ?? "circle",
      transportContent?.fontColor ?? "#000",
      transportContent?.fontWeight ?? "500",
      transportContent?.fontStyle ?? "normal"
    )
  );
  const schoolAddressFormik = useFormik(
    createFormikConfig(
      schoolAddressContent?.x_axis ?? "10",
      schoolAddressContent?.y_axis ?? "289",
      schoolAddressContent?.fontSize ?? "9",
      schoolAddressContent?.labelName ?? "School Address",
      schoolAddressContent?.labelKeyName ?? "School Address",
      schoolAddressContent?.shape ?? "circle",
      schoolAddressContent?.fontColor ?? "#000",
      schoolAddressContent?.fontWeight ?? "500",
      schoolAddressContent?.fontStyle ?? "normal"
    )
  );

  const idCardNumberFormik = useFormik(
    createFormikConfig(
      idCardNumberContent?.x_axis ?? "87",
      idCardNumberContent?.y_axis ?? "126",
      idCardNumberContent?.fontSize ?? "5",
      idCardNumberContent?.labelName ?? "ID",
      idCardNumberContent?.labelKeyName ?? "Id Card Number",
      idCardNumberContent?.shape ?? "circle",
      idCardNumberContent?.fontColor ?? "#000",
      idCardNumberContent?.fontWeight ?? "500",
      idCardNumberContent?.fontStyle ?? "normal"
    )
  );
  const admissonDateFormik = useFormik(
    createFormikConfig(
      admissionDateContent?.x_axis ?? "87",
      admissionDateContent?.y_axis ?? "126",
      admissionDateContent?.fontSize ?? "7",
      admissionDateContent?.labelName ?? "Adm Date",
      admissionDateContent?.labelKeyName ?? "Admission Date",
      admissionDateContent?.shape ?? "circle",
      admissionDateContent?.fontColor ?? "#000",
      admissionDateContent?.fontWeight ?? "500",
      admissionDateContent?.fontStyle ?? "normal"
    )
  );

  const rollNoFormik = useFormik(
    createFormikConfig(
      rollNoContent?.x_axis ?? "87",
      rollNoContent?.y_axis ?? "126",
      rollNoContent?.fontSize ?? "9",
      rollNoContent?.labelName ?? "Roll No",
      rollNoContent?.labelKeyName ?? "Roll No",
      rollNoContent?.shape ?? "circle",
      rollNoContent?.fontColor ?? "#000",
      rollNoContent?.fontWeight ?? "500",
      rollNoContent?.fontStyle ?? "normal"
    )
  );

  const genderFormik = useFormik(
    createFormikConfig(
      genderContent?.x_axis ?? "87",
      genderContent?.y_axis ?? "126",
      genderContent?.fontSize ?? "9",
      genderContent?.labelName ?? "Gender",
      genderContent?.labelKeyName ?? "Gender",
      genderContent?.shape ?? "circle",
      genderContent?.fontColor ?? "#000",
      genderContent?.fontWeight ?? "500",
      genderContent?.fontStyle ?? "normal"
    )
  );
  const bloodGroupFormik = useFormik(
    createFormikConfig(
      bloodGroupContent?.x_axis ?? "87",
      bloodGroupContent?.y_axis ?? "126",
      bloodGroupContent?.fontSize ?? "9",
      bloodGroupContent?.labelName ?? "Blood Group",
      bloodGroupContent?.labelKeyName ?? "Blood Group",
      bloodGroupContent?.shape ?? "circle",
      bloodGroupContent?.fontColor ?? "#000",
      bloodGroupContent?.fontWeight ?? "500",
      bloodGroupContent?.fontStyle ?? "normal"
    )
  );
  const regNoFormik = useFormik(
    createFormikConfig(
      regNoContent?.x_axis ?? "87",
      regNoContent?.y_axis ?? "126",
      regNoContent?.fontSize ?? "9",
      regNoContent?.labelName ?? "Reg No",
      regNoContent?.labelKeyName ?? "Reg No",
      regNoContent?.shape ?? "circle",
      regNoContent?.fontColor ?? "#000",
      regNoContent?.fontWeight ?? "500",
      regNoContent?.fontStyle ?? "normal"
    )
  );
  const srNoFormik = useFormik(
    createFormikConfig(
      srNoContent?.x_axis ?? "87",
      srNoContent?.y_axis ?? "126",
      srNoContent?.fontSize ?? "9",
      srNoContent?.labelName ?? "SR No",
      srNoContent?.labelKeyName ?? "SR No",
      srNoContent?.shape ?? "circle",
      srNoContent?.fontColor ?? "#000",
      srNoContent?.fontWeight ?? "500",
      srNoContent?.fontStyle ?? "normal"
    )
  );
  const penNoFormik = useFormik(
    createFormikConfig(
      penNoContent?.x_axis ?? "87",
      penNoContent?.y_axis ?? "126",
      penNoContent?.fontSize ?? "9",
      penNoContent?.labelName ?? "PEN No",
      penNoContent?.labelKeyName ?? "PEN No",
      penNoContent?.shape ?? "circle",
      penNoContent?.fontColor ?? "#000",
      penNoContent?.fontWeight ?? "500",
      penNoContent?.fontStyle ?? "normal"
    )
  );
  const admissionTypeFormik = useFormik(
    createFormikConfig(
      admissionTypeContent?.x_axis ?? "87",
      admissionTypeContent?.y_axis ?? "126",
      admissionTypeContent?.fontSize ?? "9",
      admissionTypeContent?.labelName ?? "Admission Type",
      admissionTypeContent?.labelKeyName ?? "Admission Type",
      admissionTypeContent?.shape ?? "circle",
      admissionTypeContent?.fontColor ?? "#000",
      admissionTypeContent?.fontWeight ?? "500",
      admissionTypeContent?.fontStyle ?? "normal"
    )
  );
  const feeRelaxationFormik = useFormik(
    createFormikConfig(
      feeRelaxationCategoryContent?.x_axis ?? "87",
      feeRelaxationCategoryContent?.y_axis ?? "126",
      feeRelaxationCategoryContent?.fontSize ?? "9",
      feeRelaxationCategoryContent?.labelName ?? "Fee Relaxation Category",
      feeRelaxationCategoryContent?.labelKeyName ?? "Fee Relaxation Category",
      feeRelaxationCategoryContent?.shape ?? "circle",
      feeRelaxationCategoryContent?.fontColor ?? "#000",
      feeRelaxationCategoryContent?.fontWeight ?? "500",
      feeRelaxationCategoryContent?.fontStyle ?? "normal"
    )
  );
  const houseFormik = useFormik(
    createFormikConfig(
      houseContent?.x_axis ?? "87",
      houseContent?.y_axis ?? "126",
      houseContent?.fontSize ?? "9",
      houseContent?.labelName ?? "House",
      houseContent?.labelKeyName ?? "House",
      houseContent?.shape ?? "circle",
      houseContent?.fontColor ?? "#000",
      houseContent?.fontWeight ?? "500",
      houseContent?.fontStyle ?? "normal"
    )
  );
  const aadharFormik = useFormik(
    createFormikConfig(
      aadharContent?.x_axis ?? "87",
      aadharContent?.y_axis ?? "126",
      aadharContent?.fontSize ?? "9",
      aadharContent?.labelName ?? "Aadhar",
      aadharContent?.labelKeyName ?? "Aadhar",
      aadharContent?.shape ?? "circle",
      aadharContent?.fontColor ?? "#000",
      aadharContent?.fontWeight ?? "500",
      aadharContent?.fontStyle ?? "normal"
    )
  );
  const categoryFormik = useFormik(
    createFormikConfig(
      categoryContent?.x_axis ?? "87",
      categoryContent?.y_axis ?? "126",
      categoryContent?.fontSize ?? "9",
      categoryContent?.labelName ?? "Category",
      categoryContent?.labelKeyName ?? "Category",
      categoryContent?.shape ?? "circle",
      categoryContent?.fontColor ?? "#000",
      categoryContent?.fontWeight ?? "500",
      categoryContent?.fontStyle ?? "normal"
    )
  );

  const [formikLoading, setFormikLoading] = useState(false);

  useEffect(() => {
    setFormikLoading(true);
    const formikDataChanger = async () => {
      if (state?.horizontalFrame) {
        await profilePicFormik?.setValues(
          createFormikConfig(
            profilePicContent?.x_axis ?? "26",
            profilePicContent?.y_axis ?? "155",
            profilePicContent?.fontSize ?? "29",
            profilePicContent?.labelName ?? "Profile Pic",
            profilePicContent?.labelKeyName ?? "Profile Pic",
            profilePicContent?.shape ?? "square",
            profilePicContent?.fontColor ?? "#000",
            profilePicContent?.fontWeight ?? "500",
            profilePicContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await schoolContactNumberFormik?.setValues(
          createFormikConfig(
            schoolContactNumberContent?.x_axis ?? "26",
            schoolContactNumberContent?.y_axis ?? "145",
            schoolContactNumberContent?.fontSize ?? "20",
            schoolContactNumberContent?.labelName ?? "School Contact Number",
            schoolContactNumberContent?.labelKeyName ?? "School Contact Number",
            schoolContactNumberContent?.shape ?? "square",
            schoolContactNumberContent?.fontColor ?? "#000",
            schoolContactNumberContent?.fontWeight ?? "500",
            schoolContactNumberContent?.fontStyle ?? "normal"
          )?.initialValues
        );

        await nameFormik?.setValues(
          createFormikConfig(
            nameContent?.x_axis ?? "62",
            nameContent?.y_axis ?? "142",
            nameContent?.fontSize ?? "7",
            nameContent?.labelName ?? "Name",
            nameContent?.labelKeyName ?? "Name",
            nameContent?.shape ?? "circle",
            nameContent?.fontColor ?? "#000",
            nameContent?.fontWeight ?? "500",
            nameContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await classNameFormik?.setValues(
          createFormikConfig(
            classNameContent?.x_axis ?? "51",
            classNameContent?.y_axis ?? "150",
            classNameContent?.fontSize ?? "7",
            classNameContent?.labelName ?? "Class",
            classNameContent?.labelKeyName ?? "Class",
            classNameContent?.shape ?? "circle",
            classNameContent?.fontColor ?? "#000",
            classNameContent?.fontWeight ?? "500",
            classNameContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await admissionFormik?.setValues(
          createFormikConfig(
            admissionContent?.x_axis ?? "43",
            admissionContent?.y_axis ?? "157",
            admissionContent?.fontSize ?? "7",
            admissionContent?.labelName ?? "Adm. No.:",
            admissionContent?.labelKeyName ?? "Admission Number",
            admissionContent?.shape ?? "circle",
            admissionContent?.fontColor ?? "#000",
            admissionContent?.fontWeight ?? "500",
            admissionContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await DOBFormik?.setValues(
          createFormikConfig(
            DOBContent?.x_axis ?? "35",
            DOBContent?.y_axis ?? "165",
            DOBContent?.fontSize ?? "7",
            DOBContent?.labelName ?? "DOB:",
            DOBContent?.labelKeyName ?? "DOB",
            DOBContent?.shape ?? "circle",
            DOBContent?.fontColor ?? "#000",
            DOBContent?.fontWeight ?? "500",
            DOBContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await addressFormik?.setValues(
          createFormikConfig(
            addressContent?.x_axis ?? "1",
            addressContent?.y_axis ?? "195",
            addressContent?.fontSize ?? "7",
            addressContent?.labelName ?? "Address",
            addressContent?.labelKeyName ?? "Address",
            addressContent?.shape ?? "circle",
            addressContent?.fontColor ?? "#000",
            addressContent?.fontWeight ?? "500",
            addressContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await fatherNameFormik?.setValues(
          createFormikConfig(
            fatherNameContent?.x_axis ?? "38",
            fatherNameContent?.y_axis ?? "179",
            fatherNameContent?.fontSize ?? "7",
            fatherNameContent?.labelName ?? "F.Name",
            fatherNameContent?.labelKeyName ?? "Father Name",
            fatherNameContent?.shape ?? "circle",
            fatherNameContent?.fontColor ?? "#000",
            fatherNameContent?.fontWeight ?? "500",
            fatherNameContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await motherNameFormik?.setValues(
          createFormikConfig(
            motherNameContent?.x_axis ?? "39",
            motherNameContent?.y_axis ?? "186",
            motherNameContent?.fontSize ?? "7",
            motherNameContent?.labelName ?? "M.Name",
            motherNameContent?.labelKeyName ?? "Mother Name",
            motherNameContent?.shape ?? "circle",
            motherNameContent?.fontColor ?? "#000",
            motherNameContent?.fontWeight ?? "500",
            motherNameContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await logoFormik?.setValues(
          createFormikConfig(
            logoContent?.x_axis ?? "23",
            logoContent?.y_axis ?? "119",
            logoContent?.fontSize ?? "21",
            logoContent?.labelName ?? "Logo",
            logoContent?.labelKeyName ?? "Logo",
            logoContent?.shape ?? "circle",
            logoContent?.fontColor ?? "#000",
            logoContent?.fontWeight ?? "500",
            logoContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await schoolNameFormik?.setValues(
          createFormikConfig(
            schoolNameContent?.x_axis ?? "47",
            schoolNameContent?.y_axis ?? "106",
            schoolNameContent?.fontSize ?? "8",
            schoolNameContent?.labelName ?? "School Name",
            schoolNameContent?.labelKeyName ?? "School Name",
            schoolNameContent?.shape ?? "circle",
            schoolNameContent?.fontColor ?? "#000",
            schoolNameContent?.fontWeight ?? "500",
            schoolNameContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await mobileNumberFormik?.setValues(
          createFormikConfig(
            mobileNumberContent?.x_axis ?? "41",
            mobileNumberContent?.y_axis ?? "173",
            mobileNumberContent?.fontSize ?? "7",
            mobileNumberContent?.labelName ?? "Mob No.",
            mobileNumberContent?.labelKeyName ?? "Mobile Number",
            mobileNumberContent?.shape ?? "circle",
            mobileNumberContent?.fontColor ?? "#000",
            mobileNumberContent?.fontWeight ?? "500",
            mobileNumberContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await principalSignFormik?.setValues(
          createFormikConfig(
            principalSignContent?.x_axis ?? "22",
            principalSignContent?.y_axis ?? "191",
            principalSignContent?.fontSize ?? "5",
            principalSignContent?.labelName ?? "Principal Sign",
            principalSignContent?.labelKeyName ?? "Principal Sign",
            principalSignContent?.shape ?? "circle",
            principalSignContent?.fontColor ?? "#000",
            principalSignContent?.fontWeight ?? "500",
            principalSignContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await transportFormik?.setValues(
          createFormikConfig(
            transportContent?.x_axis ?? "21",
            transportContent?.y_axis ?? "198",
            transportContent?.fontSize ?? "5",
            transportContent?.labelName ?? "Transport",
            transportContent?.labelKeyName ?? "Transport",
            transportContent?.shape ?? "circle",
            transportContent?.fontColor ?? "#000",
            transportContent?.fontWeight ?? "500",
            transportContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await schoolAddressFormik?.setValues(
          createFormikConfig(
            schoolAddressContent?.x_axis ?? "35",
            schoolAddressContent?.y_axis ?? "125",
            schoolAddressContent?.fontSize ?? "5",
            schoolAddressContent?.labelName ?? "School Address",
            schoolAddressContent?.labelKeyName ?? "School Address",
            schoolAddressContent?.shape ?? "circle",
            schoolAddressContent?.fontColor ?? "#000",
            schoolAddressContent?.fontWeight ?? "500",
            schoolAddressContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await idCardNumberFormik?.setValues(
          createFormikConfig(
            idCardNumberContent?.x_axis ?? "26",
            idCardNumberContent?.y_axis ?? "147",
            idCardNumberContent?.fontSize ?? "5",
            idCardNumberContent?.labelName ?? "ID",
            idCardNumberContent?.labelKeyName ?? "Id Card Number",
            idCardNumberContent?.shape ?? "circle",
            idCardNumberContent?.fontColor ?? "#000",
            idCardNumberContent?.fontWeight ?? "500",
            idCardNumberContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await admissonDateFormik?.setValues(
          createFormikConfig(
            admissionContent?.x_axis ?? "26",
            admissionContent?.y_axis ?? "147",
            admissionContent?.fontSize ?? "7",
            admissionContent?.labelName ?? "Adm Date",
            admissionContent?.labelKeyName ?? "Admission Date",
            admissionContent?.shape ?? "circle",
            admissionContent?.fontColor ?? "#000",
            admissionContent?.fontWeight ?? "500",
            admissionContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await rollNoFormik?.setValues(
          createFormikConfig(
            rollNoContent?.x_axis ?? "26",
            rollNoContent?.y_axis ?? "147",
            rollNoContent?.fontSize ?? "7",
            rollNoContent?.labelName ?? "Roll No",
            rollNoContent?.labelKeyName ?? "Roll No",
            rollNoContent?.shape ?? "circle",
            rollNoContent?.fontColor ?? "#000",
            rollNoContent?.fontWeight ?? "500",
            rollNoContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await genderFormik?.setValues(
          createFormikConfig(
            genderContent?.x_axis ?? "26",
            genderContent?.y_axis ?? "147",
            genderContent?.fontSize ?? "7",
            genderContent?.labelName ?? "Gender",
            genderContent?.labelKeyName ?? "Gender",
            genderContent?.shape ?? "circle",
            genderContent?.fontColor ?? "#000",
            genderContent?.fontWeight ?? "500",
            genderContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await bloodGroupFormik?.setValues(
          createFormikConfig(
            bloodGroupContent?.x_axis ?? "26",
            bloodGroupContent?.y_axis ?? "147",
            bloodGroupContent?.fontSize ?? "7",
            bloodGroupContent?.labelName ?? "Blood Group",
            bloodGroupContent?.labelKeyName ?? "Blood Group",
            bloodGroupContent?.shape ?? "circle",
            bloodGroupContent?.fontColor ?? "#000",
            bloodGroupContent?.fontWeight ?? "500",
            bloodGroupContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await regNoFormik?.setValues(
          createFormikConfig(
            regNoContent?.x_axis ?? "26",
            regNoContent?.y_axis ?? "147",
            regNoContent?.fontSize ?? "7",
            regNoContent?.labelName ?? "Reg No",
            regNoContent?.labelKeyName ?? "Reg No",
            regNoContent?.shape ?? "circle",
            regNoContent?.fontColor ?? "#000",
            regNoContent?.fontWeight ?? "500",
            regNoContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await srNoFormik?.setValues(
          createFormikConfig(
            srNoContent?.x_axis ?? "26",
            srNoContent?.y_axis ?? "147",
            srNoContent?.fontSize ?? "7",
            srNoContent?.labelName ?? "SR No",
            srNoContent?.labelKeyName ?? "SR No",
            srNoContent?.shape ?? "circle",
            srNoContent?.fontColor ?? "#000",
            srNoContent?.fontWeight ?? "500",
            srNoContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await penNoFormik?.setValues(
          createFormikConfig(
            penNoContent?.x_axis ?? "26",
            penNoContent?.y_axis ?? "147",
            penNoContent?.fontSize ?? "7",
            penNoContent?.labelName ?? "PEN No",
            penNoContent?.labelKeyName ?? "PEN No",
            penNoContent?.shape ?? "circle",
            penNoContent?.fontColor ?? "#000",
            penNoContent?.fontWeight ?? "500",
            penNoContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await admissionTypeFormik?.setValues(
          createFormikConfig(
            admissionTypeContent?.x_axis ?? "26",
            admissionTypeContent?.y_axis ?? "147",
            admissionTypeContent?.fontSize ?? "7",
            admissionTypeContent?.labelName ?? "Admission Type",
            admissionTypeContent?.labelKeyName ?? "Admission Type",
            admissionTypeContent?.shape ?? "circle",
            admissionTypeContent?.fontColor ?? "#000",
            admissionTypeContent?.fontWeight ?? "500",
            admissionTypeContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await feeRelaxationFormik?.setValues(
          createFormikConfig(
            feeRelaxationCategoryContent?.x_axis ?? "26",
            feeRelaxationCategoryContent?.y_axis ?? "147",
            feeRelaxationCategoryContent?.fontSize ?? "7",
            feeRelaxationCategoryContent?.labelName ??
              "Fee Relaxation Category",
            feeRelaxationCategoryContent?.labelKeyName ??
              "Fee Relaxation Category",
            feeRelaxationCategoryContent?.shape ?? "circle",
            feeRelaxationCategoryContent?.fontColor ?? "#000",
            feeRelaxationCategoryContent?.fontWeight ?? "500",
            feeRelaxationCategoryContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await houseFormik?.setValues(
          createFormikConfig(
            houseContent?.x_axis ?? "26",
            houseContent?.y_axis ?? "147",
            houseContent?.fontSize ?? "7",
            houseContent?.labelName ?? "House",
            houseContent?.labelKeyName ?? "House",
            houseContent?.shape ?? "circle",
            houseContent?.fontColor ?? "#000",
            houseContent?.fontWeight ?? "500",
            houseContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await aadharFormik?.setValues(
          createFormikConfig(
            aadharContent?.x_axis ?? "26",
            aadharContent?.y_axis ?? "147",
            aadharContent?.fontSize ?? "7",
            aadharContent?.labelName ?? "Aadhar",
            aadharContent?.labelKeyName ?? "Aadhar",
            aadharContent?.shape ?? "circle",
            aadharContent?.fontColor ?? "#000",
            aadharContent?.fontWeight ?? "500",
            aadharContent?.fontStyle ?? "normal"
          )?.initialValues
        );

        await categoryFormik?.setValues(
          createFormikConfig(
            categoryContent?.x_axis ?? "26",
            categoryContent?.y_axis ?? "147",
            categoryContent?.fontSize ?? "7",
            categoryContent?.labelName ?? "Category",
            categoryContent?.labelKeyName ?? "Category",
            categoryContent?.shape ?? "circle",
            categoryContent?.fontColor ?? "#000",
            categoryContent?.fontWeight ?? "500",
            categoryContent?.fontStyle ?? "normal"
          )?.initialValues
        );
      } else {
        await profilePicFormik?.setValues(
          createFormikConfig(
            profilePicContent?.x_axis ?? "76",
            profilePicContent?.y_axis ?? "71",
            profilePicContent?.fontSize ?? "55",
            profilePicContent?.labelName ?? "Profile Pic",
            profilePicContent?.labelKeyName ?? "Profile Pic",
            profilePicContent?.shape ?? "circle",
            profilePicContent?.fontColor ?? "#000",
            profilePicContent?.fontWeight ?? "500",
            profilePicContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await schoolContactNumberFormik?.setValues(
          createFormikConfig(
            schoolContactNumberContent?.x_axis ?? "60",
            schoolContactNumberContent?.y_axis ?? "71",
            schoolContactNumberContent?.fontSize ?? "20",
            schoolContactNumberContent?.labelName ?? "School Contact Number",
            schoolContactNumberContent?.labelKeyName ?? "School Contact Number",
            schoolContactNumberContent?.shape ?? "circle",
            schoolContactNumberContent?.fontColor ?? "#000",
            schoolContactNumberContent?.fontWeight ?? "500",
            schoolContactNumberContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await nameFormik?.setValues(
          createFormikConfig(
            nameContent?.x_axis ?? "54",
            nameContent?.y_axis ?? "129",
            nameContent?.fontSize ?? "12",
            nameContent?.labelName ?? "Name",
            nameContent?.labelKeyName ?? "Name",
            nameContent?.shape ?? "circle",
            nameContent?.fontColor ?? "#000",
            nameContent?.fontWeight ?? "500",
            nameContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await classNameFormik?.setValues(
          createFormikConfig(
            classNameContent?.x_axis ?? "72",
            classNameContent?.y_axis ?? "143",
            classNameContent?.fontSize ?? "10",
            classNameContent?.labelName ?? "Class",
            classNameContent?.labelKeyName ?? "Class",
            classNameContent?.shape ?? "circle",
            classNameContent?.fontColor ?? "#000",
            classNameContent?.fontWeight ?? "500",
            classNameContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await admissionFormik?.setValues(
          createFormikConfig(
            admissionContent?.x_axis ?? "33",
            admissionContent?.y_axis ?? "156",
            admissionContent?.fontSize ?? "9",
            admissionContent?.labelName ?? "Adm. No.:",
            admissionContent?.labelKeyName ?? "Admission Number",
            admissionContent?.shape ?? "circle",
            admissionContent?.fontColor ?? "#000",
            admissionContent?.fontWeight ?? "500",
            admissionContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await DOBFormik?.setValues(
          createFormikConfig(
            DOBContent?.x_axis ?? "33",
            DOBContent?.y_axis ?? "169",
            DOBContent?.fontSize ?? "9",
            DOBContent?.labelName ?? "DOB:",
            DOBContent?.labelKeyName ?? "DOB",
            DOBContent?.shape ?? "circle",
            DOBContent?.fontColor ?? "#000",
            DOBContent?.fontWeight ?? "500",
            DOBContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await addressFormik?.setValues(
          createFormikConfig(
            addressContent?.x_axis ?? "30",
            addressContent?.y_axis ?? "223",
            addressContent?.fontSize ?? "9",
            addressContent?.labelName ?? "Address",
            addressContent?.labelKeyName ?? "Address",
            addressContent?.shape ?? "circle",
            addressContent?.fontColor ?? "#000",
            addressContent?.fontWeight ?? "500",
            addressContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await fatherNameFormik?.setValues(
          createFormikConfig(
            fatherNameContent?.x_axis ?? "30",
            fatherNameContent?.y_axis ?? "195",
            fatherNameContent?.fontSize ?? "9",
            fatherNameContent?.labelName ?? "F.Name",
            fatherNameContent?.labelKeyName ?? "Father Name",
            fatherNameContent?.shape ?? "circle",
            fatherNameContent?.fontColor ?? "#000",
            fatherNameContent?.fontWeight ?? "500",
            fatherNameContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await motherNameFormik?.setValues(
          createFormikConfig(
            motherNameContent?.x_axis ?? "30",
            motherNameContent?.y_axis ?? "208",
            motherNameContent?.fontSize ?? "9",
            motherNameContent?.labelName ?? "M.Name",
            motherNameContent?.labelKeyName ?? "Mother Name",
            motherNameContent?.shape ?? "circle",
            motherNameContent?.fontColor ?? "#000",
            motherNameContent?.fontWeight ?? "500",
            motherNameContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await logoFormik?.setValues(
          createFormikConfig(
            logoContent?.x_axis ?? "10",
            logoContent?.y_axis ?? "13",
            logoContent?.fontSize ?? "35",
            logoContent?.labelName ?? "Logo",
            logoContent?.labelKeyName ?? "Logo",
            logoContent?.shape ?? "circle",
            logoContent?.fontColor ?? "#000",
            logoContent?.fontWeight ?? "500",
            logoContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await schoolNameFormik?.setValues(
          createFormikConfig(
            schoolNameContent?.x_axis ?? "44",
            schoolNameContent?.y_axis ?? "3",
            schoolNameContent?.fontSize ?? "14",
            schoolNameContent?.labelName ?? "School Name",
            schoolNameContent?.labelKeyName ?? "School Name",
            schoolNameContent?.shape ?? "circle",
            schoolNameContent?.fontColor ?? "#000",
            schoolNameContent?.fontWeight ?? "500",
            schoolNameContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await mobileNumberFormik?.setValues(
          createFormikConfig(
            mobileNumberContent?.x_axis ?? "33",
            mobileNumberContent?.y_axis ?? "180",
            mobileNumberContent?.fontSize ?? "9",
            mobileNumberContent?.labelName ?? "Mob No.",
            mobileNumberContent?.labelKeyName ?? "Mobile Number",
            mobileNumberContent?.shape ?? "circle",
            mobileNumberContent?.fontColor ?? "#000",
            mobileNumberContent?.fontWeight ?? "500",
            mobileNumberContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await principalSignFormik?.setValues(
          createFormikConfig(
            principalSignContent?.x_axis ?? "11",
            principalSignContent?.y_axis ?? "275",
            principalSignContent?.fontSize ?? "9",
            principalSignContent?.labelName ?? "Principal Sign",
            principalSignContent?.labelKeyName ?? "Principal Sign",
            principalSignContent?.shape ?? "circle",
            principalSignContent?.fontColor ?? "#000",
            principalSignContent?.fontWeight ?? "500",
            principalSignContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await transportFormik?.setValues(
          createFormikConfig(
            transportContent?.x_axis ?? "139",
            transportContent?.y_axis ?? "275",
            transportContent?.fontSize ?? "9",
            transportContent?.labelName ?? "Transport",
            transportContent?.labelKeyName ?? "Transport",
            transportContent?.shape ?? "circle",
            transportContent?.fontColor ?? "#000",
            transportContent?.fontWeight ?? "500",
            transportContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await schoolAddressFormik?.setValues(
          createFormikConfig(
            schoolAddressContent?.x_axis ?? "10",
            schoolAddressContent?.y_axis ?? "289",
            schoolAddressContent?.fontSize ?? "9",
            schoolAddressContent?.labelName ?? "School Address",
            schoolAddressContent?.labelKeyName ?? "School Address",
            schoolAddressContent?.shape ?? "circle",
            schoolAddressContent?.fontColor ?? "#000",
            schoolAddressContent?.fontWeight ?? "500",
            schoolAddressContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await idCardNumberFormik?.setValues(
          createFormikConfig(
            idCardNumberContent?.x_axis ?? "87",
            idCardNumberContent?.y_axis ?? "126",
            idCardNumberContent?.fontSize ?? "5",
            idCardNumberContent?.labelName ?? "ID",
            idCardNumberContent?.labelKeyName ?? "Id Card Number",
            idCardNumberContent?.shape ?? "circle",
            idCardNumberContent?.fontColor ?? "#000",
            idCardNumberContent?.fontWeight ?? "500",
            idCardNumberContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await admissonDateFormik?.setValues(
          createFormikConfig(
            admissionDateContent?.x_axis ?? "23",
            admissionDateContent?.y_axis ?? "200",
            admissionDateContent?.fontSize ?? "9",
            admissionDateContent?.labelName ?? "Adm Date",
            admissionDateContent?.labelKeyName ?? "Admission Date",
            admissionDateContent?.shape ?? "circle",
            admissionDateContent?.fontColor ?? "#000",
            admissionDateContent?.fontWeight ?? "500",
            admissionDateContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await rollNoFormik?.setValues(
          createFormikConfig(
            rollNoContent?.x_axis ?? "23",
            rollNoContent?.y_axis ?? "200",
            rollNoContent?.fontSize ?? "9",
            rollNoContent?.labelName ?? "",
            rollNoContent?.labelKeyName ?? "Roll No",
            rollNoContent?.shape ?? "circle",
            rollNoContent?.fontColor ?? "#000",
            rollNoContent?.fontWeight ?? "500",
            rollNoContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await genderFormik?.setValues(
          createFormikConfig(
            genderContent?.x_axis ?? "26",
            genderContent?.y_axis ?? "147",
            genderContent?.fontSize ?? "7",
            genderContent?.labelName ?? "Gender",
            genderContent?.labelKeyName ?? "Gender",
            genderContent?.shape ?? "circle",
            genderContent?.fontColor ?? "#000",
            genderContent?.fontWeight ?? "500",
            genderContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await bloodGroupFormik?.setValues(
          createFormikConfig(
            bloodGroupContent?.x_axis ?? "26",
            bloodGroupContent?.y_axis ?? "147",
            bloodGroupContent?.fontSize ?? "7",
            bloodGroupContent?.labelName ?? "Blood Group",
            bloodGroupContent?.labelKeyName ?? "Blood Group",
            bloodGroupContent?.shape ?? "circle",
            bloodGroupContent?.fontColor ?? "#000",
            bloodGroupContent?.fontWeight ?? "500",
            bloodGroupContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await regNoFormik?.setValues(
          createFormikConfig(
            regNoContent?.x_axis ?? "26",
            regNoContent?.y_axis ?? "147",
            regNoContent?.fontSize ?? "7",
            regNoContent?.labelName ?? "Reg No",
            regNoContent?.labelKeyName ?? "Reg No",
            regNoContent?.shape ?? "circle",
            regNoContent?.fontColor ?? "#000",
            regNoContent?.fontWeight ?? "500",
            regNoContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await srNoFormik?.setValues(
          createFormikConfig(
            srNoContent?.x_axis ?? "26",
            srNoContent?.y_axis ?? "147",
            srNoContent?.fontSize ?? "7",
            srNoContent?.labelName ?? "SR No",
            srNoContent?.labelKeyName ?? "SR No",
            srNoContent?.shape ?? "circle",
            srNoContent?.fontColor ?? "#000",
            srNoContent?.fontWeight ?? "500",
            srNoContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await penNoFormik?.setValues(
          createFormikConfig(
            penNoContent?.x_axis ?? "26",
            penNoContent?.y_axis ?? "147",
            penNoContent?.fontSize ?? "7",
            penNoContent?.labelName ?? "PEN No",
            penNoContent?.labelKeyName ?? "PEN No",
            penNoContent?.shape ?? "circle",
            penNoContent?.fontColor ?? "#000",
            penNoContent?.fontWeight ?? "500",
            penNoContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await admissionTypeFormik?.setValues(
          createFormikConfig(
            admissionTypeContent?.x_axis ?? "26",
            admissionTypeContent?.y_axis ?? "147",
            admissionTypeContent?.fontSize ?? "7",
            admissionTypeContent?.labelName ?? "Admission Type",
            admissionTypeContent?.labelKeyName ?? "Admission Type",
            admissionTypeContent?.shape ?? "circle",
            admissionTypeContent?.fontColor ?? "#000",
            admissionTypeContent?.fontWeight ?? "500",
            admissionTypeContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await feeRelaxationFormik?.setValues(
          createFormikConfig(
            feeRelaxationCategoryContent?.x_axis ?? "26",
            feeRelaxationCategoryContent?.y_axis ?? "147",
            feeRelaxationCategoryContent?.fontSize ?? "7",
            feeRelaxationCategoryContent?.labelName ??
              "Fee Relaxation Category",
            feeRelaxationCategoryContent?.labelKeyName ??
              "Fee Relaxation Category",
            feeRelaxationCategoryContent?.shape ?? "circle",
            feeRelaxationCategoryContent?.fontColor ?? "#000",
            feeRelaxationCategoryContent?.fontWeight ?? "500",
            feeRelaxationCategoryContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await houseFormik?.setValues(
          createFormikConfig(
            houseContent?.x_axis ?? "26",
            houseContent?.y_axis ?? "147",
            houseContent?.fontSize ?? "7",
            houseContent?.labelName ?? "House",
            houseContent?.labelKeyName ?? "House",
            houseContent?.shape ?? "circle",
            houseContent?.fontColor ?? "#000",
            houseContent?.fontWeight ?? "500",
            houseContent?.fontStyle ?? "normal"
          )?.initialValues
        );
        await aadharFormik?.setValues(
          createFormikConfig(
            aadharContent?.x_axis ?? "26",
            aadharContent?.y_axis ?? "147",
            aadharContent?.fontSize ?? "7",
            aadharContent?.labelName ?? "Aadhar",
            aadharContent?.labelKeyName ?? "Aadhar",
            aadharContent?.shape ?? "circle",
            aadharContent?.fontColor ?? "#000",
            aadharContent?.fontWeight ?? "500",
            aadharContent?.fontStyle ?? "normal"
          )?.initialValues
        );

        await categoryFormik?.setValues(
          createFormikConfig(
            categoryContent?.x_axis ?? "26",
            categoryContent?.y_axis ?? "147",
            categoryContent?.fontSize ?? "7",
            categoryContent?.labelName ?? "Category",
            categoryContent?.labelKeyName ?? "Category",
            categoryContent?.shape ?? "circle",
            categoryContent?.fontColor ?? "#000",
            categoryContent?.fontWeight ?? "500",
            categoryContent?.fontStyle ?? "normal"
          )?.initialValues
        );
      }
    };
    formikDataChanger();
    setFormikLoading(false);
  }, [state?.horizontalFrame, state?.verticalFrame, frameCode]);

  const handlePayUpload = (e) => {
    let file = e.target.files[0];
    let fileType = file.type;
    let fileSize = file.size;

    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
    if (!allowedTypes.includes(fileType)) {
      toast.error("Invalid file type");
      return;
    }
    if (fileSize > 5 * 1000000) {
      // fileSize > 5MB then show popup message
      toast.error(
        `File size is too large, please upload image of size less than 5MB.\nSelected File Size: ${
          fileSize / 1000000
        }MB only`
      );
      return;
    }
    setState((prev) => ({
      ...prev,
      frameImage: {
        file: e.target.files[0],
        link: URL.createObjectURL(e.target.files[0]),
      },
    }));
  };

  useEffect(() => {
    if (state?.frameImage?.file) {
      handleUploadPayImage();
    }
  }, [state?.frameImage?.file]);

  const handleUploadPayImage = async (values) => {
    try {
      setLoading(true);
      let s3FilePath = `public/${state?.frameImage?.file?.name}`;
      let attachmentUrl = await uploadFile(
        state?.frameImage?.file,
        s3FilePath,
        state?.frameImage?.file?.type
      );
      setState((prev) => ({
        ...prev,
        backgroundFrame: attachmentUrl?.fileURL,
      }));
      setLoading(false);
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  };

  const {
    mutate: customizeIdCardFrameMutate,
    isLoading: customizeIdCardFrameLoading,
  } = useMutation(customizeIdCardFrame, {
    queryKey: ["customizeIdCardFrame"],
    onSuccess: ({ data }) => {
      toast.success(data?.message);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
    enabled: false,
  });
  const { mutate: editIdCardFrameMutate, isLoading: editIdCardFrameLoading } =
    useMutation(editIdCardFrame, {
      queryKey: ["editIdCardFrame"],
      onSuccess: ({ data }) => {
        toast.success(data?.message);
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
      enabled: false,
    });

  const reportTemplateRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => reportTemplateRef.current,
    documentTitle: "Id Card",
    pageStyle: {},
  });

  const fields = [
    { stateKey: state?.logo, formik: logoFormik, labelKeyName: "Logo" },
    {
      stateKey: state?.schoolName,
      formik: schoolNameFormik,
      labelKeyName: "schoolName",
    },
    {
      stateKey: state?.profilePic,
      formik: profilePicFormik,
      labelKeyName: "Profile Pic",
    },
    {
      stateKey: state?.StudentName,
      formik: nameFormik,
      labelKeyName: "Name",
    },
    {
      stateKey: state?.Class,
      formik: classNameFormik,
      labelKeyName: "Class",
    },
    {
      stateKey: state?.AdmissionNumber,
      formik: admissionFormik,
      labelKeyName: "Admission Number",
    },
    { stateKey: state?.DOB, formik: DOBFormik, labelKeyName: "DOB" },
    {
      stateKey: state?.MobileNumber,
      formik: mobileNumberFormik,
      labelKeyName: "Mobile Number",
    },
    {
      stateKey: state?.FatherName,
      formik: fatherNameFormik,
      labelKeyName: "Father Name",
    },
    {
      stateKey: state?.MotherName,
      formik: motherNameFormik,
      labelKeyName: "Mother Name",
    },
    {
      stateKey: state?.Address,
      formik: addressFormik,
      labelKeyName: "Address",
    },
    {
      stateKey: state?.PrincipalSign,
      formik: principalSignFormik,
      labelKeyName: "Principal Sign",
    },
    {
      stateKey: state?.Transport,
      formik: transportFormik,
      labelKeyName: "Transport",
    },
    {
      stateKey: state?.IdCardNumber,
      formik: idCardNumberFormik,
      labelKeyName: "Id Card Number",
    },
    {
      stateKey: state?.SchoolAddress,
      formik: schoolAddressFormik,
      labelKeyName: "School Address",
    },
    {
      stateKey: state?.admissionDate,
      formik: admissonDateFormik,
      labelKeyName: "Admission Date",
    },
    {
      stateKey: state?.rollNo,
      formik: rollNoFormik,
      labelKeyName: "Roll No",
    },
    {
      stateKey: state?.gender,
      formik: genderFormik,
      labelKeyName: "Gender",
    },
    {
      stateKey: state?.bloodGroup,
      formik: bloodGroupFormik,
      labelKeyName: "Blood Group",
    },
    {
      stateKey: state?.regNo,
      formik: regNoFormik,
      labelKeyName: "Reg No",
    },
    {
      stateKey: state?.srNo,
      formik: srNoFormik,
      labelKeyName: "SR No",
    },
    {
      stateKey: state?.penNo,
      formik: penNoFormik,
      labelKeyName: "PEN No",
    },
    {
      stateKey: state?.admissionType,
      formik: admissionTypeFormik,
      labelKeyName: "Admission Type",
    },
    {
      stateKey: state?.feeRelaxationCategory,
      formik: feeRelaxationFormik,
      labelKeyName: "Fee Relaxation Category",
    },
    {
      stateKey: state?.house,
      formik: houseFormik,
      labelKeyName: "House",
    },
    {
      stateKey: state?.aadhar,
      formik: aadharFormik,
      labelKeyName: "Aadhar",
    },
    {
      stateKey: state?.category,
      formik: categoryFormik,
      labelKeyName: "Category",
    },
    {
      stateKey: state?.schoolContact,
      formik: schoolContactNumberFormik,
      labelKeyName: "School Contact Number",
    },
  ];

  const handleSaveTemplate = () => {
    addIdCardFrameMutate({
      themeColor: "red",
      for: "Student",
      orientation: state?.verticalFrame ? "portrait" : "landscape",
      width: "200",
      height: "200",
      sizeFor: "Normal",
      frameURL: state?.backgroundFrame,
      contents: fields.flatMap((field) => generateObject(field)),
    });
  };

  const handleCustomize = () => {
    editIdCardFrameMutate({
      idCardFrameDocId: existingFrames?._id,
      themeColor: "red",
      for: "Student",
      orientation: state?.verticalFrame ? "portrait" : "landscape",
      width: "200",
      height: "200",
      sizeFor: "Normal",
      frameURL: state?.backgroundFrame,
      contents: fields.flatMap((field) => generateObject(field)),
    });
  };

  // useEffect for for the handling of the asynchronous data
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      backgroundFrame: existingFrames?.frameURL,
      Address: addressContent ? true : false,
      Class: classNameContent ? true : false,
      AdmissionNumber: admissionContent ? true : false,
      DOB: DOBContent ? true : false,
      FatherName: fatherNameContent ? true : false,
      MotherName: motherNameContent ? true : false,
      MobileNumber: mobileNumberContent ? true : false,
      StudentName: nameContent ? true : false,
      PrincipalSign: principalSignContent ? true : false,
      Transport: transportContent ? true : false,
      SchoolAddress: schoolAddressContent ? true : false,
      IdCardNumber: idCardNumberContent ? true : false,
      logo: logoContent ? true : false,
      profilePic: profilePicContent ? true : false,
      schoolName: schoolNameContent ? true : false,
      admissionDate: admissionDateContent ? true : false,
      rollNo: rollNoContent ? true : false,
    }));
  }, [frameCode, getTotalIdCardFrame_Data]);

  const DetailsOnCard = () => {
    return (
      <>
        {/* school name */}
        {state?.schoolName && (
          <TypographyFormik
            formik={schoolNameFormik}
            value={"Ekalsutra Edtech Pvt Ltd"}
            fontSize="20px"
            textAling="center"
          />
        )}
        {/* school contact number */}
        {state?.schoolContact && (
          <StudentTypographyFormik
            formik={schoolContactNumberFormik}
            value={selectedSchool?.mobileNumber ?? "1234567890"}
            label={
              schoolContactNumberFormik?.values?.labelName ??
              "School Contact Number: "
            }
            close={false}
          />
        )}
        {/* adress */}
        {state?.SchoolAddress && (
          <TypographyFormik
            formik={schoolAddressFormik}
            label="School Address: "
            value={`${selectedSchool?.schoolAddress?.address ?? "Address"}, ${
              selectedSchool?.schoolAddress?.dist ?? "District"
            }, ${selectedSchool?.schoolAddress?.state ?? "State"}, ${
              selectedSchool?.schoolAddress?.pinCode ?? "Pincode"
            }`}
          />
        )}
        {/* name */}
        {state?.StudentName && (
          <StudentTypographyFormik
            formik={nameFormik}
            value={nameFormik?.values?.labelName ?? "Student Name"}
          />
        )}
        {/* classname */}
        {state?.Class && (
          <StudentTypographyFormik
            formik={classNameFormik}
            // value={"Class Name"}

            label={classNameFormik?.values?.labelName ?? "Class Name"}
          />
        )}
        {/* admission Number */}
        {state?.AdmissionNumber && (
          <StudentTypographyFormik
            formik={admissionFormik}
            label={admissionFormik?.values?.labelName ?? "Adm. No.: "}
            value="200737308"
          />
        )}
        {/* DOB */}
        {state?.DOB && (
          <StudentTypographyFormik
            formik={DOBFormik}
            label={DOBFormik?.values?.labelName ?? "DOB: "}
            value="12/12/1999"
          />
        )}
        {/* pphone number */}
        {state?.MobileNumber && (
          <StudentTypographyFormik
            formik={mobileNumberFormik}
            label={mobileNumberFormik?.values?.labelName ?? "Mob No.: "}
            value="9876543210"
          />
        )}

        {/* Father Name */}
        {state?.FatherName && (
          <StudentTypographyFormik
            formik={fatherNameFormik}
            label={fatherNameFormik?.values?.labelName ?? "F. Name: "}
            value="Father Name"
          />
        )}
        {/* Mother Name */}
        {state?.MotherName && (
          <StudentTypographyFormik
            formik={motherNameFormik}
            label={motherNameFormik?.values?.labelName ?? "M. Name: "}
            value="Mother Name"
          />
        )}
        {/* Address*/}
        {state?.Address && (
          <StudentTypographyFormik
            formik={addressFormik}
            label={addressFormik?.values?.labelName ?? "Address: "}
            value="State Name"
          />
        )}
        {/* principale sign*/}
        {state?.PrincipalSign && (
          <StudentTypographyFormik
            formik={principalSignFormik}
            value="Principal Sign"
          />
        )}
        {/* transport*/}
        {state?.Transport && (
          <StudentTypographyFormik
            formik={transportFormik}
            value="Transport: Yes"
          />
        )}
        {/* id card number */}
        {state?.IdCardNumber && (
          <StudentTypographyFormik
            // label={idCardNumberFormik?.values?.labelName ?? ""}
            formik={idCardNumberFormik}
            value="1234567890"
          />
        )}
        {/* admission Date */}
        {state?.admissionDate && (
          <StudentTypographyFormik
            formik={admissonDateFormik}
            value={moment(new Date()).format("LL")}
            label={admissonDateFormik?.values?.labelName ?? "Admission Date: "}
          />
        )}
        {/* roll no */}
        {state?.rollNo && (
          <StudentTypographyFormik
            formik={rollNoFormik}
            value={"200737013008"}
            label={rollNoFormik?.values?.labelName ?? "Roll No.: "}
          />
        )}
        {/* gender */}
        {state?.gender && (
          <StudentTypographyFormik
            formik={genderFormik}
            value={"Gender"}
            label={genderFormik?.values?.labelName ?? "Gender: "}
          />
        )}
        {/* blood group */}
        {state?.bloodGroup && (
          <StudentTypographyFormik
            formik={bloodGroupFormik}
            value={"Blood Group"}
            label={bloodGroupFormik?.values?.labelName ?? "Blood Group: "}
          />
        )}
        {/* reg no */}
        {state?.regNo && (
          <StudentTypographyFormik
            formik={regNoFormik}
            value={"Reg No"}
            label={regNoFormik?.values?.labelName ?? "Reg No: "}
          />
        )}
        {/* sr no */}
        {state?.srNo && (
          <StudentTypographyFormik
            formik={srNoFormik}
            value={"SR No"}
            label={srNoFormik?.values?.labelName ?? "SR No: "}
          />
        )}
        {state?.penNo && (
          <StudentTypographyFormik
            formik={penNoFormik}
            value={"PEN No"}
            label={penNoFormik?.values?.labelName ?? "PEN No: "}
          />
        )}
        {state?.admissionType && (
          <StudentTypographyFormik
            formik={admissionTypeFormik}
            value={"Admission Type"}
            label={admissionTypeFormik?.values?.labelName ?? "Admission Type: "}
          />
        )}
        {state?.feeRelaxation && (
          <StudentTypographyFormik
            formik={feeRelaxationFormik}
            value={"Fee Relaxation Category"}
            label={
              feeRelaxationFormik?.values?.labelName ??
              "Fee Relaxation Category: "
            }
          />
        )}

        {state?.house && (
          <StudentTypographyFormik
            formik={houseFormik}
            value={"House"}
            label={houseFormik?.values?.labelName ?? "House: "}
          />
        )}
        {state?.aadhar && (
          <StudentTypographyFormik
            formik={aadharFormik}
            value={"Aadhar"}
            label={aadharFormik?.values?.labelName ?? "Aadhar: "}
          />
        )}
        {state?.category && (
          <StudentTypographyFormik
            formik={categoryFormik}
            value={"Category"}
            label={categoryFormik?.values?.labelName ?? "Category: "}
          />
        )}
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Custom ID Card</title>
      </Helmet>

      <CustomLoader
        show={
          getTotalIdCardFrameFetching ||
          getTotalIdCardFrameLoading ||
          loading ||
          formikLoading ||
          addIdCardFrameLoading
        }
      />

      {/* two check box for the horizontal and vertifical fram */}
      <Stack direction="row" alignItems="center" spacing={10}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <input
            type="checkbox"
            checked={state?.horizontalFrame}
            style={{ cursor: "pointer", width: "20px", height: "20px" }}
            onClick={() => {
              setState({
                ...state,
                horizontalFrame: !state?.horizontalFrame,
                verticalFrame: false,
              });
            }}
          />
          <Typography>Horizontal Frame</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <input
            type="checkbox"
            checked={state?.verticalFrame}
            style={{ cursor: "pointer", width: "20px", height: "20px" }}
            onClick={() => {
              setState({
                ...state,
                horizontalFrame: false,
                verticalFrame: true,
              });
            }}
          />
          <Typography>Veritcal Frame</Typography>
        </Stack>
      </Stack>
      <CustomLoader show={loading || addIdCardFrameLoading} />
      <Grid container>
        {/* for showing images */}
        <Grid item xs={4}>
          {state?.horizontalFrame && (
            <Grid
              item
              xs={12}
              sx={{}}
              md={6}
              ref={reportTemplateRef}
              margin={5}
            >
              <Box
                sx={{
                  height: horizontalHeight,
                  width: horizontalWidth,
                  alignSelf: "center",
                  backgroundImage: `url(${state?.backgroundFrame ?? ""})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  position: "absolute",
                  zIndex: 1,
                }}
              >
                {/* school logo */}
                {state?.logo && (
                  <Typography
                    sx={{
                      position: "absolute",
                      zIndex: 1,
                      top: `${logoFormik?.values?.y_axis}px` ?? 0,
                      left: `${logoFormik?.values?.x_axis}px` ?? 0,
                    }}
                  >
                    <img
                      style={{
                        height: `100%`,
                        width: `${logoFormik?.values?.fontSize}px`,
                        color: logoFormik?.values?.fontColor ?? "#000000",
                        fontWeight: logoFormik?.values?.fontWeight,
                        fontStyle: logoFormik?.values?.fontStyle ?? "normal",
                        borderRadius:
                          logoFormik?.values?.shape == "circle" ? "50%" : "0%",
                        objectFit: "contain",
                      }}
                      src={demoLogo}
                    />
                  </Typography>
                )}

                {/* profile pic */}
                {state?.profilePic && (
                  <Box
                    style={{
                      position: "absolute",
                      zIndex: 1,
                      top: `${profilePicFormik?.values?.y_axis}px` ?? 0,
                      left: `${profilePicFormik?.values?.x_axis}px` ?? 0,
                      height: `${profilePicFormik?.values?.fontSize}px`,
                      width: `${profilePicFormik?.values?.fontSize}px`,
                      borderRadius:
                        profilePicFormik?.values?.shape == "circle"
                          ? "50%"
                          : "0%",
                      objectFit: "contain",
                      backgroundColor: "#00CC8C10",
                      borderWidth: 1,
                    }}
                  >
                    <img
                      src={profilePlaceHolder}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                        alignSelf: "center",
                        borderRadius:
                          profilePicFormik?.values?.shape == "circle"
                            ? "50%"
                            : "0%",
                        overflow: "hidden",
                      }}
                      alt="profile-pic"
                    />
                  </Box>
                )}

                <DetailsOnCard />
              </Box>
            </Grid>
          )}

          {state?.verticalFrame && (
            <Grid
              item
              xs={12}
              sx={{}}
              md={6}
              ref={reportTemplateRef}
              margin={5}
            >
              <Box
                sx={{
                  height: CARD_HEIGHT,
                  width: CARD_WIDTH,
                  alignSelf: "center",
                  backgroundImage: `url(${state?.backgroundFrame ?? ""})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  position: "absolute",
                  zIndex: 1,
                }}
              >
                {/* school logo */}
                {state?.logo && (
                  <Typography
                    sx={{
                      position: "absolute",
                      zIndex: 1,
                      top: `${logoFormik?.values?.y_axis}px` ?? 0,
                      left: `${logoFormik?.values?.x_axis}px` ?? 0,
                    }}
                  >
                    <img
                      style={{
                        height: `100%`,
                        width: `${logoFormik?.values?.fontSize}px`,
                        color: logoFormik?.values?.fontColor ?? "#000000",
                        fontWeight: logoFormik?.values?.fontWeight,
                        fontStyle: logoFormik?.values?.fontStyle ?? "normal",
                        borderRadius:
                          logoFormik?.values?.shape == "circle" ? "50%" : "0%",
                        objectFit: "contain",
                      }}
                      src={demoLogo}
                    />
                  </Typography>
                )}

                {/* profile pic */}
                {state?.profilePic && (
                  <Box
                    style={{
                      position: "absolute",
                      zIndex: 1,
                      top: `${profilePicFormik?.values?.y_axis}px` ?? 0,
                      left: `${profilePicFormik?.values?.x_axis}px` ?? 0,
                      height: `${profilePicFormik?.values?.fontSize}px`,
                      width: `${profilePicFormik?.values?.fontSize}px`,
                      borderRadius:
                        profilePicFormik?.values?.shape == "circle"
                          ? "50%"
                          : "0%",
                      objectFit: "contain",
                      backgroundColor: "#00CC8C10",
                      borderWidth: 1,
                    }}
                  >
                    <img
                      src={profilePlaceHolder}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                        alignSelf: "center",
                        borderRadius:
                          profilePicFormik?.values?.shape == "circle"
                            ? "50%"
                            : "0%",
                        overflow: "hidden",
                      }}
                      alt="profile-pic"
                    />
                  </Box>
                )}

                <DetailsOnCard />
              </Box>
            </Grid>
          )}
        </Grid>

        {/* for giving of list of items */}
        <Grid
          item
          xs={8}
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* uplaod button for frame */}
          <Stack direction="row" alignItems="center" spacing={4}>
            <Box>
              <Button
                variant="contained"
                color="secondary"
                component="label"
                style={{ margin: "10px" }}
              >
                <input
                  type="file"
                  accept="image/*,application/pdf"
                  hidden
                  onChange={handlePayUpload}
                />
                Upload
              </Button>
            </Box>
            <Box>
              <Button
                disabled={state?.backgroundFrame ? false : true}
                variant="contained"
                color="secondary"
                component="label"
                onClick={handlePrint}
                style={{ margin: "10px" }}
              >
                Print
              </Button>
            </Box>
            <Box>
              <Button
                disabled={state?.backgroundFrame ? false : true}
                variant="contained"
                color="secondary"
                component="label"
                onClick={() => {
                  if (frameCode) {
                    handleCustomize();
                  } else {
                    handleSaveTemplate();
                  }
                }}
                style={{ margin: "10px" }}
              >
                Save
              </Button>
            </Box>
            <Box>
              <Button
                disabled={state?.backgroundFrame ? false : true}
                variant="contained"
                color="secondary"
                component="label"
                onClick={() => {
                  setState({
                    ...state,
                    frameImage: null,
                    backgroundFrame: null,
                    StudentName: false,
                    FatherName: false,
                    MotherName: false,
                    MobileNumber: false,
                    DOB: false,
                    Address: false,
                    Class: false,
                    AdmissionNumber: false,
                    PrincipalSign: false,
                    Transport: false,
                    SchoolAddress: false,
                    IdCardNumber: false,
                    logo: false,
                    profilePic: false,
                    schoolName: false,
                    admissionDate: false,
                    rollNo: false,
                    aadhar: false,
                    category: false,
                    house: false,
                    feeRelaxation: false,
                    admissionType: false,
                    srNo: false,
                    regNo: false,
                    penNo: false,
                    bloodGroup: false,
                  });
                }}
                style={{ margin: "10px" }}
              >
                Remove Frame
              </Button>
            </Box>
          </Stack>
          {/* map the states for showing checkbox with the label */}
          {/* for logo */}
          <Stack direction="row" alignItems="center" spacing={1} margin={2}>
            <input
              disabled={state?.backgroundFrame ? false : true}
              type="checkbox"
              checked={state?.logo}
              style={{ cursor: "pointer", width: "20px", height: "20px" }}
              onClick={() => {
                setState({
                  ...state,
                  logo: !state?.logo,
                });
              }}
            />
            <LocationName
              item={"logo"}
              showShape={true}
              openedCard={openedCard}
              setOpenedCard={setOpenedCard}
              formik={logoFormik}
              showColor={false}
              showFontWeight={false}
              showFontStyle={false}
              disabledLabel={true}
            />
          </Stack>
          {/* for profile pic */}
          <Stack direction="row" alignItems="center" spacing={1} margin={2}>
            <input
              disabled={state?.backgroundFrame ? false : true}
              type="checkbox"
              checked={state?.profilePic}
              style={{ cursor: "pointer", width: "20px", height: "20px" }}
              onClick={() => {
                setState({
                  ...state,
                  profilePic: !state?.profilePic,
                });
              }}
            />
            <LocationName
              item={"profilePic"}
              openedCard={openedCard}
              setOpenedCard={setOpenedCard}
              formik={profilePicFormik}
              showShape={true}
              disabledLabel={true}
              showFontWeight={false}
              showFontStyle={false}
              showColor={false}
            />
          </Stack>
          {/* text based cards */}
          {[
            {
              state: "schoolName",
              formik: schoolNameFormik,
              label: "schoolName",
            },
            {
              state: "StudentName",
              formik: nameFormik,
              label: "name",
            },
            {
              state: "Class",
              formik: classNameFormik,
              label: "class",
            },
            {
              state: "AdmissionNumber",
              formik: admissionFormik,
              label: "admission",
            },
            {
              state: "DOB",
              formik: DOBFormik,
              label: "DOB",
            },
            {
              state: "MobileNumber",
              formik: mobileNumberFormik,
              label: "phone",
            },
            {
              state: "FatherName",
              formik: fatherNameFormik,
              label: "fatherName",
            },
            {
              state: "MotherName",
              formik: motherNameFormik,
              label: "motherName",
            },
            {
              state: "Address",
              formik: addressFormik,
              label: "Address",
            },
            {
              state: "PrincipalSign",
              formik: principalSignFormik,
              label: "PrincipalSign",
            },
            {
              state: "Transport",
              formik: transportFormik,
              label: "Transport",
            },
            {
              state: "SchoolAddress",
              formik: schoolAddressFormik,
              label: "SchoolAddress",
            },
            {
              state: "IdCardNumber",
              formik: idCardNumberFormik,
              label: "Id Card Number",
            },
            {
              state: "admissionDate",
              formik: admissonDateFormik,
              label: "Admission Date",
            },
            {
              state: "rollNo",
              formik: rollNoFormik,
              label: "Roll No",
            },
            {
              state: "gender",
              formik: genderFormik,
              label: "Gender",
            },
            {
              state: "bloodGroup",
              formik: bloodGroupFormik,
              label: "Blood Group",
            },
            {
              state: "regNo",
              formik: regNoFormik,
              label: "Reg No",
            },
            {
              state: "srNo",
              formik: srNoFormik,
              label: "SR No",
            },
            {
              state: "penNo",
              formik: penNoFormik,
              label: "PEN No",
            },
            {
              state: "admissionType",
              formik: admissionTypeFormik,
              label: "Admission Type",
            },
            {
              state: "feeRelaxation",
              formik: feeRelaxationFormik,
              label: "Fee Relaxation Category",
            },
            {
              state: "house",
              formik: houseFormik,
              label: "House",
            },
            {
              state: "aadhar",
              formik: aadharFormik,
              label: "Aadhar",
            },
            {
              state: "category",
              formik: categoryFormik,
              label: "Category",
            },
            {
              state: "schoolContact",
              formik: schoolContactNumberFormik,
              label: "School Contact Number",
            },
          ].map((item, index) => {
            return renderStack({
              stateName: item.state,
              formik: item.formik,
              label: item.label,
              openedCard: openedCard,
              setOpenedCard: setOpenedCard,
              state: state,
              setState: setState,
            });
          })}
        </Grid>
      </Grid>
    </>
  );
};

export default CustomIdCardPage;
