import { useEffect } from "react";
import { FormioStyleOverrider } from "app/components/styled/FormioStyleOverrider";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "app/store";
import AppRoutes from "app/components/AppRoutes";
import { QueryClient, QueryClientProvider } from "react-query";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import {
  createTheme,
  ThemeProvider as MaterialThemeProvider,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});
const theme = createTheme({
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#0C2F49",
    },
    secondary: {
      main: "rgba(39, 137, 253, 1)",
    },
    text: {
      primary: "#0C2F49",
    },
  },
});
const App = () => {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <FormioStyleOverrider id="app">
      <QueryClientProvider client={queryClient}>
        <ReduxProvider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <MaterialThemeProvider theme={theme}>
              <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false}
                theme="colored"
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <AppRoutes />
              </LocalizationProvider>
            </MaterialThemeProvider>
          </PersistGate>
        </ReduxProvider>
      </QueryClientProvider>
    </FormioStyleOverrider>
  );
};

export default App;
