import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
} from "@mui/material";
import CommonDialog from "app/components/common/CommonDialog";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import { changeStatusOfSchoolEnquiry } from "app/services/school";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

const ChangeStatusDialog = ({
  open = false,
  onClose = () => {},
  refetch = () => {},
  data = {},
}) => {
  const [state, setState] = useState({
    status: "",
    remark: "",
  });

  const handleClose = () => {
    onClose();
    setState((prev) => ({ ...prev, status: "", remark: "" }));
  };
  const { isLoading: changeStatusOfSchoolEnquiryLoading, mutate } = useMutation(
    "changeStatusOfSchoolEnquiry",
    changeStatusOfSchoolEnquiry,
    {
      onSuccess: (success) => {
        toast.success(success?.data?.message);
        refetch();
        handleClose();
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );
  const handleUpdate = () => {
    mutate({
      schoolEnquiryDocId: data?._id,
      status: state.status,
    });
  };
  useEffect(() => {
    if (open) {
      setState((prev) => ({ ...prev, status: data.status }));
    }
  }, [open]);

  return (
    <CommonDialog
      open={open}
      onClose={handleClose}
      title="Change Status"
      minWidth="800px"
    >
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextFieldLabel title={"Change Status"} />
            <CustomSelectBox
              fullWidth
              size="small"
              value={state.status}
              onChange={(e) =>
                setState((prev) => ({ ...prev, status: e.target.value }))
              }
            >
              <MenuItem value="" disabled>
                Select
              </MenuItem>
              <MenuItem value="WON">Won</MenuItem>
              <MenuItem value="LOST">Lost</MenuItem>
              <MenuItem value="DEAD">Dead</MenuItem>
              <MenuItem value="ACTIVE">Active</MenuItem>
              {/* <MenuItem value="INACTIVE">In-Active</MenuItem> */}
            </CustomSelectBox>
          </Grid>
          {state.status !== "WON" && (
            <Grid item xs={4}>
              <TextFieldLabel title={"Change Status"} />
              <CustomTextField
                placeholder="Type remark"
                size="small"
                fullWidth
                value={state.remark}
                onChange={(e) =>
                  setState((prev) => ({ ...prev, remark: e.target.value }))
                }
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          size="large"
          variant="contained"
          color="secondary"
          onClick={() => handleUpdate()}
          loading={changeStatusOfSchoolEnquiryLoading}
        >
          Update
        </LoadingButton>
      </DialogActions>
    </CommonDialog>
  );
};

export default ChangeStatusDialog;
