import React from "react";
import {
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { useMutation, useQuery } from "react-query";

import { toast } from "react-toastify";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import {
  CustomTextField,
  CustomTextFieldDisabled,
} from "assets/styles/globalStyledComponent";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AddCircle, Delete } from "@mui/icons-material";
import { getDuration } from "app/utils/helper";
import CustomLoader from "app/components/common/CustomLoader";
import uploadFile from "app/utils/uploadFile";
import {
  deleteExperienceInfo,
  getExperienceInfo,
  updateExperienceInfo,
} from "app/services/auth/user.service";

const VALIDATION_SCHEMA = Yup.object({
  employerName: Yup.string().required("Required"),
  designation: Yup.string().required("Required"),
  worksOn: Yup.string().required("Required"),
});

const PrevExperienceModal = ({ handleCloseModal, getUserDetail }) => {
  const dt = new Date();
  const {
    values,
    setValues,
    handleSubmit,
    handleChange,
    resetForm,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      from: dt,
      to: dt,
      employerName: "",
      designation: "",
      worksOn: "",
      experienceCertificateName: "",
      experienceCertificateFile: "",
      salary: 0,
      loading: false,
    },
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: (formValues) => {
      if (formValues.experienceCertificateName) {
        handleSubmitForm();
      } else {
        mutate({
          from: formValues.from,
          to: formValues.to,
          employerName: formValues.employerName,
          designation: formValues.designation,
          worksOn: formValues.worksOn,
          experienceCertificate: null,
          salary: formValues.salary ? formValues.salary : 0,
        });
      }
    },
  });
  const handleSubmitForm = async () => {
    setValues((prev) => ({ ...prev, loading: true }));
    try {
      let s3FilePath = `staffProfil/${values.experienceCertificateFile.name}`;
      let attachmentUrl = await uploadFile(
        values.experienceCertificateFile,
        s3FilePath,
        values.experienceCertificateFile.type
      );
      mutate({
        from: values.from,
        to: values.to,
        employerName: values.employerName,
        designation: values.designation,
        worksOn: values.worksOn,
        experienceCertificate: attachmentUrl.fileURL,
        salary: values.salary,
      });

      setValues((prev) => ({ ...prev, loading: false }));
    } catch (error) {
      toast.error(error);
      setValues((prev) => ({ ...prev, loading: false }));
    }
  };
  const {
    isLoading: getExperienceInfoDataLoading,
    isFetching: getExperienceInfoDataFetching,
    refetch: getExperienceInfoRefetch,
    data: getExperienceInfoData,
  } = useQuery({
    queryKey: ["getExperienceInfo"],
    queryFn: () => getExperienceInfo(),
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
  });
  const { mutate: deleteMutate, isLoading: deleteExperienceInfoLoading } =
    useMutation(deleteExperienceInfo, {
      onSuccess: ({ data }) => {
        toast.success(data?.message);
        getExperienceInfoRefetch();
        resetForm();
        getUserDetail();
      },
      onError: ({ response: { data } }) => {
        toast.error(data?.message);
      },
    });
  const { mutate, isLoading: updateExperienceInfoLoading } = useMutation(
    updateExperienceInfo,
    {
      onSuccess: ({ data }) => {
        toast.success(data?.message);
        getUserDetail();
        resetForm();
        getExperienceInfoRefetch();
      },
      onError: ({ response: { data } }) => {
        toast.error(data?.message);
      },
    }
  );

  return (
    <>
      <CustomLoader show={deleteExperienceInfoLoading} />
      {(getExperienceInfoDataFetching || getExperienceInfoDataLoading) && (
        <Stack direction="row" justifyContent="center" sx={{ py: 1 }}>
          <CircularProgress size={25} />
        </Stack>
      )}
      {getExperienceInfoData?.data?.list?.map((item, i) => (
        <Grid container spacing={1} sx={{ pt: 1.5 }} key={i}>
          <Grid item xs={11.3}>
            <Grid
              container
              spacing={1}
              sx={{
                border: "1px solid rgba(19, 115, 229, 0.20)",
                backgroundColor: "rgba(39, 137, 253, 0.05)",
                borderRadius: "10px",
                p: 0.5,
              }}
            >
              <Grid item xs={4}>
                <TextFieldLabel title="Employer’s Name" />
                <CustomTextFieldDisabled
                  size="small"
                  fullWidth
                  value={item?.employerName}
                />
              </Grid>
              <Grid item xs={3}>
                <TextFieldLabel title="Designation" />
                <CustomTextFieldDisabled
                  size="small"
                  fullWidth
                  value={item?.designation}
                />
              </Grid>
              <Grid item xs={2}>
                <TextFieldLabel title="From" />
                <DesktopDatePicker
                  inputFormat="DD MMM, YYYY"
                  value={item.joiningDate}
                  renderInput={(params) => (
                    <CustomTextFieldDisabled
                      {...params}
                      size="small"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <TextFieldLabel title="To" />
                <DesktopDatePicker
                  inputFormat="DD MMM, YYYY"
                  value={item.separationDate}
                  renderInput={(params) => (
                    <CustomTextFieldDisabled
                      {...params}
                      size="small"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={1}>
                <TextFieldLabel title="Duration" />
                <Typography
                  sx={{
                    textAlign: "center",
                    mt: 1.5,
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  {getDuration(item.separationDate, item.joiningDate)}
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <TextFieldLabel title="Works On" />
                <CustomTextFieldDisabled
                  size="small"
                  fullWidth
                  value={item?.worksOn}
                />
              </Grid>
              <Grid item xs={2}>
                <TextFieldLabel title="Salary" />
                <CustomTextFieldDisabled
                  size="small"
                  fullWidth
                  value={item?.basicSalary}
                />
              </Grid>
              <Grid item xs={3}>
                <TextFieldLabel title="Experience Certificate" />
                {item?.experienceCertificate && (
                  <Typography sx={{ textAlign: "center" }}>
                    <a
                      href={item?.experienceCertificate}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      view
                    </a>
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={0.7}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <IconButton
              sx={{ p: "4px !imortant" }}
              onClick={() => deleteMutate(item?._id)}
            >
              <Delete
                sx={{
                  color: (theme) => theme.palette.error.main,
                  fontSize: "25px",
                }}
              />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1} sx={{ pt: 1.5 }}>
          <Grid item xs={11.3}>
            <Grid
              container
              spacing={1}
              sx={{
                border: "1px solid rgba(19, 115, 229, 0.20)",
                backgroundColor: "rgba(39, 137, 253, 0.05)",
                borderRadius: "10px",
                p: 0.5,
              }}
            >
              <Grid item xs={4}>
                <TextFieldLabel title="Employer’s Name" />
                <CustomTextField
                  size="small"
                  fullWidth
                  placeholder="Type employee name"
                  name="employerName"
                  value={values.employerName}
                  onChange={handleChange}
                  error={Boolean(errors.employerName) && touched.employerName}
                  helperText={touched.employerName && errors.employerName}
                />
              </Grid>
              <Grid item xs={3}>
                <TextFieldLabel title="Designation" />
                <CustomTextField
                  size="small"
                  fullWidth
                  placeholder="Type designation"
                  name="designation"
                  value={values.designation}
                  onChange={handleChange}
                  error={Boolean(errors.designation) && touched.designation}
                  helperText={touched.designation && errors.designation}
                />
              </Grid>
              <Grid item xs={2}>
                <TextFieldLabel title="From" />
                <DesktopDatePicker
                  inputFormat="DD MMM, YYYY"
                  onChange={(e) => setValues((prev) => ({ ...prev, from: e }))}
                  value={values.from}
                  renderInput={(params) => (
                    <CustomTextField {...params} size="small" fullWidth />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <TextFieldLabel title="To" />
                <DesktopDatePicker
                  inputFormat="DD MMM, YYYY"
                  onChange={(e) => setValues((prev) => ({ ...prev, to: e }))}
                  value={values.to}
                  renderInput={(params) => (
                    <CustomTextField {...params} size="small" fullWidth />
                  )}
                />
              </Grid>
              <Grid item xs={1}>
                <TextFieldLabel title="Duration" />
                <Typography
                  sx={{
                    textAlign: "center",
                    mt: 1.5,
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  {getDuration(values.to, values.from)}
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <TextFieldLabel title="Works On" />
                <CustomTextField
                  size="small"
                  fullWidth
                  placeholder="Type works on"
                  name="worksOn"
                  value={values.worksOn}
                  onChange={handleChange}
                  error={Boolean(errors.worksOn) && touched.worksOn}
                  helperText={touched.worksOn && errors.worksOn}
                />
              </Grid>
              <Grid item xs={2}>
                <TextFieldLabel title="Salary" />
                <CustomTextField
                  size="small"
                  fullWidth
                  placeholder="Type salary"
                  name="salary"
                  type="number"
                  value={values.salaryworksOn}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={3}>
                <TextFieldLabel title="Experience Certificate" />
                <Typography>{values.experienceCertificateName}</Typography>
                <LoadingButton
                  variant="outlined"
                  color="secondary"
                  size="large"
                  sx={{
                    textTransform: "capitalize",
                    borderRadius: "10px",
                    backgroundColor: "#fff",
                  }}
                  component="label"
                >
                  <input
                    hidden
                    type="file"
                    accept="image/*,application/pdf"
                    onChange={(e) =>
                      setValues((prev) => ({
                        ...prev,
                        experienceCertificateFile: e.target.files[0],
                        experienceCertificateName: e.target.files[0].name,
                      }))
                    }
                  />
                  Upload
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={0.7}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {updateExperienceInfoLoading || values.loading ? (
              <CircularProgress size={20} />
            ) : (
              <IconButton sx={{ p: "4px !imortant" }} type="submit">
                <AddCircle
                  sx={{
                    color: (theme) => theme.palette.secondary.main,
                    fontSize: "30px",
                  }}
                />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default PrevExperienceModal;
