import React, { useState } from "react";
import CommonFilterDialog from "../CommonFilterDialog";
import {
  Box,
  Checkbox,
  DialogContent,
  FormControlLabel,
  MenuItem,
} from "@mui/material";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import { CustomSelectBox } from "assets/styles/globalStyledComponent";
import { PRODUCT_NAMES } from "app/config/constants";
import FilterButton from "app/components/common/FilterButton";
import { green, orange, red } from "@mui/material/colors";
const ProductFilter = ({ mutate = () => {} }) => {
  const [state, setState] = useState({
    dialog: false,
    session: "",
    productType: "",
    productName: "",
    status: "",
  });

  const [checkboxes, setCheckboxes] = useState({
    productName_and_type: false,
    status: false,
  });
  const handleCloseDialog = () => {
    setState((prev) => ({ ...prev, dialog: false }));
  };
  const handleView = () => {
    mutate({
      ...(checkboxes.productName_and_type &&
        state.productType && { productType: state.productType }),
      ...(checkboxes.productName_and_type &&
        state.productName && { productName: state.productName }),
      ...(checkboxes.status && state.status && { status: state.status }),
    });
    handleCloseDialog();
  };
  return (
    <>
      <FilterButton
        handleClick={() => setState((prev) => ({ ...prev, dialog: true }))}
        count={Object.values(checkboxes).filter((item) => item === true).length}
      />
      <CommonFilterDialog
        open={state.dialog}
        onClose={handleCloseDialog}
        minWidth="400px"
        handleSaveAndView={handleView}
      >
        <DialogContent>
          <Box>
            <FormControlLabel
              control={<Checkbox color="secondary" />}
              checked={checkboxes.productName_and_type}
              onChange={() =>
                setCheckboxes((prev) => ({
                  ...prev,
                  productName_and_type: !prev.productName_and_type,
                }))
              }
              componentsProps={{
                typography: {
                  sx: { fontSize: "14px", fontWeight: 600 },
                },
              }}
              label="Product Type and Product Name"
            />
            {checkboxes.productName_and_type && (
              <Box sx={{ ml: 2 }}>
                <Box>
                  <TextFieldLabel title={"Product Type"} />
                  <CustomSelectBox
                    native
                    displayEmpty
                    size="small"
                    fullWidth
                    name="productType"
                    value={state.productType}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        productType: e.target.value,
                        productName: "",
                      }))
                    }
                  >
                    <MenuItem value="" disabled component="option">
                      Select
                    </MenuItem>
                    {Object.keys(PRODUCT_NAMES)?.map((item, i) => (
                      <MenuItem value={item} key={i} component="option">
                        {item}
                      </MenuItem>
                    ))}
                  </CustomSelectBox>
                </Box>
                <Box sx={{ my: 1 }}>
                  <TextFieldLabel title={"Product Name"} />
                  <CustomSelectBox
                    native
                    displayEmpty
                    size="small"
                    fullWidth
                    name="productName"
                    value={state.productName}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        productName: e.target.value,
                      }))
                    }
                  >
                    <MenuItem value="" disabled component="option">
                      Select
                    </MenuItem>

                    {PRODUCT_NAMES[state.productType]?.map((item, i) => (
                      <MenuItem value={item} key={i} component="option">
                        {item}
                      </MenuItem>
                    ))}
                  </CustomSelectBox>
                </Box>
              </Box>
            )}
          </Box>
          <Box mt={1.5}>
            <FormControlLabel
              control={<Checkbox color="secondary" />}
              checked={checkboxes.status}
              onChange={() =>
                setCheckboxes((prev) => ({
                  ...prev,
                  status: !prev.status,
                }))
              }
              componentsProps={{
                typography: {
                  sx: { fontSize: "14px", fontWeight: 600 },
                },
              }}
              label="Status"
            />
            {checkboxes.status && (
              <Box sx={{ ml: 2 }}>
                <Box>
                  <TextFieldLabel title={"Status"} />
                  <CustomSelectBox
                    native
                    displayEmpty
                    size="small"
                    fullWidth
                    value={state.status}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        status: e.target.value,
                      }))
                    }
                  >
                    <MenuItem value="" disabled component="option">
                      Select
                    </MenuItem>
                    <MenuItem
                      value="REQUESTED"
                      component="option"
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: orange[600],
                      }}
                    >
                      REQUESTED
                    </MenuItem>
                    <MenuItem
                      value="APPROVED"
                      component="option"
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: green[600],
                      }}
                    >
                      APPROVED
                    </MenuItem>
                    <MenuItem
                      value="REJECTED"
                      component="option"
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: red[600],
                      }}
                    >
                      REJECTED
                    </MenuItem>
                  </CustomSelectBox>
                </Box>
              </Box>
            )}
          </Box>
        </DialogContent>
      </CommonFilterDialog>
    </>
  );
};

export default ProductFilter;
