import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import CommonDialog from "app/components/common/CommonDialog";
import CustomLoader from "app/components/common/CustomLoader";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import {
  addStaffInInstituteGroup,
  getStaffListByInstitute,
} from "app/services/org/school.service";
import { MenuProps } from "app/utils/helper";
import { CustomSelectBox } from "assets/styles/globalStyledComponent";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
const AddMoreStaffDialog = ({
  open = false,
  onClose = () => {},
  refetch = () => {},
  instituteArray = [],
  designationArray = ["Director", "Managing Director"],
}) => {
  const { id } = useParams();
  const formik = useFormik({
    validationSchema: Yup.object({
      institute: Yup.string().required("Institute Required"),
      staff: Yup.string().required("Staff Required"),
      designation: Yup.string().required("Designation Required"),
    }),
    initialValues: {
      institute: "",
      staff: "",
      designation: "",
      staffList: [],
    },
    onSubmit: (values) => {
      mutate({
        instituteGroupDocId: id,
        staffDocId: values.staff,
        designation: values.designation,
      });
    },
  });
  const handleClose = () => {
    onClose();
    formik.resetForm();
  };
  const {
    isLoading: getStaffListByInstituteLoading,
    isFetching: getStaffListByInstituteFetching,
  } = useQuery({
    queryKey: ["getStaffListByInstitute", formik.values.institute],
    queryFn: () =>
      getStaffListByInstitute({ employerDocId: formik.values.institute }),
    onSuccess: (success) => {
      formik.setValues((prev) => ({ ...prev, staffList: success?.data?.list }));
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
    enabled: formik.values.institute ? true : false,
  });
  const { mutate, isLoading: addStaffInInstituteGroupLoading } = useMutation(
    "addStaffInInstituteGroup",
    addStaffInInstituteGroup,
    {
      onSuccess: (success) => {
        toast.success(success?.data?.message);
        handleClose();
        refetch();
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );
  return (
    <>
      <CustomLoader
        show={
          getStaffListByInstituteLoading ||
          getStaffListByInstituteFetching ||
          addStaffInInstituteGroupLoading
        }
      />
      <CommonDialog
        open={open}
        onClose={handleClose}
        minWidth="600px"
        title="Add Staff"
      >
        <DialogContent>
          <form onSubmit={formik.handleSubmit} id="add-institute-staff">
            <Grid container spacing={1}>
              <Grid item xs={12} md={8}>
                <TextFieldLabel title={"Select Institute"} />
                <CustomSelectBox
                  MenuProps={MenuProps}
                  displayEmpty
                  fullWidth
                  size="small"
                  name="institute"
                  value={formik.values.institute}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.institute && Boolean(formik.errors.institute)
                  }
                >
                  <MenuItem disabled value="">
                    Select
                  </MenuItem>
                  {instituteArray.map((item, i) => (
                    <MenuItem value={item?._id} key={i}>
                      {item?.schoolName}
                    </MenuItem>
                  ))}
                </CustomSelectBox>
                {formik.touched.institute &&
                  Boolean(formik.errors.institute) && (
                    <Typography color="error">
                      {formik.errors.institute}
                    </Typography>
                  )}
              </Grid>
              <Grid item xs={12} md={6}>
                <TextFieldLabel title={"Staff"} />
                <CustomSelectBox
                  MenuProps={MenuProps}
                  displayEmpty
                  fullWidth
                  size="small"
                  name="staff"
                  value={formik.values.staff}
                  onChange={formik.handleChange}
                  error={formik.touched.staff && Boolean(formik.errors.staff)}
                >
                  <MenuItem disabled value="">
                    Select
                  </MenuItem>
                  {formik.values.staffList?.map((item, i) => (
                    <MenuItem value={item?.staffDocId} key={i}>
                      {item?.firstName ?? ""} {item?.middleName ?? ""}{" "}
                      {item?.lastName ?? ""}
                    </MenuItem>
                  ))}
                </CustomSelectBox>
                {formik.touched.staff && Boolean(formik.errors.staff) && (
                  <Typography color="error">{formik.errors.staff}</Typography>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <TextFieldLabel title={"Designation"} />
                <CustomSelectBox
                  MenuProps={MenuProps}
                  displayEmpty
                  fullWidth
                  size="small"
                  name="designation"
                  value={formik.values.designation}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.designation &&
                    Boolean(formik.errors.designation)
                  }
                >
                  <MenuItem disabled value="">
                    Select
                  </MenuItem>
                  {designationArray.map((item, i) => (
                    <MenuItem value={item} key={i}>
                      {item}
                    </MenuItem>
                  ))}
                </CustomSelectBox>
                {formik.touched.designation &&
                  Boolean(formik.errors.designation) && (
                    <Typography color="error">
                      {formik.errors.designation}
                    </Typography>
                  )}
              </Grid>
            </Grid>
          </form>
          <Typography color="orange" sx={{ mt: 1, fontSize: "10px" }}>
            *If Staff is not showing in list, add staff in “Administration”
            department of selected Institute.
          </Typography>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            size="large"
            color="secondary"
            sx={{ px: 5 }}
            type="submit"
            form="add-institute-staff"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </CommonDialog>
    </>
  );
};

export default AddMoreStaffDialog;
