import React from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import useSelectorHook from "app/services/redux/useSelectorHook";

/**
 * @param  {object} {props}
 * Private Route manages authentication at route level.
 * If session becomes invalid due to any reason, this component
 * ensures that a redirection is made to passed {redirectPath} or "/" (by default)
 * @returns {JSX} Private Route
 */
const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = useSelectorHook();
  return isAuthenticated ? children : <Navigate to="/" />;
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
