import React from "react";
import {
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useMutation, useQuery } from "react-query";
import {
  CustomTextField,
  CustomTextFieldDisabled,
} from "assets/styles/globalStyledComponent";
import { toast } from "react-toastify";
import { AddCircle, Delete } from "@mui/icons-material";
import CustomLoader from "app/components/common/CustomLoader";
import {
  deleteEducationInfo,
  getEducationInfo,
  updateEducationInfo,
} from "app/services/auth/user.service";
const TextFieldLabel = ({ title }) => {
  return (
    <Typography
      sx={{
        color: (theme) => theme.palette.primary.main,
        opacity: 0.75,
        fontSize: "15px",
      }}
    >
      {title}
    </Typography>
  );
};

const VALIDATION_SCHEMA = Yup.object({
  discription: Yup.string().required("required"),
  instituteName: Yup.string().required("required"),
  passingYear: Yup.string().required("required"),
});

const EducationInfoModal = ({ handleCloseModal, getUserDetail }) => {
  const {
    values,
    setValues,
    handleChange,
    handleSubmit,
    resetForm,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      discription: "",
      instituteName: "",
      percentage: "",
      passingYear: "",
      grade: "",
    },
    onSubmit: (values) => {
      mutate({
        description: values.discription,
        percentage: values.percentage,
        instituteName: values.instituteName,
        passingYear: values.passingYear,
        grades: values.grade,
      });
    },
    validationSchema: VALIDATION_SCHEMA,
  });
  const {
    isLoading: getEducationInfoDataLoading,
    isFetching: getEducationInfoDataFetching,
    refetch: getEducationInfoRefetch,
    data: getEducationInfoData,
  } = useQuery({
    queryKey: ["getEducationInfo"],
    queryFn: () => getEducationInfo(),
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
  });
  const { mutate: deleteMutate, isLoading: deleteEducationInfoLoading } =
    useMutation(deleteEducationInfo, {
      onSuccess: ({ data }) => {
        toast.success(data?.message);
        getEducationInfoRefetch();
      },
      onError: ({ response: { data } }) => {
        toast.error(data?.message);
      },
    });
  const { mutate, isLoading: updatingEducationInfo } = useMutation(
    updateEducationInfo,
    {
      onSuccess: ({ data }) => {
        toast.success(data?.message);
        resetForm();
        getUserDetail();
        getEducationInfoRefetch();
      },
      onError: ({ response: { data } }) => {
        toast.error(data?.message);
      },
    }
  );
  return (
    <>
      <CustomLoader show={deleteEducationInfoLoading} />
      {(getEducationInfoDataFetching || getEducationInfoDataLoading) && (
        <Stack direction="row" justifyContent="center" sx={{ py: 1 }}>
          <CircularProgress size={25} />
        </Stack>
      )}
      <Grid container spacing={1} sx={{ pt: 1 }} columns={13}>
        <Grid item xs={4}>
          <TextFieldLabel title="Description" />
        </Grid>
        <Grid item xs={3}>
          <TextFieldLabel title="University/Board" />
        </Grid>
        <Grid item xs={2}>
          <TextFieldLabel title="Percentage" />
        </Grid>
        <Grid item xs={2}>
          <TextFieldLabel title="Grade" />
        </Grid>

        <Grid item xs={1.5}>
          <TextFieldLabel title="Passing Year" />
        </Grid>
        <Grid item xs={0.5}>
          <TextFieldLabel title="Action" />
        </Grid>
      </Grid>
      {getEducationInfoData?.data?.list?.map((item, i) => (
        <Grid container spacing={1} sx={{ pt: 1 }} key={i} columns={13}>
          <Grid item xs={4}>
            <CustomTextFieldDisabled
              size="small"
              fullWidth
              value={item.description}
            />
          </Grid>
          <Grid item xs={3}>
            <CustomTextFieldDisabled
              size="small"
              fullWidth
              value={item.instituteName}
            />
          </Grid>
          <Grid item xs={2}>
            <CustomTextFieldDisabled
              size="small"
              fullWidth
              value={item.percentage}
            />
          </Grid>
          <Grid item xs={2}>
            <CustomTextFieldDisabled
              size="small"
              fullWidth
              value={item.grades}
            />
          </Grid>

          <Grid item xs={1.5}>
            <CustomTextFieldDisabled
              size="small"
              fullWidth
              value={item.passingYear}
            />
          </Grid>
          <Grid
            item
            xs={0.5}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <IconButton
              sx={{ p: "4px !imortant" }}
              onClick={() => deleteMutate(item?._id)}
            >
              <Delete
                sx={{
                  color: (theme) => theme.palette.error.main,
                  fontSize: "25px",
                }}
              />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1} sx={{ pt: 1 }} columns={13}>
          <Grid item xs={4}>
            <CustomTextField
              size="small"
              fullWidth
              name="discription"
              value={values.discription}
              onChange={handleChange}
              placeholder="Type.."
              error={Boolean(errors.discription) && touched.discription}
              helperText={touched.discription && errors.discription}
            />
          </Grid>
          <Grid item xs={3}>
            <CustomTextField
              size="small"
              fullWidth
              name="instituteName"
              value={values.instituteName}
              onChange={handleChange}
              placeholder="Type.."
              error={Boolean(errors.instituteName) && touched.instituteName}
              helperText={touched.instituteName && errors.instituteName}
            />
          </Grid>
          <Grid item xs={2}>
            <CustomTextField
              size="small"
              fullWidth
              name="percentage"
              value={values.percentage}
              onChange={handleChange}
              placeholder="Type.."
              type="number"
            />
          </Grid>
          <Grid item xs={2}>
            <CustomTextField
              size="small"
              fullWidth
              name="grade"
              value={values.grade}
              onChange={handleChange}
              placeholder="Type.."
            />
          </Grid>

          <Grid item xs={1.5}>
            <CustomTextField
              size="small"
              fullWidth
              name="passingYear"
              value={values.passingYear}
              onChange={handleChange}
              placeholder="Type.."
              error={Boolean(errors.passingYear) && touched.passingYear}
              helperText={touched.passingYear && errors.passingYear}
            />
          </Grid>
          <Grid
            item
            xs={0.5}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {updatingEducationInfo ? (
              <CircularProgress size={20} />
            ) : (
              <IconButton sx={{ p: "4px !imortant" }} type="submit">
                <AddCircle
                  sx={{
                    color: (theme) => theme.palette.secondary.main,
                    fontSize: "25px",
                  }}
                />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default EducationInfoModal;
