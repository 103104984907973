const baseRoutes = {
  login: `login`,
  register: `register`,
  institute: `institute`,
  collection: `collection`,
  instituteManagement: `instituteManagement`,
  studentManagement: `student-management`,
  ledgerAndAccount: `ledger-and-account`,
  accessControl: `access-control`,
  communication: `communication`,
  feeManagement: `fee-management`,
  dashboard: `dashboard`,
  product: `product`,
};

const appRoutes = {
  invoice: "invoice",
  login: baseRoutes.login,
  register: baseRoutes.register,
  forgotPassword: "/forgot-password",
  // passwordChange: "/password-change",
  bioMetric: baseRoutes.bioMetric,
  userProfile: "user-profile",

  validateSession: baseRoutes.validateSession,
  // dashboard
  dashboard: baseRoutes.dashboard,
  schoolDashboard: `${baseRoutes.dashboard}/school`,
  // product
  product: baseRoutes.product,

  // school
  institute: baseRoutes.institute,
  collection: baseRoutes.collection,
  aboutInstitute: `/${baseRoutes.institute}/about-school`,
  instituteProfile: `/${baseRoutes.institute}/school-profile`,
  instituteEnquiry: `${baseRoutes.institute}/enquiry`,
  instituteList: `${baseRoutes.institute}/institute-list`,
  singleInstituteView: `${baseRoutes.institute}/institute-list/singleInstitßuteView`,
  instituteGroup: `${baseRoutes.institute}/institute-group`,
  viewInstituteGroup: `${baseRoutes.institute}/view-institute-group`,

  studentManagement: `${baseRoutes.studentManagement}`,
  idCard: `${baseRoutes.studentManagement}/id-card`,
  customIdCard: `${baseRoutes.studentManagement}/custom-id-card`,
  customStaffIdCard: `${baseRoutes.studentManagement}/custom-staff-id-card`,
  erpCollection: `${baseRoutes.collection}/erp-collection`,
  taxInvoice: `${baseRoutes.collection}/tax-invoice`,

  // fee routes
  feeCollection: `${baseRoutes.feeManagement}/fee-collection`,
  feeStructure: `${baseRoutes.feeManagement}/fee-structure`,
  feeReport: `${baseRoutes.feeManagement}/fee-report`,

  // ledger and accounts
  ledgerCollection: `${baseRoutes.ledgerAndAccount}/collection`,
  ledgerExpense: `${baseRoutes.ledgerAndAccount}/expense`,
  ledgerReport: `${baseRoutes.ledgerAndAccount}/report`,

  // institute management
  sessionSetting: `${baseRoutes.instituteManagement}/session-setting`,
  class: `${baseRoutes.instituteManagement}/class`,
  department: `${baseRoutes.instituteManagement}/department`,

  // communication
  communication: baseRoutes.communication,
  whatsAppTemplate: `${baseRoutes.communication}/whats-app-template`,
  textSms: `${baseRoutes.communication}/text-sms`,

  // access control
  permissions: `${baseRoutes.accessControl}/permissions`,
  authorizedPerson: `${baseRoutes.accessControl}/authorized-person`,
};

export default appRoutes;
