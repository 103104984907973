import { Box, Stack, styled } from "@mui/material";
import React from "react";

const TableActionButtons = ({
  showEdit = false,
  showView = false,
  showDelete = false,
  showAdd = false,
  handleEdit = () => {},
  handleView = () => {},
  handleDelete = () => {},
  handleAdd = () => {},
}) => {
  return (
    <StyledWrapper direction="row" gap={0.8}>
      {showAdd && (
        <Box onClick={handleAdd} sx={{ cursor: "pointer" }}>
          <img alt="edit" src="/add-icon.png" className="action_icon" />
        </Box>
      )}
      {showEdit && (
        <Box onClick={handleEdit} sx={{ cursor: "pointer" }}>
          <img alt="edit" src="/edit-icon.png" className="action_icon" />
        </Box>
      )}
      {showView && (
        <Box onClick={handleView} sx={{ cursor: "pointer" }}>
          <img alt="edit" src="/view-icon.png" className="action_icon" />
        </Box>
      )}
      {showDelete && (
        <Box onClick={handleDelete} sx={{ cursor: "pointer" }}>
          <img alt="edit" src="/delete-icon.png" className="action_icon" />
        </Box>
      )}
    </StyledWrapper>
  );
};

export default TableActionButtons;
const StyledWrapper = styled(Stack)`
  .action_icon {
    height: 28px;
    width: 28px;
    object-fit: contain;
  }
`;
