import React, { useEffect } from "react";
import { Grid, DialogActions, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

import { useMutation } from "react-query";

import { CustomTextField } from "assets/styles/globalStyledComponent";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import { updateParentInfo } from "app/services/auth/user.service";

const ParentInfoModal = ({
  handleCloseModal,
  getUserDetail,
  staffResponse,
}) => {
  const validationSchema = yup.object({
    m_fName: yup.string().required("First Name Required"),
    f_fName: yup.string().required("First Name Required"),
  });
  const formik = useFormik({
    initialValues: {
      m_fName: "",
      m_lName: "",
      m_mName: "",
      f_fName: "",
      f_lName: "",
      f_mName: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const newParentInfo = {
        fatherInfo: {
          firstName: values.f_fName,
          middleName: values.f_mName,
          lastName: values.f_lName,
        },
        motherInfo: {
          firstName: values.m_fName,
          middleName: values.m_mName,
          lastName: values.m_lName,
        },
      };
      mutate(newParentInfo);
    },
  });

  const { mutate, isLoading: updatingParentInfoLoading } = useMutation(
    updateParentInfo,
    {
      onSuccess: ({ data }) => {
        toast.success(data?.message);
        getUserDetail();
        handleCloseModal();
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  useEffect(() => {
    if (staffResponse) {
      const staff = staffResponse?.data?.obj?.staffDetail;
      formik.setValues((prev) => ({
        ...prev,
        m_fName: staff?.motherInfo?.firstName,
        m_lName: staff?.motherInfo?.lastName,
        m_mName: staff?.motherInfo?.middleName,
        f_fName: staff?.fatherInfo?.firstName,
        f_lName: staff?.fatherInfo?.lastName,
        f_mName: staff?.fatherInfo?.middleName,
      }));
    }
  }, [staffResponse]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Typography
          sx={{
            fontSize: "16px",
            color: (theme) => theme.palette.primary.main,
            fontWeight: 600,
            mb: 1,
          }}
        >
          Mother's Info
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextFieldLabel title="First Name" />
            <CustomTextField
              placeholder="Type first name"
              id="m_fName"
              name="m_fName"
              size="small"
              fullWidth
              value={formik.values.m_fName}
              onChange={formik.handleChange}
              error={formik.touched.m_fName && Boolean(formik.errors.m_fName)}
              helperText={formik.touched.m_fName && formik.errors.m_fName}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldLabel title="Middle Name" />
            <CustomTextField
              placeholder="Type middle name"
              id="m_mName"
              name="m_mName"
              size="small"
              fullWidth
              value={formik.values.m_mName}
              onChange={formik.handleChange}
              error={formik.touched.m_mName && Boolean(formik.errors.m_mName)}
              helperText={formik.touched.m_mName && formik.errors.m_mName}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldLabel title="Last Name" />
            <CustomTextField
              placeholder="Type last name"
              id="m_lName"
              name="m_lName"
              size="small"
              fullWidth
              value={formik.values.m_lName}
              onChange={formik.handleChange}
              error={formik.touched.m_lName && Boolean(formik.errors.m_lName)}
              helperText={formik.touched.m_lName && formik.errors.m_lNamm}
            />
          </Grid>
        </Grid>

        <Typography
          sx={{
            fontSize: "16px",
            color: (theme) => theme.palette.primary.main,
            fontWeight: 600,
            mt: 2,
          }}
        >
          Father's Info
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextFieldLabel title="First Name" />
            <CustomTextField
              placeholder="Type first name"
              id="f_fName"
              name="f_fName"
              size="small"
              fullWidth
              value={formik.values.f_fName}
              onChange={formik.handleChange}
              error={formik.touched.f_fName && Boolean(formik.errors.f_fName)}
              helperText={formik.touched.f_fName && formik.errors.f_fName}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldLabel title="Middle Name" />
            <CustomTextField
              placeholder="Type middle name"
              id="f_mName"
              name="f_mName"
              size="small"
              fullWidth
              value={formik.values.f_mName}
              onChange={formik.handleChange}
              error={formik.touched.f_mName && Boolean(formik.errors.f_mName)}
              helperText={formik.touched.f_mName && formik.errors.f_mName}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldLabel title="Last Name" />
            <CustomTextField
              placeholder="Type last name"
              id="f_lName"
              name="f_lName"
              size="small"
              fullWidth
              value={formik.values.f_lName}
              onChange={formik.handleChange}
              error={formik.touched.f_lName && Boolean(formik.errors.f_lName)}
              helperText={formik.touched.f_lName && formik.errors.f_lName}
            />
          </Grid>
        </Grid>

        <DialogActions>
          <LoadingButton
            loading={updatingParentInfoLoading}
            color="secondary"
            variant="contained"
            type="submit"
            size="large"
            sx={{ textTransform: "capitalize" }}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </>
  );
};

export default ParentInfoModal;
