import React from "react";
import Fallback from "app/components/Fallback";
const SchoolDashboard = React.lazy(() =>
  import("app/modules/dashboard/pages/SchoolDashboard")
);

export const SchoolDashboardPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <SchoolDashboard {...props} />
  </React.Suspense>
);
