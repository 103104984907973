import React from "react";
import { MaterialReactTable } from "material-react-table";
import { useMediaQuery } from "@mui/material";
const CommonTablePagination = ({
  columns = [],
  data = [],
  maxHeight = "65vh",
  page = 1,
  totalPage = 1,
  setPagination,
  ...props
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      enableStickyHeader={true}
      enableTopToolbar={true}
      paginationDisplayMode="pages"
      muiPaginationProps={{
        showRowsPerPage: false,
        shape: "rounded",
        page: page,
        count: totalPage,
        onChange: setPagination,
        rowsPerPage: 20,
      }}
      muiTableProps={{
        sx: {
          tableLayout: isMobile ? "auto" : "fixed",
        },
      }}
      muiTablePaperProps={{
        sx: {
          boxShadow: 0,
          border: "1px solid #B6C8D6",
          borderRadius: "10px",
        },
      }}
      muiTopToolbarProps={{
        sx: {
          borderRadius: "10px 10px 0 0",
          backgroundColor: "#f6f8fc",
        },
      }}
      muiTableContainerProps={{
        sx: {
          maxHeight: maxHeight,
          overflowY: "auto",
          borderRadius: "0 0 10px 10px",
          "&::-webkit-scrollbar": {
            width: 10,
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-track": {
            border: "1px solid #48657c",
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#465f72",
            borderRadius: "10px",
          },
        },
      }}
      muiTableBodyCellProps={{
        sx: {
          fontSize: isMobile ? ".9rem" : "12px",
          color: "#0C2F49",
        },
      }}
      muiTableHeadCellProps={{
        sx: {
          fontWeight: "normal",
          fontSize: isMobile ? "1rem" : "14px",
          color: "#0C2F49",
          textAlign: "center",
          marginX: "auto",
          marginY: "auto",
        },
      }}
      muiTableHeadRowProps={{
        sx: {
          height: "50px",
          width: "100%",
          backgroundColor: "#F6F8FC",
        },
      }}
      enableColumnFilters
      muiTableBodyProps={{
        sx: {
          fontSize: "14px",
        },
      }}
      {...props}
    />
  );
};

export default CommonTablePagination;
