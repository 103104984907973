import { axiosInstance } from "../axios";
const reporting_route = `${process.env.REACT_APP_SCHOOL_REPORTING_BASE}/certificate`;

export const getTotalIdCardFrame = (params) => {
  return axiosInstance.get(`${reporting_route}/getTotalIdCardFrame`, {
    params,
  });
};
export const getIdCardFrameBySchool = (params) => {
  return axiosInstance.get(`${reporting_route}/getIdCardFrameBySchool`, {
    params,
  });
};

export const deleteIdCardFrame = (_id) => {
  return axiosInstance.delete(`${reporting_route}/deleteIdCardFrame/${_id}`);
};

export const customizeIdCardFrame = (body) => {
  return axiosInstance.put(`${reporting_route}/customizeIdCardFrame`, body);
};
export const editIdCardFrame = (body) => {
  return axiosInstance.put(`${reporting_route}/editIdCardFrame`, body);
};
export const addIdCardFrame = (body) => {
  return axiosInstance.post(`${reporting_route}/addIdCardFrame`, body);
};
