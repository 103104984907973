import React, { useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { FormatFullName } from "app/utils/helper";
import demoLogo from "assets/media/images/NoticeBoard-images/demoLogo.png";
import profilePlaceHolder from "assets/media/images/idCard-Images/userImage.png";

const CARD_WIDTH = "5.5cm";
const CARD_HEIGHT = "8.6cm";

const GridContainer = ({ item, labelName, value, index, close = false }) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        position: "absolute",
        top: `${item?.y_axis}px` ?? "20px",
        left: `${item?.x_axis}px` ?? "20px",
      }}
    >
      <Grid
        item
        xs={close ? 1 : 4}
        display={"flex"}
        justifyContent={"flex-start"}
      >
        <Typography
          key={index}
          sx={{
            fontSize: `${item?.fontSize}px`,
            fontWeight: `${item?.fontWeight}`,
            color: item?.fontColor ?? "#000000",
            fontStyle: "bold",
          }}
        >
          {labelName ?? "labelName"}
        </Typography>
      </Grid>
      <Grid item xs={close ? 4 : 8}>
        <Typography
          key={index}
          noWrap
          sx={{
            fontSize: `${item?.fontSize}px`,
            fontWeight: `${item?.fontWeight}`,
            color: item?.fontColor ?? "#000000",
            fontStyle: `${item?.fontStyle}` ?? "normal",
          }}
        >
          {value ?? "--"}
        </Typography>
      </Grid>
    </Grid>
  );
};

const VerticalStaffIdCard = ({
  data,
  value,
  idCardDetailLocation,
  schoolDetail,
  showPositionAbsolute = false,
}) => {
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    if (value.startsWith("verticalIdCard")) {
      const index = parseInt(value.replace("verticalIdCard", "")) - 1;
      setRowData(data[index]);
    }
    if (value === "mainIdFormat") {
      setRowData(data);
    }
  }, [data, value]);
  const returnState = (text) => {
    try {
      if (String(text).includes("&")) {
        const spl = String(text).split("&");
        return `${spl[0].charAt(0).toUpperCase()}.${spl[1]
          .charAt(1)
          .toUpperCase()}`;
      } else {
        const isSpace = /\s/g.test(text);
        if (isSpace) {
          const spl = String(text).split(" ");

          return `${spl[0].charAt(0).toUpperCase()}.${spl[1]
            .charAt(0)
            .toUpperCase()}`;
        } else {
          return text;
        }
      }
    } catch (error) {}
  };
  return (
    <Wrapper>
      <Box
        sx={{
          position: !showPositionAbsolute ? "relative" : "absolute",
          height: CARD_HEIGHT,
          width: CARD_WIDTH,
          backgroundImage: `url(${idCardDetailLocation?.frameURL ?? ""})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        {/* for logo and school name */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "Logo") {
            return (
              <Typography
                key={index}
                sx={{
                  position: "absolute",
                  zIndex: 1,
                  top: `${item?.y_axis}px`,
                  left: `${item?.x_axis}px`,
                }}
              >
                <img
                  style={{
                    height: `100%`,
                    width: `${item?.fontSize}px`,
                    color: item?.fontColor ?? "#000000",
                    fontWeight: item?.fontWeight,
                    fontStyle: item?.fontStyle ?? "normal",
                    borderRadius: item?.shape === "circle" ? "50%" : "0",
                    objectFit: "contain",
                  }}
                  src={schoolDetail?.schoolLogo ?? demoLogo}
                  alt="logo"
                />
              </Typography>
            );
          }
          return null;
        })}

        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "SchoolName") {
            return (
              <Typography
                key={index}
                sx={{
                  fontSize: `${item?.fontSize}px`,
                  fontWeight: `${item?.fontWeight}`,
                  position: "absolute",
                  zIndex: 2,
                  textAlign: "center",
                  color: item?.fontColor ?? "#000000",
                  top: `${item?.y_axis}px` ?? 20,
                  left: `${item?.x_axis}px` ?? 20,
                  fontStyle: `${item?.fontStyle}` ?? "normal",
                }}
              >
                {schoolDetail?.schoolName ?? "EkalSutra Edtech Pvt. Ltd."}
              </Typography>
            );
          }
          return null;
        })}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "Profile Pic") {
            return (
              <Typography
                key={index}
                sx={{
                  position: "absolute",
                  zIndex: 1,
                  top: `${item?.y_axis}px`,
                  left: `${item?.x_axis}px`,
                }}
              >
                <img
                  style={{
                    height: `${item?.fontSize}px`,
                    width: `${item?.fontSize}px`,
                    color: item?.fontColor ?? "#000000",
                    fontWeight: item?.fontWeight,
                    fontStyle: item?.fontStyle ?? "normal",
                    borderRadius: item?.shape === "circle" ? "50%" : "0%",
                    objectFit: "contain",
                  }}
                  src={rowData?.profilePic ?? profilePlaceHolder}
                  alt="profilePic"
                />
              </Typography>
            );
          }
          return null;
        })}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "StaffName") {
            const name = FormatFullName(
              rowData?.firstName,
              rowData?.middleName,
              rowData?.lastName
            );
            const fontSize =
              name.length > 20 ? "smallerFontSize" : `${item?.fontSize}px`;
            return (
              <div
                key={index}
                style={{
                  position: "absolute",
                  top: `${item?.y_axis}px` ?? "20px",
                  // left: `${item?.x_axis}px` ?? "20px",
                  width: "100%",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  key={index}
                  sx={{
                    fontSize: fontSize,
                    fontWeight: `${item?.fontWeight}`,
                    color: item?.fontColor ?? "#000000",
                    fontStyle: `${item?.fontStyle}` ?? "normal",
                  }}
                >
                  {name ?? "Staff Name"}
                </Typography>
              </div>
            );
          }
          return null;
        })}

        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "StaffDOB") {
            return (
              <GridContainer
                item={item}
                index={index}
                labelName={item?.labelName ?? "DOB"}
                value={moment(rowData?.dob ?? new Date()).format("DD-MM-YYYY")}
              />
            );
          }
          return null;
        })}
        {/* department */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "Department") {
            return (
              <Typography
                key={index}
                sx={{
                  fontSize: `${item?.fontSize}px`,
                  fontWeight: `${item?.fontWeight}`,
                  position: "absolute",
                  zIndex: 2,
                  width: "100%",
                  textAlign: "center",
                  color: item?.fontColor ?? "#000000",
                  top: `${item?.y_axis}px` ?? 20,
                  fontStyle: `${item?.fontStyle}` ?? "normal",
                }}
              >
                {`${rowData?.department ?? "Department"}`}
              </Typography>
            );
          }
        })}
        {/* joining date */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "StaffJoiningDate") {
            return (
              <GridContainer
                item={item}
                index={index}
                labelName={item?.labelName ?? "Joining Date"}
                value={moment(rowData?.joiningDate).format("DD-MM-YYYY")}
              />
            );
          }
        })}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "StaffMobileNumber") {
            return (
              <GridContainer
                item={item}
                index={index}
                labelName={item?.labelName ?? "Mobile Number"}
                value={rowData?.mobileNumber ?? "--"}
              />
            );
          }
        })}
        {/* father name */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "StaffFatherName") {
            return (
              <GridContainer
                item={item}
                index={index}
                labelName={item?.labelName ?? "F.Name"}
                value={FormatFullName(
                  rowData?.fatherInfo?.firstName,
                  rowData?.fatherInfo?.middleName,
                  rowData?.fatherInfo?.lastName
                )}
              />
            );
          }
        })}
        {/* mother name */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "StaffMotherName") {
            return (
              <GridContainer
                item={item}
                index={index}
                labelName={item?.labelName ?? "M.Name"}
                value={FormatFullName(
                  rowData?.motherInfo?.firstName,
                  rowData?.motherInfo?.middleName,
                  rowData?.motherInfo?.lastName
                )}
              />
            );
          }
        })}
        {/* satff address */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "StaffAddress") {
            return (
              <GridContainer
                item={item}
                index={index}
                labelName={item?.labelName ?? "Address"}
                value={`${rowData?.address ?? ""} ${
                  rowData?.dist ?? ""
                } ${returnState(rowData?.state)} ${rowData?.pinCode ?? ""}`}
              />
            );
          }
        })}
        {/* principale sign */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "StaffPrincipalSign") {
            return (
              <>
                <img
                  style={{
                    position: "absolute",
                    zIndex: 1,
                    height: `${20}px`,
                    width: `20%`,
                    color: item?.fontColor ?? "#000000",
                    fontWeight: item?.fontWeight,
                    fontStyle: item?.fontStyle ?? "normal",
                    objectFit: "contain",
                    top: `${item?.y_axis - 20}px`,
                  }}
                  src={schoolDetail?.signatureForIdCard}
                  alt="Sign"
                />
                <Typography
                  key={index}
                  sx={{
                    position: "absolute",
                    zIndex: 1,
                    top: `${item?.y_axis}px`,
                  }}
                >
                  Principle
                </Typography>
              </>
            );
          }
        })}

        {/* school address */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "SchoolAddress") {
            return (
              <Stack
                style={{
                  position: "absolute",
                  top: `${item?.y_axis}px` ?? "20px",
                  left: `${item?.x_axis}px` ?? "20px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  key={index}
                  sx={{
                    fontSize: `${item?.fontSize}px`,
                    fontWeight: `${item?.fontWeight}`,
                    color: item?.fontColor ?? "#000000",
                    fontStyle: `${item?.fontStyle}` ?? "normal",
                  }}
                >
                  {item?.labelName ?? "Address"}{" "}
                  {schoolDetail?.schoolAddress?.address}
                  {schoolDetail?.schoolAddress?.dist}{" "}
                  {returnState(schoolDetail?.schoolAddress?.state)},{" "}
                  {schoolDetail?.schoolAddress?.pinCode}
                </Typography>
              </Stack>
            );
          }
        })}
        {/* id card number */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "StaffIdCardNumber") {
            return (
              <GridContainer
                item={item}
                labelName={item?.labelName ?? "Id Card No"}
                value={rowData?.session ?? "--"}
                index={index}
                close={true}
              />
            );
          }
        })}
        {/* Employee Id */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "StaffEmployeeId") {
            return (
              <GridContainer
                item={item}
                index={index}
                labelName={item?.labelName ?? "Employee Id"}
                value={
                  rowData?.employeeId
                    ? moment(rowData?.employeeId).format("LL")
                    : "--"
                }
              />
            );
          }
        })}
        {/* school contact number */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "School Contact Number") {
            return (
              <GridContainer
                item={item}
                labelName={item?.labelName ?? "Contact No:"}
                value={schoolDetail?.mobileNumber ?? "--"}
                index={index}
              />
            );
          }
        })}
        {/*employment type */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "StaffEmploymentType") {
            return (
              <GridContainer
                item={item}
                index={index}
                labelName={item?.labelName ?? "Employment Type"}
                value={rowData?.employmentType ?? "--"}
              />
            );
          }
        })}
        {/*Gender*/}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "StaffGender") {
            return (
              <GridContainer
                item={item}
                index={index}
                labelName={item?.labelName ?? "--"}
                value={rowData?.gender ?? "--"}
              />
            );
          }
        })}
        {/*Blood Group*/}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "StaffBloodGroup") {
            return (
              <GridContainer
                item={item}
                index={index}
                labelName={item?.labelName ?? "Blood Group"}
                value={rowData?.bloodGroup ?? "--"}
              />
            );
          }
        })}
        {/*PAN No*/}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "StaffPAN") {
            return (
              <GridContainer
                item={item}
                index={index}
                labelName={item?.labelName ?? "PAN"}
                value={rowData?.panNo ?? "--"}
              />
            );
          }
        })}
        {/*Staff Aadhar*/}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "StaffAadhar") {
            return (
              <GridContainer
                item={item}
                index={index}
                labelName={item?.labelName ?? "Aadhar"}
                value={rowData?.aadhar ?? "--"}
              />
            );
          }
        })}
        {/*designation*/}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "StaffDesignation") {
            return (
              <GridContainer
                item={item}
                index={index}
                labelName={item?.labelName ?? "Designation"}
                value={rowData?.designation ?? "--"}
              />
            );
          }
        })}
      </Box>
    </Wrapper>
  );
};

export default VerticalStaffIdCard;

const Wrapper = styled.div`
  height: ${CARD_HEIGHT};
  width: ${CARD_WIDTH};
`;
