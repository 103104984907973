export const States = [
  "Andaman & Nicobar",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chandigarh",
  "Chhattisgarh",
  "Dadra & Nagar Haveli",
  "Daman & Diu",
  "Delhi",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu & Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Lakshadweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Orissa",
  "Pondicherry",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Tripura",
  "Uttar Pradesh",
  "Uttaranchal",
  "West Bengal",
];

export const boards = [
  "CBSE - Central Board of Secondary Education",
  "ISC - Indian School Certificate",
  "ICSE - Indian School Certificate Examinations",
  "NIOS – National Institute of Open Schooling",
  "UP Board - Board of High School and Intermediate Education Uttar Pradesh",
  "JKBOSE - Jammu and Kashmir State Board of School Education",
  "RBSE - Board of Secondary Education Rajasthan",
  "HPBOSE - Himachal Pradesh Board of School Education",
  "MPBSE - Madhya Pradesh Board of Secondary Education",
  "CGBSE - Chhattisgarh Board of Secondary Education",
  "PSEB – Punjab School Education Board",
  "BSEH - Haryana Board of School Education (HBSE)",
  "BSEB - Bihar School Examination Board",
  "GSEB - Gujarat Secondary and Higher Secondary Education Board",
  "MSBSHSE – Maharashtra State Board Of Secondary and Higher Secondary Education",
  "BIEAP - Andhra Pradesh Board of Intermediate Education",
  "BSEAP - Andhra Pradesh Board of Secondary Education",
  "WBBSE - West Bengal Board of Secondary Education",
  "WBCHSE - West Bengal Council of Higher Secondary Education",
];

export const boardsShort = [
  "CBSE",
  "ISC",
  "ICSE",
  "NIOS",
  "UP Board",
  "JKBOSE",
  "RBSE",
  "HPBOSE",
  "MPBSE",
  "CGBSE",
  "PSEB",
  "BSEH",
  "BSEB",
  "GSEB",
  "MSBSHSE",
  "BIEAP",
  "BSEAP",
  "WBBSE",
  "WBCHSE",
];

export const GenderChoice = ["Male", "Female", "Other"];
export const HouseColor = ["Red", "Green", "Yellow", "Blue"];

export const FeeIncrementTypeUnit = {
  PerDayFixedRate: "perDayFixedRate",
  PerDayPercent: "perDayPercent",
  FixedAmount: "fixedAmount",
};

export const ExamTypeOptions = [
  "Half Yearly",
  "Annual Exam",
  "Unit Test",
  "Class Test",
  "Other",
];

export const primaryRoles = {
  Principal: ["Principal"],
  "Other-Staff": ["Transport In-Charge", "Driver", "Conductor", "Peon"],
  Teacher: ["Primary Teacher", "Junior Teacher", "Secondary Teacher"],
  Receptionist: ["Receptionist"],
  Accountant: ["Accountant"],
  HR: ["HR"],
};
export const SUBJECTS = [
  {
    label: "Hindi",
    value: "Hindi",
  },

  {
    label: "English",
    value: "English",
  },
  {
    label: "Maths",
    value: "Maths",
  },
  {
    label: "Science",
    value: "Science",
  },
  {
    label: "Biology",
    value: "Biology",
  },
  {
    label: "Commerce",
    value: "Commerce",
  },
  {
    label: "Home Science",
    value: "Home Science",
  },
  {
    label: "Social Science",
    value: "Social Science",
  },
  {
    label: "Agriculture",
    value: "Agriculture",
  },

  {
    label: "General Knowledge",
    value: "General Knowledge",
  },
];
export const CLASSNAMES = [
  {
    label: "Nursery",
    value: "Nursery",
  },
  {
    label: "LKG",
    value: "LKG",
  },
  {
    label: "UKG",
    value: "UKG",
  },
  {
    label: "1st",
    value: "1st",
  },
  {
    label: "2nd",
    value: "2nd",
  },
  {
    label: "3rd",
    value: "3rd",
  },
  {
    label: "4th",
    value: "4th",
  },
  {
    label: "5th",
    value: "5th",
  },
  {
    label: "6th",
    value: "6th",
  },
  {
    label: "7th",
    value: "7th",
  },
  {
    label: "8th",
    value: "8th",
  },
  {
    label: "9th",
    value: "9th",
  },
  {
    label: "10th",
    value: "10th",
  },
  {
    label: "11th",
    value: "11th",
  },
  {
    label: "12th",
    value: "12th",
  },
];

export const Category = ["General", "OBC", "SC", "ST", "Other"];
export const CategoryForFee = ["GENERAL", "OBC", "SC", "ST"];
export const Community = [
  "Hindu",
  "Islam",
  "Christian",
  "Sikh",
  "Buddh",
  "Jain",
  "others",
];
export const Distrct = [
  [""],
  [
    "Alipur",
    "Andaman Island",
    "Anderson Island",
    "Arainj-Laka-Punga",
    "Austinabad",
    "Bamboo Flat",
    "Barren Island",
    "Beadonabad",
    "Betapur",
    "Bindraban",
    "Bonington",
    "Brookesabad",
    "Cadell Point",
    "Calicut",
    "Chetamale",
    "Cinque Islands",
    "Defence Island",
    "Digilpur",
    "Dolyganj",
    "Flat Island",
    "Geinyale",
    "Great Coco Island",
    "Haddo",
    "Havelock Island",
    "Henry Lawrence Island",
    "Herbertabad",
    "Hobdaypur",
    "Ilichar",
    "Ingoie",
    "Inteview Island",
    "Jangli Ghat",
    "Jhon Lawrence Island",
    "Karen",
    "Kartara",
    "KYD Islannd",
    "Landfall Island",
    "Little Andmand",
    "Little Coco Island",
    "Long Island",
    "Maimyo",
    "Malappuram",
    "Manglutan",
    "Manpur",
    "Mitha Khari",
    "Neill Island",
    "Nicobar Island",
    "North Brother Island",
    "North Passage Island",
    "North Sentinel Island",
    "Nothen Reef Island",
    "Outram Island",
    "Pahlagaon",
    "Palalankwe",
    "Passage Island",
    "Phaiapong",
    "Phoenix Island",
    "Port Blair",
    "Preparis Island",
    "Protheroepur",
    "Rangachang",
    "Rongat",
    "Rutland Island",
    "Sabari",
    "Saddle Peak",
    "Shadipur",
    "Smith Island",
    "Sound Island",
    "South Sentinel Island",
    "Spike Island",
    "Tarmugli Island",
    "Taylerabad",
    "Titaije",
    "Toibalawe",
    "Tusonabad",
    "West Island",
    "Wimberleyganj",
    "Yadita",
  ],
  [
    "Achampet",
    "Adilabad",
    "Adoni",
    "Alampur",
    "Allagadda",
    "Alur",
    "Amalapuram",
    "Amangallu",
    "Anakapalle",
    "Anantapur",
    "Andole",
    "Araku",
    "Armoor",
    "Asifabad",
    "Aswaraopet",
    "Atmakur",
    "B. Kothakota",
    "Badvel",
    "Banaganapalle",
    "Bandar",
    "Bangarupalem",
    "Banswada",
    "Bapatla",
    "Bellampalli",
    "Bhadrachalam",
    "Bhainsa",
    "Bheemunipatnam",
    "Bhimadole",
    "Bhimavaram",
    "Bhongir",
    "Bhooragamphad",
    "Boath",
    "Bobbili",
    "Bodhan",
    "Chandoor",
    "Chavitidibbalu",
    "Chejerla",
    "Chepurupalli",
    "Cherial",
    "Chevella",
    "Chinnor",
    "Chintalapudi",
    "Chintapalle",
    "Chirala",
    "Chittoor",
    "Chodavaram",
    "Cuddapah",
    "Cumbum",
    "Darsi",
    "Devarakonda",
    "Dharmavaram",
    "Dichpalli",
    "Divi",
    "Donakonda",
    "Dronachalam",
    "East Godavari",
    "Eluru",
    "Eturnagaram",
    "Gadwal",
    "Gajapathinagaram",
    "Gajwel",
    "Garladinne",
    "Giddalur",
    "Godavari",
    "Gooty",
    "Gudivada",
    "Gudur",
    "Guntur",
    "Hindupur",
    "Hunsabad",
    "Huzurabad",
    "Huzurnagar",
    "Hyderabad",
    "Ibrahimpatnam",
    "Jaggayyapet",
    "Jagtial",
    "Jammalamadugu",
    "Jangaon",
    "Jangareddygudem",
    "Jannaram",
    "Kadiri",
    "Kaikaluru",
    "Kakinada",
    "Kalwakurthy",
    "Kalyandurg",
    "Kamalapuram",
    "Kamareddy",
    "Kambadur",
    "Kanaganapalle",
    "Kandukuru",
    "Kanigiri",
    "Karimnagar",
    "Kavali",
    "Khammam",
    "Khanapur (AP)",
    "Kodangal",
    "Koduru",
    "Koilkuntla",
    "Kollapur",
    "Kothagudem",
    "Kovvur",
    "Krishna",
    "Krosuru",
    "Kuppam",
    "Kurnool",
    "Lakkireddipalli",
    "Madakasira",
    "Madanapalli",
    "Madhira",
    "Madnur",
    "Mahabubabad",
    "Mahabubnagar",
    "Mahadevapur",
    "Makthal",
    "Mancherial",
    "Mandapeta",
    "Mangalagiri",
    "Manthani",
    "Markapur",
    "Marturu",
    "Medachal",
    "Medak",
    "Medarmetla",
    "Metpalli",
    "Mriyalguda",
    "Mulug",
    "Mylavaram",
    "Nagarkurnool",
    "Nalgonda",
    "Nallacheruvu",
    "Nampalle",
    "Nandigama",
    "Nandikotkur",
    "Nandyal",
    "Narasampet",
    "Narasaraopet",
    "Narayanakhed",
    "Narayanpet",
    "Narsapur",
    "Narsipatnam",
    "Nazvidu",
    "Nelloe",
    "Nellore",
    "Nidamanur",
    "Nirmal",
    "Nizamabad",
    "Nuguru",
    "Ongole",
    "Outsarangapalle",
    "Paderu",
    "Pakala",
    "Palakonda",
    "Paland",
    "Palmaneru",
    "Pamuru",
    "Pargi",
    "Parkal",
    "Parvathipuram",
    "Pathapatnam",
    "Pattikonda",
    "Peapalle",
    "Peddapalli",
    "Peddapuram",
    "Penukonda",
    "Piduguralla",
    "Piler",
    "Pithapuram",
    "Podili",
    "Polavaram",
    "Prakasam",
    "Proddatur",
    "Pulivendla",
    "Punganur",
    "Putturu",
    "Rajahmundri",
    "Rajampeta",
    "Ramachandrapuram",
    "Ramannapet",
    "Rampachodavaram",
    "Rangareddy",
    "Rapur",
    "Rayachoti",
    "Rayadurg",
    "Razole",
    "Repalle",
    "Saluru",
    "Sangareddy",
    "Sathupalli",
    "Sattenapalle",
    "Satyavedu",
    "Shadnagar",
    "Siddavattam",
    "Siddipet",
    "Sileru",
    "Sircilla",
    "Sirpur Kagaznagar",
    "Sodam",
    "Sompeta",
    "Srikakulam",
    "Srikalahasthi",
    "Srisailam",
    "Srungavarapukota",
    "Sudhimalla",
    "Sullarpet",
    "Tadepalligudem",
    "Tadipatri",
    "Tanduru",
    "Tanuku",
    "Tekkali",
    "Tenali",
    "Thungaturthy",
    "Tirivuru",
    "Tirupathi",
    "Tuni",
    "Udaygiri",
    "Ulvapadu",
    "Uravakonda",
    "Utnor",
    "V.R. Puram",
    "Vaimpalli",
    "Vayalpad",
    "Venkatgiri",
    "Venkatgirikota",
    "Vijayawada",
    "Vikrabad",
    "Vinjamuru",
    "Vinukonda",
    "Visakhapatnam",
    "Vizayanagaram",
    "Vizianagaram",
    "Vuyyuru",
    "Wanaparthy",
    "Warangal",
    "Wardhannapet",
    "Yelamanchili",
    "Yelavaram",
    "Yeleswaram",
    "Yellandu",
    "Yellanuru",
    "Yellareddy",
    "Yerragondapalem",
    "Zahirabad",
  ],
  [
    "Along",
    "Anini",
    "Anjaw",
    "Bameng",
    "Basar",
    "Changlang",
    "Chowkhem",
    "Daporizo",
    "Dibang Valley",
    "Dirang",
    "Hayuliang",
    "Huri",
    "Itanagar",
    "Jairampur",
    "Kalaktung",
    "Kameng",
    "Khonsa",
    "Kolaring",
    "Kurung Kumey",
    "Lohit",
    "Lower Dibang Valley",
    "Lower Subansiri",
    "Mariyang",
    "Mechuka",
    "Miao",
    "Nefra",
    "Pakkekesang",
    "Pangin",
    "Papum Pare",
    "Passighat",
    "Roing",
    "Sagalee",
    "Seppa",
    "Siang",
    "Tali",
    "Taliha",
    "Tawang",
    "Tezu",
    "Tirap",
    "Tuting",
    "Upper Siang",
    "Upper Subansiri",
    "Yiang Kiag",
  ],
  [
    "Abhayapuri",
    "Baithalangshu",
    "Barama",
    "Barpeta Road",
    "Bihupuria",
    "Bijni",
    "Bilasipara",
    "Bokajan",
    "Bokakhat",
    "Boko",
    "Bongaigaon",
    "Cachar",
    "Cachar Hills",
    "Darrang",
    "Dhakuakhana",
    "Dhemaji",
    "Dhubri",
    "Dibrugarh",
    "Digboi",
    "Diphu",
    "Goalpara",
    "Gohpur",
    "Golaghat",
    "Guwahati",
    "Hailakandi",
    "Hajo",
    "Halflong",
    "Hojai",
    "Howraghat",
    "Jorhat",
    "Kamrup",
    "Karbi Anglong",
    "Karimganj",
    "Kokarajhar",
    "Kokrajhar",
    "Lakhimpur",
    "Maibong",
    "Majuli",
    "Mangaldoi",
    "Mariani",
    "Marigaon",
    "Moranhat",
    "Morigaon",
    "Nagaon",
    "Nalbari",
    "Rangapara",
    "Sadiya",
    "Sibsagar",
    "Silchar",
    "Sivasagar",
    "Sonitpur",
    "Tarabarihat",
    "Tezpur",
    "Tinsukia",
    "Udalgiri",
    "Udalguri",
    "UdarbondhBarpeta",
  ],
  [
    "Adhaura",
    "Amarpur",
    "Araria",
    "Areraj",
    "Arrah",
    "Arwal",
    "Aurangabad",
    "Bagaha",
    "Banka",
    "Banmankhi",
    "Barachakia",
    "Barauni",
    "Barh",
    "Barosi",
    "Begusarai",
    "Benipatti",
    "Benipur",
    "Bettiah",
    "Bhabhua",
    "Bhagalpur",
    "Bhojpur",
    "Bidupur",
    "Biharsharif",
    "Bikram",
    "Bikramganj",
    "Birpur",
    "Buxar",
    "Chakai",
    "Champaran",
    "Chapara",
    "Dalsinghsarai",
    "Danapur",
    "Darbhanga",
    "Daudnagar",
    "Dhaka",
    "Dhamdaha",
    "Dumraon",
    "Ekma",
    "Forbesganj",
    "Gaya",
    "Gogri",
    "Gopalganj",
    "H.Kharagpur",
    "Hajipur",
    "Hathua",
    "Hilsa",
    "Imamganj",
    "Jahanabad",
    "Jainagar",
    "Jamshedpur",
    "Jamui",
    "Jehanabad",
    "Jhajha",
    "Jhanjharpur",
    "Kahalgaon",
    "Kaimur (Bhabua)",
    "Katihar",
    "Katoria",
    "Khagaria",
    "Kishanganj",
    "Korha",
    "Lakhisarai",
    "Madhepura",
    "Madhubani",
    "Maharajganj",
    "Mahua",
    "Mairwa",
    "Mallehpur",
    "Masrakh",
    "Mohania",
    "Monghyr",
    "Motihari",
    "Motipur",
    "Munger",
    "Muzaffarpur",
    "Nabinagar",
    "Nalanda",
    "Narkatiaganj",
    "Naugachia",
    "Nawada",
    "Pakribarwan",
    "Pakridayal",
    "Patna",
    "Phulparas",
    "Piro",
    "Pupri",
    "Purena",
    "Purnia",
    "Rafiganj",
    "Rajauli",
    "Ramnagar",
    "Raniganj",
    "Raxaul",
    "Rohtas",
    "Rosera",
    "S.Bakhtiarpur",
    "Saharsa",
    "Samastipur",
    "Saran",
    "Sasaram",
    "Seikhpura",
    "Sheikhpura",
    "Sheohar",
    "Sherghati",
    "Sidhawalia",
    "Singhwara",
    "Sitamarhi",
    "Siwan",
    "Sonepur",
    "Supaul",
    "Thakurganj",
    "Triveniganj",
    "Udakishanganj",
    "Vaishali",
    "Wazirganj",
  ],
  ["Chandigarh", "Mani Marja"],
  [
    "Ambikapur",
    "Antagarh",
    "Arang",
    "Bacheli",
    "Bagbahera",
    "Bagicha",
    "Baikunthpur",
    "Balod",
    "Balodabazar",
    "Balrampur",
    "Barpalli",
    "Basana",
    "Bastanar",
    "Bastar",
    "Bderajpur",
    "Bemetara",
    "Berla",
    "Bhairongarh",
    "Bhanupratappur",
    "Bharathpur",
    "Bhatapara",
    "Bhilai",
    "Bhilaigarh",
    "Bhopalpatnam",
    "Bijapur",
    "Bilaspur",
    "Bodla",
    "Bokaband",
    "Chandipara",
    "Chhinagarh",
    "Chhuriakala",
    "Chingmut",
    "Chuikhadan",
    "Dabhara",
    "Dallirajhara",
    "Dantewada",
    "Deobhog",
    "Dhamda",
    "Dhamtari",
    "Dharamjaigarh",
    "Dongargarh",
    "Durg",
    "Durgakondal",
    "Fingeshwar",
    "Gariaband",
    "Garpa",
    "Gharghoda",
    "Gogunda",
    "Ilamidi",
    "Jagdalpur",
    "Janjgir",
    "Janjgir-Champa",
    "Jarwa",
    "Jashpur",
    "Jashpurnagar",
    "Kabirdham-Kawardha",
    "Kanker",
    "Kasdol",
    "Kathdol",
    "Kathghora",
    "Kawardha",
    "Keskal",
    "Khairgarh",
    "Kondagaon",
    "Konta",
    "Korba",
    "Korea",
    "Kota",
    "Koyelibeda",
    "Kuakunda",
    "Kunkuri",
    "Kurud",
    "Lohadigundah",
    "Lormi",
    "Luckwada",
    "Mahasamund",
    "Makodi",
    "Manendragarh",
    "Manpur",
    "Marwahi",
    "Mohla",
    "Mungeli",
    "Nagri",
    "Narainpur",
    "Narayanpur",
    "Neora",
    "Netanar",
    "Odgi",
    "Padamkot",
    "Pakhanjur",
    "Pali",
    "Pandaria",
    "Pandishankar",
    "Parasgaon",
    "Pasan",
    "Patan",
    "Pathalgaon",
    "Pendra",
    "Pratappur",
    "Premnagar",
    "Raigarh",
    "Raipur",
    "Rajnandgaon",
    "Rajpur",
    "Ramchandrapur",
    "Saraipali",
    "Saranggarh",
    "Sarona",
    "Semaria",
    "Shakti",
    "Sitapur",
    "Sukma",
    "Surajpur",
    "Surguja",
    "Tapkara",
    "Toynar",
    "Udaipur",
    "Uproda",
    "Wadrainagar",
  ],
  [
    "Amal",
    "Amli",
    "Bedpa",
    "Chikhli",
    "Dadra & Nagar Haveli",
    "Dahikhed",
    "Dolara",
    "Galonda",
    "Kanadi",
    "Karchond",
    "Khadoli",
    "Kharadpada",
    "Kherabari",
    "Kherdi",
    "Kothar",
    "Luari",
    "Mashat",
    "Rakholi",
    "Rudana",
    "Saili",
    "Sili",
    "Silvassa",
    "Sindavni",
    "Udva",
    "Umbarkoi",
    "Vansda",
    "Vasona",
    "Velugam",
  ],
  [
    "Brancavare",
    "Dagasi",
    "Daman",
    "Diu",
    "Magarvara",
    "Nagwa",
    "Pariali",
    "Passo Covo",
  ],
  [
    "Central Delhi",
    "East Delhi",
    "New Delhi",
    "North Delhi",
    "North East Delhi",
    "North West Delhi",
    "South Delhi",
    "South West Delhi",
    "West Delhi",
  ],
  [
    "Canacona",
    "Candolim",
    "Chinchinim",
    "Cortalim",
    "Goa",
    "Jua",
    "Madgaon",
    "Mahem",
    "Mapuca",
    "Marmagao",
    "Panji",
    "Ponda",
    "Sanvordem",
    "Terekhol",
  ],
  [
    "Ahmedabad",
    "Ahwa",
    "Amod",
    "Amreli",
    "Anand",
    "Anjar",
    "Ankaleshwar",
    "Babra",
    "Balasinor",
    "Banaskantha",
    "Bansada",
    "Bardoli",
    "Bareja",
    "Baroda",
    "Barwala",
    "Bayad",
    "Bhachav",
    "Bhanvad",
    "Bharuch",
    "Bhavnagar",
    "Bhiloda",
    "Bhuj",
    "Billimora",
    "Borsad",
    "Botad",
    "Chanasma",
    "Chhota Udaipur",
    "Chotila",
    "Dabhoi",
    "Dahod",
    "Damnagar",
    "Dang",
    "Danta",
    "Dasada",
    "Dediapada",
    "Deesa",
    "Dehgam",
    "Deodar",
    "Devgadhbaria",
    "Dhandhuka",
    "Dhanera",
    "Dharampur",
    "Dhari",
    "Dholka",
    "Dhoraji",
    "Dhrangadhra",
    "Dhrol",
    "Dwarka",
    "Fortsongadh",
    "Gadhada",
    "Gandhi Nagar",
    "Gariadhar",
    "Godhra",
    "Gogodar",
    "Gondal",
    "Halol",
    "Halvad",
    "Harij",
    "Himatnagar",
    "Idar",
    "Jambusar",
    "Jamjodhpur",
    "Jamkalyanpur",
    "Jamnagar",
    "Jasdan",
    "Jetpur",
    "Jhagadia",
    "Jhalod",
    "Jodia",
    "Junagadh",
    "Junagarh",
    "Kalawad",
    "Kalol",
    "Kapad Wanj",
    "Keshod",
    "Khambat",
    "Khambhalia",
    "Khavda",
    "Kheda",
    "Khedbrahma",
    "Kheralu",
    "Kodinar",
    "Kotdasanghani",
    "Kunkawav",
    "Kutch",
    "Kutchmandvi",
    "Kutiyana",
    "Lakhpat",
    "Lakhtar",
    "Lalpur",
    "Limbdi",
    "Limkheda",
    "Lunavada",
    "M.M.Mangrol",
    "Mahuva",
    "Malia-Hatina",
    "Maliya",
    "Malpur",
    "Manavadar",
    "Mandvi",
    "Mangrol",
    "Mehmedabad",
    "Mehsana",
    "Miyagam",
    "Modasa",
    "Morvi",
    "Muli",
    "Mundra",
    "Nadiad",
    "Nakhatrana",
    "Nalia",
    "Narmada",
    "Naswadi",
    "Navasari",
    "Nizar",
    "Okha",
    "Paddhari",
    "Padra",
    "Palanpur",
    "Palitana",
    "Panchmahals",
    "Patan",
    "Pavijetpur",
    "Porbandar",
    "Prantij",
    "Radhanpur",
    "Rahpar",
    "Rajaula",
    "Rajkot",
    "Rajpipla",
    "Ranavav",
    "Sabarkantha",
    "Sanand",
    "Sankheda",
    "Santalpur",
    "Santrampur",
    "Savarkundla",
    "Savli",
    "Sayan",
    "Sayla",
    "Shehra",
    "Sidhpur",
    "Sihor",
    "Sojitra",
    "Sumrasar",
    "Surat",
    "Surendranagar",
    "Talaja",
    "Thara",
    "Tharad",
    "Thasra",
    "Una-Diu",
    "Upleta",
    "Vadgam",
    "Vadodara",
    "Valia",
    "Vallabhipur",
    "Valod",
    "Valsad",
    "Vanthali",
    "Vapi",
    "Vav",
    "Veraval",
    "Vijapur",
    "Viramgam",
    "Visavadar",
    "Visnagar",
    "Vyara",
    "Waghodia",
    "Wankaner",
  ],
  [
    "Adampur Mandi",
    "Ambala",
    "Assandh",
    "Bahadurgarh",
    "Barara",
    "Barwala",
    "Bawal",
    "Bawanikhera",
    "Bhiwani",
    "Charkhidadri",
    "Cheeka",
    "Chhachrauli",
    "Dabwali",
    "Ellenabad",
    "Faridabad",
    "Fatehabad",
    "Ferojpur Jhirka",
    "Gharaunda",
    "Gohana",
    "Gurgaon",
    "Hansi",
    "Hisar",
    "Jagadhari",
    "Jatusana",
    "Jhajjar",
    "Jind",
    "Julana",
    "Kaithal",
    "Kalanaur",
    "Kalanwali",
    "Kalka",
    "Karnal",
    "Kosli",
    "Kurukshetra",
    "Loharu",
    "Mahendragarh",
    "Meham",
    "Mewat",
    "Mohindergarh",
    "Naraingarh",
    "Narnaul",
    "Narwana",
    "Nilokheri",
    "Nuh",
    "Palwal",
    "Panchkula",
    "Panipat",
    "Pehowa",
    "Ratia",
    "Rewari",
    "Rohtak",
    "Safidon",
    "Sirsa",
    "Siwani",
    "Sonipat",
    "Tohana",
    "Tohsam",
    "Yamunanagar",
  ],
  [
    "Amb",
    "Arki",
    "Banjar",
    "Bharmour",
    "Bilaspur",
    "Chamba",
    "Churah",
    "Dalhousie",
    "Dehra Gopipur",
    "Hamirpur",
    "Jogindernagar",
    "Kalpa",
    "Kangra",
    "Kinnaur",
    "Kullu",
    "Lahaul",
    "Mandi",
    "Nahan",
    "Nalagarh",
    "Nirmand",
    "Nurpur",
    "Palampur",
    "Pangi",
    "Paonta",
    "Pooh",
    "Rajgarh",
    "Rampur Bushahar",
    "Rohru",
    "Shimla",
    "Sirmaur",
    "Solan",
    "Spiti",
    "Sundernagar",
    "Theog",
    "Udaipur",
    "Una",
  ],
  [
    "Akhnoor",
    "Anantnag",
    "Badgam",
    "Bandipur",
    "Baramulla",
    "Basholi",
    "Bedarwah",
    "Budgam",
    "Doda",
    "Gulmarg",
    "Jammu",
    "Kalakot",
    "Kargil",
    "Karnah",
    "Kathua",
    "Kishtwar",
    "Kulgam",
    "Kupwara",
    "Leh",
    "Mahore",
    "Nagrota",
    "Nobra",
    "Nowshera",
    "Nyoma",
    "Padam",
    "Pahalgam",
    "Patnitop",
    "Poonch",
    "Pulwama",
    "Rajouri",
    "Ramban",
    "Ramnagar",
    "Reasi",
    "Samba",
    "Srinagar",
    "Udhampur",
    "Vaishno Devi",
  ],
  [
    "Bagodar",
    "Baharagora",
    "Balumath",
    "Barhi",
    "Barkagaon",
    "Barwadih",
    "Basia",
    "Bermo",
    "Bhandaria",
    "Bhawanathpur",
    "Bishrampur",
    "Bokaro",
    "Bolwa",
    "Bundu",
    "Chaibasa",
    "Chainpur",
    "Chakardharpur",
    "Chandil",
    "Chatra",
    "Chavparan",
    "Daltonganj",
    "Deoghar",
    "Dhanbad",
    "Dumka",
    "Dumri",
    "Garhwa",
    "Garu",
    "Ghaghra",
    "Ghatsila",
    "Giridih",
    "Godda",
    "Gomia",
    "Govindpur",
    "Gumla",
    "Hazaribagh",
    "Hunterganj",
    "Ichak",
    "Itki",
    "Jagarnathpur",
    "Jamshedpur",
    "Jamtara",
    "Japla",
    "Jharmundi",
    "Jhinkpani",
    "Jhumaritalaiya",
    "Kathikund",
    "Kharsawa",
    "Khunti",
    "Koderma",
    "Kolebira",
    "Latehar",
    "Lohardaga",
    "Madhupur",
    "Mahagama",
    "Maheshpur Raj",
    "Mandar",
    "Mandu",
    "Manoharpur",
    "Muri",
    "Nagarutatri",
    "Nala",
    "Noamundi",
    "Pakur",
    "Palamu",
    "Palkot",
    "Patan",
    "Rajdhanwar",
    "Rajmahal",
    "Ramgarh",
    "Ranchi",
    "Sahibganj",
    "Saraikela",
    "Simaria",
    "Simdega",
    "Singhbhum",
    "Tisri",
    "Torpa",
  ],
  [
    "Afzalpur",
    "Ainapur",
    "Aland",
    "Alur",
    "Anekal",
    "Ankola",
    "Arsikere",
    "Athani",
    "Aurad",
    "Bableshwar",
    "Badami",
    "Bagalkot",
    "Bagepalli",
    "Bailhongal",
    "Bangalore",
    "Bangalore Rural",
    "Bangarpet",
    "Bantwal",
    "Basavakalyan",
    "Basavanabagewadi",
    "Basavapatna",
    "Belgaum",
    "Bellary",
    "Belthangady",
    "Belur",
    "Bhadravati",
    "Bhalki",
    "Bhatkal",
    "Bidar",
    "Bijapur",
    "Biligi",
    "Chadchan",
    "Challakere",
    "Chamrajnagar",
    "Channagiri",
    "Channapatna",
    "Channarayapatna",
    "Chickmagalur",
    "Chikballapur",
    "Chikkaballapur",
    "Chikkanayakanahalli",
    "Chikkodi",
    "Chikmagalur",
    "Chincholi",
    "Chintamani",
    "Chitradurga",
    "Chittapur",
    "Cowdahalli",
    "Davanagere",
    "Deodurga",
    "Devangere",
    "Devarahippargi",
    "Dharwad",
    "Doddaballapur",
    "Gadag",
    "Gangavathi",
    "Gokak",
    "Gowribdanpur",
    "Gubbi",
    "Gulbarga",
    "Gundlupet",
    "H.B.Halli",
    "H.D. Kote",
    "Haliyal",
    "Hampi",
    "Hangal",
    "Harapanahalli",
    "Hassan",
    "Haveri",
    "Hebri",
    "Hirekerur",
    "Hiriyur",
    "Holalkere",
    "Holenarsipur",
    "Honnali",
    "Honnavar",
    "Hosadurga",
    "Hosakote",
    "Hosanagara",
    "Hospet",
    "Hubli",
    "Hukkeri",
    "Humnabad",
    "Hungund",
    "Hunsagi",
    "Hunsur",
    "Huvinahadagali",
    "Indi",
    "Jagalur",
    "Jamkhandi",
    "Jewargi",
    "Joida",
    "K.R. Nagar",
    "Kadur",
    "Kalghatagi",
    "Kamalapur",
    "Kanakapura",
    "Kannada",
    "Kargal",
    "Karkala",
    "Karwar",
    "Khanapur",
    "Kodagu",
    "Kolar",
    "Kollegal",
    "Koppa",
    "Koppal",
    "Koratageri",
    "Krishnarajapet",
    "Kudligi",
    "Kumta",
    "Kundapur",
    "Kundgol",
    "Kunigal",
    "Kurugodu",
    "Kustagi",
    "Lingsugur",
    "Madikeri",
    "Madugiri",
    "Malavalli",
    "Malur",
    "Mandya",
    "Mangalore",
    "Manipal",
    "Manvi",
    "Mashal",
    "Molkalmuru",
    "Mudalgi",
    "Muddebihal",
    "Mudhol",
    "Mudigere",
    "Mulbagal",
    "Mundagod",
    "Mundargi",
    "Murugod",
    "Mysore",
    "Nagamangala",
    "Nanjangud",
    "Nargund",
    "Narsimrajapur",
    "Navalgund",
    "Nelamangala",
    "Nimburga",
    "Pandavapura",
    "Pavagada",
    "Puttur",
    "Raibag",
    "Raichur",
    "Ramdurg",
    "Ranebennur",
    "Ron",
    "Sagar",
    "Sakleshpur",
    "Salkani",
    "Sandur",
    "Saundatti",
    "Savanur",
    "Sedam",
    "Shahapur",
    "Shankarnarayana",
    "Shikaripura",
    "Shimoga",
    "Shirahatti",
    "Shorapur",
    "Siddapur",
    "Sidlaghatta",
    "Sindagi",
    "Sindhanur",
    "Sira",
    "Sirsi",
    "Siruguppa",
    "Somwarpet",
    "Sorab",
    "Sringeri",
    "Sriniwaspur",
    "Srirangapatna",
    "Sullia",
    "T. Narsipur",
    "Tallak",
    "Tarikere",
    "Telgi",
    "Thirthahalli",
    "Tiptur",
    "Tumkur",
    "Turuvekere",
    "Udupi",
    "Virajpet",
    "Wadi",
    "Yadgiri",
    "Yelburga",
    "Yellapur",
  ],
  [
    "Adimaly",
    "Adoor",
    "Agathy",
    "Alappuzha",
    "Alathur",
    "Alleppey",
    "Alwaye",
    "Amini",
    "Androth",
    "Attingal",
    "Badagara",
    "Bitra",
    "Calicut",
    "Cannanore",
    "Chetlet",
    "Ernakulam",
    "Idukki",
    "Irinjalakuda",
    "Kadamath",
    "Kalpeni",
    "Kalpetta",
    "Kanhangad",
    "Kanjirapally",
    "Kannur",
    "Karungapally",
    "Kasargode",
    "Kavarathy",
    "Kiltan",
    "Kochi",
    "Koduvayur",
    "Kollam",
    "Kottayam",
    "Kovalam",
    "Kozhikode",
    "Kunnamkulam",
    "Malappuram",
    "Mananthodi",
    "Manjeri",
    "Mannarghat",
    "Mavelikkara",
    "Minicoy",
    "Munnar",
    "Muvattupuzha",
    "Nedumandad",
    "Nedumgandam",
    "Nilambur",
    "Palai",
    "Palakkad",
    "Palghat",
    "Pathaanamthitta",
    "Pathanamthitta",
    "Payyanur",
    "Peermedu",
    "Perinthalmanna",
    "Perumbavoor",
    "Punalur",
    "Quilon",
    "Ranni",
    "Shertallai",
    "Shoranur",
    "Taliparamba",
    "Tellicherry",
    "Thiruvananthapuram",
    "Thodupuzha",
    "Thrissur",
    "Tirur",
    "Tiruvalla",
    "Trichur",
    "Trivandrum",
    "Uppala",
    "Vadakkanchery",
    "Vikom",
    "Wayanad",
  ],
  [
    "Agatti Island",
    "Bingaram Island",
    "Bitra Island",
    "Chetlat Island",
    "Kadmat Island",
    "Kalpeni Island",
    "Kavaratti Island",
    "Kiltan Island",
    "Lakshadweep Sea",
    "Minicoy Island",
    "North Island",
    "South Island",
  ],
  [
    "Agar",
    "Ajaigarh",
    "Alirajpur",
    "Amarpatan",
    "Amarwada",
    "Ambah",
    "Anuppur",
    "Arone",
    "Ashoknagar",
    "Ashta",
    "Atner",
    "Babaichichli",
    "Badamalhera",
    "Badarwsas",
    "Badnagar",
    "Badnawar",
    "Badwani",
    "Bagli",
    "Baihar",
    "Balaghat",
    "Baldeogarh",
    "Baldi",
    "Bamori",
    "Banda",
    "Bandhavgarh",
    "Bareli",
    "Baroda",
    "Barwaha",
    "Barwani",
    "Batkakhapa",
    "Begamganj",
    "Beohari",
    "Berasia",
    "Berchha",
    "Betul",
    "Bhainsdehi",
    "Bhander",
    "Bhanpura",
    "Bhikangaon",
    "Bhimpur",
    "Bhind",
    "Bhitarwar",
    "Bhopal",
    "Biaora",
    "Bijadandi",
    "Bijawar",
    "Bijaypur",
    "Bina",
    "Birsa",
    "Birsinghpur",
    "Budhni",
    "Burhanpur",
    "Buxwaha",
    "Chachaura",
    "Chanderi",
    "Chaurai",
    "Chhapara",
    "Chhatarpur",
    "Chhindwara",
    "Chicholi",
    "Chitrangi",
    "Churhat",
    "Dabra",
    "Damoh",
    "Datia",
    "Deori",
    "Deosar",
    "Depalpur",
    "Dewas",
    "Dhar",
    "Dharampuri",
    "Dindori",
    "Gadarwara",
    "Gairatganj",
    "Ganjbasoda",
    "Garoth",
    "Ghansour",
    "Ghatia",
    "Ghatigaon",
    "Ghorandogri",
    "Ghughari",
    "Gogaon",
    "Gohad",
    "Goharganj",
    "Gopalganj",
    "Gotegaon",
    "Gourihar",
    "Guna",
    "Gunnore",
    "Gwalior",
    "Gyraspur",
    "Hanumana",
    "Harda",
    "Harrai",
    "Harsud",
    "Hatta",
    "Hoshangabad",
    "Ichhawar",
    "Indore",
    "Isagarh",
    "Itarsi",
    "Jabalpur",
    "Jabera",
    "Jagdalpur",
    "Jaisinghnagar",
    "Jaithari",
    "Jaitpur",
    "Jaitwara",
    "Jamai",
    "Jaora",
    "Jatara",
    "Jawad",
    "Jhabua",
    "Jobat",
    "Jora",
    "Kakaiya",
    "Kannod",
    "Kannodi",
    "Karanjia",
    "Kareli",
    "Karera",
    "Karhal",
    "Karpa",
    "Kasrawad",
    "Katangi",
    "Katni",
    "Keolari",
    "Khachrod",
    "Khajuraho",
    "Khakner",
    "Khalwa",
    "Khandwa",
    "Khaniadhana",
    "Khargone",
    "Khategaon",
    "Khetia",
    "Khilchipur",
    "Khirkiya",
    "Khurai",
    "Kolaras",
    "Kotma",
    "Kukshi",
    "Kundam",
    "Kurwai",
    "Kusmi",
    "Laher",
    "Lakhnadon",
    "Lamta",
    "Lanji",
    "Lateri",
    "Laundi",
    "Maheshwar",
    "Mahidpurcity",
    "Maihar",
    "Majhagwan",
    "Majholi",
    "Malhargarh",
    "Manasa",
    "Manawar",
    "Mandla",
    "Mandsaur",
    "Manpur",
    "Mauganj",
    "Mawai",
    "Mehgaon",
    "Mhow",
    "Morena",
    "Multai",
    "Mungaoli",
    "Nagod",
    "Nainpur",
    "Narsingarh",
    "Narsinghpur",
    "Narwar",
    "Nasrullaganj",
    "Nateran",
    "Neemuch",
    "Niwari",
    "Niwas",
    "Nowgaon",
    "Pachmarhi",
    "Pandhana",
    "Pandhurna",
    "Panna",
    "Parasia",
    "Patan",
    "Patera",
    "Patharia",
    "Pawai",
    "Petlawad",
    "Pichhore",
    "Piparia",
    "Pohari",
    "Prabhapattan",
    "Punasa",
    "Pushprajgarh",
    "Raghogarh",
    "Raghunathpur",
    "Rahatgarh",
    "Raisen",
    "Rajgarh",
    "Rajpur",
    "Ratlam",
    "Rehli",
    "Rewa",
    "Sabalgarh",
    "Sagar",
    "Sailana",
    "Sanwer",
    "Sarangpur",
    "Sardarpur",
    "Satna",
    "Saunsar",
    "Sehore",
    "Sendhwa",
    "Seondha",
    "Seoni",
    "Seonimalwa",
    "Shahdol",
    "Shahnagar",
    "Shahpur",
    "Shajapur",
    "Sheopur",
    "Sheopurkalan",
    "Shivpuri",
    "Shujalpur",
    "Sidhi",
    "Sihora",
    "Silwani",
    "Singrauli",
    "Sirmour",
    "Sironj",
    "Sitamau",
    "Sohagpur",
    "Sondhwa",
    "Sonkatch",
    "Susner",
    "Tamia",
    "Tarana",
    "Tendukheda",
    "Teonthar",
    "Thandla",
    "Tikamgarh",
    "Timarani",
    "Udaipura",
    "Ujjain",
    "Umaria",
    "Umariapan",
    "Vidisha",
    "Vijayraghogarh",
    "Waraseoni",
    "Zhirnia",
  ],
  [
    "Ahmednagar",
    "Akola",
    "Amravati",
    "Aurangabad",
    "Beed",
    "Bhandara",
    "Buldhana",
    "Chandrapur",
    "Dhule",
    "Gadchiroli",
    "Gondia",
    "Hingoli",
    "Jalgaon",
    "Jalna",
    "Kolhapur",
    "Latur",
    "Mumbai City",
    "Mumbai Suburban",
    "Nagpur",
    "Nanded",
    "Nandurbar",
    "Nashik",
    "Osmanabad",
    "Palghar",
    "Parbhani",
    "Pune",
    "Raigad",
    "Ratnagiri",
    "Sangli",
    "Satara",
    "Sindhudurg",
    "Solapur",
    "Thane",
    "Wardha",
    "Washim",
    "Yavatmal",
  ],
  [
    "Bishnupur",
    "Chakpikarong",
    "Chandel",
    "Chattrik",
    "Churachandpur",
    "Imphal",
    "Jiribam",
    "Kakching",
    "Kalapahar",
    "Mao",
    "Mulam",
    "Parbung",
    "Sadarhills",
    "Saibom",
    "Sempang",
    "Senapati",
    "Sochumer",
    "Taloulong",
    "Tamenglong",
    "Thinghat",
    "Thoubal",
    "Ukhrul",
  ],
  [
    "Amlaren",
    "Baghmara",
    "Cherrapunjee",
    "Dadengiri",
    "Garo Hills",
    "Jaintia Hills",
    "Jowai",
    "Khasi Hills",
    "Khliehriat",
    "Mariang",
    "Mawkyrwat",
    "Nongpoh",
    "Nongstoin",
    "Resubelpara",
    "Ri Bhoi",
    "Shillong",
    "Tura",
    "Williamnagar",
  ],
  [
    "Aizawl",
    "Champhai",
    "Demagiri",
    "Kolasib",
    "Lawngtlai",
    "Lunglei",
    "Mamit",
    "Saiha",
    "Serchhip",
  ],
  [
    "Dimapur",
    "Jalukie",
    "Kiphire",
    "Kohima",
    "Mokokchung",
    "Mon",
    "Phek",
    "Tuensang",
    "Wokha",
    "Zunheboto",
  ],
  [
    "Anandapur",
    "Angul",
    "Anugul",
    "Aska",
    "Athgarh",
    "Athmallik",
    "Attabira",
    "Bagdihi",
    "Balangir",
    "Balasore",
    "Baleswar",
    "Baliguda",
    "Balugaon",
    "Banaigarh",
    "Bangiriposi",
    "Barbil",
    "Bargarh",
    "Baripada",
    "Barkot",
    "Basta",
    "Berhampur",
    "Betanati",
    "Bhadrak",
    "Bhanjanagar",
    "Bhawanipatna",
    "Bhubaneswar",
    "Birmaharajpur",
    "Bisam Cuttack",
    "Boriguma",
    "Boudh",
    "Buguda",
    "Chandbali",
    "Chhatrapur",
    "Chhendipada",
    "Cuttack",
    "Daringbadi",
    "Daspalla",
    "Deodgarh",
    "Deogarh",
    "Dhanmandal",
    "Dharamgarh",
    "Dhenkanal",
    "Digapahandi",
    "Dunguripali",
    "G. Udayagiri",
    "Gajapati",
    "Ganjam",
    "Ghatgaon",
    "Gudari",
    "Gunupur",
    "Hemgiri",
    "Hindol",
    "Jagatsinghapur",
    "Jajpur",
    "Jamankira",
    "Jashipur",
    "Jayapatna",
    "Jeypur",
    "Jharigan",
    "Jharsuguda",
    "Jujumura",
    "Kalahandi",
    "Kalimela",
    "Kamakhyanagar",
    "Kandhamal",
    "Kantabhanji",
    "Kantamal",
    "Karanjia",
    "Kashipur",
    "Kendrapara",
    "Kendujhar",
    "Keonjhar",
    "Khalikote",
    "Khordha",
    "Khurda",
    "Komana",
    "Koraput",
    "Kotagarh",
    "Kuchinda",
    "Lahunipara",
    "Laxmipur",
    "M. Rampur",
    "Malkangiri",
    "Mathili",
    "Mayurbhanj",
    "Mohana",
    "Motu",
    "Nabarangapur",
    "Naktideul",
    "Nandapur",
    "Narlaroad",
    "Narsinghpur",
    "Nayagarh",
    "Nimapara",
    "Nowparatan",
    "Nowrangapur",
    "Nuapada",
    "Padampur",
    "Paikamal",
    "Palla Hara",
    "Papadhandi",
    "Parajang",
    "Pardip",
    "Parlakhemundi",
    "Patnagarh",
    "Pattamundai",
    "Phiringia",
    "Phulbani",
    "Puri",
    "Puruna Katak",
    "R. Udayigiri",
    "Rairakhol",
    "Rairangpur",
    "Rajgangpur",
    "Rajkhariar",
    "Rayagada",
    "Rourkela",
    "Sambalpur",
    "Sohela",
    "Sonapur",
    "Soro",
    "Subarnapur",
    "Sunabeda",
    "Sundergarh",
    "Surada",
    "T. Rampur",
    "Talcher",
    "Telkoi",
    "Titlagarh",
    "Tumudibandha",
    "Udala",
    "Umerkote",
  ],
  [
    "Bahur",
    "Karaikal",
    "Mahe",
    "Pondicherry",
    "Purnankuppam",
    "Valudavur",
    "Villianur",
    "Yanam",
  ],
  [
    "Abohar",
    "Ajnala",
    "Amritsar",
    "Balachaur",
    "Barnala",
    "Batala",
    "Bathinda",
    "Chandigarh",
    "Dasua",
    "Dinanagar",
    "Faridkot",
    "Fatehgarh Sahib",
    "Fazilka",
    "Ferozepur",
    "Garhashanker",
    "Goindwal",
    "Gurdaspur",
    "Guruharsahai",
    "Hoshiarpur",
    "Jagraon",
    "Jalandhar",
    "Jugial",
    "Kapurthala",
    "Kharar",
    "Kotkapura",
    "Ludhiana",
    "Malaut",
    "Malerkotla",
    "Mansa",
    "Moga",
    "Muktasar",
    "Nabha",
    "Nakodar",
    "Nangal",
    "Nawanshahar",
    "Nawanshahr",
    "Pathankot",
    "Patiala",
    "Patti",
    "Phagwara",
    "Phillaur",
    "Phulmandi",
    "Quadian",
    "Rajpura",
    "Raman",
    "Rayya",
    "Ropar",
    "Rupnagar",
    "Samana",
    "Samrala",
    "Sangrur",
    "Sardulgarh",
    "Sarhind",
    "SAS Nagar",
    "Sultanpur Lodhi",
    "Sunam",
    "Tanda Urmar",
    "Tarn Taran",
    "Zira",
  ],
  [
    "Abu Road",
    "Ahore",
    "Ajmer",
    "Aklera",
    "Alwar",
    "Amber",
    "Amet",
    "Anupgarh",
    "Asind",
    "Aspur",
    "Atru",
    "Bagidora",
    "Bali",
    "Bamanwas",
    "Banera",
    "Bansur",
    "Banswara",
    "Baran",
    "Bari",
    "Barisadri",
    "Barmer",
    "Baseri",
    "Bassi",
    "Baswa",
    "Bayana",
    "Beawar",
    "Begun",
    "Behror",
    "Bhadra",
    "Bharatpur",
    "Bhilwara",
    "Bhim",
    "Bhinmal",
    "Bikaner",
    "Bilara",
    "Bundi",
    "Chhabra",
    "Chhipaborad",
    "Chirawa",
    "Chittorgarh",
    "Chohtan",
    "Churu",
    "Dantaramgarh",
    "Dausa",
    "Deedwana",
    "Deeg",
    "Degana",
    "Deogarh",
    "Deoli",
    "Desuri",
    "Dhariawad",
    "Dholpur",
    "Digod",
    "Dudu",
    "Dungarpur",
    "Dungla",
    "Fatehpur",
    "Gangapur",
    "Gangdhar",
    "Gerhi",
    "Ghatol",
    "Girwa",
    "Gogunda",
    "Hanumangarh",
    "Hindaun",
    "Hindoli",
    "Hurda",
    "Jahazpur",
    "Jaipur",
    "Jaisalmer",
    "Jalore",
    "Jhalawar",
    "Jhunjhunu",
    "Jodhpur",
    "Kaman",
    "Kapasan",
    "Karauli",
    "Kekri",
    "Keshoraipatan",
    "Khandar",
    "Kherwara",
    "Khetri",
    "Kishanganj",
    "Kishangarh",
    "Kishangarhbas",
    "Kolayat",
    "Kota",
    "Kotputli",
    "Kotra",
    "Kotri",
    "Kumbalgarh",
    "Kushalgarh",
    "Ladnun",
    "Ladpura",
    "Lalsot",
    "Laxmangarh",
    "Lunkaransar",
    "Mahuwa",
    "Malpura",
    "Malvi",
    "Mandal",
    "Mandalgarh",
    "Mandawar",
    "Mangrol",
    "Marwar-Jn",
    "Merta",
    "Nadbai",
    "Nagaur",
    "Nainwa",
    "Nasirabad",
    "Nathdwara",
    "Nawa",
    "Neem Ka Thana",
    "Newai",
    "Nimbahera",
    "Nohar",
    "Nokha",
    "Onli",
    "Osian",
    "Pachpadara",
    "Pachpahar",
    "Padampur",
    "Pali",
    "Parbatsar",
    "Phagi",
    "Phalodi",
    "Pilani",
    "Pindwara",
    "Pipalda",
    "Pirawa",
    "Pokaran",
    "Pratapgarh",
    "Raipur",
    "Raisinghnagar",
    "Rajgarh",
    "Rajsamand",
    "Ramganj Mandi",
    "Ramgarh",
    "Rashmi",
    "Ratangarh",
    "Reodar",
    "Rupbas",
    "Sadulshahar",
    "Sagwara",
    "Sahabad",
    "Salumber",
    "Sanchore",
    "Sangaria",
    "Sangod",
    "Sapotra",
    "Sarada",
    "Sardarshahar",
    "Sarwar",
    "Sawai Madhopur",
    "Shahapura",
    "Sheo",
    "Sheoganj",
    "Shergarh",
    "Sikar",
    "Sirohi",
    "Siwana",
    "Sojat",
    "Sri Dungargarh",
    "Sri Ganganagar",
    "Sri Karanpur",
    "Sri Madhopur",
    "Sujangarh",
    "Taranagar",
    "Thanaghazi",
    "Tibbi",
    "Tijara",
    "Todaraisingh",
    "Tonk",
    "Udaipur",
    "Udaipurwati",
    "Uniayara",
    "Vallabhnagar",
    "Viratnagar",
  ],
  [
    "Barmiak",
    "Be",
    "Bhurtuk",
    "Chhubakha",
    "Chidam",
    "Chubha",
    "Chumikteng",
    "Dentam",
    "Dikchu",
    "Dzongri",
    "Gangtok",
    "Gauzing",
    "Gyalshing",
    "Hema",
    "Kerung",
    "Lachen",
    "Lachung",
    "Lema",
    "Lingtam",
    "Lungthu",
    "Mangan",
    "Namchi",
    "Namthang",
    "Nanga",
    "Nantang",
    "Naya Bazar",
    "Padamachen",
    "Pakhyong",
    "Pemayangtse",
    "Phensang",
    "Rangli",
    "Rinchingpong",
    "Sakyong",
    "Samdong",
    "Singtam",
    "Siniolchu",
    "Sombari",
    "Soreng",
    "Sosing",
    "Tekhug",
    "Temi",
    "Tsetang",
    "Tsomgo",
    "Tumlong",
    "Yangang",
    "Yumtang",
  ],
  [
    "Ambasamudram",
    "Anamali",
    "Arakandanallur",
    "Arantangi",
    "Aravakurichi",
    "Ariyalur",
    "Arkonam",
    "Arni",
    "Aruppukottai",
    "Attur",
    "Avanashi",
    "Batlagundu",
    "Bhavani",
    "Chengalpattu",
    "Chengam",
    "Chennai",
    "Chidambaram",
    "Chingleput",
    "Coimbatore",
    "Courtallam",
    "Cuddalore",
    "Cumbum",
    "Denkanikoitah",
    "Devakottai",
    "Dharampuram",
    "Dharmapuri",
    "Dindigul",
    "Erode",
    "Gingee",
    "Gobichettipalayam",
    "Gudalur",
    "Gudiyatham",
    "Harur",
    "Hosur",
    "Jayamkondan",
    "Kallkurichi",
    "Kanchipuram",
    "Kangayam",
    "Kanyakumari",
    "Karaikal",
    "Karaikudi",
    "Karur",
    "Keeranur",
    "Kodaikanal",
    "Kodumudi",
    "Kotagiri",
    "Kovilpatti",
    "Krishnagiri",
    "Kulithalai",
    "Kumbakonam",
    "Kuzhithurai",
    "Madurai",
    "Madurantgam",
    "Manamadurai",
    "Manaparai",
    "Mannargudi",
    "Mayiladuthurai",
    "Mayiladutjurai",
    "Mettupalayam",
    "Metturdam",
    "Mudukulathur",
    "Mulanur",
    "Musiri",
    "Nagapattinam",
    "Nagarcoil",
    "Namakkal",
    "Nanguneri",
    "Natham",
    "Neyveli",
    "Nilgiris",
    "Oddanchatram",
    "Omalpur",
    "Ootacamund",
    "Ooty",
    "Orathanad",
    "Palacode",
    "Palani",
    "Palladum",
    "Papanasam",
    "Paramakudi",
    "Pattukottai",
    "Perambalur",
    "Perundurai",
    "Pollachi",
    "Polur",
    "Pondicherry",
    "Ponnamaravathi",
    "Ponneri",
    "Pudukkottai",
    "Rajapalayam",
    "Ramanathapuram",
    "Rameshwaram",
    "Ranipet",
    "Rasipuram",
    "Salem",
    "Sankagiri",
    "Sankaran",
    "Sathiyamangalam",
    "Sivaganga",
    "Sivakasi",
    "Sriperumpudur",
    "Srivaikundam",
    "Tenkasi",
    "Thanjavur",
    "Theni",
    "Thirumanglam",
    "Thiruraipoondi",
    "Thoothukudi",
    "Thuraiyure",
    "Tindivanam",
    "Tiruchendur",
    "Tiruchengode",
    "Tiruchirappalli",
    "Tirunelvelli",
    "Tirupathur",
    "Tirupur",
    "Tiruttani",
    "Tiruvallur",
    "Tiruvannamalai",
    "Tiruvarur",
    "Tiruvellore",
    "Tiruvettipuram",
    "Trichy",
    "Tuticorin",
    "Udumalpet",
    "Ulundurpet",
    "Usiliampatti",
    "Uthangarai",
    "Valapady",
    "Valliyoor",
    "Vaniyambadi",
    "Vedasandur",
    "Vellore",
    "Velur",
    "Vilathikulam",
    "Villupuram",
    "Virudhachalam",
    "Virudhunagar",
    "Wandiwash",
    "Yercaud",
  ],
  [
    "Agartala",
    "Ambasa",
    "Bampurbari",
    "Belonia",
    "Dhalai",
    "Dharam Nagar",
    "Kailashahar",
    "Kamal Krishnabari",
    "Khopaiyapara",
    "Khowai",
    "Phuldungsei",
    "Radha Kishore Pur",
    "Tripura",
  ],
  [
    "Agra",
    "Aligarh",
    "Allahabad",
    "Ambedkar Nagar",
    "Amethi (Chatrapati Sahuji Mahraj Nagar)",
    "Amroha (J.P. Nagar)",
    "Auraiya",
    "Azamgarh",
    "Baghpat",
    "Bahraich",
    "Ballia",
    "Balrampur",
    "Banda",
    "Barabanki",
    "Bareilly",
    "Basti",
    "Bhadohi",
    "Bijnor",
    "Budaun",
    "Bulandshahr",
    "Chandauli",
    "Chitrakoot",
    "Deoria",
    "Etah",
    "Etawah",
    "Faizabad",
    "Farrukhabad",
    "Fatehpur",
    "Firozabad",
    "Gautam Buddha Nagar",
    "Ghaziabad",
    "Ghazipur",
    "Gonda",
    "Gorakhpur",
    "Hamirpur",
    "Hapur (Panchsheel Nagar)",
    "Hardoi",
    "Hathras",
    "Jalaun",
    "Jaunpur",
    "Jhansi",
    "Kannauj",
    "Kanpur Dehat",
    "Kanpur Nagar",
    "Kanshiram Nagar (Kasganj)",
    "Kaushambi",
    "Kushinagar (Padrauna)",
    "Lakhimpur - Kheri",
    "Lalitpur",
    "Lucknow",
    "Maharajganj",
    "Mahoba",
    "Mainpuri",
    "Mathura",
    "Mau",
    "Meerut",
    "Mirzapur",
    "Moradabad",
    "Muzaffarnagar",
    "Pilibhit",
    "Pratapgarh",
    "RaeBareli",
    "Rampur",
    "Saharanpur",
    "Sambhal (Bhim Nagar)",
    "Sant Kabir Nagar",
    "Shahjahanpur",
    "Shamali (Prabuddh Nagar)",
    "Shravasti",
    "Siddharth Nagar",
    "Sitapur",
    "Sonbhadra",
    "Sultanpur",
    "Unnao",
    "Varanasi",
  ],
  [
    "Almora",
    "Bageshwar",
    "Bhatwari",
    "Chakrata",
    "Chamoli",
    "Champawat",
    "Dehradun",
    "Deoprayag",
    "Dharchula",
    "Dunda",
    "Haldwani",
    "Haridwar",
    "Joshimath",
    "Karan Prayag",
    "Kashipur",
    "Khatima",
    "Kichha",
    "Lansdown",
    "Munsiari",
    "Mussoorie",
    "Nainital",
    "Pantnagar",
    "Partapnagar",
    "Pauri Garhwal",
    "Pithoragarh",
    "Purola",
    "Rajgarh",
    "Ranikhet",
    "Roorkee",
    "Rudraprayag",
    "Tehri Garhwal",
    "Udham Singh Nagar",
    "Ukhimath",
    "Uttarkashi",
  ],
  [
    "Adra",
    "Alipurduar",
    "Amlagora",
    "Arambagh",
    "Asansol",
    "Balurghat",
    "Bankura",
    "Bardhaman",
    "Basirhat",
    "Berhampur",
    "Bethuadahari",
    "Birbhum",
    "Birpara",
    "Bishanpur",
    "Bolpur",
    "Bongoan",
    "Bulbulchandi",
    "Burdwan",
    "Calcutta",
    "Canning",
    "Champadanga",
    "Contai",
    "Cooch Behar",
    "Daimond Harbour",
    "Dalkhola",
    "Dantan",
    "Darjeeling",
    "Dhaniakhali",
    "Dhuliyan",
    "Dinajpur",
    "Dinhata",
    "Durgapur",
    "Gangajalghati",
    "Gangarampur",
    "Ghatal",
    "Guskara",
    "Habra",
    "Haldia",
    "Harirampur",
    "Harishchandrapur",
    "Hooghly",
    "Howrah",
    "Islampur",
    "Jagatballavpur",
    "Jalpaiguri",
    "Jhalda",
    "Jhargram",
    "Kakdwip",
    "Kalchini",
    "Kalimpong",
    "Kalna",
    "Kandi",
    "Karimpur",
    "Katwa",
    "Kharagpur",
    "Khatra",
    "Krishnanagar",
    "Mal Bazar",
    "Malda",
    "Manbazar",
    "Mathabhanga",
    "Medinipur",
    "Mekhliganj",
    "Mirzapur",
    "Murshidabad",
    "Nadia",
    "Nagarakata",
    "Nalhati",
    "Nayagarh",
    "Parganas",
    "Purulia",
    "Raiganj",
    "Rampur Hat",
    "Ranaghat",
    "Seharabazar",
    "Siliguri",
    "Suri",
    "Takipur",
    "Tamluk",
  ],
];

export const fullMonthName = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const Hour = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
];

export const Months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const Weeks = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
