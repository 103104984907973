import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { Divider, Grid, MenuItem, Stack, Typography } from "@mui/material";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";

const LocationName = ({
  item,
  formik,
  openedCard,
  setOpenedCard,
  showShape = false,
  labelName = "Label Name",
  showColor = true,
  showFontWeight = true,
  showFontStyle = true,
  disabledLabel = false,
}) => {
  const [color, setColor] = useState({
    ColorPickerOpen: false,
    color: "#fff",
  });
  const handleClick = () => {
    setColor({ ...color, ColorPickerOpen: !color.ColorPickerOpen });
  };

  const handleCloseColorPicker = () => {
    setColor({ ...color, ColorPickerOpen: false });
  };

  const handleColorChange = (color) => {
    console.log(color, "in the handle color change");
    setColor({ ...color, color: color.hex });
    formik?.setValues((prev) => ({
      ...prev,
      fontColor: color?.hex,
    }));
  };

  const handleDropDown = () => {
    if (openedCard === item) {
      setOpenedCard(null);
    } else {
      setOpenedCard(item);
    }
  };

  const showDropDown = openedCard === item;

  return (
    <>
      <Stack
        sx={{
          border: 1,
          borderRadius: 2,
          borderColor: "primary.rnPrimary",
          boxShadow: "0px 4px 10px rgba(61, 57, 137, 0.06)",
          justifyContent: "space-between",
          backgroundColor: "#00CC8C10",
          padding: 0.5,
          width: "100%",
        }}
      >
        <Stack
          direction={"row"}
          onClick={handleDropDown}
          sx={{
            justifyContent: "space-between",
            cursor: "pointer",
          }}
        >
          <Typography
            sx={{
              color: "primary.main",
              cursor: "pointer",
              fontSize: "20px",
              margin: "1px",
              fontWeight: "500",
            }}
          >
            {item}
          </Typography>
          {showDropDown ? (
            <KeyboardArrowDown
              sx={{
                fontSize: "30px",
              }}
            />
          ) : (
            <KeyboardArrowRight
              sx={{
                fontSize: "30px",
              }}
            />
          )}
        </Stack>
        {showDropDown && (
          <Divider
            sx={{
              width: "101%",
              alignSelf: "center",
              height: 1,
              backgroundColor: "primary.main",
            }}
          />
        )}
        {showDropDown && (
          <>
            <Grid container spacing={2} sx={{ padding: "10px 0" }}>
              <Grid item xs={3}>
                <CustomTextField
                  fullWidth
                  label="x_axis"
                  variant="outlined"
                  size="small"
                  name="x_axis"
                  type="number"
                  value={formik?.values?.x_axis}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={
                    formik?.touched.x_axis && Boolean(formik?.errors.x_axis)
                  }
                  helperText={formik?.touched.x_axis && formik?.errors.x_axis}
                />
              </Grid>
              <Grid item xs={3}>
                <CustomTextField
                  fullWidth
                  label="y_axis"
                  variant="outlined"
                  size="small"
                  name="y_axis"
                  type="number"
                  value={formik?.values?.y_axis}
                  onChange={formik?.handleChange("y_axis")}
                  onBlur={formik?.handleBlur}
                  error={
                    formik?.touched.y_axis && Boolean(formik?.errors.y_axis)
                  }
                  helperText={formik?.touched.y_axis && formik?.errors.y_axis}
                />
              </Grid>
              {showColor && (
                <Grid item xs={4} flexDirection={"row"}>
                  <Stack
                    onClick={handleClick}
                    sx={{
                      border: 1,
                      borderRadius: 2,
                      borderColor: "rgba(208, 208, 226, 1)",
                      boxShadow: "0px 4px 10px rgba(61, 57, 137, 0.06)",
                      height: 40,
                      width: 80,
                      backgroundColor: formik?.values?.fontColor ?? "#fff",
                    }}
                  />
                  {color?.ColorPickerOpen && (
                    <SketchPicker
                      color={formik?.values?.color}
                      onChange={handleColorChange}
                    />
                  )}
                </Grid>
              )}
              {showFontWeight && (
                <Grid item xs={6}>
                  <CustomTextField
                    fullWidth
                    label="fontWeight"
                    variant="outlined"
                    size="small"
                    name="fontWeight"
                    type="number"
                    value={formik?.values?.fontWeight}
                    onChange={formik?.handleChange("fontWeight")}
                    onBlur={formik?.handleBlur}
                    error={
                      formik?.touched?.fontWeight &&
                      Boolean(formik?.errors?.fontWeight)
                    }
                    helperText={
                      formik?.touched?.fontWeight && formik?.errors?.fontWeight
                    }
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <CustomTextField
                  fullWidth
                  label="Size"
                  variant="outlined"
                  size="small"
                  name="fontSize"
                  type="number"
                  value={formik?.values?.fontSize}
                  onChange={formik?.handleChange("fontSize")}
                  onBlur={formik?.handleBlur}
                  error={
                    formik?.touched?.fontSize &&
                    Boolean(formik?.errors?.fontSize)
                  }
                  helperText={
                    formik?.touched?.fontSize && formik?.errors?.fontSize
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {showFontStyle && (
                <Grid item xs={3}>
                  <Typography
                    sx={{
                      color: "text.secondary",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    fontStyle
                  </Typography>

                  <CustomSelectBox
                    name="fontStyle"
                    onBlur={formik?.handleBlur}
                    displayEmpty
                    fullWidth
                    value={formik?.values?.fontStyle}
                    onChange={formik?.handleChange}
                    error={
                      formik?.touched?.fontStyle && formik?.errors?.fontStyle
                    }
                  >
                    <MenuItem value="">Select</MenuItem>
                    {["normal", "italic", "oblique"]?.map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </CustomSelectBox>
                  {formik?.touched?.fontStyle && formik?.errors?.fontStyle && (
                    <Typography sx={{ color: "red" }}>
                      {formik?.errors?.fontStyle}
                    </Typography>
                  )}
                </Grid>
              )}
              <Grid item xs={3}>
                {showShape && (
                  <>
                    <Typography
                      sx={{
                        color: "text.secondary",
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                    >
                      Shape
                    </Typography>

                    <CustomSelectBox
                      name="shape"
                      onBlur={formik?.handleBlur}
                      displayEmpty
                      fullWidth
                      value={formik?.values?.shape}
                      onChange={formik?.handleChange}
                      error={formik?.touched?.shape && formik?.errors?.shape}
                    >
                      <MenuItem value="">Select</MenuItem>
                      {["square", "circle"]?.map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                    </CustomSelectBox>
                    {formik?.touched?.shape && formik?.errors?.shape && (
                      <Typography sx={{ color: "red" }}>
                        {formik?.errors?.shape}
                      </Typography>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ padding: "10px 0" }}>
              <Grid item xs={6}>
                <CustomTextField
                  fullWidth
                  disabled={disabledLabel}
                  label={labelName}
                  variant="outlined"
                  size="small"
                  name="labelName"
                  type="text"
                  value={formik?.values?.labelName}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={
                    formik?.touched.labelName &&
                    Boolean(formik?.errors.labelName)
                  }
                  helperText={
                    formik?.touched.labelName && formik?.errors.labelName
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <CustomTextField
                  fullWidth
                  disabled
                  label="labelKeyName"
                  variant="outlined"
                  size="small"
                  name="labelKeyName"
                  type="text"
                  value={formik?.values?.labelKeyName}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  error={
                    formik?.touched.labelKeyName &&
                    Boolean(formik?.errors.labelKeyName)
                  }
                  helperText={
                    formik?.touched.labelKeyName && formik?.errors.labelKeyName
                  }
                />
              </Grid>
            </Grid>
          </>
        )}
      </Stack>
    </>
  );
};

export default LocationName;
