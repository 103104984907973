import React, { useEffect } from "react";
import { MenuItem, Grid, DialogActions } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import styled from "styled-components";

import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { MenuProps } from "app/utils/helper";
import {
  CustomTextField,
  CustomSelectBox,
} from "assets/styles/globalStyledComponent";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import { LoadingButton } from "@mui/lab";
import { DesktopDatePicker } from "@mui/x-date-pickers";

import { updatePersonalInfo } from "app/services/auth/user.service";

const PersonalInfoModal = ({
  handleCloseModal,
  getUserDetail,
  staffResponse,
}) => {
  const validationSchema = yup.object({});
  const { mutate, isLoading: updatingProfileInfo } = useMutation(
    updatePersonalInfo,
    {
      onSuccess: ({ data }) => {
        toast.success(data?.message);
        getUserDetail();
        handleCloseModal();
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
      retry: false,
    }
  );
  const dt = new Date();
  const formik = useFormik({
    initialValues: {
      profilePic: "",
      fName: "",
      mName: "",
      lName: "",
      DOB: dt,
      gender: "",
      mobile: "",
      altMobile: "",
      maritalStatus: "",
      PAN: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const newPersonalInfo = {
        profilePic: values.profilePic ?? "",
        firstName: values.fName ?? "",
        middleName: values.mName ?? "",
        lastName: values.lName ?? "",
        gender: values.gender ?? "",
        DOB: values.DOB ?? dt,
        mobileNumber: values.mobile ?? "",
        alternateNumber: values.altMobile ?? "",
        PAN: values.PAN ?? "",
        maritalStatus: values.maritalStatus ?? "",
      };
      console.log(newPersonalInfo);
      mutate(newPersonalInfo);
    },
  });
  useEffect(() => {
    if (staffResponse?.data?.obj) {
      const staff = staffResponse?.data?.obj;
      formik.setValues((prev) => ({
        ...prev,
        profilePic: staff?.profilePic,
        fName: staff?.firstName,
        mName: staff?.middleName,
        lName: staff?.lastName,
        DOB: staff?.DOB,
        gender: staff?.gender,
        mobile: staff?.mobileNumber,
        altMobile: staff?.alternateNumber,
        maritalStatus: staff?.maritalStatus,
        PAN: staff?.PAN,
      }));
    }
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextFieldLabel title="First Name" />
            <CustomTextField
              id="fName"
              name="fName"
              size="small"
              placeholder="Type Firstname"
              fullWidth
              value={formik.values.fName}
              onChange={formik.handleChange}
              error={formik.touched.fName && Boolean(formik.errors.fName)}
              helperText={formik.touched.fName && formik.errors.fName}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldLabel title="Middle Name" />
            <CustomTextField
              id="mName"
              name="mName"
              size="small"
              fullWidth
              placeholder="Type Middlename"
              value={formik.values.mName}
              onChange={formik.handleChange}
              error={formik.touched.mName && Boolean(formik.errors.mName)}
              helperText={formik.touched.mName && formik.errors.mName}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldLabel title="Last Name" />
            <CustomTextField
              id="lName"
              name="lName"
              size="small"
              fullWidth
              placeholder="Type Lastname"
              value={formik.values.lName}
              onChange={formik.handleChange}
              error={formik.touched.lName && Boolean(formik.errors.lName)}
              helperText={formik.touched.lName && formik.errors.lName}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ pt: 1 }}>
          <Grid item xs={4}>
            <TextFieldLabel title="Date of Birth" />

            <DesktopDatePicker
              inputFormat="DD MMM, YYYY"
              name="DOB"
              onChange={(e) =>
                formik.setValues((prev) => ({ ...prev, DOB: e }))
              }
              value={formik.values.DOB}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  size="small"
                  fullWidth
                  error={formik.touched.DOB && Boolean(formik.errors.DOB)}
                  helperText={formik.touched.DOB && formik.errors.DOB}
                />
              )}
            />
          </Grid>

          <Grid item xs={4}>
            <TextFieldLabel title="Gender" />
            <CustomSelectBox
              fullWidth
              size="small"
              displayEmpty
              id="gender"
              required
              value={formik.values.gender}
              name="gender"
              onChange={formik.handleChange}
              MenuProps={MenuProps}
            >
              <MenuItem disabled value="">
                Select
              </MenuItem>
              <MenuItem value={"Male"}>Male</MenuItem>
              <MenuItem value={"Female"}>Female</MenuItem>
              <MenuItem value={"Others"}>Others</MenuItem>
            </CustomSelectBox>
          </Grid>
        </Grid>

        <Grid container spacing={1} sx={{ pt: 1 }}>
          <Grid item xs={4}>
            <TextFieldLabel title="Mobile Number" />
            <CustomTextField
              id="mobile"
              name="mobile"
              value={formik.values.mobile}
              onChange={formik.handleChange}
              size="small"
              fullWidth
              placeholder="Type mobile number"
              inputProps={{
                maxLength: 10,
              }}
              error={formik.touched.mobile && Boolean(formik.errors.mobile)}
              helperText={formik.touched.mobile && formik.errors.mobile}
            />
          </Grid>

          <Grid item xs={4}>
            <TextFieldLabel title="Alternate  Number" />
            <CustomTextField
              id="altMobile"
              name="altMobile"
              value={formik.values.altMobile}
              onChange={formik.handleChange}
              size="small"
              placeholder="Type alternate number"
              inputProps={{
                maxLength: 10,
              }}
              fullWidth
              error={
                formik.touched.altMobile && Boolean(formik.errors.altMobile)
              }
              helperText={formik.touched.altMobile && formik.errors.altMobile}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldLabel title="Marital Status" />
            <CustomSelectBox
              displayEmpty
              id="maritalStatus"
              required
              value={formik.values.maritalStatus}
              name="maritalStatus"
              onChange={formik.handleChange}
              size="small"
              fullWidth
              MenuProps={MenuProps}
            >
              <MenuItem disabled value="">
                Select
              </MenuItem>
              <MenuItem value={"Single"}>Single</MenuItem>
              <MenuItem value={"Married"}>Married</MenuItem>
              <MenuItem value={"Unmarried"}>Unmarried</MenuItem>
              <MenuItem value={"Divorced"}>Divorced</MenuItem>
              <MenuItem value={"Widowed"}>Widowed</MenuItem>
            </CustomSelectBox>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ pt: 1 }}>
          <Grid item xs={6}>
            <TextFieldLabel title=" PAN Number" />
            <CustomTextField
              id="PAN"
              name="PAN"
              size="small"
              fullWidth
              placeholder="Type pan number"
              inputProps={{
                maxLength: 10,
              }}
              value={formik.values.PAN}
              onChange={formik.handleChange}
              error={formik.touched.PAN && Boolean(formik.errors.PAN)}
              helperText={formik.touched.PAN && formik.errors.PAN}
            />
          </Grid>
        </Grid>

        <DialogActions>
          <LoadingButton
            loading={updatingProfileInfo}
            color="secondary"
            variant="contained"
            type="submit"
            size="large"
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </>
  );
};

export default PersonalInfoModal;

const Wrapper = styled.div``;

const UploadProfilePicBtn = styled.button`
  background: #ffffff;
  border: 1px solid #3d3efd;
  border-radius: 20px;
  width: 7rem;
  display: flex;
  height: 3rem;
  justify-content: center;
  align-items: center;
  p {
    padding-top: 12px;
    font-weight: 500;
    font-size: 11px;
    color: #3d3efd;
  }
`;
const UploadProfilePicDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 98px;
  background: #d0d0e2;
  border-radius: 20px;
  margin: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: "10px";
`;

const Content = styled.div`
  align-items: left;
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin: 10px;
`;

const UploadBtn = styled.button`
  height: 50px;
  width: 10rem;
  background: #2789fd;
  margin: 5px;
  border-radius: 5px;
  color: white;
  outline: none;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  &:hover {
    background-color: white;
    border: 2px solid #2789fd;
    color: #2789fd;
    transition: 100ms all ease-in-out;
  }
`;
