import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { screen } from "app/utils/mixins";
import { useDispatch, useSelector } from "react-redux";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { generateNameInitial } from "app/utils/helper";
import {
  Badge,
  Box,
  Container,
  InputAdornment,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import UserProfileDrawer from "./components/UserProfileDrawer";
import useSelectorHook from "app/services/redux/useSelectorHook";
import { CustomSelectBox } from "assets/styles/globalStyledComponent";
import { setSession } from "app/services/redux/commonSlice";
import SessionBox from "../SessionBox";
const UserHeader = () => {
  const { userDetail, selectedSession } = useSelectorHook();

  const [state, setState] = useState({
    showSidebar: false,
  });

  return (
    <>
      <UserProfileDrawer state={state} setState={setState} />
      <StyledContainer>
        <div className="inner-nav">
          <Container maxWidth="xl">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack
                direction="row"
                gap={1.3}
                alignItems="center"
                component={Link}
                to={"/"}
                sx={{ textDecoration: "none" }}
              >
                <img
                  src="./logo152.png"
                  style={{
                    height: "60px",
                    width: "60px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                  alt="ekalsutra"
                />
                <Box sx={{ ml: 1, flex: 1 }}>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: 600,
                      color: (theme) => theme.palette.primary.main,
                      textDecoration: "none",
                    }}
                  >
                    Ekalsutra Edtech PVT. LTD.
                  </Typography>
                </Box>
              </Stack>
              <SessionBox />
              <Stack direction="row" alignItems="center" gap={1}>
                <ProfileNameWrapper>
                  <p className="profile-name">
                    {userDetail?.staffDetail?.firstName}{" "}
                    {userDetail?.staffDetail?.lastName}
                  </p>
                  <p className="role" style={{ textTransform: "capitalize" }}>
                    {/* {String(primaryRole).split("_")[1]?.toLowerCase()} */}
                  </p>
                </ProfileNameWrapper>
                <Box
                  onClick={() =>
                    setState((prev) => ({
                      ...prev,
                      showSidebar: !prev.showSidebar,
                    }))
                  }
                  sx={{ cursor: "pointer" }}
                >
                  {userDetail?.staffDetail?.profilePic ? (
                    <img
                      src={userDetail?.staffDetail?.profilePic}
                      style={{
                        height: "50px",
                        width: "50px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <span className="avatar avatar-24 bg-gray rounded-circle text-color p-4 fw-bold">
                      {generateNameInitial(
                        userDetail?.staffDetail?.firstName,
                        userDetail?.staffDetail?.lastName
                      )}
                    </span>
                  )}
                </Box>
              </Stack>
            </Stack>
          </Container>
        </div>
      </StyledContainer>
    </>
  );
};

export default UserHeader;

const ProfileNameWrapper = styled.div`
  .profile-name {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 10rem;
    line-height: 126.19%;
    text-align: center;
    color: #0c2f49;
    width: 10rem;
    /* line-height: 126.19%; */
    color: #0c2f49;
    font-weight: 600;
    /* margin:0 10px; */
    margin: 0 !important;
  }
  .role {
    text-overflow: ellipsis;
    width: 10rem;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 156.19%;
    color: #0c2f49;
  }
`;

const StyledContainer = styled.div`
  position: fixed;
  top: 0;
  z-index: 20;
  .MuiBadge-badge {
    height: 14px !important;
    width: 14px !important;
    border-radius: 7px !important;
  }
  .navbar-brand {
    svg {
      width: 200px;
    }
  }
  .navbar-bg {
    background: #084c84;
    padding-top: 0px;
    margin-left: 0;
    transition: 0.4s;
    padding-bottom: 0px;
    padding-left: 200px;
  }
  .navbar-bg-NX {
    background: #084c84;
    padding-top: 0px;
    margin-left: 0;
    padding-bottom: 0px;
    transition: 0.4s;
  }
  .inner-nav {
    background: white;
    width: 100vw;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    height: 70px;
    display: flex;
    align-items: center;
  }
  .text-color {
    color: #084c84;
  }
  .header_text {
    font-weight: 500;
    font-size: 20px;
    color: #0c2f49;
  }
  ${screen.medium.standard`
      width: 100px
    `};
`;
