import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createStateSyncMiddleware } from "redux-state-sync";
import { withReduxStateSync } from "redux-state-sync";

import authSlice from "./services/redux/authSlice";
import commonSlice from "./services/redux/commonSlice";

const persistConfig = {
  key: "ekalsutra_org",
  storage: storage,
};

// redux-state-sync config
const config = {
  blacklist: ["persist/PERSIST", "persist/REHYDRATE"],
};
const synckStateMiddleware = [createStateSyncMiddleware(config)];

const rootReducer = combineReducers({
  auth: authSlice,
  // schools: schoolS,
  commonStore: commonSlice,
});

const persistedReducer = persistReducer(
  persistConfig,
  withReduxStateSync(rootReducer)
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(synckStateMiddleware),
  devTools: false,
});

export const persistor = persistStore(store);
