import appRoutes from "app/config/routes";
import useSelectorHook from "app/services/redux/useSelectorHook";
import React from "react";
import { Navigate } from "react-router-dom";

const AuthPrivateRoute = ({ children }) => {
  const { isAuthenticated } = useSelectorHook();
  return isAuthenticated ? (
    <Navigate to={`/${appRoutes.dashboard}`} />
  ) : (
    children
  );
};

export default AuthPrivateRoute;
