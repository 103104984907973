import {
  Box,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { getSchool } from "app/services/school";
import { toast } from "react-toastify";
import CommonTable1 from "app/components/Tables/CommonTable1";
import TableActionButtons from "app/components/common/TableActionButtons";
import ExportButtonGroup from "app/components/common/ExportButtonGroup";
import { Link } from "react-router-dom";
import appRoutes from "app/config/routes";
import CustomLoader from "app/components/common/CustomLoader";
import InstituteListFilter from "app/components/Dialogs/InstituteList/InstituteListFilter";
import ChangeStatusDialog from "app/components/Dialogs/InstituteList/ChangeStatusDialog";

const InstituteList = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [state, setState] = useState({
    filterDialog: false,
    callGetSchool: false,
    dataList: [],
    filterData: false,
    fetch: false,
    changeStatusDialog: false,
    statusData: {},
  });
  const handleFilter = (payload) => {
    setState((prev) => ({ ...prev, filterData: payload, fetch: !prev.fetch }));
  };
  const {
    isLoading: getSchoolLoading,
    isFetching: getSchoolIsFetching,
    refetch: getSchoolRefetch,
  } = useQuery({
    queryKey: ["getSchool", state.fetch],
    queryFn: () =>
      getSchool(
        state.filterData
          ? state.filterData
          : { from: new Date(), to: new Date() }
      ),
    onSuccess: (success) => {
      toast.success(success?.data?.message);
      setState((prev) => ({
        ...prev,
        dataList: success?.data?.list,
        filterDialog: false,
        callGetSchool: false,
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  // showing table column
  const columns = useMemo(() => {
    return [
      {
        header: "Sr.No",
        Cell: ({ cell }) => (
          <Typography>{parseInt(cell.row.id) + 1}</Typography>
        ),
        maxSize: 20,
      },
      {
        header: "License Number",
        accessorKey: "ekalRefNumber",
        maxSize: 40,
      },
      {
        header: "School Name",
        accessorKey: "schoolName",
        maxSize: 30,
      },

      {
        header: "Director Name",
        accessorKey: "director?.firstName",
        Cell: ({ cell, row }) => {
          return (
            <>
              {row?.original?.schoolAdmin ? (
                <Typography>
                  {row?.original?.schoolAdmin?.personName}
                </Typography>
              ) : (
                "---"
              )}
            </>
          );
        },
        maxSize: 30,
      },
      {
        header: "State",
        accessorKey: "schoolAddress?.state",
        Cell: ({ cell, row }) => {
          return (
            <>
              {row?.original?.schoolAddress ? (
                <Typography>{row?.original?.schoolAddress?.state}</Typography>
              ) : (
                "---"
              )}
            </>
          );
        },
        maxSize: 30,
      },
      {
        header: "District",
        accessorKey: "schoolAddress?.dist",
        Cell: ({ cell, row }) => {
          return (
            <>
              {row?.original?.schoolAddress ? (
                <Typography>{row?.original?.schoolAddress?.dist}</Typography>
              ) : (
                "---"
              )}
            </>
          );
        },
        maxSize: 30,
      },

      {
        header: "Account Status",
        accessorKey: "accountStatus",
        Cell: ({ cell, row }) => {
          return (
            <Stack direction="row" gap={0.5}>
              <Typography
                sx={{
                  color:
                    row?.original?.accountStatus === "WON"
                      ? "rgba(20, 219, 28, 1)"
                      : row?.original?.accountStatus === "LOST"
                      ? "rgba(255, 0, 0, 1)"
                      : row?.original?.accountStatus === "DEAD"
                      ? "rgba(12, 47, 73, 1)"
                      : "rgba(251, 177, 34, 1)",
                }}
              >
                {row?.original?.accountStatus}
              </Typography>
            </Stack>
          );
        },
        maxSize: 20,
      },

      {
        header: "Actions",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <Link
              to={`/${appRoutes.singleInstituteView}?schoolDocId=${original?._id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <TableActionButtons
                showView
                handleView={() =>
                  setState((prev) => ({
                    ...prev,
                    viewDialog: true,
                    schoolEnquiryDocId: original?._id,
                  }))
                }
              />
            </Link>
          );
        },
        maxSize: 20,
      },
    ];
  }, []);

  return (
    <>
      {/* filter Dialog */}
      <CustomLoader show={getSchoolLoading || getSchoolIsFetching} />

      <Container maxWidth="xl" disableGutters={isMobile ? true : false}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <InstituteListFilter mutate={handleFilter} />
          </Box>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <ExportButtonGroup showExcel showPdf />
          </Box>
        </Stack>
        <Box sx={{ mt: 2 }}>
          <CommonTable1
            columns={columns}
            data={state.dataList}
            maxHeight="70vh"
          />
        </Box>
      </Container>
    </>
  );
};

export default InstituteList;
