import {
  CartDash,
  Speedometer,
  Gear,
  PersonVideo2,
  UniversalAccessCircle,
} from "react-bootstrap-icons";
import { ROLE_ADMIN, ROLE_TEACHER } from "app/config/roles";
import { INSTITUTE_TYPES } from "app/config/institutes";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SchoolIcon from "@mui/icons-material/School";
import appRoutes from "./routes";
import {
  CurrencyRupee,
  InventoryOutlined,
  ManageAccounts,
} from "@mui/icons-material";
export const sidebarItems = [
  {
    key: "dashboard",
    name: "Dashboard",
    icon: <DashboardIcon size={15} />,
    link: `/dashboard`,
    roles: [ROLE_ADMIN, ROLE_TEACHER],
    module: "DASHBOARD",
    screenName: "DASHBOARD",
  },
  {
    key: "product",
    name: "Product",
    icon: <InventoryOutlined size={15} />,
    link: appRoutes.product,
    roles: [ROLE_ADMIN, ROLE_TEACHER],
    module: "PRODUCT",
    screenName: "PRODUCT",
  },
  {
    key: "collection",
    name: "Collection",
    icon: <SchoolIcon size={15} />,
    roles: [ROLE_ADMIN, ROLE_TEACHER],
    module: "COLLECTION",
    screenName: "COLLECTION",
    subMenu: [
      {
        key: "erpCollection",
        name: "ERP Collection",
        icon: <ManageAccounts size={15} />,
        link: appRoutes.erpCollection,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        module: "COLLECTION",
        screenName: "ERP_COLLECTION",
      },
      {
        key: "taxInvoice",
        name: "Tax Invoice",
        icon: <CurrencyRupee size={15} />,
        link: appRoutes.taxInvoice,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        module: "COLLECTION",
        screenName: "TAX_INVOICE",
      },
    ],
  },
  {
    key: "institute",
    name: "Institute",
    icon: <SchoolIcon size={15} />,
    roles: [ROLE_ADMIN, ROLE_TEACHER],
    module: "INSTITUTE",
    screenName: "INSTITUTE",
    subMenu: [
      {
        key: "instituteGroup",
        name: "Institute Group",
        icon: <CartDash size={15} />,
        link: appRoutes.instituteGroup,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        module: "INSTITUTE",
        screenName: "INSTITUTE_GROUP",
      },
      {
        key: "instituteList",
        name: "Institute List",
        icon: <CartDash size={15} />,
        link: appRoutes.instituteList,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        module: "INSTITUTE",
        screenName: "INSTITUTE_LIST",
      },
      {
        key: "enquiry",
        name: "Institute Enquiry",
        icon: <CartDash size={15} />,
        link: appRoutes.instituteEnquiry,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        module: "INSTITUTE",
        screenName: "INSTITUTE_ENQUIRY",
      },
    ],
  },

  {
    key: "studentsManagement",
    name: "Student Management",
    icon: <SchoolIcon size={15} />,
    roles: [ROLE_ADMIN, ROLE_TEACHER],
    module: "STUDENT_MANAGEMENT",
    screenName: "STUDENT_MANAGEMENT",
    subMenu: [
      {
        key: "students",
        name: "Students",
        icon: <CartDash size={15} />,
        link: appRoutes.studentManagement,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        module: "STUDENT_MANAGEMENT",
        screenName: "STUDENT",
      },
      {
        key: "idcard",
        name: "ID Card",
        icon: <CartDash size={15} />,
        link: appRoutes.idCard,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        module: "STUDENT_MANAGEMENT",
        screenName: "IDCARD",
      },
    ],
  },
  {
    key: "feeManagement",
    name: "Fee Management",
    icon: <SchoolIcon size={15} />,
    roles: [ROLE_ADMIN, ROLE_TEACHER],
    module: "FEE_MANAGEMENT",
    screenName: "FEE_MANAGEMENT",
    subMenu: [
      {
        key: "feeCollection",
        name: "Fee Collection",
        icon: <CartDash size={15} />,
        link: appRoutes.feeCollection,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        module: "FEE_MANAGEMENT",
        screenName: "FEE_COLLECTION",
      },
      {
        key: "feeStructure",
        name: "Fee Structure",
        icon: <CartDash size={15} />,
        link: appRoutes.feeStructure,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        module: "FEE_MANAGEMENT",
        screenName: "FEE_STRUCTURE",
      },
      {
        key: "feeReport",
        name: "Fee Report",
        icon: <CartDash size={15} />,
        link: appRoutes.feeReport,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        module: "FEE_MANAGEMENT",
        screenName: "FEE_REPORT",
      },
    ],
  },
  {
    key: "ledgerandAccount",
    name: "Ledger/Account",
    icon: <SchoolIcon size={15} />,
    roles: [ROLE_ADMIN, ROLE_TEACHER],
    module: "LEDGER_ACCOUNT",
    screenName: "LEDGER_ACCOUNT",
    subMenu: [
      {
        key: "ledgerCollection",
        name: "Collection",
        icon: <CartDash size={15} />,
        link: appRoutes.ledgerCollection,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        module: "LEDGER_ACCOUNT",
        screenName: "COLLECTION",
      },
      {
        key: "collection",
        name: "Expense",
        icon: <CartDash size={15} />,
        link: appRoutes.ledgerExpense,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        module: "LEDGER_ACCOUNT",
        screenName: "EXPENSE",
      },
      {
        key: "report",
        name: "Report",
        icon: <CartDash size={15} />,
        link: appRoutes.ledgerReport,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        module: "LEDGER_ACCOUNT",
        screenName: "REPORT",
      },
    ],
  },
  {
    key: "instituteManagement",
    name: "Institute Management",
    icon: <SchoolIcon size={15} />,
    roles: [ROLE_ADMIN, ROLE_TEACHER],
    module: "INSTITUTE_MANAGEMENT",
    screenName: "INSTITUTE_MANAGEMENT",
    subMenu: [
      {
        key: "sessionSetting",
        name: "Session Setting",
        icon: <CartDash size={15} />,
        link: appRoutes.sessionSetting,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        module: "INSTITUTE_MANAGEMENT",
        screenName: "SESSION_SETTING",
      },
      {
        key: "class",
        name: "Class",
        icon: <CartDash size={15} />,
        link: appRoutes.class,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        module: "INSTITUTE_MANAGEMENT",
        screenName: "CLASS",
      },
      {
        key: "department",
        name: "Department",
        icon: <CartDash size={15} />,
        link: appRoutes.department,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        module: "INSTITUTE_MANAGEMENT",
        screenName: "DEPARTMENT",
      },
    ],
  },
  {
    key: "communication",
    name: "Communication",
    icon: <SchoolIcon size={15} />,
    roles: [ROLE_ADMIN, ROLE_TEACHER],
    module: "COMMUNICATION",
    screenName: "COMMUNICATION",
    subMenu: [
      {
        key: "whatsAppTemplate",
        name: "Whats-App Templates",
        icon: <CartDash size={15} />,
        link: appRoutes.whatsAppTemplate,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        module: "COMMUNICATION",
        screenName: "WHATS_APP_TEMPLATE",
      },
      {
        key: "text",
        name: "Text Sms",
        icon: <CartDash size={15} />,
        link: appRoutes.textSms,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        module: "COMMUNICATION",
        screenName: "TEXT_SMS",
      },
    ],
  },
  {
    key: "accessControl",
    name: "Access Control",
    icon: <SchoolIcon size={15} />,
    roles: [ROLE_ADMIN, ROLE_TEACHER],
    module: "ACCESS_CONTROL",
    screenName: "ACCESS_CONTROL",
    subMenu: [
      {
        key: "permission",
        name: "Permission",
        icon: <CartDash size={15} />,
        link: appRoutes.permissions,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        module: "ACCESS_CONTROL",
        screenName: "PERMISSION",
      },
      {
        key: "authorizedPerson",
        name: "Authorized Person",
        icon: <CartDash size={15} />,
        link: appRoutes.authorizedPerson,
        roles: [ROLE_ADMIN, ROLE_TEACHER],
        module: "ACCESS_CONTROL",
        screenName: "AUTHORIZED_PERSON",
      },
    ],
  },
];
