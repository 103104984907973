import React from "react";
import { Box, Stack, Typography, styled } from "@mui/material";
import { THEME } from "app/config/constants";

const InstituteBrand = ({ onClick }) => {
  return (
    <StyledWrapper direction="row" alignItems="center">
      <div style={{ width: "190px", display: "flex", alignItems: "center" }}>
        <div
          onClick={onClick}
          style={{
            cursor: "pointer",
            height: 20,
            width: 20,
            marginTop: "-13px",
            paddingLeft: "17px",
          }}
        >
          <div className="hamburger">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <img
        src="./logo152.png"
        style={{
          height: "60px",
          width: "60px",
          borderRadius: "50%",
          objectFit: "cover",
        }}
        alt="ekalsutra"
      />
      <Box sx={{ ml: 1, flex: 1 }}>
        <Typography className="school_name_text">
          Ekalsutra Edtech PVT. LTD.
        </Typography>
      </Box>
    </StyledWrapper>
  );
};

export default InstituteBrand;

const StyledWrapper = styled(Stack)`
  .hamburger span {
    display: block;
    margin: 5px 0px;
    background-color: #084c84;
    border-radius: 15px;
    height: 5px;
    width: 30px;
  }
  img {
    width: 5rem;
    height: 5rem;
  }
  .school_name_text {
    color: ${THEME.primary};
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.7rem;

    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .address_text {
    color: ${THEME.primary};
    font-size: 1.1rem;
    font-weight: 500;
    width: 95%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;
