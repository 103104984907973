import Fallback from "app/components/Fallback";
import React from "react";

const SchoolEnquiry = React.lazy(() =>
  import("app/modules/schools/pages/schoolEnquiry")
);
const InstituteGroup = React.lazy(() =>
  import("app/modules/schools/pages/InstituteGroup")
);
const InstituteList = React.lazy(() =>
  import("app/modules/schools/pages/InstituteList/index")
);
const SingleInstituteView = React.lazy(() =>
  import("app/modules/schools/pages/InstituteList/SingleInstituteView")
);
const AboutSchool = () => {
  return <p>about school</p>;
};
const SchoolProfile = () => {
  return <p>school profile</p>;
};
export const AboutSchoolPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <AboutSchool {...props} />
  </React.Suspense>
);
export const SchoolProfilePage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <SchoolProfile {...props} />
  </React.Suspense>
);
export const SchooEnquiryPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <SchoolEnquiry {...props} />
  </React.Suspense>
);
export const InstituteListPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <InstituteList {...props} />
  </React.Suspense>
);
export const InstituteGroupPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <InstituteGroup {...props} />
  </React.Suspense>
);
export const SingleInstituteViewPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <SingleInstituteView {...props} />
  </React.Suspense>
);
