import React from "react";
import { Navigate, Outlet, useRoutes } from "react-router-dom";
import appRoutes from "app/config/routes";
import {
  InstituteGroupPage,
  InstituteListPage,
  SchooEnquiryPage,
  SchoolProfilePage,
  SingleInstituteViewPage,
} from "app/modules/schools/pages";
import { AuthPage } from "../../modules/auth/pages";
import PrivateRoute from "../PrivateRoute";
import { SchoolDashboardPage } from "app/modules/dashboard/pages";
import Layout from "app/layout";
import { AboutSchoolPage } from "app/modules/schools/pages";
import { UserProfilePage } from "app/modules/userProfile/pages";
import UserHeader from "app/layout/Header/UserHeader";
import ForgotPassword from "app/modules/auth/components/ForgotPassword";
import AuthPrivateRoute from "../PrivateRoute/AuthPrivateRoute";
import TextSMS from "app/modules/schools/pages/Communication/TextSMS";
import Product from "app/modules/product";
import ViewInstituteGroup from "app/modules/schools/pages/InstituteGroup/ViewInstituteGroup";
import Invoice from "../Invoice/Invoice";
import {
  ErpCollectionPage,
  TaxInvoicePage,
} from "app/modules/collection/pages";
import IDCard from "app/modules/studentManagement/idCard/pages";
import CustomIdCardPage from "app/modules/studentManagement/idCard/pages/CustomIdCardPage";
import CustomStaffIdCardPage from "app/modules/studentManagement/idCard/pages/CustomStaffIdCardPage";
const routes = [
  {
    path: "/",
    element: (
      <Layout>
        <Outlet />
      </Layout>
    ),
    children: [
      {
        path: `/`,
        element: <Navigate to={`/${appRoutes.login}`} />,
      },
      {
        path: appRoutes.aboutInstitute,
        element: (
          <PrivateRoute>
            <AboutSchoolPage />
          </PrivateRoute>
        ),
      },
      {
        path: appRoutes.instituteProfile,
        element: (
          <PrivateRoute>
            <SchoolProfilePage />
          </PrivateRoute>
        ),
      },
      // dashboard
      {
        path: `/${appRoutes.dashboard}`,
        element: (
          <PrivateRoute>
            <SchoolDashboardPage />
          </PrivateRoute>
        ),
      },
      // product
      {
        path: `/${appRoutes.product}`,
        element: (
          <PrivateRoute>
            <Product />
          </PrivateRoute>
        ),
      },
      // school management
      {
        path: `/${appRoutes.institute}`,
        element: <Outlet />,
        children: [
          {
            path: `/${appRoutes.instituteEnquiry}`,
            element: (
              <PrivateRoute>
                <SchooEnquiryPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.instituteEnquiry}`,
            element: (
              <PrivateRoute>
                <SchooEnquiryPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.instituteGroup}`,
            element: (
              <PrivateRoute>
                <InstituteGroupPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.instituteList}`,
            element: (
              <PrivateRoute>
                <InstituteListPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.singleInstituteView}`,
            element: (
              <PrivateRoute>
                <SingleInstituteViewPage />
              </PrivateRoute>
            ),
          },

          {
            path: `/${appRoutes.viewInstituteGroup}/:id`,
            element: (
              <PrivateRoute>
                <ViewInstituteGroup />
              </PrivateRoute>
            ),
          },

          // {
          //   path: `/${appRoutes.accessControl}`,
          //   element: (
          //     <PrivateRoute>
          //       <AccessControlPage />
          //     </PrivateRoute>
          //   ),
          // },
        ],
      },
      {
        path: `/${appRoutes.studentManagement}`,
        element: <Outlet />,
        children: [
          {
            path: `/${appRoutes.idCard}`,
            element: (
              <PrivateRoute>
                <IDCard />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.customIdCard}/:frameCode?`, // frameCode is optional
            element: (
              <PrivateRoute>
                <CustomIdCardPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.customStaffIdCard}/:frameCode?`, // frameCode is optional
            element: (
              <PrivateRoute>
                <CustomStaffIdCardPage />
              </PrivateRoute>
            ),
          },
        ],
      },
      // collection
      {
        path: `/${appRoutes.collection}`,
        element: <Outlet />,
        children: [
          {
            path: `/${appRoutes.erpCollection}`,
            element: (
              <PrivateRoute>
                <ErpCollectionPage />
              </PrivateRoute>
            ),
          },
          {
            path: `/${appRoutes.taxInvoice}`,
            element: (
              <PrivateRoute>
                <TaxInvoicePage />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        path: `/${appRoutes.invoice}/:invoiceId`,
        element: (
          <PrivateRoute>
            <Invoice />
          </PrivateRoute>
        ),
      },
      {
        path: `/${appRoutes.communication}`,
        element: <Outlet />,
        children: [
          {
            path: `/${appRoutes.textSms}`,
            element: (
              <PrivateRoute>
                <TextSMS />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        path: `*`,
        element: <>Not Found</>,
      },
    ],
  },

  {
    path: `/${appRoutes.userProfile}`,
    element: (
      <PrivateRoute>
        <UserHeader />
        <UserProfilePage />
      </PrivateRoute>
    ),
  },
  {
    path: `/${appRoutes.login}`,
    element: (
      <AuthPrivateRoute>
        <AuthPage />
      </AuthPrivateRoute>
    ),
  },
  {
    path: `/${appRoutes.register}`,
    element: (
      <AuthPrivateRoute>
        <AuthPage />
      </AuthPrivateRoute>
    ),
  },
  {
    path: `/${appRoutes.forgotPassword}`,
    element: (
      <AuthPrivateRoute>
        <ForgotPassword />
      </AuthPrivateRoute>
    ),
  },
];

const AppRoutes = () => {
  const appRoutes = useRoutes(routes);
  return appRoutes;
};

export default AppRoutes;
