import React, { useState } from "react";
import InstituteBrand from "./components/InstituteBrand";
import { screen } from "app/utils/mixins";

import { generateNameInitial } from "app/utils/helper";
import { Box, Stack, styled } from "@mui/material";
import UserProfileDrawer from "./components/UserProfileDrawer";
import { SIZES, THEME } from "app/config/constants";
import useSelectorHook from "app/services/redux/useSelectorHook";
import SessionBox from "../SessionBox";
const Header = ({ handleOnClickHamburgeMenu }) => {
  const { userDetail } = useSelectorHook();
  const [state, setState] = useState({
    showSidebar: false,
  });

  return (
    <StyledContainer>
      <UserProfileDrawer state={state} setState={setState} />
      <div className={"navbar-bg-NX"}>
        <div
          className="inner-nav"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <InstituteBrand onClick={handleOnClickHamburgeMenu} />
          <SessionBox />

          <Stack direction="row" alignItems="center" gap={1} sx={{ pr: 2 }}>
            <ProfileNameWrapper>
              <p className="profile-name">
                {userDetail?.staffDetail?.firstName}{" "}
                {userDetail?.staffDetail?.lastName}
              </p>
            </ProfileNameWrapper>
            <Box
              onClick={() =>
                setState((prev) => ({
                  ...prev,
                  showSidebar: !prev.showSidebar,
                }))
              }
              sx={{ cursor: "pointer" }}
            >
              {userDetail?.staffDetail?.profilePic ? (
                <img
                  alt=""
                  src={userDetail?.staffDetail?.profilePic}
                  style={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <span className="">
                  {generateNameInitial(
                    userDetail?.staffDetail?.firstName,
                    userDetail?.staffDetail?.lastName
                  )}
                </span>
              )}
            </Box>
          </Stack>
        </div>
      </div>
    </StyledContainer>
  );
};

export default Header;

const ProfileNameWrapper = styled("div")`
  .profile-name {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 10rem;
    line-height: 126.19%;
    text-align: center;
    color: #0c2f49;
    width: 10rem;
    /* line-height: 126.19%; */
    color: #0c2f49;
    font-weight: 600;
    /* margin:0 10px; */
    margin: 0 !important;
  }
  .role {
    text-overflow: ellipsis;
    width: 10rem;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 156.19%;
    color: #0c2f49;
  }
`;

const StyledContainer = styled("div")`
  .MuiBadge-badge {
    height: 14px !important;
    width: 14px !important;
    border-radius: 7px !important;
  }
  .navbar-brand {
    svg {
      width: 200px;
    }
  }
  .navbar-bg {
    background: #084c84;
    padding-top: 0px;
    margin-left: 0;
    transition: 0.4s;
    padding-bottom: 0px;
    padding-left: 200px;
  }
  .navbar-bg-NX {
    background: #084c84;
    padding-top: 0px;
    margin-left: 0;
    padding-bottom: 0px;
    transition: 0.4s;
  }
  .inner-nav {
    background: ${THEME.white};
    width: 100%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    height: ${SIZES.desktopNavbarHeight};
    display: flex;
    align-items: center;
  }
  .text-color {
    color: #084c84;
  }
  ${screen.medium.standard`
      width: 100px
    `};
`;
