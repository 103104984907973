import React, { useState } from "react";
import styled from "styled-components";
import { List } from "react-bootstrap-icons";
import { Box, Stack } from "@mui/material";
import MobileInstituteBrand from "../Header/components/MobileInstituteBrand";
import UserProfileDrawer from "../Header/components/UserProfileDrawer";
import useSelectorHook from "app/services/redux/useSelectorHook";
import { generateNameInitial } from "app/utils/helper";
const MobileHeader = ({ setShow }) => {
  const { userDetail } = useSelectorHook();
  const [state, setState] = useState({
    showSidebar: false,
  });

  return (
    <>
      <UserProfileDrawer state={state} setState={setState} />
      <StyledContainer>
        <Box>
          <div className="inner-nav">
            <Stack direction="row" px={1} alignItems="center">
              <List
                size={30}
                onClick={() => setShow(true)}
                style={{ color: "#fff" }}
              />

              <Box sx={{ flexGrow: 1 }}>
                <MobileInstituteBrand />
              </Box>
              <Box
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    showSidebar: !prev.showSidebar,
                  }))
                }
                sx={{ cursor: "pointer" }}
              >
                {userDetail?.staffDetail?.profilePic ? (
                  <img
                    alt=""
                    src={userDetail?.staffDetail?.profilePic}
                    style={{
                      height: "50px",
                      width: "50px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <span className="">
                    {generateNameInitial(
                      userDetail?.staffDetail?.firstName,
                      userDetail?.staffDetail?.lastName
                    )}
                  </span>
                )}
              </Box>
            </Stack>
          </div>
        </Box>
      </StyledContainer>
    </>
  );
};

export default MobileHeader;

const StyledContainer = styled.div`
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  .navbar-bg {
    background: #084c84;
    transition: 0.4s;
    padding-bottom: 0px;
  }
  .inner-nav {
    /* padding: 15px 0px 7px 0px; */
    padding-top: 3px;
    background: #084c84;
    width: 100%;
    border-bottom: 2px solid grey;
  }
  .text-color {
    color: white;
  }
`;
