import {
  CircularProgress,
  DialogContent,
  Grid,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import CommonDialog from "app/components/common/CommonDialog";
import LabelTextComponent from "app/components/common/LabelTextComponent";
import { getProduct } from "app/services/org/product.service";
import useSelectorHook from "app/services/redux/useSelectorHook";
import { IndianCurrency } from "app/utils/helper";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";

const ViewProductDialog = ({ productId = "", onClose = () => {} }) => {
  const { selectedSession } = useSelectorHook();
  const [state, setState] = useState({
    data: {},
  });
  const handleClose = () => {
    onClose();
  };
  const { isLoading: getProductLoading, isFetching: getProductFetching } =
    useQuery({
      queryKey: ["getProduct", productId],
      queryFn: () =>
        getProduct({ productDocId: productId, session: selectedSession }),

      onSuccess: (success) => {
        setState((prev) => ({ ...prev, data: success?.data?.obj }));
      },
      onError: (err) => {
        toast.error(err?.response?.data?.message);
      },
      enabled: productId ? true : false,
    });
  return (
    <CommonDialog
      open={Boolean(productId)}
      onClose={handleClose}
      title="View Product"
      minWidth="900px"
    >
      <DialogContent>
        {(getProductLoading || getProductFetching) && (
          <Stack direction="row" justifyContent={"center"}>
            <CircularProgress />
          </Stack>
        )}
        <ViewBox>
          <Grid container rowSpacing={2} columnSpacing={1}>
            <Grid item xs={12} md={3}>
              <LabelTextComponent
                label={"Product Type"}
                text={state.data?.productType}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <LabelTextComponent
                label={"Product Name"}
                text={state.data?.productName}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <LabelTextComponent
                label={"Product Description"}
                text={state.data?.productDescription}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <LabelTextComponent
                label={"UOM"}
                text={state.data?.unitOfMesurement}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <LabelTextComponent label={"Rate"} text={state.data?.rate} />
            </Grid>
            <Grid item xs={12} md={2}>
              <LabelTextComponent label={"Factor"} text={state.data?.factor} />
            </Grid>
            <Grid item xs={12} md={2}>
              <LabelTextComponent
                label={"Amount"}
                text={IndianCurrency(
                  Number(state.data?.factor ?? 0) *
                    Number(state.data?.rate ?? 0)
                )}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <LabelTextComponent
                label={"Due Date"}
                text={dayjs(state.data?.dueDate).format("DD MMM, YYYY")}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <LabelTextComponent
                label={"Expiry Date"}
                text={dayjs(state.data?.expiresOn).format("DD MMM, YYYY")}
              />
            </Grid>
            <Grid item xs={12}>
              <LabelTextComponent
                label={"Remarks"}
                text={state.data?.remarks}
              />
            </Grid>
          </Grid>
        </ViewBox>
      </DialogContent>
    </CommonDialog>
  );
};

export default ViewProductDialog;
const ViewBox = styled("div")`
  border-radius: 10px;
  border: 1px solid #b6c8d6;
  background: #f9fafb;
  padding: 10px;
  margin-top: 15px;
`;
