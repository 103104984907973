import LogoutIcon from "@mui/icons-material/Logout";
import { Close, Key } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Drawer,
  IconButton,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import appRoutes from "app/config/routes";
import { persistor } from "app/store";
import { generateNameInitial } from "app/utils/helper";
import { useState } from "react";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CancelPresentationSharpIcon from "@mui/icons-material/CancelPresentationSharp";
import { CustomTextField } from "assets/styles/globalStyledComponent";
import { THEME } from "app/config/constants";
import useSelectorHook from "app/services/redux/useSelectorHook";
import { setLogout } from "app/services/redux/authSlice";
import { changePasswordWithoutOtp } from "app/services/auth/auth.service";
const UserProfileDrawer = ({ setState, state }) => {
  const [passwordShow, setpasswordShow] = useState(false);
  const [inputPassword, setinputPassword] = useState("");
  const [inputNewPassword, setinputNewPassword] = useState("");
  const [inputConfirmNewPassword, setinputConfirmNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userDetail } = useSelectorHook();
  const { mutate } = useMutation(changePasswordWithoutOtp, {
    onSuccess: ({ data }) => {
      toast.success(data?.message);
      setpasswordShow(false);
      localStorage.removeItem("schoolToken");
      localStorage.removeItem("schoolRefreshToken");
      localStorage.removeItem("authRefreshToken");
      localStorage.removeItem("selectedSchoolId");
      navigate("/login", { replace: true });
    },

    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  const navigateToProfile = () => {
    setState((prev) => ({ ...prev, showSidebar: false }));
    navigate(`/${appRoutes.userProfile}`);
  };
  const handleChangePassword = () => {
    setpasswordShow(true);
  };
  const handleClosePassword = () => {
    setpasswordShow(false);
  };
  const submitPassword = () => {
    if (!inputPassword || !inputNewPassword || !inputConfirmNewPassword) {
      // One or more fields are empty, show error message
      setErrorMessage("Please fill in all fields.");
      return;
    } else {
      setErrorMessage("");
      let query = {
        confirmPassword: inputConfirmNewPassword,
        currentPassword: inputPassword,
        password: inputNewPassword,
      };

      mutate(query);
    }
  };
  return (
    <>
      {/* change password dialog */}
      <Dialog
        open={passwordShow}
        onClose={() => setpasswordShow(false)}
        maxWidth="sm"
        fullWidth
      >
        <Stack
          sx={{
            padding: "1em",
            backgroundColor: "#f5f5f5",
          }}
        >
          <Stack
            direction="row"
            sx={{
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
              padding: "5px",
            }}
          >
            <DialogTitle
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                padding: "15px",
              }}
            >
              Update Fee Relaxation
            </DialogTitle>
            <Button
              size="large"
              endIcon={
                <CancelPresentationSharpIcon backgroundColor="secondary" />
              }
              onClick={() => handleClosePassword(true)}
            ></Button>
          </Stack>
          <Stack
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
              padding: "15px",
              gap: "5px",
            }}
          >
            <Typography sx={{ whiteSpace: "nowrap" }}>
              Current Password
            </Typography>
            <span style={{ color: "red" }}>*</span>
            <CustomTextField
              fullWidth
              size="small"
              sx={{ marginTop: "8px" }}
              id="fullWidth"
              onChange={(e) => {
                setinputPassword(e.target.value);
              }}
            />
          </Stack>
          <Stack
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              padding: "15px",
            }}
          >
            {" "}
            <Typography sx={{ whiteSpace: "nowrap" }}>New Password</Typography>
            <span style={{ color: "red" }}>*</span>
            <CustomTextField
              fullWidth
              sx={{ marginTop: "8px" }}
              id="fullWidth"
              onChange={(e) => {
                setinputNewPassword(e.target.value);
              }}
            />
          </Stack>
          <Stack
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              padding: "15px",
            }}
          >
            {" "}
            <Typography sx={{ whiteSpace: "nowrap" }}>
              Confirm New Password
            </Typography>
            <span style={{ color: "red" }}>*</span>
            <CustomTextField
              fullWidth
              sx={{ marginTop: "8px" }}
              id="fullWidth"
              onChange={(e) => {
                setinputConfirmNewPassword(e.target.value);
              }}
            />
          </Stack>
          {errorMessage && (
            <Typography variant="body2" color="error">
              {errorMessage}
            </Typography>
          )}
          <Stack>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#2789FD",
                color: "white",
                marginLeft: "auto",
              }}
              onClick={submitPassword}
            >
              Reset Password
            </Button>
          </Stack>
        </Stack>
      </Dialog>
      <Drawer
        anchor="right"
        onClose={() => setState((prev) => ({ ...prev, showSidebar: false }))}
        open={state.showSidebar}
        PaperProps={{
          sx: {
            minWidth: "300px",
            backgroundColor: THEME.primary,
            color: THEME.white,
          },
        }}
      >
        <Stack direction="row" justifyContent="flex-start">
          <IconButton
            onClick={() =>
              setState((prev) => ({ ...prev, showSidebar: false }))
            }
          >
            <Close sx={{ color: THEME.white }} />
          </IconButton>
        </Stack>
        <FlexBoxCol onClick={navigateToProfile}>
          {userDetail?.staffDetail?.profilePic ? (
            <ProfileImg
              alt="Profile"
              src={userDetail?.staffDetail?.profilePic}
            ></ProfileImg>
          ) : (
            <span className="avatar avatar-24 bg-gray rounded-circle text-color p-4 fw-bold">
              {generateNameInitial(
                userDetail?.staffDetail?.firstName,
                userDetail?.staffDetail?.lastName
              )}
            </span>
          )}
          {/* Onclick navigate to Staff management */}
          <Typography
            sx={{
              fontWeight: 600,
            }}
          >
            {userDetail?.staffDetail?.firstName}{" "}
            {userDetail?.staffDetail?.middleName}{" "}
            {userDetail?.staffDetail?.lastName}
          </Typography>
        </FlexBoxCol>
        <Stack
          sx={{ mt: 2, height: "100%", mb: 4 }}
          direction="column"
          justifyContent="space-between"
        >
          <Box>
            <Stack
              direction="row"
              alignItems="center"
              gap={1}
              sx={{ cursor: "pointer", px: 3, py: 1 }}
            >
              <Key />
              <Typography
                onClick={handleChangePassword}
                sx={{ fontSize: "14px" }}
              >
                Change Password
              </Typography>
            </Stack>

            {/* Switch schools button */}

            {/* Logout button */}
            <Stack
              direction="row"
              alignItems="center"
              gap={1}
              sx={{ cursor: "pointer", px: 3, py: 1 }}
              onClick={() => {
                setState((prev) => ({ ...prev, showSidebar: false }));
                dispatch(setLogout());
                navigate("/login", { replace: true });
                persistor.purge();
              }}
            >
              <LogoutIcon />
              <Typography sx={{ fontSize: "14px" }}>Logout</Typography>
            </Stack>
          </Box>
        </Stack>
      </Drawer>
    </>
  );
};
export default UserProfileDrawer;
const FlexBoxCol = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  p {
    display: flex;
    font-size: 15px;
    margin: auto 20px;
    align-items: center;
  }
`;
const ProfileImg = styled("img")`
  height: 70px;
  width: 70px;
  margin: auto 20px;
  border-radius: 40px;
`;
