import React from "react";

import { Typography, styled } from "@mui/material";

const MobileInstituteBrand = () => {
  return (
    <StyledWrapper>
      <img
        src="./logo152.png"
        style={{
          height: "60px",
          width: "60px",
          borderRadius: "50%",
          objectFit: "cover",
        }}
        alt="ekalsutra"
      />
      <div>
        <Typography className="institute-name">Ekalsutra PVT. LTD.</Typography>
      </div>
    </StyledWrapper>
  );
};

export default MobileInstituteBrand;

const StyledWrapper = styled("div")`
  color: white;
  display: flex;
  align-items: center;
  gap: 5px;

  img {
    width: 5rem;
    height: 5rem;
  }
  .institute-name {
    font-size: 14px;

    width: ${window.innerWidth - 185}px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .institute-info {
    font-size: 10px;
    width: ${window.innerWidth - 185}px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;
