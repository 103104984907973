import Fallback from "app/components/Fallback";
import { Suspense, lazy } from "react";

const ErpCollection = lazy(() =>
  import("app/modules/collection/pages/ErpCollection")
);
const TaxInvoice = lazy(() =>
  import("app/modules/collection/pages/TaxInvoice")
);
export const ErpCollectionPage = (props) => (
  <Suspense fallback={<Fallback />}>
    <ErpCollection {...props} />
  </Suspense>
);
export const TaxInvoicePage = (props) => (
  <Suspense fallback={<Fallback />}>
    <TaxInvoice {...props} />
  </Suspense>
);
