import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Stack, styled, useMediaQuery } from "@mui/material";
const SidebarItem = ({ item, isSidebarToggled, setShow = () => {} }) => {
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  let pathname = location.pathname;
  const isMobile = useMediaQuery("(max-width: 768px)");
  // remove '/' from starting of link
  pathname = pathname.slice(1);
  useEffect(() => {
    if (String(pathname).split("/")[0] === item?.key) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [pathname]);

  return (
    <StyledWrapper sx={{ mt: "15px" }}>
      <div className={open ? `sidebar-item open` : "sidebar-item"}>
        <Link
          to={item.link}
          className="link-hover"
          onClick={() => {
            setShowSubmenu(!showSubmenu);
            setOpen(!open);
          }}
        >
          <Stack direction="row" alignItems="center" sx={{ pl: 0.6 }}>
            <Box className="icon-wrapper">{item.icon}</Box>

            {isSidebarToggled && (
              <>
                <span
                  style={{
                    fontSize: "14px",
                    flexGrow: 1,
                    fontWeight: 400,
                    letterSpacing: "0.5px",
                  }}
                >
                  {item.name}
                </span>
                <span className="icon-wrapper-arrow">
                  <KeyboardArrowDownIcon
                    className={open ? "rotated" : "unrotate"}
                    style={{
                      fontSize: "14px",
                      marginBottom: "-5px",
                      marginRight: "5px",
                    }}
                  />
                </span>
              </>
            )}

            {/* for mobile */}

            {isMobile && (
              <>
                <span style={{ fontSize: "14px", flexGrow: 1 }}>
                  {item.name}
                </span>
                <span className="icon-wrapper">
                  <KeyboardArrowDownIcon
                    // size={10}
                    className={open ? "rotated" : "unrotate"}
                    style={{
                      fontSize: "14px",
                      marginBottom: "-5px",
                      marginRight: "5px",
                    }}
                  />
                </span>
              </>
            )}
          </Stack>
        </Link>
        <div className="sidebar-content">
          {item.subMenu.map((sub, i) => (
            <Link to={sub.link} onClick={() => setShow(false)} key={i}>
              <SubMenuItem
                key={sub.key}
                item={sub}
                pathname={pathname}
                open={open}
                isSidebarToggled={isSidebarToggled}
                isMobile={isMobile}
              />
            </Link>
          ))}
        </div>
      </div>
    </StyledWrapper>
  );
};

const SubMenuItem = ({ item, pathname, open, isSidebarToggled, isMobile }) => {
  return (
    <>
      <li className={pathname.includes(item.link) && open ? "active" : ""}>
        <div
          className={
            pathname.includes(item.link) && open
              ? isMobile
                ? ""
                : "before"
              : ""
          }
        ></div>
        <StyledWrapper className="sub-item">
          {isSidebarToggled && (
            <Stack direction="row" alignItems="center">
              <span className="icon-wrapper">{item.icon}</span>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  letterSpacing: "0.5px",
                }}
              >
                {item.name}
              </span>
            </Stack>
          )}
          {isMobile && (
            <div>
              <span className=" icon-wrapper">{item.icon}</span>
              <span style={{ fontSize: "14px" }}>{item.name}</span>
            </div>
          )}
        </StyledWrapper>
        <div
          className={
            pathname.includes(item.link) && open
              ? isMobile
                ? ""
                : "after"
              : ""
          }
        ></div>
      </li>
    </>
  );
};

export default SidebarItem;

const StyledWrapper = styled(Box)`
  .margin-3 {
    margin-left: 10px;
    margin-top: 5px;
  }
  .icon-wrapper {
    margin-bottom: -4px;
    margin-right: 2px;
    svg {
      height: 18px;
      width: 18px;
    }
  }
  .icon-wrapper-arrow {
    svg {
      height: 18px;
      width: 18px;
    }
  }
  .sidebar-item {
    margin: 0;
  }
  .sidebar-content {
    padding-top: 0.25rem;
    height: 0;
    transition: 0.4s;
  }

  .rotated {
    transform: rotate(0deg);
    transition: 0.5s;
  }
  .unrotate {
    transition: 0.5s;
    transform: rotate(-90deg);
  }

  .sub-item:hover {
    color: black;
    background: white;
  }
  .sub-item {
    padding-left: 20px;
    padding-top: 7px;
    padding-bottom: 7px;
    .icon-wrapper {
      margin-bottom: -3px;
      margin-right: 2px;
      svg {
        height: 18px;
        width: 18px;
      }
    }
  }

  .sidebar-item.open .sidebar-content {
    padding-top: 0.5rem;
    padding-bottom: 1.5rem;
    height: auto;
  }

  .before {
    position: absolute;
    margin-top: -20px;
    right: 0px;
    width: 20px;
    height: 20px;
    background: transparent;
    border-radius: 50%;
    box-shadow: 10px 10px 0 white;
    transition: 0.5s;
  }

  .after {
    position: absolute;
    right: 0px;
    width: 20px;
    height: 20px;
    background: transparent;
    border-radius: 50%;
    box-shadow: 10px -10px 0 white;
    transition: 0.5s;
  }
  .active {
    border-radius: 10px;
    background: white;
    transition: 0.5s;
  }

  .active span {
    color: black;
  }
`;
