import React, { useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { FormatFullName } from "app/utils/helper";
import demoLogo from "assets/media/images/NoticeBoard-images/demoLogo.png";
import profilePlaceHolder from "assets/media/images/idCard-Images/userImage.png";

const CARD_HEIGHT = "5.39cm";
const CARD_WIDTH = "8.56cm";

const GridContainer = ({ item, labelName, value, index, close = false }) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        position: "absolute",
        top: `${item?.y_axis}px` ?? "20px",
        left: `${item?.x_axis}px` ?? "20px",
      }}
    >
      <Grid
        item
        xs={close ? 1 : 4}
        display={"flex"}
        justifyContent={"flex-start"}
      >
        <Typography
          key={index}
          sx={{
            fontSize: `${item?.fontSize}px`,
            fontWeight: `${item?.fontWeight}`,
            color: item?.fontColor ?? "#000000",
            fontStyle: "bold",
          }}
        >
          {labelName ?? "labelName"}
        </Typography>
      </Grid>
      <Grid item xs={close ? 4 : 8}>
        <Typography
          key={index}
          noWrap
          sx={{
            fontSize: `${item?.fontSize}px`,
            fontWeight: `${item?.fontWeight}`,
            color: item?.fontColor ?? "#000000",
            fontStyle: `${item?.fontStyle}` ?? "normal",
          }}
        >
          {value ?? "--"}
        </Typography>
      </Grid>
    </Grid>
  );
};

const HorizontalStaffIdCard = ({
  data,
  value,
  idCardDetailLocation,
  schoolDetail,
  showPositionAbsolute = false, //for double scrolling issue
}) => {
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    if (value.startsWith("verticalIdCard")) {
      const index = parseInt(value.replace("verticalIdCard", "")) - 1;
      setRowData(data[index]);
    }
    if (value === "mainIdFormat") {
      setRowData(data);
    }
  }, [data, value]);
  const returnState = (text) => {
    try {
      if (String(text).includes("&")) {
        const spl = String(text).split("&");
        return `${spl[0].charAt(0).toUpperCase()}.${spl[1]
          .charAt(1)
          .toUpperCase()}`;
      } else {
        const isSpace = /\s/g.test(text);
        if (isSpace) {
          const spl = String(text).split(" ");

          return `${spl[0].charAt(0).toUpperCase()}.${spl[1]
            .charAt(0)
            .toUpperCase()}`;
        } else {
          return text;
        }
      }
    } catch (error) {}
  };

  const commonContainerStyles = {
    position: "absolute",
    width: "80%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  };
  return (
    <Wrapper>
      <Box
        sx={{
          position: !showPositionAbsolute ? "relative" : "absolute",
          height: CARD_HEIGHT,
          width: CARD_WIDTH,
          backgroundImage: `url(${idCardDetailLocation?.frameURL ?? ""})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        {/* for logo  */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "Logo") {
            return (
              <Typography
                key={index}
                sx={{
                  position: "absolute",
                  zIndex: 1,
                  top: `${item?.y_axis}px`,
                  left: `${item?.x_axis}px`,
                }}
              >
                <img
                  style={{
                    height: `100%`,
                    width: `${item?.fontSize}px`,
                    color: item?.fontColor ?? "#000000",
                    fontWeight: item?.fontWeight,
                    fontStyle: item?.fontStyle ?? "normal",
                    borderRadius: item?.shape === "circle" ? "50%" : "0",
                    objectFit: "contain",
                  }}
                  src={schoolDetail?.schoolLogo ?? demoLogo}
                  alt="logo"
                />
              </Typography>
            );
          }
          return null;
        })}

        {/* school Name */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "SchoolName") {
            return (
              <Typography
                key={index}
                sx={{
                  fontSize: `${item?.fontSize}px`,
                  fontWeight: `${item?.fontWeight}`,
                  position: "absolute",
                  zIndex: 2,
                  textAlign: "center",
                  color: item?.fontColor ?? "#000000",
                  top: `${item?.y_axis + 12}px` ?? 20,
                  left: `${item?.x_axis}px` ?? 20,
                  fontStyle: `${item?.fontStyle}` ?? "normal",
                }}
              >
                {schoolDetail?.schoolName ?? "EkalSutra Edtech Pvt. Ltd."}
              </Typography>
            );
          }
          return null;
        })}
        {/* profile pic */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "Profile Pic") {
            return (
              <Typography
                key={index}
                sx={{
                  position: "absolute",
                  zIndex: 1,
                  top: `${item?.y_axis}px`,
                  left: `${item?.x_axis}px`,
                }}
              >
                <img
                  style={{
                    height: `${item?.fontSize}px`,
                    width: `${item?.fontSize}px`,
                    color: item?.fontColor ?? "#000000",
                    fontWeight: item?.fontWeight,
                    fontStyle: item?.fontStyle ?? "normal",
                    borderRadius: item?.shape === "circle" ? "50%" : "0%",
                    objectFit: "contain",
                  }}
                  src={rowData?.profilePic ?? profilePlaceHolder}
                  alt="profilePic"
                />
              </Typography>
            );
          }
          return null;
        })}
        {/* staff name */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "StaffName") {
            const name = FormatFullName(
              rowData?.firstName,
              rowData?.middleName,
              rowData?.lastName
            );
            const fontSize =
              name.length > 20 ? "smallerFontSize" : `${item?.fontSize}px`;
            return (
              <div
                key={index}
                style={{
                  position: "absolute",
                  top: `${item?.y_axis}px` ?? "20px",
                  left: `${item?.x_axis}px` ?? "20px",
                  width: "100%",
                  // textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  // alignItems: "center",
                  // justifyContent: "center",
                }}
              >
                <Typography
                  key={index}
                  sx={{
                    fontSize: fontSize,
                    fontWeight: `${item?.fontWeight}`,
                    color: item?.fontColor ?? "#000000",
                    fontStyle: `${item?.fontStyle}` ?? "normal",
                  }}
                >
                  {FormatFullName(
                    rowData?.firstName,
                    rowData?.middleName,
                    rowData?.lastName
                  )}
                </Typography>
              </div>
            );
          }
          return null;
        })}
        {/* school contact number */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "School Contact Number") {
            return (
              <GridContainer
                item={item}
                labelName={item?.labelName ?? "Contact No:"}
                value={schoolDetail?.mobileNumber ?? "--"}
                index={index}
              />
            );
          }
        })}
        {/* staff dob */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "StaffDOB") {
            return (
              <GridContainer
                item={item}
                index={index}
                labelName={item?.labelName ?? "DOB"}
                value={moment(rowData?.dob ?? new Date()).format("DD-MM-YYYY")}
              />
            );
          }
          return null;
        })}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "Department") {
            return (
              <GridContainer
                item={item}
                index={index}
                labelName={item?.labelName ?? "Department"}
                value={rowData?.department ?? "--"}
              />
            );
          }
        })}

        {/* joining date */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "StaffJoiningDate") {
            return (
              <GridContainer
                item={item}
                index={index}
                labelName={"Joining Date"}
                value={moment(rowData?.joiningDate ?? new Date()).format(
                  "DD-MM-YYYY"
                )}
              />
            );
          }
        })}
        {/* mobile number */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "StaffMobileNumber") {
            return (
              <GridContainer
                item={item}
                index={index}
                labelName={item?.labelName ?? "Mobile Number"}
                value={rowData?.mobileNumber ?? "1234567890"}
              />
            );
          }
        })}
        {/* father name */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "StaffFatherName") {
            return (
              <GridContainer
                item={item}
                index={index}
                labelName={item?.labelName ?? "Father Name"}
                value={
                  rowData?.fatherInfo
                    ? FormatFullName(
                        rowData?.fatherInfo?.firstName,
                        rowData?.fatherInfo?.middleName,
                        rowData?.fatherInfo?.lastName
                      )
                    : "Father Name"
                }
              />
            );
          }
        })}
        {/* mother name */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "StaffMotherName") {
            return (
              <GridContainer
                item={item}
                index={index}
                labelName={item?.labelName ?? "Mother Name"}
                value={
                  rowData?.motherInfo
                    ? FormatFullName(
                        rowData?.motherInfo?.firstName,
                        rowData?.motherInfo?.middleName,
                        rowData?.motherInfo?.lastName
                      )
                    : "Mother Name"
                }
              />
            );
          }
        })}
        {/* adrreess */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "StaffAddress") {
            return (
              <GridContainer
                item={item}
                index={index}
                labelName={item?.labelName ?? "Address"}
                value={
                  rowData?.currentAddress
                    ? `${rowData?.currentAddress?.address}, ${
                        rowData?.currentAddress?.dist
                      }, ${returnState(rowData?.currentAddress?.state)}`
                    : "Address"
                }
              />
            );
          }
        })}
        {/* principale  */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "StaffPrincipalSign") {
            return (
              <Stack alignItems={"center"}>
                <img
                  style={{
                    position: "absolute",
                    zIndex: 1,
                    height: `${20}px`,
                    width: `100%`,
                    left: `${item?.x_axis}px` ?? "20px",
                    color: item?.fontColor ?? "#000000",
                    fontWeight: item?.fontWeight,
                    fontStyle: item?.fontStyle ?? "normal",
                    objectFit: "contain",
                    top: `${item?.y_axis}px`,
                  }}
                  src={schoolDetail?.signatureForIdCard}
                  alt="Sign"
                />
                <Typography
                  key={index}
                  sx={{
                    position: "absolute",
                    zIndex: 1,
                    width: "100%",
                    top: `${item?.y_axis}px`,
                    left: `${item?.x_axis}px` ?? "20px",
                    fontSize: `${item?.fontSize}px`,
                  }}
                >
                  Principle
                </Typography>
              </Stack>
            );
          }
        })}
        {/* school addresss */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "SchoolAddress") {
            return (
              <GridContainer
                item={item}
                index={index}
                labelName={item?.labelName ?? "School Address"}
                value={
                  schoolDetail?.SchoolAdress?.address
                    ? `${schoolDetail?.SchoolAdress?.address}, ${
                        schoolDetail?.SchoolAdress?.dist
                      }, ${returnState(schoolDetail?.SchoolAdress?.state)}`
                    : "School Address"
                }
              />
            );
          }
        })}

        {/* id card number */}
        {/* id card number */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "StaffIdCardNumber") {
            return (
              <GridContainer
                item={item}
                labelName={item?.labelName ?? "Id Card No"}
                value={rowData?.session ?? "--"}
                index={index}
                close={true}
              />
            );
          }
        })}

        {/* StaffEmployeeId */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "StaffEmployeeId") {
            return (
              <GridContainer
                item={item}
                index={index}
                labelName={item?.labelName ?? "Employee Id"}
                value={rowData?.employeeId ?? "Emp Id"}
              />
            );
          }
        })}
        {/*StaffEmploymentType*/}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "StaffEmploymentType") {
            return (
              <GridContainer
                item={item}
                index={index}
                labelName={item?.labelName ?? "Employment Type"}
                value={rowData?.employmentType ?? "--"}
              />
            );
          }
        })}
        {/*StaffGender*/}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "StaffGender") {
            return (
              <GridContainer
                item={item}
                index={index}
                labelName={item?.labelName ?? "Gender"}
                value={rowData?.gender ?? "--"}
              />
            );
          }
        })}

        {/*Blood Group*/}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "StaffBloodGroup") {
            return (
              <GridContainer
                item={item}
                index={index}
                labelName={item?.labelName ?? "Blood Group"}
                value={rowData?.bloodGroup ?? "--"}
              />
            );
          }
        })}
        {/*Reg No*/}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "StaffPAN") {
            return (
              <GridContainer
                item={item}
                index={index}
                labelName={item?.labelName ?? "PAN"}
                value={rowData?.panNo ?? "--"}
              />
            );
          }
        })}
        {/*StaffAadhar*/}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "StaffAadhar") {
            return (
              <GridContainer
                item={item}
                index={index}
                labelName={item?.labelName ?? "Aadhar"}
                value={rowData?.aadhar ?? "--"}
              />
            );
          }
        })}
        {/*StaffDesignation*/}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "StaffDesignation") {
            return (
              <GridContainer
                item={item}
                index={index}
                labelName={item?.labelName ?? "Designation"}
                value={rowData?.designation ?? "--"}
              />
            );
          }
        })}
      </Box>
    </Wrapper>
  );
};

export default HorizontalStaffIdCard;

const Wrapper = styled.div`
  height: ${CARD_HEIGHT};
  width: ${CARD_WIDTH};
`;
