import React, { useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { FormatFullName } from "app/utils/helper";
import demoLogo from "assets/media/images/NoticeBoard-images/demoLogo.png";
import profilePlaceHolder from "assets/media/images/idCard-Images/userImage.png";

const CARD_HEIGHT = "5.39cm";
const CARD_WIDTH = "8.56cm";

const GridContainer = ({
  item,
  showLabel = true,
  labelName,
  value,
  index,
  close = false,
}) => {
  return showLabel ? (
    <Grid
      container
      spacing={2}
      sx={{
        position: "absolute",
        top: `${item?.y_axis}px` ?? "20px",
        left: `${item?.x_axis}px` ?? "20px",
      }}
    >
      <Grid
        item
        xs={close ? 1 : 4}
        display={"flex"}
        justifyContent={"flex-start"}
      >
        <Typography
          key={index}
          sx={{
            fontSize: `${item?.fontSize}px`,
            fontWeight: `${item?.fontWeight}`,
            color: item?.fontColor ?? "#000000",
            fontStyle: "bold",
          }}
        >
          {labelName ?? "labelName"}
        </Typography>
      </Grid>
      <Grid item xs={close ? 4 : 4}>
        <Typography
          key={index}
          noWrap
          sx={{
            fontSize: `${item?.fontSize}px`,
            fontWeight: `${item?.fontWeight}`,
            color: item?.fontColor ?? "#000000",
            fontStyle: `${item?.fontStyle}` ?? "normal",
          }}
        >
          {value ?? "--"}
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <Grid
      container
      spacing={2}
      sx={{
        position: "absolute",
        top: `${item?.y_axis}px` ?? "20px",
        left: `${item?.x_axis}px` ?? "20px",
      }}
    >
      <Grid item xs={12}>
        <Typography
          key={index}
          noWrap
          sx={{
            fontSize: `${item?.fontSize}px`,
            fontWeight: `${item?.fontWeight}`,
            color: item?.fontColor ?? "#000000",
            fontStyle: `${item?.fontStyle}` ?? "normal",
          }}
        >
          {value ?? "--"}
        </Typography>
      </Grid>
    </Grid>
  );
};

const HorizontalCard = ({
  data,
  value,
  idCardDetailLocation,
  schoolDetail,
  showPositionAbsolute = false, //for double scrolling issue
}) => {
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    if (value.startsWith("verticalIdCard")) {
      const index = parseInt(value.replace("verticalIdCard", "")) - 1;
      setRowData(data[index]);
    }
    if (value === "mainIdFormat") {
      setRowData(data);
    }
  }, [data, value]);
  const returnState = (text) => {
    try {
      if (String(text).includes("&")) {
        const spl = String(text).split("&");
        return `${spl[0].charAt(0).toUpperCase()}.${spl[1]
          .charAt(1)
          .toUpperCase()}`;
      } else {
        const isSpace = /\s/g.test(text);
        if (isSpace) {
          const spl = String(text).split(" ");

          return `${spl[0].charAt(0).toUpperCase()}.${spl[1]
            .charAt(0)
            .toUpperCase()}`;
        } else {
          return text;
        }
      }
    } catch (error) {}
  };

  const commonContainerStyles = {
    position: "absolute",

    width: "80%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  };
  return (
    <Wrapper>
      <Box
        sx={{
          position: !showPositionAbsolute ? "relative" : "absolute",
          height: CARD_HEIGHT,
          width: CARD_WIDTH,
          backgroundImage: `url(${idCardDetailLocation?.frameURL ?? ""})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          border: "1px solid grey",
          margin: "10px",
        }}
      >
        {/* for logo and school name */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "Logo") {
            return (
              <Typography
                key={index}
                sx={{
                  position: "absolute",
                  zIndex: 1,
                  top: `${item?.y_axis}px`,
                  left: `${item?.x_axis}px`,
                }}
              >
                <img
                  style={{
                    height: `100%`,
                    width: `${item?.fontSize}px`,
                    color: item?.fontColor ?? "#000000",
                    fontWeight: item?.fontWeight,
                    fontStyle: item?.fontStyle ?? "normal",
                    borderRadius: item?.shape === "circle" ? "50%" : "0",
                    objectFit: "contain",
                  }}
                  src={schoolDetail?.schoolLogo ?? demoLogo}
                  alt="logo"
                />
              </Typography>
            );
          }
          return null;
        })}
        {/* school name */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "schoolName") {
            return (
              <Typography
                key={index}
                sx={{
                  fontSize: `${item?.fontSize}px`,
                  fontWeight: `${item?.fontWeight}`,
                  position: "absolute",
                  zIndex: 2,
                  textAlign: "center",
                  color: item?.fontColor ?? "#000000",
                  top: `${item?.y_axis}px` ?? 20,
                  left: `${item?.x_axis}px` ?? 20,
                  fontStyle: `${item?.fontStyle}` ?? "normal",
                }}
              >
                {schoolDetail?.schoolName ?? "EkalSutra Edtech Pvt. Ltd."}
              </Typography>
            );
          }
          return null;
        })}
        {/* profile pic */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "Profile Pic") {
            return (
              <Typography
                key={index}
                sx={{
                  position: "absolute",
                  zIndex: 1,
                  top: `${item?.y_axis}px`,
                  left: `${item?.x_axis}px`,
                }}
              >
                <img
                  style={{
                    height: `${item?.fontSize}px`,
                    width: `${item?.fontSize}px`,
                    color: item?.fontColor ?? "#000000",
                    fontWeight: item?.fontWeight,
                    fontStyle: item?.fontStyle ?? "normal",
                    borderRadius: item?.shape === "circle" ? "50%" : "0%",
                    objectFit: "contain",
                  }}
                  src={rowData?.profilePic ?? profilePlaceHolder}
                  alt="profilePic"
                />
              </Typography>
            );
          }
          return null;
        })}
        {/* name */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "Name") {
            const name = FormatFullName(
              rowData?.firstName,
              rowData?.middleName,
              rowData?.lastName
            );

            return (
              <div
                key={index}
                style={{
                  position: "absolute",
                  top: `${item?.y_axis}px` ?? "20px",
                  left: `${item?.x_axis}px` ?? "20px",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  key={index}
                  sx={{
                    fontSize: item?.fontSize ?? "12px",
                    fontWeight: `${item?.fontWeight}`,
                    color: item?.fontColor ?? "#000000",
                    fontStyle: `${item?.fontStyle}` ?? "normal",
                    flexWrap: "wrap",
                  }}
                >
                  {FormatFullName(
                    rowData?.firstName,
                    rowData?.middleName,
                    rowData?.lastName
                  )}
                </Typography>
              </div>
            );
          }
          return null;
        })}
        {/* DOB */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "DOB") {
            return (
              <>
                <GridContainer
                  item={item}
                  labelName={item?.labelName ?? "DOB"}
                  value={
                    rowData?.DOB
                      ? moment(rowData?.DOB).format("DD/MM/YYYY")
                      : "--"
                  }
                  index={index}
                />
              </>
            );
          }
          return null;
        })}
        {/* class */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "Class") {
            return (
              <GridContainer
                item={item}
                labelName={item?.labelName ?? "Class"}
                showLabel={false}
                value={`${rowData?.className ?? " "}-${
                  rowData?.stream ?? " "
                }-${rowData?.section ?? " "}`}
                index={index}
              />
            );
          }
        })}
        {/* admission number */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "Admission Number") {
            return (
              <GridContainer
                item={item}
                labelName={item?.labelName ?? "Adm No"}
                value={rowData?.admissionNumber ?? "1234567890"}
                index={index}
              />
            );
          }
        })}
        {/* mobile number */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "Mobile Number") {
            return (
              <GridContainer
                item={item}
                labelName={item?.labelName ?? "Mobile"}
                value={rowData?.mobileNumber ?? "1234567890"}
                index={index}
              />
            );
          }
        })}
        {/* father name */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "Father Name") {
            return (
              <GridContainer
                item={item}
                labelName={item?.labelName ?? "Father Name"}
                value={
                  rowData?.fatherInfo
                    ? FormatFullName(
                        rowData?.fatherInfo?.firstName,
                        rowData?.fatherInfo?.middleName,
                        rowData?.fatherInfo?.lastName
                      )
                    : "Father Name"
                }
                index={index}
              />
            );
          }
        })}
        {/* mother name */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "Mother Name") {
            return (
              <GridContainer
                item={item}
                labelName={item?.labelName ?? "Mother Name"}
                value={
                  rowData?.motherInfo
                    ? FormatFullName(
                        rowData?.motherInfo?.firstName,
                        rowData?.motherInfo?.middleName,
                        rowData?.motherInfo?.lastName
                      )
                    : "Mother Name"
                }
                index={index}
              />
            );
          }
        })}
        {/* address */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "Address") {
            return (
              <GridContainer
                item={item}
                labelName={item?.labelName ?? "Address"}
                value={
                  rowData?.currentAddress
                    ? `${rowData?.currentAddress?.address} ${
                        rowData?.currentAddress?.dist
                      } ${returnState(rowData?.currentAddress?.state)} ${
                        rowData?.currentAddress?.pinCode
                      }`
                    : "Address"
                }
                index={index}
              />
            );
          }
        })}
        {/* school contact number */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "School Contact Number") {
            return (
              <GridContainer
                item={item}
                labelName={item?.labelName ?? "Contact No:"}
                value={schoolDetail?.mobileNumber ?? "--"}
                index={index}
              />
            );
          }
        })}
        {/* principal sign */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "Principal Sign") {
            return (
              <>
                <img
                  style={{
                    position: "absolute",
                    zIndex: 1,
                    height: `${20}px`,
                    width: `20%`,
                    left: `${item?.x_axis}px` ?? "20px",
                    color: item?.fontColor ?? "#000000",
                    fontWeight: item?.fontWeight,
                    fontStyle: item?.fontStyle ?? "normal",
                    objectFit: "contain",
                    top: `${item?.y_axis - 20}px`,
                  }}
                  src={schoolDetail?.signatureForIdCard}
                  alt="Sign"
                />
                <Typography
                  key={index}
                  sx={{
                    position: "absolute",
                    zIndex: 1,
                    top: `${item?.y_axis}px`,
                    left: `${item?.x_axis}px` ?? "20px",
                    fontSize: `${item?.fontSize}px`,
                  }}
                >
                  Principle
                </Typography>
              </>
            );
          }
        })}
        {/* transport */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "Transport") {
            return (
              <Stack
                style={{
                  position: "absolute",
                  top: `${item?.y_axis}px` ?? "20px",
                  left: `${item?.x_axis}px` ?? "20px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <Typography
                  key={index}
                  sx={{
                    fontSize: `${item?.fontSize}px`,
                    fontWeight: `${item?.fontWeight}`,
                    textAlign: "center",
                    color: item?.fontColor ?? "#000000",
                    fontStyle: "bold",
                  }}
                >
                  {"Transport"}
                </Typography>
                <Typography
                  key={index}
                  sx={{
                    fontSize: `${item?.fontSize}px`,
                    fontWeight: `${item?.fontWeight}`,
                    color: item?.fontColor ?? "#000000",
                    fontStyle: `${item?.fontStyle}` ?? "normal",
                  }}
                >
                  {rowData?.transportInfo?.using ?? "No"}
                </Typography>
              </Stack>
            );
          }
        })}
        {/* school address */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "School Address") {
            return (
              <Stack
                style={{
                  position: "absolute",
                  top: `${item?.y_axis}px` ?? "20px",
                  left: `${item?.x_axis}px` ?? "20px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  key={index}
                  sx={{
                    fontSize: `${item?.fontSize}px`,
                    fontWeight: `${item?.fontWeight}`,
                    color: item?.fontColor ?? "#000000",
                    fontStyle: `${item?.fontStyle}` ?? "normal",
                  }}
                >
                  {schoolDetail?.schoolAddress?.address}
                  {schoolDetail?.schoolAddress?.dist}{" "}
                  {returnState(schoolDetail?.schoolAddress?.state)},{" "}
                  {schoolDetail?.schoolAddress?.pinCode}
                </Typography>
              </Stack>
            );
          }
        })}

        {/* id card number */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "Id Card Number") {
            return (
              <GridContainer
                item={item}
                labelName={item?.labelName ?? "Id Card Number"}
                value={rowData?.session ?? "1234567890"}
                index={index}
                close={true}
              />
            );
          }
        })}

        {/* admission date */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "Admission Date") {
            return (
              <GridContainer
                item={item}
                labelName={item?.labelName ?? "Adm Date"}
                value={
                  rowData?.admissionDate
                    ? moment(rowData?.admissionDate).format("DD/MM/YYYY")
                    : "--"
                }
                index={index}
              />
            );
          }
        })}
        {/*Roll No */}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "Roll No") {
            return (
              <GridContainer
                item={item}
                labelName={item?.labelName ?? "Roll No"}
                value={rowData?.rollNumber ?? "--"}
                index={index}
              />
            );
          }
        })}
        {/*Gender*/}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "Gender") {
            return (
              <GridContainer
                item={item}
                labelName={item?.labelName ?? "Gender"}
                value={rowData?.gender ?? "--"}
                index={index}
              />
            );
          }
        })}
        {/*Blood Group*/}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "Blood Group") {
            return (
              <GridContainer
                item={item}
                labelName={item?.labelName ?? "Blood Group"}
                value={rowData?.bloodGroup ?? "--"}
                index={index}
              />
            );
          }
        })}
        {/*Reg No*/}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "Reg No") {
            return (
              <GridContainer
                item={item}
                labelName={item?.labelName ?? "Reg No"}
                value={rowData?.registrationNumber ?? "--"}
                index={index}
              />
            );
          }
        })}
        {/*Sr  No*/}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "SR No") {
            return (
              <GridContainer
                item={item}
                labelName={item?.labelName ?? "SR No"}
                value={rowData?.srNumber ?? "--"}
                index={index}
              />
            );
          }
        })}
        {/*Pen No*/}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "PEN No") {
            return (
              <GridContainer
                item={item}
                labelName={item?.labelName ?? "PEN No"}
                value={rowData?.penNumber ?? "--"}
                index={index}
              />
            );
          }
        })}
        {/*Admission Type*/}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "Admission Type") {
            return (
              <GridContainer
                item={item}
                labelName={item?.labelName ?? "Admission Type"}
                value={rowData?.admissionType ?? "--"}
                index={index}
              />
            );
          }
        })}
        {/*Relaxation Category*/}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "Fee Relaxation Category") {
            return (
              <GridContainer
                item={item}
                labelName={item?.labelName ?? "Fee Relaxation Category"}
                value={rowData?.feeRelaxationCategory ?? "--"}
                index={index}
              />
            );
          }
        })}
        {/*House*/}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "House") {
            return (
              <GridContainer
                item={item}
                labelName={item?.labelName ?? "House"}
                value={
                  FormatFullName(
                    rowData?.house?.houseName,
                    rowData?.house?.houseColor,
                    rowData?.house?.membershipRole
                  ) ?? "--"
                }
                index={index}
              />
            );
          }
        })}
        {/*Aadhar*/}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "Aadhar") {
            return (
              <GridContainer
                item={item}
                labelName={item?.labelName ?? "Aadhar"}
                value={rowData?.AADHAR ?? "--"}
                index={index}
              />
            );
          }
        })}
        {/*Categoru*/}
        {idCardDetailLocation?.contents?.map((item, index) => {
          if (item?.labelKeyName === "Category") {
            return (
              <GridContainer
                item={item}
                labelName={item?.labelName ?? "Category"}
                value={rowData?.category ?? "--"}
                index={index}
              />
            );
          }
        })}
      </Box>
    </Wrapper>
  );
};

export default HorizontalCard;

const Wrapper = styled.div`
  height: ${CARD_HEIGHT};
  width: ${CARD_WIDTH};
`;
