import axios from "axios";
import { axiosInstance } from "../axios";
const AUTH_URL = process.env.REACT_APP_AUTH_BASE;
const ORG_URL = process.env.REACT_APP_ORG_BASE;

export const login = (payload) => {
  return axios.post(`${AUTH_URL}/auth/signin`, payload);
};

export const getUserProfile = () => {
  return axiosInstance.get(`${ORG_URL}/auth/getProfile`);
};
export const register = (payload) => {
  return axios.post(`${AUTH_URL}/auth/staffSignup`, payload);
};

export const getPhoneOtp = (mobileNo) => {
  return axios.post(`${AUTH_URL}/auth/getOtpPhone/${mobileNo}`);
};

export const getEmailOtp = (email) => {
  return axios.post(`${AUTH_URL}/auth/registrationOTPonEmail/${email}`);
};

export const otpVerify = (data) => {
  return axios.put(`${AUTH_URL}/auth/verifyOTP`, data);
};

export const forgotPasswordMailSend = (email) => {
  return axios.get(`${AUTH_URL}/auth/forgetPassword/${email}`);
};

export const changePassword = (data) => {
  return axios.put(`${AUTH_URL}/auth/changePassword`, data);
};
export const changePasswordWithoutOtp = (payload) => {
  return axiosInstance.put(
    `${AUTH_URL}/auth/changePasswordWithoutOtp`,
    payload
  );
};
