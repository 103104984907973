import Fallback from "app/components/Fallback";
import React from "react";
import IDCardTablePage from "./IDCardTablePage";

const IDCard = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <IDCardTablePage {...props} />
  </React.Suspense>
);

export default IDCard;
