import React, { useState } from "react";

import Header from "./Header";
import Sidebar from "./Sidebar";

import { BuildingGear, Building } from "react-bootstrap-icons";
import {
  Box,
  Stack,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
  styled,
} from "@mui/material";
import MobileHeader from "./MobileHeader";
import MobileSidebar from "./MobileSidebar";
import { Link } from "react-router-dom";
import useSelectorHook from "app/services/redux/useSelectorHook";
const bottomStripHeight = "25px";

const Layout = ({ children }) => {
  const [isSidebarToggled, setIsSidebarToggled] = useState(true);
  const [show, setShow] = useState(false);
  const { isAuthenticated } = useSelectorHook();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const handleOnClickHamburgeMenu = () => {
    setIsSidebarToggled(!isSidebarToggled);
  };
  return (
    <StyledWrapper id="layout">
      {isAuthenticated ? (
        isMobile ? (
          <>
            <MobileHeader setShow={setShow} />
          </>
        ) : (
          <div>
            <Header handleOnClickHamburgeMenu={handleOnClickHamburgeMenu} />
          </div>
        )
      ) : null}
      {isMobile ? (
        <>
          <SwipeableDrawer
            open={show}
            onClose={() => {
              setShow(false);
            }}
            onOpen={() => {
              setShow(true);
            }}
            anchor="left"
          >
            <MobileSidebar setShow={setShow} />
          </SwipeableDrawer>

          <Box
            sx={{
              overflowY: "auto",
              height: "calc(100vh - 80px)",
              mt: "60px",
              p: 1.5,
            }}
          >
            {children}
          </Box>
        </>
      ) : (
        <>
          <Stack direction="row">
            <Box
              sx={{
                display: !isSidebarToggled ? "none" : "block",
                boxShadow: 3,
              }}
              className="sidebar-wrapper"
            >
              {isAuthenticated ? (
                <Sidebar isSidebarToggled={isSidebarToggled} />
              ) : null}
            </Box>

            <Box sx={{ width: "100%" }}>
              <Box
                className="for-scroll"
                sx={{
                  paddingTop: "20px",
                  px: 1.5,
                  height: "calc(100vh - 120px)",
                  overflowY: "auto",
                  pb: bottomStripHeight,
                }}
              >
                {children}
              </Box>
            </Box>
          </Stack>
          {/* bottom Strip */}
          <Box
            sx={{
              backgroundColor: "#f9f9f9",
              width: "100%",
              height: bottomStripHeight,
              position: "fixed",
              bottom: 0,
              borderTop: "1px solid rgba(0, 0, 0,0.3)",
              zIndex: 1000,
            }}
          >
            <Stack
              direction="row"
              justifyContent="flex-end"
              gap={2}
              alignItems="center"
              height="100%"
              sx={{ px: 2 }}
            >
              <Stack
                direction="row"
                gap={0.5}
                component={Link}
                to="/schools/about-school"
                sx={{ textDecoration: "none" }}
                alignItems="center"
              >
                <BuildingGear style={{ color: "#000", opacity: 0.8 }} />
                <Typography sx={{ color: "primary.main", fontWeight: 400 }}>
                  About School
                </Typography>
              </Stack>
              <Stack
                direction="row"
                gap={0.5}
                component={Link}
                to="/schools/school-profile"
                sx={{ textDecoration: "none" }}
                alignItems="center"
              >
                <Building style={{ color: "#000", opacity: 0.8 }} />
                <Typography sx={{ color: "primary.main", fontWeight: 400 }}>
                  School Profile
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </>
      )}
    </StyledWrapper>
  );
};

export default Layout;

const StyledWrapper = styled("div")`
  .for-scroll {
    ::-webkit-scrollbar {
      width: 1em;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    ::-webkit-scrollbar-thumb {
      background-color: rgba(8, 76, 132, 1);
      /* outline: 1px solid rgba(8, 76, 132, 1); */
      border-radius: 10px;
    }
  }
  /* background-color: #fbfbff; */
  background-color: #f0f2f5;
  .bg-blue {
    background: blue;
  }
  ul {
    padding: 0px;
  }
  .sidebar-wrapper {
    background: #084c84;
    margin: 0;
    height: "100%";

    transition: 0.4s;
  }
`;
