import { sidebarItems } from "app/config/sidebar";
import { useMemo } from "react";
import useSelectorHook from "app/services/redux/useSelectorHook";
export const useSidebarMenuItems = () => {
  const { roles } = useSelectorHook();
  const sidebarOptions = {};
  return useMemo(() => {
    return sidebarItems;
    // Initialize sidebarItems
    let modules_list = [];

    // if sidebarOptions is not empty
    if (sidebarOptions) {
      modules_list = Object.keys(sidebarOptions);
    }

    // return sidebarItems;
    return sidebarItems.reduce((final_sidebarItems, item) => {
      // Menu item
      const menuItem = { ...item };
      // check if subMenu is present
      if (item.subMenu && item.subMenu.length > 0) {
        menuItem.subMenu = item.subMenu.filter((subItem) => {
          // if module and screenName is present in sidebarOptions
          if (
            modules_list.includes(subItem.module) &&
            sidebarOptions[subItem.module].includes(subItem.screenName)
          ) {
            return subItem;
          }
        });

        final_sidebarItems.push(menuItem);
      } else {
        // if subMenu is not present
        if (modules_list.includes(item.module)) {
          // push meun item in final_sidebarItems
          final_sidebarItems.push(menuItem);
        }
      }
      return [];
    }, []);
  }, [roles]);
};
