import { useSelector } from "react-redux";

const useSelectorHook = () => {
  const { login_Data, userDetail, isAuthenticated } = useSelector(
    (store) => store.auth
  );
  const { selectedSession } = useSelector((store) => store.commonStore);
  return {
    userDocId: login_Data?.userDocId,
    isAuthenticated,
    userDetail,
    roles: login_Data?.roles,
    selectedSession,
  };
};

export default useSelectorHook;
