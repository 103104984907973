import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import styled from "styled-components";
import {
  MenuItem,
  DialogActions,
  Grid,
  Typography,
  Stack,
  Checkbox,
} from "@mui/material";
import { useMutation } from "react-query";

import TextFieldLabel from "app/components/common/TextFieldLabel";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { MenuProps } from "app/utils/helper";
import { updateAddressInfo } from "app/services/auth/user.service";
import { Distrct, States } from "app/config/administrativeConstants";

const AddressInfoModal = ({
  handleCloseModal,
  staffDetails,
  getUserDetail,
}) => {
  const validationSchema = yup.object({
    c_address: yup
      .string("Enter Address")
      .required("Current Address is Required"),
    c_pinCode: yup.string("Enter Pincode").required("Pincode is Required"),
    c_state: yup.string("Enter State").required("Current State is Required"),
    c_district: yup
      .string("Enter District")
      .required("Current District is Required"),
    p_address: yup
      .string("Enter Address")
      .required("Permanent Address is Required"),
    p_pinCode: yup.string("Enter Pincode").required("Pincode is Required"),
    p_state: yup.string("Enter State").required("Permanent State is Required"),
    p_district: yup
      .string("Enter District")
      .required("Permanent District is Required"),
  });

  const { mutate, isLoading: updateAddressnInfoLoading } = useMutation(
    updateAddressInfo,
    {
      onSuccess: ({ data }) => {
        getUserDetail();
        toast.success(data?.message);
        handleCloseModal();
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
      retry: false,
    }
  );
  const formik = useFormik({
    initialValues: {
      c_address: "",
      c_pinCode: "",
      c_state: "",
      c_district: "",
      p_address: "",
      p_pinCode: "",
      p_state: "",
      p_district: "",
      sameas: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const newAddressDetails = {
        currentAddress: {
          address: values.c_address,
          dist: values.c_district,
          state: values.c_state,
          pinCode: values.c_pinCode,
        },
        permanentAddress: {
          address: values.p_address,
          dist: values.p_district,
          state: values.p_state,
          pinCode: values.p_pinCode,
        },
      };
      mutate(newAddressDetails);
    },
  });
  const handleChangeSameAs = (e) => {
    if (formik.values.sameas) {
      formik.setValues((prev) => ({
        ...prev,
        p_address: "",
        p_pinCode: "",
        p_state: "",
        p_district: "",
        sameas: false,
      }));
    } else {
      formik.setValues((prev) => ({
        ...prev,
        sameas: true,
        p_address: prev.c_address,
        p_pinCode: prev.c_pinCode,
        p_state: prev.c_state,
        p_district: prev.c_district,
      }));
    }
  };
  useEffect(() => {
    if (staffDetails?.currentAddress) {
      formik.setValues((prev) => ({
        ...prev,
        c_address: staffDetails?.currentAddress?.address,
        c_pinCode: staffDetails?.currentAddress?.pinCode,
        c_state: staffDetails?.currentAddress?.state,
        c_district: staffDetails?.currentAddress?.dist,
        p_address: staffDetails?.permanentAddress?.address,
        p_pinCode: staffDetails?.permanentAddress?.pinCode,
        p_state: staffDetails?.permanentAddress?.state,
        p_district: staffDetails?.permanentAddress?.dist,
      }));
    }
  }, []);

  return (
    <Wrapper>
      <form onSubmit={formik.handleSubmit}>
        <Typography
          sx={{
            fontSize: "16px",
            color: (theme) => theme.palette.primary.main,
            fontWeight: 600,
            mb: 1,
          }}
        >
          Current Address
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextFieldLabel title=" Address" />
            <CustomTextField
              id="c_address"
              name="c_address"
              placeholder="Type address"
              fullWidth
              size="small"
              value={formik.values.c_address}
              onChange={formik.handleChange}
              error={
                formik.touched.c_address && Boolean(formik.errors.c_address)
              }
              helperText={formik.touched.c_address && formik.errors.c_address}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} sx={{ pt: 1 }}>
          <Grid item xs={4}>
            <TextFieldLabel title="Pin Code" />
            <CustomTextField
              id="c_pinCode"
              name="c_pinCode"
              placeholder="Type pin code"
              fullWidth
              size="small"
              value={formik.values.c_pinCode}
              onChange={formik.handleChange}
              inputProps={{
                maxLength: 6,
              }}
              error={
                formik.touched.c_pinCode && Boolean(formik.errors.c_pinCode)
              }
              helperText={formik.touched.c_pinCode && formik.errors.c_pinCode}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldLabel title="State" />
            <CustomSelectBox
              id="c_state"
              displayEmpty
              required
              fullWidth
              size="small"
              value={formik.values.c_state}
              name="c_state"
              onChange={formik.handleChange}
              MenuProps={MenuProps}
            >
              <MenuItem disabled value="">
                Select
              </MenuItem>
              {States.map((state, index) => (
                <MenuItem key={state} value={state}>
                  {state}
                </MenuItem>
              ))}
            </CustomSelectBox>
          </Grid>
          <Grid item xs={4}>
            <TextFieldLabel title="District" />
            <CustomSelectBox
              displayEmpty
              id="c_district"
              required
              size="small"
              fullWidth
              value={formik.values.c_district}
              name="c_district"
              onChange={formik.handleChange}
              MenuProps={MenuProps}
            >
              <MenuItem disabled value="">
                Select
              </MenuItem>
              {formik.values.c_state &&
                Distrct[States.indexOf(formik.values.c_state) + 1].map(
                  (district) => (
                    <MenuItem key={district} value={district}>
                      {district}
                    </MenuItem>
                  )
                )}
            </CustomSelectBox>
          </Grid>
        </Grid>
        <Stack direction="row" alignItems="center" sx={{ my: 1.5 }}>
          <Checkbox
            inputProps={{ "aria-label": "controlled" }}
            onChange={handleChangeSameAs}
            checked={formik.values.sameas}
          />
          <Typography>Same as Above</Typography>
        </Stack>
        <Typography
          sx={{
            fontSize: "16px",
            color: (theme) => theme.palette.primary.main,
            fontWeight: 600,
            mt: 2,
          }}
        >
          Permanent Address
        </Typography>
        <Grid container spacing={1} sx={{ pt: 1 }}>
          <Grid item xs={6}>
            <TextFieldLabel title="Address" />
            <CustomTextField
              id="p_address"
              name="p_address"
              fullWidth
              placeholder="Type address"
              size="small"
              value={formik.values.p_address}
              onChange={formik.handleChange}
              error={
                formik.touched.p_address && Boolean(formik.errors.p_address)
              }
              helperText={formik.touched.p_address && formik.errors.p_address}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ pt: 1 }}>
          <Grid item xs={4}>
            <TextFieldLabel title="Pin Code" />
            <CustomTextField
              id="p_pinCode"
              name="p_pinCode"
              size="small"
              placeholder="Type pin code"
              fullWidth
              value={formik.values.p_pinCode}
              onChange={formik.handleChange}
              error={
                formik.touched.p_pinCode && Boolean(formik.errors.p_pinCode)
              }
              helperText={formik.touched.p_pinCode && formik.errors.p_pinCode}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldLabel title="State" />
            <CustomSelectBox
              displayEmpty
              id="p_state"
              required
              value={formik.values.p_state}
              name="p_state"
              onChange={formik.handleChange}
              size="small"
              fullWidth
              MenuProps={MenuProps}
            >
              <MenuItem disabled value="">
                Select
              </MenuItem>
              {States.map((state, index) => (
                <MenuItem key={state} value={state}>
                  {state}
                </MenuItem>
              ))}
            </CustomSelectBox>
          </Grid>
          <Grid item xs={4}>
            <TextFieldLabel title="District" />
            <CustomSelectBox
              size="small"
              id="p_district"
              required
              value={formik.values.p_district}
              name="p_district"
              onChange={formik.handleChange}
              fullWidth
              displayEmpty
              MenuProps={MenuProps}
            >
              <MenuItem disabled value="">
                Select
              </MenuItem>
              {formik.values.p_state &&
                Distrct[States.indexOf(formik.values.p_state) + 1].map(
                  (district) => (
                    <MenuItem key={district} value={district}>
                      {district}
                    </MenuItem>
                  )
                )}
            </CustomSelectBox>
          </Grid>
        </Grid>

        <DialogActions>
          <LoadingButton
            loading={updateAddressnInfoLoading}
            color="secondary"
            variant="contained"
            type="submit"
            size="large"
            sx={{ textTransform: "capitalize" }}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Wrapper>
  );
};

export default AddressInfoModal;

const Wrapper = styled.div``;

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const Content = styled.div`
  align-items: left;
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin: 10px;
`;
