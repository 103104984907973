import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SidebarItem from "./components/SidebarItem";
import { useSidebarMenuItems } from "./hooks";
import styled from "styled-components";
import SearcMenuInput from "./components/SearcMenuInput";
import { Stack } from "@mui/material";
const Sidebar = ({ isSidebarToggled }) => {
  const menuItems = useSidebarMenuItems();
  const location = useLocation();
  const pathname = location.pathname;

  return (
    <>
      <StyledWrapper>
        <div
          className={
            isSidebarToggled
              ? "side-nav-container"
              : "side-nav-container side-nav-container-NX"
          }
        >
          <div className="nav-upper">
            <div className="nav-menu">
              <SearcMenuInput />
              <div className="ul">
                {menuItems.map((item, index) => (
                  <>
                    {item.subMenu && item.subMenu.length > 0 ? (
                      <>
                        <div className="ul-li" role="button" key={index}>
                          <SidebarItem
                            key={item.key}
                            item={item}
                            isSidebarToggled={isSidebarToggled}
                          />
                        </div>
                      </>
                    ) : (
                      <div
                        className={
                          pathname.includes(item.link)
                            ? "ul-li active-link "
                            : "ul-li"
                        }
                        key={item.key}
                        role="button"
                      >
                        <Link to={item.link} className="link-hover">
                          <Stack
                            direction="row"
                            alignItems="center"
                            style={{ paddingLeft: "5px" }}
                            sx={{ py: 1.5, pl: "5px" }}
                          >
                            <span className="icon-wrapper">{item.icon}</span>
                            {isSidebarToggled && (
                              <span style={{ fontSize: "14px" }}>
                                {item.name}
                              </span>
                            )}
                          </Stack>
                        </Link>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </StyledWrapper>
    </>
  );
};

export default Sidebar;

const StyledWrapper = styled.div`
  background: #084c84;

  li:not(.active-link) {
    .sub-menu {
      display: none !important;
    }
  }
  .side-nav-container {
    background: #084c84;
    height: 88vh;
    overflow-y: auto;
    position: relative;
    color: white;
    transition: 0.4s;
    border-radius: 0 0 20px 0;
    /* hide scroll bar */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .side-nav-container-NX {
    width: 56px;
  }

  .nav-brand-img {
    margin: -30px 10% 0 25%;
  }
  .nav-brand-img-EX {
    width: 70%;
    height: 70%;
    transition: 0.4s;
    margin: 10px;
  }

  .hamburger_opened {
    position: absolute;
    right: 0;
    background: none;
    border: none;
    cursor: pointer;
    margin: -30px -35px 10px -5px;
    background-color: #6b728e;
    border: 0px solid grey;
    padding: 10px;
    border-radius: 0px 5px 5px 0px;
    height: 70px;
    transition: 0.4s;
    width: 35px;
  }
  .hamburger_closed {
    position: absolute;
    right: 0;
    background: none;
    border: none;
    cursor: pointer;
    margin: -30px -35px 10px -5px;
    background-color: #6b728e;
    border: 0px solid grey;
    padding: 10px;
    border-radius: 0px 5px 5px 0px;
    height: 50px;
    transition: 0.4s;
    width: 35px;
  }

  .rotate-btn {
    margin-left: -5px;
    transition: 0.4s;
    transform: rotate(180deg);
  }
  .unrotate-btn {
    margin-left: -5px;
    transition: 0.4s;
  }
  .hamburger span {
    display: block;
    margin-top: 5px;
    background-color: white;
    border-radius: 15px;
    height: 2px;
    width: 30px;
  }

  .nav-menu {
    grid-template-rows: repeat(7, 1fr);
    margin-top: 7px;
  }
  .menu-item {
    height: 57px;
    display: flex;
    color: var(--light);
    text-decoration: none;
    text-transform: uppercase;
    margin: auto 20px;
    border-radius: 10px;
  }

  .menu-item-NX {
    margin: auto;
  }
  .menu-item:hover {
    background-color: var(--primary);
  }
  .menu-item img {
    width: 30px;
    padding: 0 20px;
  }

  .nav-footer {
    width: 100%;
    height: 87px;
    position: absolute;
    bottom: 0;
    grid-template-rows: 1fr;
    grid-template-columns: 2fr 1fr;
  }

  .nav-details {
    display: flex;
  }
  .nav-details img {
    width: 50px;
    padding: 0 20px;
  }
  .icon-wrapper {
    margin-bottom: -4px;
    margin-right: 2px;
    svg {
      height: 18px;
      width: 18px;
    }
  }

  .ul {
    background: rgb(8, 76, 132);
    padding: 0px 0 0 0px;
    width: 100%;
    transition: 0.5s;
    overflow: hidden;
  }

  .ul-li {
    list-style: none;
    color: white;
    font-size: 15px;
    margin-bottom: 5px;
    cursor: pointer;
    position: relative;
    transition: 0s;
  }
  .ul-li a:hover {
    color: #2789fd;
  }
  .ul-li a {
    text-decoration: none;
    color: white;
  }
  .ul-li ul li :hover {
    color: black;
  }
  img {
    width: 7rem;
    height: 7rem;
    margin: 0 4rem 3rem 4rem;
  }
  .ul:hover {
    width: 100%;
  }

  .sidebar-content {
    padding-top: 0.25rem;
    height: 0;
    overflow: hidden;
  }

  .ul-li.open .sidebar-content {
    padding-top: 0.25rem;
    height: 50px;
  }

  .active-link:before {
    content: "";
    position: absolute;
    top: -20px;
    right: 0px;
    width: 20px;
    height: 20px;
    background: transparent;
    border-radius: 50%;
    box-shadow: 10px 10px 0 white;
    transition: 0.5s;
  }

  .active-link:after {
    content: "";
    position: absolute;
    bottom: -20px;
    right: 0px;
    width: 20px;
    height: 20px;
    background: transparent;
    border-radius: 50%;
    box-shadow: 10px -10px 0 white;
    transition: 0.5s;
  }
  .active-link {
    background: white;
    transition: 0.5s;
  }

  .active-link a {
    color: black;
  }

  @include media-breakpoint-up(sm) {
    max-width: 100px;
  }
`;
