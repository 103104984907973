import { Typography } from "@mui/material";
import React from "react";

const CustomErrorText = ({ text }) => {
  return (
    <Typography color="error" fontSize={"11px"}>
      {text}
    </Typography>
  );
};

export default CustomErrorText;
